import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody } from 'reactstrap';
import ReactTable from "react-table";
import { toast } from 'react-toastify';
import matchSorter from 'match-sorter'
import { withTranslation } from "react-i18next";

import ActionsMenu from './ActionsMenu';
import PageTitle from 'Layout/Components/PageTitle';
import ConfirmAction from 'Layout/Components/ConfirmAction';

import { CampaignContext } from './CampaignContext';

class Campaigns extends React.Component {

  static contextType = CampaignContext;

  constructor(props) {
    super(props);

    this.state = {
      filtered: [],
      filterAll: ""
    };

    this.searchCampaign = this.searchCampaign.bind(this);
  }

  searchCampaign(name) {
    this.setState({
      filtered: [{ id: 'actions', value: name }],
      filterAll: name
    });
  }

  render() {
    const { t, i18n, history } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("campaigns.title")}
          icon="pe-7s-speaker icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <ActionsMenu doSearch={this.searchCampaign} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    columns={[
                      {
                        Header: t("campaigns.id"),
                        accessor: 'Key'
                      },
                      {
                        Header: t("campaigns.description"),
                        accessor: 'Description',
                        id: 'Description'
                      },
                      {
                        Header: t("campaigns.actions.title"),
                        id: 'actions',
                        accessor: 'Key',
                        width: 300,
                        Cell: (prop) => (
                          <div className="d-block w-100 text-center">
                            <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                              message={t('messages.confirm_delete', { element: 'campaign', object: prop.value })}
                              onConfirm={() => this.context.deleteCampaign(prop.value)}>{t("campaigns.actions.delete")}</ConfirmAction>
                          </div>),
                        filterMethod: (filter, rows) => {
                          const result = matchSorter(rows, filter.value, {
                            keys: [
                              "Description"
                            ], threshold: matchSorter.rankings.WORD_STARTS_WITH
                          });
                          return result;
                        },
                        filterAll: true
                      }
                    ]}
                    data={this.context.campaigns}
                    defaultPageSize={10}
                    filterable={false}
                    filtered={this.state.filtered}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                    className="-striped -highlight"
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            if (e.target.tagName.toLowerCase() === 'button') {
                              return; // Ignore clicks on buttons
                            }
                            history.push(`/admin/campaign/${rowInfo.original.Key}`)
                          },
                          style: {
                            cursor: 'pointer'
                          }
                        }
                      }
                      return {}
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withRouter(withTranslation()(Campaigns))