import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';

const ProtectedRoute = ({ component: Component, role, ...rest }) => {
  
  const auth = useContext(AuthContext);
  const user = auth.user;

  return (
    <Route {...rest} render={props => (
      user && user.roles && user.roles.includes(role)
        ? <Component {...props} />
        : <Redirect to='/' />
    )} />
  );
}

export default ProtectedRoute;
