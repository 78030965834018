import React from 'react';

import consoleAPI from 'API/Console/ConsoleAPI';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })

    consoleAPI.reportError(error && error.toString(), errorInfo).then(val => {
      console.error("Error reported!");
    }).catch(error => {
      console.error("Error sending log", error);
    });

    console.error("Error: ", error);
    console.error("Error Info:", errorInfo);
  }

  render() {

    if (this.state.errorInfo) {
      return (
        <div>
          <h3 className="form-heading pt-3 pb-3">Sorry, something went wrong.</h3>
          <p>The issue has been reported and we will work on it as soon as possible.</p>
          <p>Please, reload the page and try again.</p>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}