import React, { Fragment } from 'react';
import {
  Col, FormGroup, Label, Input, Row, Button, Modal, ModalHeader, ModalBody, Form, ModalFooter
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

class VisualizeResult extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
    const { t, i18n, result } = this.props;

    return (
      <Fragment>
        <Modal isOpen={this.state.modal} toggle={this.toggle}
          size='xl' className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{t("campaigns.actions.visualize")}</ModalHeader>
          <ModalBody>
            <img src={`data:image/jpeg;base64,${result}`} alt={'Result'} 
            className='img-fluid'/>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.close')}</Button>
          </ModalFooter>
        </Modal>
      </Fragment >
    );
  }
}

VisualizeResult.defaultProps = {
  open: false
}

export default withTranslation()(VisualizeResult);
