import React, { Fragment } from 'react';
import { Col } from 'reactstrap';

import logo from '../../logo.png';

export default class Loading extends React.Component {

    render() {
        return (
            <Fragment>
                <div className="h-100 bg-everitas bg-animation">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <Col md="8" className="mx-auto app-login-box">
                            <div className="modal-dialog w-100 mx-auto">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="h5 modal-title text-center">
                                            <h4 className="mt-2">
                                                <img src={logo} className="App-logo" alt="logo" />
                                                <div>loading...</div>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center text-white opacity-8 mt-3">
                                &copy;2023 EmailVeritas
                    </div>
                        </Col>
                    </div>
                </div>
            </Fragment>
        );
    }
}
