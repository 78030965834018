import { doGet, doPost, doDelete } from "./BaseAPI";

export function getOrganizations(page) {
  if (page) {
    return doGet(`/organization/page/${page}`);
  }
  return doGet("/organization/");
}

export function getOrganizationsAll() {
  return doGet("/organization/all");
}

export function searchOrganizations(query) {
  return doGet(`/organization/search/${query}`);
}

export function getOrganization(domain) {
  if (!domain) {
    return doGet(`/organization/domain`);
  }
  return doGet(`/organization/domain/${domain}`);
}

export function createOrganization(organization) {
  return doPost("/organization/create", organization);
}

export function updateOrganization(organization) {
  return doPost("/organization/update", organization);
}

export function deleteOrganization(domain) {
  return doDelete(`/organization/delete/${domain}`)
}