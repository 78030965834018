import { trackPromise } from 'react-promise-tracker';
import {
  acquireAdminConsent, getAccessTokenMicrosoft, outlookTokenRequest
} from '../context/microsoftProvider';
import { fetchWithTimeout, handleResponse } from './BaseAPI';
import User from '../context/User';

export const outlookUrl = process.env.REACT_APP_OUTLOOK_API;

function getAccessToken(email, interactive = false) {
  return getAccessTokenMicrosoft(email, outlookTokenRequest, interactive);
}

function requestHeaders(accessToken) {
  const requestHeaders = new Headers();
  requestHeaders.append('Accept', 'application/json');
  requestHeaders.append('Content-Type', 'application/json');
  requestHeaders.append("Authorization", `Bearer ${accessToken}`);
  return requestHeaders;
}

function doGet(url, accessToken) {
  return trackPromise(
    fetchWithTimeout(outlookUrl + url, {
      method: 'GET',
      headers: requestHeaders(accessToken),
      timeout: process.env.REACT_APP_OUTLOOK_REQUEST_TIMEOUT
    }).then(response => {
      return handleResponse(response);
    })
  );
}

function doPost(url, body, accessToken) {
  return trackPromise(
    fetchWithTimeout(outlookUrl + url, {
      method: 'POST',
      headers: requestHeaders(accessToken),
      body: JSON.stringify(body),
      timeout: process.env.REACT_APP_OUTLOOK_REQUEST_TIMEOUT
    }).then(response => {
      return handleResponse(response);
    })
  );
}

function doDelete(url, accessToken) {
  return trackPromise(
    fetchWithTimeout(outlookUrl + url, {
      method: 'DELETE',
      headers: requestHeaders(accessToken),
      timeout: process.env.REACT_APP_OUTLOOK_REQUEST_TIMEOUT
    }).then(response => {
      return handleResponse(response);
    })
  );
}

export async function verify() {
  const user = User.getUserInfo();
  const accessToken = await getAccessToken(user.email);
  return doGet(`/admin/${user.domain}/status?admin=${user.email}`, accessToken);
}

export async function authenticate() {
  const user = User.getUserInfo();
  const accessToken = await getAccessToken(user.email);
  const ok = await acquireAdminConsent(user);
  return doPost(`/admin/${user.domain}/auth`, { email: user.email }, accessToken);
}

export async function listAccounts(name, department, filterStatus, top, skiptoken) {
  const user = User.getUserInfo();
  const accessToken = await getAccessToken(user.email);
  let queryParams = []
  if (name && name.length > 0) {
    queryParams.push(`name=${name}`);
  }
  if (department && department.length > 0) {
    queryParams.push(`department=${department}`);
  }
  if (filterStatus) {
    queryParams.push(`filterStatus=${filterStatus.join(',')}`);
  }
  if (top && top > 0) {
    queryParams.push(`top=${top}`);
  }
  if (skiptoken && skiptoken.length > 0) {
    queryParams.push(`skiptoken=${skiptoken}`);
  }
  return doGet(`/admin/${user.domain}/accounts?${queryParams.join('&')}`, accessToken);
}

export async function saveAccounts(accounts) {
  const user = User.getUserInfo();
  const accessToken = await getAccessToken(user.email);
  return doPost(`/admin/${user.domain}/accounts`, accounts, accessToken);
}

export async function updateAccountStatus(account) {
  const user = User.getUserInfo();
  const accessToken = await getAccessToken(user.email);
  return doPost(`/admin/${user.domain}/account`, account, accessToken);
}

export async function deleteAccount(email) {
  const user = User.getUserInfo();
  const accessToken = await getAccessToken(user.email);
  return doDelete(`/admin/${user.domain}/account/${email}`, accessToken);
}

export async function searchAccounts(account) {
  const user = User.getUserInfo();
  const accessToken = await getAccessToken(user.email);
  let queryString = account.indexOf('@') >= 0 ? `email=${account}` : `domain=${account}`;
  return doGet(`/admin/accounts?${queryString}`, accessToken);
}

export async function unregisterAccounts(emails) {
  const user = User.getUserInfo();
  const accessToken = await getAccessToken(user.email);
  let accounts = [];
  for (let email of emails) {
    accounts.push({email: email, status: 'unregister'});
  }
  return doPost(`/admin/accounts`, accounts, accessToken);
}