import { doGet, doPost, doDelete, getDomain } from "./BaseAPI";

// DLP Predefined Expressions

export function getPredefined() {
  return doGet("/dlp/predefined/");
}

export function createPredefined(description, expression, id, testFail, testPass) {
  return doPost("/dlp/predefined/create", {
    description: description,
    expression: expression,
    id: id,
    testPass: testPass,
    testFail: testFail
  });
}

export function deletePredefined(id) {
  return doDelete(`/dlp/predefined/delete/${id}`);
}

// DLP Policies

export function getPolicies() {
  return doGet("/organization/dlp");
}

export function createPolicy(policy) {
  policy.domain = getDomain();
  return doPost("/organization/dlp/create", policy);
}

export function deletePolicy(id) {
  return doDelete(`/organization/dlp/delete/${id}`);
}

// DLP Reports

export function getReportByThreat(page) {
  return doGet(`/organization/dlp/report/messageorigin/${page}`);
}

export function getReportByAccount() {
  return doGet("/organization/dlp/report/overview");
}
