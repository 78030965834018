import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { OrganizationContext } from './OrganizationContext';
import BootstrapTable from 'react-bootstrap-table-next';
import ContactModal from './ContactModal';
import InfoPopover from 'Layout/Components/InfoPopover';
import ConfirmAction from 'Layout/Components/ConfirmAction';

class Contacts extends React.Component {

  static contextType = OrganizationContext;

  constructor(props) {
    super(props);

    this.state = {
      openEdit: false
    };
  }

  render() {
    const { t, i18n } = this.props;

    const editContactModal = this.state.openEdit &&
      <ContactModal showOpenButton={false} open={true}
        onClose={() => this.setState({ openEdit: false })} />;
    
    return (
      <Fragment>
        <h3 className="form-heading pt-3 pb-3 float-left">
          {t('organization.contact.title')}
        </h3>
        <ContactModal buttonClass="btn-heading-position" />
        <InfoPopover id="contact" mid body={t('organization.contact.instructions')} />
        {editContactModal}

        <BootstrapTable
          bootstrap4
          keyField="email"
          data={this.context.contacts}
          bordered={false}
          columns={[
            {
              dataField: 'name',
              text: t("organization.contact.name")
            },
            {
              dataField: 'email',
              text: t("organization.contact.email")
            },
            {
              dataField: 'address',
              text: t("organization.contact.address")
            },
            {
              dataField: 'phone',
              text: t("organization.contact.phone")
            },
            {
              dataField: 'rule',
              text: t("organization.contact.rule")
            },
            {
              dataField: 'id',
              text: t("organization.contact.actions.title"),
              isDummyField: true,
              hidden: !this.context.isAdmin,
              formatter: (cell, row, index) => {
                return (
                  <div>
                    <div className="d-block w-100 text-center">
                      <ConfirmAction btnClassName="mb-2 mr-2" color="warning" title={t('actions.confirmation')}
                        message={t('organization.contact.actions.confirm.login-as', { account: row.email })}
                        onConfirm={() => this.context.changeSession(row.name, row.email, this.props.authContext)}>{t("organization.contact.actions.login-as")}</ConfirmAction>
                    </div>
                  </div>
                );
              }
            }
          ]}
          rowEvents={{
            onClick: (e, row, rowIndex) => {
              if (e.target.tagName.toLowerCase() === 'button') {
                return; // Ignore clicks on buttons
              }
              this.context.openContact(row);
              this.setState({ openEdit: true })
            },
            style: {
              cursor: 'pointer'
            }
          }} />

      </Fragment >
    )
  }
}

export default withRouter(withTranslation()(Contacts));