import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { toDate } from "../Helpers/TextHelper";
import { saveAd } from "../../API/AdsAPI";
import { toast } from 'react-toastify';

import { AuthContext } from 'context/AuthContext';

class AdModal extends React.Component {

  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      isEdit: this.props.ad && this.props.ad.id ? true : false,
      ad: this.initAd(),
      file: null,
      fileName: '',
      tagsData: []
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  initAd() {
    return {
      client: false,
      enddate: "",
      free: false,
      id: null,
      language: "",
      message: "",
      poc: false,
      startdate: "",
      title: ""
    }
  }

  componentDidMount() {
    if (this.state.isEdit) {
      const ad = this.props.ad;
      ad.startdate = toDate(ad.startdate); // adjust date
      ad.enddate = toDate(ad.enddate); // adjust date
      this.setState({
        ad: ad
      })
    }
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      ad: this.initAd()
    });
  }

  handleChange(event) {
    const obj = event.target || event;
    const key = obj.name;
    const value = obj.type === 'checkbox' ? obj.checked : obj.value;
    this.setState(updater => ({
      ad: {
        ...updater.ad,
        [key]: value
      }
    }));
  }

  submit() {
    const ad = this.state.ad;

    // adjust date format
    if (!ad.startdate instanceof Date || typeof ad.updated === 'number') {
      ad.startdate = new Date(ad.startdate).toISOString();
    }
    if (!ad.enddate instanceof Date || typeof ad.enddate === 'number') {
      ad.enddate = new Date(ad.enddate).toISOString();
    }

    saveAd(ad).then(result => {
      if (typeof this.props.onSubmitCallback === 'function') {
        this.props.onSubmitCallback();
        this.toggle();
      }
    }).catch(error => {
      console.error("Error submiting ad", error);
      const messageKey = this.state.isEdit ? 'errors.updating' : 'errors.creating';
      toast["error"](this.props.t(messageKey, { element: 'Ad', message: error.message }));
    });
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
        onClick={() => this.toggle()}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('ads.actions.create')}
      </Button>;

    const title = this.state.isEdit ? t('ads.actions.edit') : t('ads.actions.create')

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}
          size='xl' scrollable={true}>
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <FormGroup row>
                <Label for="title" sm={2}>{t('ads.ads_title')}</Label>
                <Col sm={10}>
                  <Input type="text" name="title" id="title"
                    value={this.state.ad.title} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="message" sm={2}>{t('ads.message')}</Label>
                <Col sm={10}>
                  <Input type="textarea" name="message" id="message"
                    value={this.state.ad.message} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="language" sm={2}>{t('ads.language')}</Label>
                <Col sm={10}>
                  <Input type="select" name="language" id="language"
                    value={this.state.ad.language} onChange={this.handleChange}>
                    <option></option>
                    <option>en</option>
                    <option>pt</option>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="by" sm={2}>{t('ads.client')}</Label>
                <Col sm={10}>
                  <Input type="checkbox" name="client" id="client"
                    checked={this.state.ad.client} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="by" sm={2}>{t('ads.free')}</Label>
                <Col sm={10}>
                  <Input type="checkbox" name="free" id="free"
                    checked={this.state.ad.free} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="by" sm={2}>{t('ads.poc')}</Label>
                <Col sm={10}>
                  <Input type="checkbox" name="poc" id="poc"
                    checked={this.state.ad.poc} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="startdate" sm={2}>{t('ads.start_date')}</Label>
                <Col sm={10}>
                  <DatePicker id="startdate" className="form-control"
                    selected={this.state.ad.startdate} dateFormat="yyyy-MM-dd h:mm:ss aa"
                    onChange={date => this.handleChange({ name: "startdate", value: date })} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="enddate" sm={2}>{t('ads.end_date')}</Label>
                <Col sm={10}>
                  <DatePicker id="enddate" className="form-control"
                    selected={this.state.ad.enddate} dateFormat="yyyy-MM-dd h:mm:ss aa"
                    onChange={date => this.handleChange({ name: "enddate", value: date })} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>{t('actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

AdModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(AdModal);
