import React, { Fragment } from 'react';
import {
  Button, Card, CardBody,
  Form, FormGroup, Input, Col, Row, Spinner, CardText
} from 'reactstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import BootstrapTable from 'react-bootstrap-table-next';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import PageTitle from '../../Layout/Components/PageTitle';
import ConfirmAction from '../../Layout/Components/ConfirmAction';

import GatewayAPI from '../../API/GatewayAPI';

class Servers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      servers: [],
      serverURL: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.add = this.add.bind(this);
    this.delete = this.delete.bind(this);
    this.sync = this.sync.bind(this);
  }

  componentDidMount() {
    this.fillTable();
  }

  fillTable() {
    GatewayAPI.getOrganizationGateways().then(result => {
      this.setState({
        servers: result,
        serverURL: ""
      });
    }).catch(error => {
      console.error("Error getting Servers", error);
      toast["error"](this.props.t('errors.loading', { element: 'Servers', message: error.message }));
    });
  }

  handleChange(event) {
    const key = event.target.name;
    const value = event.target.value;
    this.setState({
      [key]: value
    });
  }

  add() {
    if (this.state.serverURL) {
      GatewayAPI.registerOrganizationGateway(this.state.serverURL).then(result => {
        toast["success"](this.props.t('messages.success_update', { element: 'server' }));
        this.fillTable();
      }).catch(error => {
        console.error("Error creating Servers", error);
        toast["error"](this.props.t('errors.creating', { element: 'server', message: error.message }));
      });
    } else {
      toast["warning"](this.props.t('messages.unable_to_create', {
        element: 'server',
        message: this.props.t('messages.empty_field', { field: "server" })
      }));
    }
  }

  delete(gatewayId) {
    GatewayAPI.unregisterOrganizationGateway(gatewayId).then(result => {
      toast["success"](this.props.t('messages.success_delete', { element: 'server' }));
      this.fillTable()
    }).catch(error => {
      console.error("Error deleting Servers", error);
      toast["error"](this.props.t('errors.deleting', { element: 'server', message: error.message }));
    });
  }

  sync() {
    GatewayAPI.synchronizeGatewayServers().then(result => {
      toast["success"](this.props.t('messages.success_synchonizing', { element: 'server' }));
      this.fillTable()
    }).catch(error => {
      console.error("Error synchonizing Servers", error);
      toast["error"](this.props.t('errors.synchronizing', { element: 'server', message: error.message }));
    });
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("servers.title")}
          icon="pe-7s-network icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12" lg="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardText>{t("servers.text")}</CardText>
                  <Form onSubmit={(e) => { e.preventDefault(); this.add(); }}>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Input type="text" name="serverURL" id="serverURL"
                            value={this.state.serverURL} onChange={this.handleChange}
                            placeholder={t("servers.url_placeholder")} />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <Button color="primary" className="mb-2 mr-2"
                          onClick={() => this.add()}>
                          {t("servers.actions.add")}
                        </Button>
                      </Col>
                      <Col md={3}>
                        <Button color="primary" className="mb-2 mr-2"
                          onClick={() => this.sync()}>
                          {t("servers.actions.sync")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={this.state.servers}
                    columns={[
                      {
                        dataField: 'url',
                        text: t("servers.server")
                      },
                      {
                        dataField: 'status',
                        text: t("servers.status"),
                        isDummyField: false,
                        formatter: (cell) => {
                          return (
                            <div className="d-block w-100 text-left">
                              {cell}
                            </div>
                          );
                        }
                      },
                      {
                        dataField: 'id',
                        text: t("servers.actions.title"),
                        isDummyField: true,
                        formatter: (cell, row, index) => {
                          return (
                            <div>
                              <div className="d-block w-100 text-center">
                                <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                                  message={t('messages.confirm_delete', { element: 'server', object: row.url })}
                                  onConfirm={() => this.delete(row.id)}>{t("servers.actions.delete")}</ConfirmAction>
                              </div>
                            </div>
                          );
                        }
                      }
                    ]} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
}

export default withTranslation()(Servers);