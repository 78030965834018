
export default class SimilarData {

  // Map of similarities: Map<id, object>
  static similarities;

  /**
   * Return the available similarities as a Map<code, object>.
   */
  static getSimilarities() {
    if (!this.similarities || this.similarities.size === 0) {
      // Convert the array to a map in the first call
      this.similarities = new Map(this.similarity_data.map(obj => [obj.id, { id: obj.id, description: obj.description, case: obj.case }]));
    }
    return this.similarities;
  }

  /**
   * Get a similarity by its id.
   */
  static getSimilarity(id) {
    return this.getSimilarities().get(id);
  }

  static similarity_data = [
    {
      "id": 1,
      "description": "SameRegistrantPhone",
      "case": "WhoIs",
    },
    {
      "id": 2,
      "description": "SameRegistrantCountry",
      "case": "WhoIs",
    },
    {
      "id": 3,
      "description": "SameRegistrantOrganization",
      "case": "WhoIs",
    },
    {
      "id": 4,
      "description": "SameRegistrantName",
      "case": "WhoIs",
    },
    {
      "id": 5,
      "description": "SameRegistrantStreet",
      "case": "WhoIs",
    },
    {
      "id": 6,
      "description": "SameRegistrantCity",
      "case": "WhoIs",
    },
    {
      "id": 7,
      "description": "SameRegistrantProvince",
      "case": "WhoIs",
    },
    {
      "id": 8,
      "description": "SameRegistrantPostalCode",
      "case": "WhoIs",
    },
    {
      "id": 9,
      "description": "SameRegistrantFax",
      "case": "WhoIs",
    },
    {
      "id": 10,
      "description": "SameRegistrantEmail",
      "case": "WhoIs",
    },
    {
      "id": 12,
      "description": "SameDomain",
      "case": "DNS",
    },
    {
      "id": 13,
      "description": "SameIp",
      "case": "DNS",
    },
    {
      "id": 14,
      "description": "SameNameServerOrganization",
      "case": "DNS",
    },
    {
      "id": 15,
      "description": "SameNameServer",
      "case": "DNS",
    },
    {
      "id": 20,
      "description": "SameLocationCountry",
      "case": "Geolocation",
    },
    {
      "id": 21,
      "description": "SameLocationCity",
      "case": "Geolocation",
    },
    {
      "id": 22,
      "description": "SameLocationLat",
      "case": "Geolocation",
    },
    {
      "id": 23,
      "description": "SameLocationLng",
      "case": "Geolocation",
    },
    {
      "id": 24,
      "description": "SameLocationPostalCode",
      "case": "Geolocation",
    },
    {
      "id": 26,
      "description": "ISP",
      "case": "Geolocation",
    },
    {
      "id": 27,
      "description": "ASName",
      "case": "Geolocation",
    },
    {
      "id": 28,
      "description": "ASNumber",
      "case": "Geolocation",
    },
    {
      "id": 29,
      "description": "SameSubject",
      "case": "Message",
    },
    {
      "id": 30,
      "description": "SameKeywords",
      "case": "Message",
    },
    {
      "id": 31,
      "description": "SameText",
      "case": "Message",
    },
    {
      "id": 32,
      "description": "SameFromName",
      "case": "Message",
    },
    {
      "id": 33,
      "description": "SameEmailAddress",
      "case": "Message",
    },
    {
      "id": 34,
      "description": "SameLanguage",
      "case": "Message",
    },
    {
      "id": 35,
      "description": "SameUrls",
      "case": "Message",
    },
    {
      "id": 36,
      "description": "SameAttachedFiles",
      "case": "Message",
    },
    {
      "id": 37,
      "description": "SameCampaignNameType",
      "case": "General",
    },
    {
      "id": 38,
      "description": "SameThreatTypes",
      "case": "General",
    },
    {
      "id": 39,
      "description": "SameStartIn",
      "case": "General",
    },
    {
      "id": 40,
      "description": "SameEndIn",
      "case": "General",
    },
    {
      "id": 41,
      "description": "SameCampaignDescription",
      "case": "General",
    },
  ];

}