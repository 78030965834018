import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import PageTitle from '../../../Layout/Components/PageTitle';
import MessageOriginMap from './MessageOriginMap';
import MessageOriginTable from './MessageOriginTable';
import MessageOriginTableUser from "./MessageOriginTableUser";
import ThreatProvider from '../ThreatContext';

class MessageOrigin extends React.Component {

  render() {
    const { t, i18n, location } = this.props;
    
    const originTable = location.pathname.startsWith('/user') ? <MessageOriginTableUser /> : <MessageOriginTable />;

    return (
      <Fragment>
        <PageTitle
          heading={t("threat_intelligence.origin.title")}
          subheading={t("threat_intelligence.origin.description")}
          icon="pe-7s-look icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <ThreatProvider>
            <Row>
              <Col md="12">
                {originTable}
              </Col>
              <Col md="12">
                <MessageOriginMap />
              </Col>
            </Row>
          </ThreatProvider>
        </CSSTransitionGroup>
      </Fragment >
    )
  }
}

export default withRouter(withTranslation()(MessageOrigin));