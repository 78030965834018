import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, CardTitle, Button, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { getCampaign, getSimilarCampaign } from '../../../API/ConsoleServicesAPI'

import PageTitle from '../../../Layout/Components/PageTitle';
import SimilarGraph from "./SimilarGraph";

class SimilarCampaign extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      loading: false,
      campaignId1: '',
      campaignId2: '',
      campaign1: {},
      campaign2: {},
      similar: []
    }

    this.getCampaign = this.getCampaign.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  getCampaign() {
    if (!this.state.campaignId1 || !this.state.campaignId2) {
      toast["warning"](this.props.t('threat_hunting.campaign_analysis.empty_id_error'));
      return;
    }
    this.setState({
      loading: true,
      campaign1: {},
      campaign2: {},
      similar: []
    });
    Promise.all([getCampaign(this.state.campaignId1), getCampaign(this.state.campaignId2),
    getSimilarCampaign(this.state.campaignId1, this.state.campaignId2)])
      .then(values => {
        if (values[0].errorMessage || values[1].errorMessage) {
          console.warn("Error message received", values[0].errorMessage ? values[0] : values[1]);
          toast["warning"](this.props.t('threat_hunting.similar_campaign.find_campaign_error', { message: values[0].errorMessage || values[1].errorMessage }));
          return;
        }
        this.setState({
          campaign1: values[0].data,
          campaign2: values[1].data,
          similar: values[2].data,
          loading: false
        })
      }).catch(error => {
        console.error("Error loading campaign", error);
        toast["error"](this.props.t('errors.loading', { element: 'Campaign', message: error.message }));
        this.setState({
          loading: false
        })
      })
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("threat_hunting.similar_campaign.title")}
          icon="pe-7s-target icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="6" sm="12">
              <Card className="main-card mb-3">
                <CardBody>
                  {t("threat_hunting.similar_campaign.info_text")}
                </CardBody>
              </Card>
            </Col>
            <Col md="6" sm="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>{t("threat_hunting.similar_campaign.action_text")}</CardTitle>
                  <Row>
                    <Col md={6}>
                      <Input type="text" name="campaignId1" id="campaignId1"
                        value={this.state.campaignId1} onChange={this.handleChange} />
                    </Col>
                    <Col md={6}>
                      <Input type="text" name="campaignId2" id="campaignId2"
                        value={this.state.campaignId2} onChange={this.handleChange} />
                    </Col>
                  </Row>
                  <br />
                  <Button color="primary" className="mb-2 mr-2" onClick={() => this.getCampaign()}>
                    {t("threat_hunting.similar_campaign.action.find_similarity")}
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="12" sm="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <SimilarGraph campaignIds={[this.state.campaignId1, this.state.campaignId2]}
                    campaigns={[this.state.campaign1, this.state.campaign2]}
                    similar={this.state.similar} loading={this.state.loading} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withTranslation()(SimilarCampaign);