import React, {Fragment} from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, CardTitle, Input, Button, Spinner, Alert } from 'reactstrap';

import PageTitle from '../../../Layout/Components/PageTitle';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import UsersAndGroupsModal from './UsersAndGroupsModal'
import UsersAndGroupsTable from './UsersAndGroupsTable'
import {getGroupList, deleteGroup} from "../../../API/PhishingAPI";

class UsersAndGroups extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      openEdit: false,
      group: {},
      groupList: [],
      isLoaded: false,
      errorFetchingData: false,
      error:""
    }
    this.onSubmitCallback = this.onSubmitCallback.bind(this);
    this.addPolicyCallback = this.addPolicyCallback.bind(this);
    this.loadLandingPageList = this.loadLandingPageList.bind(this);
    this.onEditCallback = this.onEditCallback.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
  }

  onSubmitCallback(){
    this.loadLandingPageList();
  }

  componentDidMount(){
    this.loadLandingPageList();
  }

  loadLandingPageList(){
    this.setState({
      isLoaded: false
    });
    // TODO(Refactor): Date format should be handled in it's own method
    getGroupList()
      .then(res => {
        console.log(res);
        res.forEach((item, i) => {
          item.modified_date = (new Date(item.modified_date)).toLocaleString(undefined, {dateStyle:"full", timeStyle:"medium"})
        });
        this.setState({
          groupList : res,
          isLoaded : true
        });
    }).catch(error => {
      console.log("Error while fetching data for UsersAndGroups",error);
      this.setState({
        errorFetchingData: true,
        error: "Error fetching data"
      });
    });
  }

  onEditCallback(group){
    this.setState({
      openEdit: true,
      group: group
    })
  }

  deleteGroup(e){
    console.log(e);
    const group_id = e._original.id;
    console.log(e._original.id);
    deleteGroup(group_id)
      .then(res => {
        this.loadLandingPageList();
      }).catch(error => {
        toast["error"](this.props.t("deleting", { element: 'Group', message: error.message }));
      })
  }

  render(){
    const { t, i18n } = this.props;

    const editGroupModal = this.state.openEdit &&
      <UsersAndGroupsModal open={true} showOpenButton={false} onClose={this.addPolicyCallback}
      group={this.state.group} onSubmit={this.onSubmitCallback}/>;

    return(
      <Fragment>
      <PageTitle
        heading={t("users_and_groups.title")}
        icon="pe-7s-users icon-gradient bg-primary"
      />
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Row>
          <Col md="12" sm="12">
            <Card className="main-card mb-3">
              <CardBody>
                <UsersAndGroupsModal onSubmit={this.onSubmitCallback} onClose={this.addPolicyCallback}/>
                {editGroupModal}
                {this.state.errorFetchingData ? <Alert color="danger">{this.state.error}</Alert> :
                <UsersAndGroupsTable groupList={this.state.groupList} isLoaded={this.state.isLoaded}
                onEdit={this.onEditCallback} onDelete={this.deleteGroup}/>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  )
  }

  addPolicyCallback(){
    this.setState({
      openEdit: false,
      group:{}
    });
  }
}
export default withTranslation()(UsersAndGroups);
