import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import SearchBox from '../../Layout/Components/SearchBox';
import AccountModal from './AccountModal';

import CreateAccounts from './CreateAccounts';
import InfoPopover from 'Layout/Components/InfoPopover';

class ActionsMenu extends React.Component {

  render() {
    const { t, i18n, doSearch } = this.props;
    return (
      <Fragment>
        <div>
          <SearchBox placeholder={t('accounts.actions.search')} doSearch={doSearch} />
          <AccountModal />
          <CreateAccounts />
          <InfoPopover id="accounts-instructions" body={t('accounts.instructions')} />
        </div>
      </Fragment >
    );
  }
}

export default withTranslation()(ActionsMenu);
