import { doGet, doPost, doDelete } from "./BaseAPI";

export function getAdmin() {
  return doGet(`/admin/all`);
}

export function createAdmin(admin) {
  return doPost("/admin/create", admin);
}

export function deleteAdmin(email) {
  return doDelete(`/admin/delete/${email}`);
}

export function updateAdmin(admin) {
  return doPost("/admin/update", admin);
}
