import React, { Fragment } from 'react';
import {
  Button, Card, CardBody, Col, Row, FormGroup, Label, Input
} from 'reactstrap';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { getPDFReport } from '../../API/ReportsAPI';
import { downloadPDF } from 'Application/Helpers/FileHelper';

class PDFReportFragment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      period: 10
    };

    this.handleChange = this.handleChange.bind(this);
    this.generateReport = this.generateReport.bind(this);
  }

  handleChange(event) {
    const obj = event.target || event;
    const name = obj.name;
    const value = obj.type === 'checkbox' || obj.value === 'select-one'
      ? obj.checked : obj.value;
    this.setState({
      [name]: value
    });
  }

  generateReport() {
    // domain is needed only by everitas admin request
    getPDFReport(this.props.isAdmin ? this.props.domain : null, this.state.period).then(result => {
      downloadPDF(result, `report_${this.props.domain}.pdf`);
    }).catch(error => {
      console.error("Error getting PDF Report", error);
      toast["error"](this.props.t('errors.loading', { element: 'Report', message: error.message }));
    })
  }

  render() {
    const { t, i18n, disabled } = this.props;

    return (
      <Fragment>
        <Row>
          <Col md="12">
            <Card className="main-card mb-3">
              <CardBody>
                <h3 className="form-heading pt-3 pb-3">
                  {t('reports.pdf_report.title')}
                </h3>
                <Row form>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="period">{t('reports.pdf_report.days')}</Label>
                      <Input type="text" name="period" id="period"
                        value={this.state.period} onChange={this.handleChange} disabled={disabled} />
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="primary" onClick={this.generateReport} disabled={disabled} >
                  {t('reports.pdf_report.generate')}
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default withTranslation()(PDFReportFragment);