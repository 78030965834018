import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
  Row, Col, Form, FormGroup, Label, Input, Button, FormText,
  Card, CardBody, CardTitle, CardImg
} from 'reactstrap';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import PageTitle from '../../Layout/Components/PageTitle';

import t_Netflix from "./Netflix.json";
import t_Interac from "./Interac.json";
import t_Language from "./Language.json";

import { sendEmail } from "../../API/EmailAPI";

class PhishinTest extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      from_name: "",
      from_email: "",
      to_emails: "",
      cc_emails: "",
      bcc_emails: "",
      subject: "",
      body_html: "",
      error: "",
      success: "",
      sent: false
    };

    this.onChange = this.onChange.bind(this);
    this.setNetflix = this.setNetflix.bind(this);
    this.setInterac = this.setInterac.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.cancel = this.cancel.bind(this);
    this.sendmail = this.sendmail.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  setNetflix() {
    console.log('Netflix');
    let st = this.state;
    st.from_email = t_Netflix.from;
    st.subject = t_Netflix.subject;
    st.body_html = t_Netflix.html;
    this.setState(st);
  }

  setInterac() {
    console.log('Interac');
    let st = this.state;
    st.from_email = t_Interac.from;
    st.subject = t_Interac.subject;
    st.body_html = t_Interac.html;
    this.setState(st);
  }

  setLanguage() {
    console.log('Language');
    let st = this.state;
    st.from_email = t_Language.from;
    st.subject = t_Language.subject;
    st.body_html = t_Language.html;
    this.setState(st);
  }

  cancel() {
    this.setState(updater => ({
      from_name: "",
      from_email: "",
      to_emails: "",
      cc_emails: "",
      bcc_emails: "",
      subject: "",
      body_html: "",
      error: "",
      success: ""
    }));
  }

  sendmail(event) {
    event.preventDefault();
    sendEmail({ ...this.state}).then(result => {
      toast["success"](this.props.t('threat_intelligence.phishing.messages.email_sent'));
    }).catch(error => {
      console.error("Error sending email", error);
      toast["error"](this.props.t('threat_intelligence.phishing.messages.email_send_error'));
    })
  }

  checkEmails(emails, domain) {
    if (emails) {
      // if any domain is different, return false
      return !emails.split(",").some(email => {
        const emailDomain = email.replace(/ /g, "").split('@')[1];
        return domain.toUpperCase() !== emailDomain.toUpperCase();
      });
    }
    return true;
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("threat_intelligence.phishing.title")}
          icon="pe-7s-mail icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col>
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>{t('threat_intelligence.phishing.custom_email')}</CardTitle>
                  <Form onSubmit={this.sendmail}>
                    <FormGroup>
                      <Label>{t('threat_intelligence.phishing.from_name')}</Label>
                      <Input type="text" name="from_name" value={this.state.from_name} onChange={this.onChange} />
                    </FormGroup>
                    <FormGroup>
                      <Label>{t('threat_intelligence.phishing.from')}</Label>
                      <Input type="email" name="from_email" value={this.state.from_email} onChange={this.onChange} required />
                    </FormGroup>
                    <FormGroup>
                      <Label>{t('threat_intelligence.phishing.to')}</Label>
                      <Input type="text" name="to_emails" value={this.state.to_emails} onChange={this.onChange} required />
                      <FormText color="muted">
                        {t('threat_intelligence.phishing.email_tips')}
                      </FormText>
                    </FormGroup>
                    {/* <FormGroup>
                      <Label>{t('threat_intelligence.phishing.cc')}</Label>
                      <Input type="text" name="cc_emails" value={this.state.cc_emails} onChange={this.onChange} />
                      <FormText color="muted">
                        {t('threat_intelligence.phishing.email_tips')}
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <Label>{t('threat_intelligence.phishing.bcc')}</Label>
                      <Input type="text" name="bcc_emails" value={this.state.bcc_emails} onChange={this.onChange} />
                      <FormText color="muted">
                        {t('threat_intelligence.phishing.email_tips')}
                      </FormText>
                    </FormGroup> */}
                    <FormGroup>
                      <Label>{t('threat_intelligence.phishing.subject')}</Label>
                      <Input type="text" name="subject" value={this.state.subject} onChange={this.onChange} />
                    </FormGroup>
                    <FormGroup>
                      <Label>{t('threat_intelligence.phishing.body')}</Label>
                      <Input type="textarea" name="body_html" value={this.state.body_html} onChange={this.onChange} />
                    </FormGroup>
                    <Button color="link" onClick={this.cancel}>{t('threat_intelligence.phishing.action_cancel')}</Button>
                    <Button type="submit" color="primary">{t('threat_intelligence.phishing.action_send')}</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" md="4">
              <Card>
                <CardBody>
                  <CardTitle>{t('select_template')}</CardTitle>
                  <Row>
                    <Card onClick={this.setNetflix} className="template_card">
                      <CardBody>
                        <CardTitle>Netflix</CardTitle>
                        <CardImg width="100%" src="imgs/netflixLogo.png" />
                      </CardBody>
                    </Card>
                  </Row>
                  <br />
                  <Row>
                    <Card onClick={this.setInterac} className="template_card">
                      <CardBody>
                        <CardTitle>Interac</CardTitle>
                        <CardImg width="100%" src="imgs/interacLogo.png" />
                      </CardBody>
                    </Card>
                  </Row>
                  <br />
                  <Row>
                    <Card onClick={this.setLanguage} className="template_card">
                      <CardBody>
                        <CardTitle>Language</CardTitle>
                        <CardImg width="100%" src="imgs/languageLogo.png" />
                      </CardBody>
                    </Card>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment >
    )
  }
}

export default withTranslation()(PhishinTest);
