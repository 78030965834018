import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MetisMenu from 'react-metismenu';

import { setEnableMobileMenu } from '../../Reducers/ThemeOptions';
import RouterLink from './RouterLink';

class Nav extends React.Component {

  constructor(props) {
    super(props);
    
    this.sidebar = React.createRef();
    
    this.state = {
      content: []
    };

    this.toggleMobileSidebar = this.toggleMobileSidebar.bind(this);
    this.updateSidebar = this.updateSidebar.bind(this);
  }

  componentDidMount() {
    this.setState({
      content: this.props.content
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.content !== prevProps.content) {
      this.setState({
        content: this.props.content
      });
    }
  }

  toggleMobileSidebar() {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  }

  updateSidebar() {
    if (this.sidebar.current) {
      this.sidebar.current.changeActiveLinkFromLocation();
    }
  }

  render() {
    return (
      <Fragment>
        <MetisMenu content={this.state.content} ref={this.sidebar} LinkComponent={RouterLink}
          onSelected={this.toggleMobileSidebar} activeLinkFromLocation 
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
          {this.updateSidebar()}
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

const mapStateToProps = state => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu
});

const mapDispatchToProps = dispatch => ({
  setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));