import React, { Fragment, useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input, Row, Tooltip, Alert
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CKEditor from 'ckeditor4-react';
import ImportSiteModal from './ImportSiteModal'
import { getwebsitecontent, createLandingPage } from "../../../API/PhishingAPI";

class LandingPageModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.open,
      isEdit: this.props.landingPage ? true : false,
      copiedVersion: false,
      url: "",
      tooltipOpen: false,
      landingPage: {
        id: "",
        name: "",
        html: "",
        capture_credentials: false,
        capture_passwords: false,
        redirect_url: ""
      },
      url: "https://www.emailveritas.com/landing-en",
      errors: {}
    };

    this.toggle = this.toggle.bind(this);
    this.import_site = this.import_site.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleCkEditorInput = this.handleCkEditorInput.bind(this);
    this.handleSourceMode = this.handleSourceMode.bind(this);
  }

  /*
  In edit mode modified date should be deleted beofre sending it to backend. Also if the modal is opened in
  Copy mode then ID has to go otherwise it will not create new landing page just edit the old one.
  */
  componentDidMount() {
    if (this.state.isEdit) {
      const { id, modified_date, ...landingPage } = this.props.landingPage;
      if (!this.props.copiedVersion) {
        landingPage['id'] = id;
      }
      this.setState({
        landingPage: landingPage
      });
    }
  }

  handleChange(event) {
    const key = event.target.name;
    console.log(key);
    const value = event.target.type === 'checkbox'
      ? event.target.checked : event.target.value;
    this.setState(updater => ({
      landingPage: {
        ...updater.landingPage,
        [key]: value
      }
    }));
    if (key == "capture_credentials") {
      this.setState(updater => ({
        landingPage: {
          ...updater.landingPage,
          "redirect_url": "https://www.emailveritas.com/landing-en"
        }
      }));
    }
  }

  handleCkEditorInput(event) {
    this.setState(updater => ({
      landingPage: {
        ...updater.landingPage,
        html: event.editor.getData()
      }
    }));
  }

  handleSourceMode(editor) {
    console.log('called');
    this.setState(updater => ({
      landingPage: {
        ...updater.landingPage,
        html: editor.getData()
      }
    }));
  }

  handleValidation() {
    var fields = this.state.landingPage;
    var errors = {};
    var formIsValid = true;

    //Name cannot be empty
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Name cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  submit() {
    const promise = createLandingPage(this.state.landingPage);
    if (!this.handleValidation()) {
      return;
    }
    promise.then(result => {
      this.toggle();
      this.props.onSubmit();
    }).catch(error => {
      const messageKey = this.state.isEdit ? 'errors.updating' : 'errors.creating';
      toast["error"](this.props.t(messageKey, { element: 'Landing Page', message: error.message }));
    });
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      landingPage: {
        id: "",
        name: "",
        html: "",
        capture_credentials: false,
        capture_passwords: false,
        redirect_url: ""
      },
      errors: {}
    });
  }

  import_site(url) {
    getwebsitecontent(url).then(result => {
      console.log('Result', result);
      this.setState({
        landingPage: { ...this.state.landingPage, html: result.html }
      });
    }).catch(error => {
      console.error("Error sending email", error);
    });
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
        onClick={() => this.toggle()}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('landing_page.actions.create')}
      </Button>;

    const title = "New Landing Page";

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="lg">
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <FormGroup>
                <Label for="name" sm={2}>Name</Label>
                <Input type="text" name="name" id="description"
                  value={this.state.landingPage.name} onChange={this.handleChange} />
                <span style={{ color: "red" }}>{this.state.errors["name"]}</span>
              </FormGroup>
              <FormGroup>
                <ImportSiteModal importSite={this.import_site} />
              </FormGroup>
              <FormGroup>
                <CKEditor
                  name="myEditor"
                  onChange={this.handleCkEditorInput}
                  onInstanceReady={({ editor }) => {
                    var componentObj = this;
                    editor.on('mode', function () {
                      if (this.mode == 'source') {
                        var editable = editor.editable();
                        editable.attachListener(editable, 'input', function () {
                          componentObj.handleSourceMode(editor);
                        })
                      }
                    })
                  }
                  }
                  data={this.state.landingPage.html}
                  config={{
                    fullPage: true,
                    allowedContent: true
                  }}
                />
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" name="capture_credentials" id="active"
                    checked={this.state.landingPage.capture_credentials} onChange={this.handleChange} />{' '}
                  {t('landing_page.redirect_to')}   <i className="lnr-question-circle btn-icon-wrapper" id="TooltipExample"> </i>
                </Label>
                <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="TooltipExample" toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}>
                  If the landing page contains a form (data is not sent/record)
                  <br />this address contains the new landing page
                  captured
                </Tooltip>
              </FormGroup>
              {/*this.state.landingPage.capture_credentials &&
                <FormGroup className="mt-2" check>
                  <Label check>
                    <Input type="checkbox" name="capture_passwords" id="active"
                      checked={this.state.landingPage.capture_passwords} onChange={this.handleChange} />
                      {' '}{t('landing_page.capture_passwords')}
                  </Label>
                  <Alert color="warning" className="mt-2">
                    <b>Warning:</b> Credentials are currently <b>not encrypted</b>. This means tht captured passwords are stored in
                    the database as cleartext. Be careful with this!
                  </Alert>
              </FormGroup>*/}
              {this.state.landingPage.capture_credentials &&
                <FormGroup className="mt-2">
                  <Input type="text" name="redirect_url" id="description"
                    value={this.state.landingPage.redirect_url} onChange={this.handleChange} />
                </FormGroup>}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('landing_page.actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>{t('landing_page.actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }
}

LandingPageModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(LandingPageModal);
