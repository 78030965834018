import React from "react";
import {
  listCampaigns, getCampaignDetails, createCampaign, addCampaigns, updateCampaign,
  deleteCampaigns
} from "API/ConsoleServicesAPI";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";

export const CampaignContext = React.createContext();
export const CampaignConsumer = CampaignContext.Consumer;

class CampaignProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      campaigns: [],
      campaign: {
        UpdateOn: "",
        GeneralInfo: {},  // allow update
        DnsInfo: [],
        WhoisInfo: [],
        WhowasInfo: {
          domain: "",
          total_records_found: 0,
          whois_records: []
        },
        GeolocationInfo: [],
        MessageInfo: [],
        Key: "",
      }
    }

    this.selectCampaign = this.selectCampaign.bind(this);
    this.loadCampaigns = this.loadCampaigns.bind(this);
    this.loadCampaign = this.loadCampaign.bind(this)
    this.isEdit = this.isEdit.bind(this);
    this.createCampaign = this.createCampaign.bind(this);
    this.addCampaign = this.addCampaign.bind(this);
    this.updateCampaign = this.updateCampaign.bind(this);
    this.deleteCampaign = this.deleteCampaign.bind(this);
    this.addDNSInfo = this.addDNSInfo.bind(this);
    this.removeDNSInfo = this.removeDNSInfo.bind(this);
    this.addWhoisInfo = this.addWhoisInfo.bind(this);
    this.removeWhoisInfo = this.removeWhoisInfo.bind(this);
    this.addGeolocationInfo = this.addGeolocationInfo.bind(this);
    this.removeGeolocationInfo = this.removeGeolocationInfo.bind(this);
    this.addMessageInfo = this.addMessageInfo.bind(this);
    this.updateMessageInfo = this.updateMessageInfo.bind(this);
    this.removeMessageInfo = this.removeMessageInfo.bind(this);
  }

  componentDidMount() {
    if (this.props.listing) {
      this.loadCampaigns();
    }
  }

  loadCampaigns() {
    listCampaigns().then(value => {
      const campaigns = value.campaigns;
      campaigns.forEach(c => { // attribute to make it selectable
        c.select = false
      });
      this.setState({
        campaigns: campaigns
      })
    }).catch(error => {
      console.error("Error listing Campaigns", error);
      toast["error"](this.props.t('errors.loading', { element: 'Campaigns', message: error.message }));
    })
  }

  selectCampaign(index, selected) {
    const campaigns = this.state.campaigns;
    campaigns[index]['select'] = selected;
    this.setState({
      campaigns: campaigns
    })
  }

  loadCampaign(key) {
    return new Promise((resolve, reject) => {
      getCampaignDetails(key).then(details => {
        let campaign = details.subdocuments;
        campaign.Key = key;
        if (!campaign.WhowasInfo) { // complete the object to avoid UI errors
          campaign.WhowasInfo = {
            domain: "test",
            total_records_found: 0,
            whois_records: []
          }
        }
        this.setState({
          campaign: campaign
        })
        resolve(campaign);
      }).catch(error => {
        console.error("Error getting Campaign", error);
        toast["error"](this.props.t('campaign.messages.no_campaign_details'));
        reject(this.props.t('campaign.messages.no_campaign_details'));
      })
    });
  }

  isEdit() {
    return this.state.campaign._id ? true : false;
  }

  createCampaign() {
    createCampaign(this.state.campaign.MessageInfo).then(result => {
      console.log(result);
      toast["success"](this.props.t('messages.success_create', { element: 'Campaign' }));
    }).catch(error => {
      console.error("Error creating Campaign", error);
      toast["error"](this.props.t('errors.creating', { element: 'Campaign', message: error.message }));
    });
  }

  addCampaign(UpdateOn, GeneralInfo) {
    const campaign = { UpdateOn: UpdateOn, GeneralInfo: GeneralInfo };
    campaign.DnsInfo = this.state.campaign.DnsInfo;
    campaign.WhoisInfo = this.state.campaign.WhoisInfo;
    campaign.GeolocationInfo = this.state.campaign.GeolocationInfo;
    campaign.MessageInfo = this.state.campaign.MessageInfo;
    addCampaigns([campaign]).then(result => {
      console.log(result);
      toast["success"](this.props.t('messages.success_create', { element: 'Campaign' }));
    }).catch(error => {
      console.error("Error adding Campaign", error);
      toast["error"](this.props.t('errors.creating', { element: 'Campaign', message: error.message }));
    });
  }

  updateCampaign(GeneralInfo) {
    let updates = [];
    for (let prop in GeneralInfo) {
      updates.push({ property: `GeneralInfo.${prop}`, value: GeneralInfo[prop] });
    }
    updateCampaign(this.state.campaign.Key, updates).then(result => {
      console.log(result);
      toast["success"](this.props.t('messages.success_update', { element: 'Campaign' }));
    }).catch(error => {
      console.error("Error updating Campaign", error);
      toast["error"](this.props.t('errors.updating', { element: 'Campaign', message: error.message }));
    });
  }

  deleteCampaign(key) {
    deleteCampaigns([key]).then(result => {
      console.log(result);
      this.loadCampaigns();
    }).catch(error => {
      console.error("Error deleting Campaigns", error);
      toast["error"](this.props.t('errors.deleting', { element: 'Campaigns', message: error.message }));
    });
  }

  addDNSInfo(DnsInfo) {
    if (DnsInfo) {
      const campaign = this.state.campaign;
      campaign.DnsInfo.push(DnsInfo);
      this.setState({
        campaign: campaign
      });
    }
  }

  removeDNSInfo(index) {
    if (index >= 0 && index < this.state.campaign.DnsInfo.length) {
      const campaign = this.state.campaign;
      campaign.DnsInfo.splice(index, 1);
      this.setState({
        campaign: campaign
      });
    }
  }

  addWhoisInfo(WhoisInfo) {
    if (WhoisInfo) {
      const campaign = this.state.campaign;
      campaign.WhoisInfo.push(WhoisInfo);
      this.setState({
        campaign: campaign
      });
    }
  }

  removeWhoisInfo(index) {
    if (index >= 0 && index < this.state.campaign.WhoisInfo.length) {
      const campaign = this.state.campaign;
      campaign.WhoisInfo.splice(index, 1);
      this.setState({
        campaign: campaign
      });
    }
  }

  addGeolocationInfo(GeolocationInfo) {
    if (GeolocationInfo) {
      const campaign = this.state.campaign;
      campaign.GeolocationInfo.push(GeolocationInfo);
      this.setState({
        campaign: campaign
      });
    }
  }

  removeGeolocationInfo(index) {
    if (index >= 0 && index < this.state.campaign.GeolocationInfo.length) {
      const campaign = this.state.campaign;
      campaign.GeolocationInfo.splice(index, 1);
      this.setState({
        campaign: campaign
      });
    }
  }

  addMessageInfo(MessageInfo) {
    if (MessageInfo) {
      const campaign = this.state.campaign;
      campaign.MessageInfo.push(MessageInfo);
      this.setState({
        campaign: campaign
      });
    }
  }

  updateMessageInfo(MessageInfo) {

  }

  removeMessageInfo(index) {
    if (index >= 0 && index < this.state.campaign.MessageInfo.length) {
      const campaign = this.state.campaign;
      campaign.MessageInfo.splice(index, 1);
      this.setState({
        campaign: campaign
      });
    }
  }

  render() {
    return (
      <CampaignContext.Provider value={
        {
          campaigns: this.state.campaigns,
          campaign: this.state.campaign,
          emails: this.state.emails,
          email: this.state.email,
          isEdit: this.isEdit,
          selectCampaign: this.selectCampaign,
          loadCampaign: this.loadCampaign,
          createCampaign: this.createCampaign,
          updateCampaign: this.updateCampaign,
          addCampaign: this.addCampaign,
          deleteCampaign: this.deleteCampaign,
          addDNSInfo: this.addDNSInfo,
          removeDNSInfo: this.removeDNSInfo,
          addWhoisInfo: this.addWhoisInfo,
          removeWhoisInfo: this.removeWhoisInfo,
          addGeolocationInfo: this.addGeolocationInfo,
          removeGeolocationInfo: this.removeGeolocationInfo,
          addMessageInfo: this.addMessageInfo,
          updateMessageInfo: this.updateMessageInfo,
          removeMessageInfo: this.removeMessageInfo
        }
      }>
        {this.props.children}
      </CampaignContext.Provider>
    )
  }
}

export default withTranslation()(CampaignProvider)
