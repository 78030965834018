import React, { Fragment } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, CardTitle, Input, Button, Spinner, Alert} from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { toast } from 'react-toastify';
import PageTitle from '../../../Layout/Components/PageTitle';
import { jsonToCSV } from 'react-papaparse';

import PhishingCampaignTimelineDetails from './PhishingCampaignTimelineDetails';
import {fetchCampaignTimelineDetails, markCampaignAsComplete, downloadPhishingCampaignReport} from "../../../API/PhishingAPI";
import EmailStatDoughnutChart from "../Dashboard/EmailStatDoughnutChart";
import './dropdown.css';
import {downloadXLSX} from '../../Helpers/FileHelper.js'

class PhisingCampaignDetails extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      campaignId: this.props.match.params.id,
      isLoaded:  false,
      campaignStatus: "",
      userList:[],
      timelineData:[],
      errorFetchingData: false,
      error:"",
      email_sent_labels: ["", "Email Sent"],
      email_opened_labels: ["", "Email Opened"],
      email_clicked_labels: ["", "Email Clicked"],
      submitted_data_labels: ["", "Submitted Data"],
      email_reported_labels: ["", "Email Reported"],
      dropdownOpen: false
    }
    this.fetchCampaignTimelineDetails = this.fetchCampaignTimelineDetails.bind(this);
    this.countUniqueEvents = this.countUniqueEvents.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.exportAsCSV = this.exportAsCSV.bind(this);
    this.completeCampaign = this.completeCampaign.bind(this);
    this.refreshCampaignDetails = this.refreshCampaignDetails.bind(this);
    //this.downloadPhishingReport = this.downloadPhishingReport.bind(this);
    this.getPercentageBetweenTwoValue = this.getPercentageBetweenTwoValue.bind(this);
  }
  componentDidMount(){
    this.fetchCampaignTimelineDetails();
    //document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount(){
    //document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClickOutside(event){
    console.log(event.target.name);
    if(event.target.name != "export_csv" && event.target.name != "dropdownitem" && this.state.dropdownOpen){
      this.setState({
        dropdownOpen: false
      })
    }
  }

  exportAsCSV(scope){
    console.log("Entered into exportAsCSV", scope);
    var fileName = this.state.campaignName + ' - ' + scope + '.csv';
    var csvScope = {}
    switch (scope) {
        case "results":
            csvScope = this.state.userList
            break;
        case "events":
            csvScope = this.state.timelineData
            break;
    }
    if(!csvScope){
      return
    }
    var csvString = jsonToCSV(csvScope);
    var csvData = new Blob([csvString], {
        type: 'text/csv;charset=utf-8;'
    });
    var csvURL = window.URL.createObjectURL(csvData);
    var dlLink = document.createElement('a');
    dlLink.href = csvURL;
    dlLink.setAttribute('download', fileName)
    document.body.appendChild(dlLink)
    dlLink.click();
    document.body.removeChild(dlLink)
    this.setState({ dropdownOpen: !this.state.dropdownOpen});
  }
  countUniqueEvents(eventName){
    var emailSetForSpecificEvent  = new Set();
    if(Array.isArray(this.state.timelineData)){
        this.state.timelineData
          .forEach(campaign => {
            if(campaign.message == eventName){
              emailSetForSpecificEvent.add(campaign.email);
            }
          })
    }
    return emailSetForSpecificEvent.size;
  }
  fetchCampaignTimelineDetails(){
    fetchCampaignTimelineDetails(this.state.campaignId)
      .then(res => {
        console.log(res);
        this.setState({
          userList: res.results,
          timelineData: res.timeline,
          isLoaded: true,
          campaignName: res.name,
          campaignStatus: res.status
        })
      }).catch(error => {
        console.log("Error while fetching data for campaign timeline details",error);
        this.setState({
          errorFetchingData: true,
          error: "Error fetching data"
        })
      })
  }
  completeCampaign(){
    markCampaignAsComplete(this.state.campaignId)
      .then(res => {
        this.setState({
          campaignStatus: "Completed"
        })
      }).catch(error => {
        toast["error"](error.message);
      })
  }
  refreshCampaignDetails(){
    this.fetchCampaignTimelineDetails();
  }

  downloadPhishingReport(){
    downloadPhishingCampaignReport(this.state.campaignId)
      .then(response => {
        downloadXLSX(response, "report.xlsx")
      })
      .catch(error => {
        console.log(error);
      })
  }
  getPercentageBetweenTwoValue(num, den){
    if(den == 0){
      return 0;
    }
    var per =  (num/den)*100 | 0;
    if(per > 100){
      return per;
    }
    return per;
  }
  render(){
    const { t, i18n } = this.props;
    const total = Array.isArray(this.state.userList) ? this.state.userList.length: 0;
    const email_sent_count = this.countUniqueEvents("Email Sent");
    const email_sent = [total - email_sent_count, email_sent_count];
    const email_opened_count = this.countUniqueEvents("Email Opened");
    const email_opened = [email_sent_count - email_opened_count > 0 ? email_sent_count - email_opened_count : 0, email_opened_count];
    const email_clicked_count = this.countUniqueEvents("Clicked Link");
    const email_clicked = [email_opened_count - email_clicked_count > 0 ? email_opened_count - email_clicked_count : 0, email_clicked_count];
    const submitted_data_count = this.countUniqueEvents("Submitted Data");
    const submitted_data = [total - submitted_data_count, submitted_data_count]
    const email_reported_count = this.countUniqueEvents("Email Reported");
    const email_reported = [total - email_reported_count, email_reported_count];

    return(
      <Fragment>
        {this.state.isLoaded && <PageTitle
          heading={t("phishing_campaign.result")+' '+this.state.campaignName}
          icon="pe-7s-helm icon-gradient bg-primary"
        />}
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
          >
          <Row>
            <Col>
            <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="secondary"  onClick={() => this.props.history.goBack()}>
              {t('phishing_campaign.actions.back')}
            </Button>
            <span>
              <Button name="export_csv" className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
                onClick={() => this.downloadPhishingReport()}>
                  Download Report
              </Button>
                {/*this.state.dropdownOpen && <ul className="dropdown">
                  <li name="dropdownitem" className="dropdownitem" onClick={() => this.exportAsCSV("results")}>Results</li>
                  <li name="dropdownitem" className="dropdownitem" onClick={() => this.exportAsCSV("events")}>Raw Events</li>
                  <li name="dropdownitem" className="dropdownitem" onClick={() => this.downloadPhishingReport()}>Download Report</li>
                </ul>*/}
            </span>
            {this.state.campaignStatus && this.state.campaignStatus != "Completed" && <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
            onClick={this.completeCampaign}>
              {t('phishing_campaign.actions.complete')}
            </Button>}

            {this.state.campaignStatus && this.state.campaignStatus != "Completed" && <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success" onClick={this.refreshCampaignDetails}>
              {t('phishing_campaign.actions.refresh')}
            </Button>}
            </Col>
          </Row>
          <Card className="mt-3">
            <CardBody>
              <Row>
                <Col sm={4}>
                  <EmailStatDoughnutChart data={email_sent} labels={this.state.email_sent_labels} label="Email Sent" per={this.getPercentageBetweenTwoValue(email_sent_count, total)}/>
                </Col>
                <Col sm={4}>
                  <EmailStatDoughnutChart data={email_opened} labels={this.state.email_opened_labels} label="Email Opened" per={this.getPercentageBetweenTwoValue(email_opened_count, email_sent_count)}/>
                </Col>
                <Col sm={4}>
                  <EmailStatDoughnutChart data={email_clicked} labels={this.state.email_clicked_labels} label="Email Clicked" per={this.getPercentageBetweenTwoValue(email_clicked_count, email_opened_count)}/>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <EmailStatDoughnutChart data={submitted_data} labels={this.state.submitted_data_labels} label="Submitted Data" per={this.getPercentageBetweenTwoValue(submitted_data_count, email_clicked_count)}/>
                </Col>
                <Col sm={4}>
                  <EmailStatDoughnutChart data={email_reported} labels={this.state.email_reported_labels} label="Email Reported" per={this.getPercentageBetweenTwoValue(email_reported_count, total)}/>
                </Col>
                <Col sm={4}>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col>
              <Card className="main-card mb-3">
                <CardBody>
                {this.state.errorFetchingData ? <Alert color="danger"> {this.state.error} </Alert> :
                <PhishingCampaignTimelineDetails userList={this.state.userList} timelineData={this.state.timelineData} isLoaded={this.state.isLoaded}/>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
}
export default withTranslation()(PhisingCampaignDetails);
