import { doDelete, doGet, doPost } from "./BaseAPI";

export function getAds() {
  return doGet(`/ads/`);
}

export function saveAd(ad) {
  return doPost("/ads/create", ad);
}

export function deleteAd(id) {
  return doDelete(`/ads/delete/${id}`)
}
