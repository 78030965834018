import React, { Fragment } from 'react';
import {
  Button, Card, CardBody,
  Form, FormGroup, Input, Col, Row, CardText, Modal, ModalBody, ModalHeader, ModalFooter, Label
} from 'reactstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { withTranslation } from 'react-i18next';
import ConfirmAction from 'Layout/Components/ConfirmAction';
import { AccountContext } from './AccountContext';
import SearchBox from 'Layout/Components/SearchBox';

class AccountBlacklist extends React.Component {

  static contextType = AccountContext;

  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      selectedIndexes: [],
      modal: this.props.open
    };

    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal
    });
    this.context.openAccount(); // clear context account
  }

  select(row, isSelect) {
    const selected = [...this.state.selected];
    let index = selected.indexOf(row)
    if (index === -1 && isSelect) {
      selected.push(row);
      this.setState({
        selected: selected
      });
    } else if (index >= 0 && !isSelect) {
      selected.splice(index, 1);
      this.setState({
        selected: selected
      });
    }
  }

  selectAll(rows, isSelect) {
    this.setState({
      selected: isSelect ? rows : []
    });
  }

  onSearch(val) {
    console.log(`onSearch`, val)
    this.setState({
      selected: []
    });
    filterBlacklist(val);
  }
  
  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size='xl'>
          <ModalHeader toggle={this.toggle}>{t("blacklist.title")}</ModalHeader>
          <ModalBody>
            <CSSTransitionGroup
              component="div"
              transitionName="TabsAnimation"
              transitionAppear={true}
              transitionAppearTimeout={0}
              transitionEnter={false}
              transitionLeave={false}>
              <Row>
                <Col md="12" lg="12">
                  <Card className="main-card mb-3">
                    <CardBody>
                      <CardText>{t("blacklist.accounts.description")}</CardText>
                      <Form onSubmit={(e) => { e.preventDefault(); }}>
                        <Row form>
                          <Col md={3}>
                            <FormGroup>
                              <Label for="name">{t('accounts.name')}</Label>
                              <Input type="text" name="name" id="name"
                                value={this.context.account.name} readOnly={true} />
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup>
                              <Label for="address">{t('accounts.email')}</Label>
                              <Input type="email" name="email" id="email"
                                value={this.context.account.email} readOnly={true} />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <SearchBox wrapperClassName="custom-input-row-no-label" placeholder={t('blacklist.actions.search')} 
                              doSearch={this.onSearch} />
                          </Col>
                          <Col md={2}>
                          <ConfirmAction btnClassName="mb-2 mr-2 custom-button-row-no-label" color="danger" title={t('actions.confirmation')}
                            message={t('messages.confirm_delete', { element: 'blacklist', object: 'selected' })} disabled={this.state.selected.length === 0}
                            onConfirm={() => this.context.deleteBlacklist(this.state.selected.map(val => val.email))}>
                            {t("blacklist.actions.delete-selected")}
                          </ConfirmAction>
                          </Col>
                        </Row>
                      </Form>
                      <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={this.context.blacklist}
                        columns={[
                          {
                            dataField: 'email',
                            text: t("blacklist.email"),
                            filter: textFilter({
                              style: { display: 'none' },
                              getFilter: (filter) => filterBlacklist = filter
                            })                      
                          },
                          {
                            dataField: 'id',
                            text: t("blacklist.actions.title"),
                            isDummyField: true,
                            formatter: (cell, row, index) => {
                              return (
                                <div>
                                  <div className="d-block w-100 text-center">
                                    <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                                      message={t('messages.confirm_delete', { element: 'blacklist', object: row.email })}
                                      onConfirm={() => this.context.deleteBlacklist(row.email)}>{t("blacklist.actions.delete")}</ConfirmAction>
                                  </div>
                                </div>
                              );
                            }
                          }
                        ]}
                        filter={ filterFactory() }
                        selectRow={{
                          mode: 'checkbox',
                          clickToSelect: true,
                          onSelect: (row, isSelect, _rowIndex, _e) => {
                            this.select(row, isSelect);
                          },
                          onSelectAll: (isSelect, rows, _e) => {
                            this.selectAll(rows, isSelect);
                          }
                        }} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CSSTransitionGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>{t('actions.close')}</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

let filterBlacklist; // custom filter function

AccountBlacklist.defaultProps = {
  open: false
}

export default withTranslation()(AccountBlacklist);