
export function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.replace(/^data:.+;base64,/, ''));
    reader.onerror = error => reject(error);
  });
}

export function readAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}


export function downloadText(text, fileName) {
  downloadFile(text, fileName, 'text/plain')
}

export function downloadPDF(text, fileName) {
  downloadFile(text, fileName, 'application/pdf')
}

export function downloadXLSX(text, fileName) {
  downloadFile(text, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
}

export function downloadCSV(text, fileName) {
  downloadFile(text, fileName, 'text/csv;charset=utf-8;')
}

export function downloadFile(content, name, type) {
  const blobContent = new Blob([content], { type: type });

  const downloadLink = document.createElement("a");
  downloadLink.download = name;
  downloadLink.innerHTML = "DownloadFile";

  if (window.webkitURL != null) {
    // Chrome allows the link to be clicked
    // without actually adding it to the DOM.
    downloadLink.href = window.webkitURL.createObjectURL(blobContent);
  } else {
    // Firefox requires the link to be added to the DOM
    // before it can be clicked.
    downloadLink.href = window.URL.createObjectURL(blobContent);
    downloadLink.onclick = (e) => document.body.removeChild(e.target);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
  }

  downloadLink.click();
}
