import React, { Fragment } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
  Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input
} from 'reactstrap';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import PageTitle from '../../Layout/Components/PageTitle';

import { getAddinConfig, updateAddinConfig } from '../../API/AddinAPI';
import InfoPopover from 'Layout/Components/InfoPopover';
import { Multiselect } from 'react-widgets';
import LanguageHelper from 'Application/Helpers/LanguageHelper';
import { getLanguages, updateLanguages } from 'API/LanguageAPI';

class AddInConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controlByUser: false,
      configFolder: "Quarantine",
      configLabeling: false,
      configLabelingLegitimate: false,
      configBanner: false,
      configDLP: false,
      // releaseQuarantine: false,
      configMoveMalware: false,
      configMovePhishing: false,
      configMoveSpam: false,
      configMoveWarning: false,
      configMoveLegitimate: false,
      configDeleteMalware: false,
      configDisablePhishingUrl: false,
      configDisableMaliciousAttachment: false,
      silentInstalation: false,
      subscriptionStep: false,
      transactionStep: false,
      languageStep: false,
      languages: [],
      domain: "",
      languages_values: LanguageHelper.langArray
    };

    this.loadInfo = this.loadInfo.bind(this);
    this.loadLanguages = this.loadLanguages.bind(this);
    this.updateConfig = this.updateConfig.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.loadInfo();
    this.loadLanguages();
  }

  loadInfo() {
    getAddinConfig().then(result => {
      if (!result) {
        throw new Error("Empty result!")
      }
      this.setState({
        controlByUser: result.controlByUser,
        configFolder: result.configAddin.folder || "",
        configLabeling: result.configAddin.labeling,
        configLabelingLegitimate: result.configAddin.labelingLegitimate,
        configBanner: result.configAddin.banner,
        configDLP: result.configAddin.dlp,
        // releaseQuarantine: result.configAddin.releaseQuarantine,
        configMoveMalware: result.configAddin.moveMalwareToFolder,
        configMovePhishing: result.configAddin.movePhishingToFolder,
        configMoveSpam: result.configAddin.moveSpamToFolder,
        configMoveWarning: result.configAddin.moveWarningToFolder,
        configMoveLegitimate: result.configAddin.moveLegitimateToFolder,
        configDeleteMalware: result.configAddin.deleteMalware,
        configDisablePhishingUrl: result.configAddin.disablePhishingUrl,
        configDisableMaliciousAttachment: result.configAddin.disableMaliciousAttachment,
        silentInstalation: result.silentInstallationMode,
        subscriptionStep: result.subscriptionStep,
        transactionStep: result.transactionStep,
        languageStep: result.languageStep
      });
    }).catch(error => {
      console.error("Error loading AddIn Config", error);
      toast["error"](this.props.t('errors.loading', { element: 'AddIn Config', message: error.message }));
    });
  }

  loadLanguages() {
    getLanguages().then(result => {
      this.setState({
        domain: result.domain,
        languages: LanguageHelper.langArray.filter((lang) => result.language.indexOf(lang.code) > -1)
      });
    }).catch(error => {
      console.error("Error loading Languages", error);
      toast["error"](this.props.t('errors.loading', { element: 'Languages', message: error.message }));
    });
  }

  updateConfig() {
    updateAddinConfig({
      controlByUser: this.state.controlByUser,
      configAddin: {
        folder: this.state.configFolder,
        movePhishingToFolder: this.state.configMovePhishing,
        moveWarningToFolder: this.state.configMoveWarning,
        moveLegitimateToFolder: this.state.configMoveLegitimate,
        moveMalwareToFolder: this.state.configMoveMalware,
        moveSpamToFolder: this.state.configMoveSpam,
        deleteMalware: this.state.configDeleteMalware,
        labeling: this.state.configLabeling,
        labelingLegitimate: this.state.configLabelingLegitimate,
        banner: this.state.configBanner,
        dlp: this.state.configDLP,
        disablePhishingUrl: this.state.configDisablePhishingUrl,
        disableMaliciousAttachment: this.state.configDisableMaliciousAttachment,
        // releaseQuarantine: this.state.releaseQuarantine,
      },
      silentInstallationMode: this.state.silentInstalation,
      subscriptionStep: this.state.subscriptionStep,
      transactionStep: this.state.transactionStep,
      languageStep: this.state.languageStep
    }).then(result => {
      toast["success"](this.props.t('messages.success_update', { element: 'AddIn Config' }));
    }).catch(error => {
      console.error("Error updating AddIn Config", error);
      toast["error"](this.props.t('errors.updating', { element: 'AddIn Config', message: error.message }));
    });
    updateLanguages(this.state.domain, this.state.languages.map(lang => lang.code)).then(result => {
      toast["success"](this.props.t('messages.success_update', { element: 'Languages' }));
    }).catch(error => {
      console.error("Error updating Languages", error);
      toast["error"](this.props.t('errors.updating', { element: 'Languages', message: error.message }));
    });
  }

  handleChange(event) {
    const key = event.target ? event.target.name : event.name;
    const value = event.target ? (event.target.type === 'checkbox' ? event.target.checked : event.target.value) : event.value;
    this.setState({
      [key]: value
    });
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("organizations.config.title")}
          subheading={t("organizations.config.description")}
          icon="pe-7s-phone icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <Form onSubmit={(e) => { e.preventDefault(); }}>
                    {/* ----- General settings ----- */}
                    <Row form>
                      <Col md={6}>
                        <FormGroup check>
                          <Input type="checkbox" name="controlByUser" id="controlByUser"
                            checked={this.state.controlByUser} onChange={this.handleChange} />
                          <Label for="controlByUser">{t('organizations.config.control-by-user')}</Label>
                          <InfoPopover id="configuser-instructions" small body={t('organizations.config.control-by-user-instructions')} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup check>
                          <Input type="checkbox" name="configLabeling" id="configLabeling"
                            checked={this.state.configLabeling} onChange={this.handleChange} />
                          <Label for="configLabeling" >{t('organizations.config.labeling')}</Label>
                          <InfoPopover id="configlabel-instructions" small body={t('organizations.config.labeling-instructions')} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup check>
                          <Input type="checkbox" name="configLabelingLegitimate" id="configLabelingLegitimate"
                            checked={this.state.configLabelingLegitimate} onChange={this.handleChange} />
                          <Label for="configLabelingLegitimate" >{t('organizations.config.labeling-legitimate')}</Label>
                          <InfoPopover id="configlabellegitimate-instructions" small body={t('organizations.config.labeling-legitimate-instructions')} />
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row form>
                      <Col md={6}>
                        <FormGroup check>
                          <Input type="checkbox" name="configBanner" id="configBanner"
                            checked={this.state.configBanner} onChange={this.handleChange} />
                          <Label for="configBanner" >{t('organizations.config.first-contact')}</Label>
                          <InfoPopover id="configbanner-instructions" small body={t('organizations.config.first-contact-instructions')} />
                        </FormGroup>
                      </Col>
                    </Row> */}
                    <Row form>
                      <Col md={6}>
                        <FormGroup check>
                          <Input type="checkbox" name="configDLP" id="configDLP"
                            checked={this.state.configDLP} onChange={this.handleChange} />
                          <Label for="configDLP" >{t('organizations.config.dlp')}</Label>
                          <InfoPopover id="configdlp-instructions" small body={t('organizations.config.dlp-instructions')} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup check>
                          <Input type="checkbox" name="configDisablePhishingUrl" id="configDisablePhishingUrl"
                            checked={this.state.configDisablePhishingUrl} onChange={this.handleChange} />
                          <Label for="configDisablePhishingUrl" >{t('organizations.config.disablePhishingUrl')}</Label>
                          <InfoPopover id="configDisablePhishingUrl-instructions" small body={t('organizations.config.disablePhishingUrl-instructions')} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup check>
                          <Input type="checkbox" name="configDisableMaliciousAttachment" id="configDisableMaliciousAttachment"
                            checked={this.state.configDisableMaliciousAttachment} onChange={this.handleChange} />
                          <Label for="configDisableMaliciousAttachment" >{t('organizations.config.disableMaliciousAttachment')}</Label>
                          <InfoPopover id="configDisableMaliciousAttachment-instructions" small body={t('organizations.config.disableMaliciousAttachment-instructions')} />
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* ----- Quarantine settings ----- */}
                    {/* <Row form> 
                      <Col md={6}>
                        <legend className="col-form-label col-sm-12">
                          {t('organizations.config.remote_quarantine')}
                        </legend>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup check>
                          <Input type="checkbox" name="releaseQuarantine" id="releaseQuarantine"
                            checked={this.state.releaseQuarantine} onChange={this.handleChange} />
                          <Label for="releaseQuarantine" >{t('organizations.config.release_by_admin')}</Label>
                        </FormGroup>
                      </Col>
                    </Row> */}

                    {/* ----- Move-To-Folder settings ----- */}
                    <Row form>
                      <Col md={6}>
                        <legend className="col-form-label col-sm-12">
                          {t('organizations.config.move-to-folder')}
                          <InfoPopover id="configfolder-instructions" small body={t('organizations.config.move-to-folder-instructions')} />
                        </legend>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={2}>
                        <FormGroup check>
                          <Input type="checkbox" name="configMovePhishing" id="configMovePhishing"
                            checked={this.state.configMovePhishing} onChange={this.handleChange} />
                          <Label for="configMovePhishing">{t('organizations.config.folder-phishing')}</Label>
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup check>
                          <Input type="checkbox" name="configMoveSpam" id="configMoveSpam"
                            checked={this.state.configMoveSpam} onChange={this.handleChange} />
                          <Label for="configMoveSpam">{t('organizations.config.folder-spam')}</Label>
                          <InfoPopover id="folder-spam-instructions" small body={t('organizations.config.folder-spam-instructions')} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="configFolder">{t('organizations.config.folder')}</Label>
                          <Input type="text" name="configFolder" id="configFolder" placeholder={t('organizations.config.folder-placeholder')}
                            value={this.state.configFolder} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup check>
                          <Input type="checkbox" name="configDeleteMalware" id="configDeleteMalware"
                            checked={this.state.configDeleteMalware} onChange={this.handleChange} />
                          <Label className='font-bold' for="configDeleteMalware">{t('organizations.config.delete-malware')}</Label>
                          <InfoPopover id="delete-malware-instructions" small body={t('organizations.config.delete-malware-instructions')} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <hr/>
                    
                    {/* ----- AddIn-Installation settings ----- */}
                    <Row form>
                      <Col md={6}>
                        <legend className="col-form-label col-sm-12">
                          {t('organizations.config.addin-installation')}
                        </legend>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup check>
                          <Input type="checkbox" name="silentInstalation" id="silentInstalation"
                            checked={this.state.silentInstalation} onChange={this.handleChange} />
                          <Label for="silentInstalation" >{t('organizations.config.silentInstalation')}</Label>
                          <InfoPopover id="silentInstalation-instructions" small body={t('organizations.config.silentInstalation-instructions')} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup check>
                          <Input type="checkbox" name="languageStep" id="languageStep"
                            checked={this.state.languageStep} onChange={this.handleChange} />
                          <Label for="languageStep" >{t('organizations.config.language-step')}</Label>
                          <InfoPopover id="language-step-instructions" small body={t('organizations.config.language-step-instructions')} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={12}>
                      <FormGroup>
                          <Label for="languages">{t('organizations.config.select-language')}</Label>
                          <InfoPopover id="select-language-instructions" small body={t('organizations.config.select-language-instructions')} />
                          <Multiselect id="languages" multiple={true} dataKey="code" textField="iso_name"
                            data={this.state.languages_values} value={this.state.languages} disabled={!this.state.languageStep}
                            onChange={value => this.handleChange({ name: "languages", value: value })} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup check>
                          <Input type="checkbox" name="subscriptionStep" id="subscriptionStep"
                            checked={this.state.subscriptionStep} onChange={this.handleChange} />
                          <Label for="subscriptionStep" >{t('organizations.config.subscriptionStep')}</Label>
                          <InfoPopover id="subscriptionStep-instructions" small body={t('organizations.config.subscriptionStep-instructions')} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup check>
                          <Input type="checkbox" name="transactionStep" id="transactionStep"
                            checked={this.state.transactionStep} onChange={this.handleChange} />
                          <Label for="transactionStep" >{t('organizations.config.transactionStep')}</Label>
                          <InfoPopover id="transactionStep-instructions" small body={t('organizations.config.transactionStep-instructions')} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <br/><br/>
                    
                    <Row form>
                      <Col md={6}>
                        <Button color="primary" className="mt-2 mb-2 mr-2" onClick={this.updateConfig}>
                          {t("organizations.actions.save-configs")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup >
      </Fragment >
    );
  }
}

export default withTranslation()(AddInConfig);
