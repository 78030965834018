import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
  Row, Col, Card, CardBody,
} from 'reactstrap';
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';

import PageTitle from 'Layout/Components/PageTitle';
import ContactMessageModal from "./ContactMessageModal";

import { getContactMessages } from "../../API/ContactMessagesAPI";
import { toast } from "react-toastify";

class ContactMessages extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      contactMessages: [],
      contactMessage: {},
      openEdit: false
    };

    this.loadContactMessages = this.loadContactMessages.bind(this);
    this.onSubmitCallback = this.onSubmitCallback.bind(this);
  }

  componentDidMount() {
    this.loadContactMessages();
  }

  loadContactMessages() {
    getContactMessages().then(result => {
      this.setState({
        contactMessages: result
      })
    }).catch(error => {
      console.error("Error getting Messages", error);
      toast["error"](this.props.t('errors.loading', { element: 'Messages', message: error.message }));
    });
  }

  onSubmitCallback() {
    this.loadContactMessages();
  }

  render() {
    const { t, i18n } = this.props;

    const editContactMessageModal = this.state.openEdit &&
      <ContactMessageModal showOpenButton={false} open={true} contactMessage={this.state.contactMessage}
        onClose={() => this.setState({ openEdit: false })}
        onSubmitCallback={this.onSubmitCallback} />;

    return (
      <Fragment>
        <PageTitle
          heading={t("contactMessages.title")}
          icon="pe-7s-settings icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <ContactMessageModal onSubmitCallback={this.onSubmitCallback} />
                  {editContactMessageModal}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    columns={[
                      {
                        Header: t("contactMessages.subject"),
                        accessor: 'subject',
                        id: 'subject'
                      },
                      {
                        Header: t("contactMessages.message"),
                        accessor: 'message',
                        id: 'message',
                        Cell: (prop) => (
                          <div className="d-block w-100 text-left">
                            {prop.value ? `${prop.value.substring(0, 200)}...` : ''}
                          </div>
                        )
                      },
                      {
                        Header: t("contactMessages.sendto"),
                        accessor: 'sendto',
                        id: 'sendto'
                      },
                      {
                        Header: t("contactMessages.lang"),
                        accessor: 'lang',
                        id: 'lang'
                      },
                      {
                        Header: t("contactMessages.links"),
                        accessor: 'links',
                        id: 'links'
                      }
                    ]}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            if (e.target.tagName.toLowerCase() === 'button') {
                              return; // Ignore clicks on buttons
                            }
                            this.setState({
                              openEdit: true,
                              contactMessage: rowInfo.original
                            })
                          },
                          style: {
                            cursor: 'pointer'
                          }
                        }
                      }
                      return {}
                    }}
                    data={this.state.contactMessages}
                    defaultPageSize={10}
                    className="-striped -highlight" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withTranslation()(ContactMessages);