import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input, Row
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { toast } from 'react-toastify';
import { PaymentsContext } from './PaymentsContext';
import { toDate } from 'Application/Helpers/TextHelper';

class PaymentModal extends React.Component {

  static contextType = PaymentsContext;

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      payment: this.initPayment()
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    if (this.context.isEdit()) {
      const payment = this.context.payment;

      // Adjust dates
      payment.cancelDate = toDate(payment.cancelDate);
      payment.expireDate = toDate(payment.expireDate);
      payment.paymentDate = toDate(payment.paymentDate);
      payment.startDate = toDate(payment.startDate);

      this.setState({
        payment: payment
      })
    }
  }

  initPayment() {
    return {
      billed_amount: 0.0,
      cancelDate: null,
      discount: 0.0,
      domain: "",
      email: "",
      expireDate: null,
      id: null,
      invoice_link: "",
      paymentDate: null,
      paypalid: "",
      idPaymentPaypal: "",
      price: 0.0,
      quantity: 0.0,
      startDate: null,
      status: "active",
      total: 0.0,
      type: ""
    }
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    const payment = this.initPayment();
    if (!this.props.payment) { // open for new payment
      payment.domain = this.context.domain;
      payment.email = this.context.email;
    }
    this.setState({
      modal: !this.state.modal,
      payment: payment
    });
    this.context.openPayment(); // clear context account
  }

  handleChange(event) {
    const obj = event.target || event;
    const key = obj.name;
    const value = obj.type === 'checkbox' ? obj.checked : obj.value;
    this.setState({
      payment: {
        ...this.state.payment,
        [key]: value
      }
    });
  }

  submit() {
    this.context.savePayment(this.state.payment);
    this.toggle();
  }

  render() {
    const { t, i18n } = this.props;

    const title = this.context.isEdit()
      ? t('payments.actions.edit') : t('payments.actions.create');

    const openButton = this.props.showOpenButton &&
      <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
        onClick={() => this.toggle()}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('payments.actions.create')}
      </Button>;

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}
          size='xl' scrollable={true}>
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="domain">{t('payments.domain')}</Label>
                    <Input type="text" name="domain" id="domain" disabled={true}
                      value={this.state.payment.domain} onChange={this.handleChange} />
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="email">{t('payments.email')}</Label>
                    <Input type="email" name="email" id="email" disabled={this.context.isEdit()}
                      value={this.state.payment.email} onChange={this.handleChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col sm={3}>
                  <FormGroup>
                    <Label for="type">{t('payments.type')}</Label>
                    <Input type="select" name="type" id="type" disabled={this.context.isEdit()}
                      value={this.state.payment.type} onChange={this.handleChange}>
                      <option></option>
                      <option>Direct</option>
                      <option>Paypal</option>
                      <option>Trial</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label for="status">{t('payments.status')}</Label>
                    <Input type="select" name="status" id="status"
                      value={this.state.payment.status} onChange={this.handleChange}>
                      <option>active</option>
                      <option>expired</option>
                      <option>waiting payment</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label for="startDate">{t('payments.start_date')}</Label>
                    <DatePicker id="startDate" className="form-control" popperPlacement="top-end"
                      selected={this.state.payment.startDate} dateFormat="yyyy-MM-dd"
                      onChange={date => this.handleChange({ name: "startDate", value: date })} />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label for="expireDate">{t('payments.expire_date')}</Label>
                    <DatePicker id="expireDate" className="form-control" popperPlacement="top-end"
                      selected={this.state.payment.expireDate} dateFormat="yyyy-MM-dd"
                      onChange={date => this.handleChange({ name: "expireDate", value: date })} />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col sm={3}>
                  <FormGroup>
                    <Label for="price">{t('payments.price')}</Label>
                    <Input type="number" step=".01" name="price" id="price"
                      value={this.state.payment.price} onChange={this.handleChange} />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label for="quantity">{t('payments.quantity')}</Label>
                    <Input type="number" step="1" min="0" name="quantity" id="quantity"
                      value={this.state.payment.quantity} onChange={this.handleChange} />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label for="discount">{t('payments.discount')}</Label>
                    <Input type="number" step=".01" name="discount" id="discount"
                      value={this.state.payment.discount} onChange={this.handleChange} />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label for="total">{t('payments.total')}</Label>
                    <Input type="number" step=".01" name="total" id="total"
                      value={this.state.payment.total} onChange={this.handleChange} />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col sm={3}>
                  <FormGroup>
                    <Label for="billed_amount">{t('payments.billed_amount')}</Label>
                    <Input type="number" step=".01" name="billed_amount" id="billed_amount"
                      value={this.state.payment.billed_amount} onChange={this.handleChange} />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label for="paymentDate">{t('payments.payment_date')}</Label>
                    <DatePicker id="paymentDate" className="form-control" popperPlacement="top-end"
                      selected={this.state.payment.paymentDate} dateFormat="yyyy-MM-dd"
                      onChange={date => this.handleChange({ name: "paymentDate", value: date })} />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label for="cancelDate">{t('payments.cancel_date')}</Label>
                    <DatePicker id="cancelDate" className="form-control" popperPlacement="top-end"
                      selected={this.state.payment.cancelDate} dateFormat="yyyy-MM-dd"
                      onChange={date => this.handleChange({ name: "cancelDate", value: date })} />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col sm={3}>
                  <FormGroup>
                    <Label for="paypalid">{t('payments.paypalid')}</Label>
                    <Input type="text" name="paypalid" id="paypalid" disabled={this.context.isEdit()}
                      value={this.state.payment.paypalid} onChange={this.handleChange} />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label for="idPaymentPaypal">{t('payments.idPaymentPaypal')}</Label>
                    <Input type="text" name="idPaymentPaypal" id="idPaymentPaypal" disabled={this.context.isEdit()}
                      value={this.state.payment.idPaymentPaypal} onChange={this.handleChange} />
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="invoice_link">{t('payments.invoice_link')}</Label>
                    <Input type="text" name="invoice_link" id="invoice_link"
                      value={this.state.payment.invoice_link} onChange={this.handleChange} />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>{t('actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

PaymentModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(PaymentModal);
