import React, { Fragment } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import LanguageHelper from '../../Helpers/LanguageHelper'

class ThreatHighlights extends React.Component {

  render() {
    const { t, i18n } = this.props;

    const { decision, message, threats, origin, campaign } = this.props;
    const threatList = threats && threats.length > 0 ? threats.map((t, i) => <Label className='w-100' key={`threat_${i}`}>{t}</Label>) : "";
    const language = message.language ? LanguageHelper.getLanguage(message.language) : "";
    const originAddress = origin && (origin.city || origin.country) ? `${origin.city} (${origin.country})` : "";

    const decisionClass = (decision) => {
      switch (decision) {
        case 'Phishing':
        case 'Spam':
        case 'Malware':
          return 'fg-classification bg-ev-phishing';
        case 'Warning':
          return 'fg-classification bg-ev-warning';
        case 'Legitimate':
          return 'fg-classification bg-ev-legitimate';
        default:
          return '';
      }
    }

    return (
      <Fragment>
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>{t("threat_intelligence.email_details.threat_highlights")}</CardTitle>
            <Row form>
              <Col md={12} className={decisionClass(decision)}>
                <Label className="mr-sm-2">{t("threat_intelligence.email_details.classification")}:</Label>
                <Label>{decision}</Label>
              </Col>
              <Col md={12}>
                {threatList}
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>{t("threat_intelligence.email_details.key_characteristics")}</CardTitle>
            <Row form>
              <Col md={12}>
                <Label className="mr-sm-2">{t("threat_intelligence.email_details.message_origin")}:</Label>
                <Label>{originAddress}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("threat_intelligence.email_details.message_language")}:</Label>
                <Label>{language}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("threat_intelligence.email_details.message_agent")}:</Label>
                <Label>{message.userAgent}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("threat_intelligence.email_details.campaign_name")}:</Label>
                <Label>{campaign.name}</Label>
                <Label>{campaign.id}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("threat_intelligence.email_details.sent_date")}:</Label>
                <Label>{new Date(message.originDate).toLocaleString()}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("threat_intelligence.email_details.sender")}:</Label>
                <Label>{message.originEmailAddress}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("threat_intelligence.email_details.sender_ip")}:</Label>
                <Label>{message.originSourceIP}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("threat_intelligence.email_details.recipient")}:</Label>
                <Label>{message.receiver}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("threat_intelligence.email_details.subject")}:</Label>
                <Label>{message.subject}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("threat_intelligence.email_details.reported")}:</Label>
                <Label>{message.reported ? "yes" : "no"}</Label>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Fragment >
    )
  }
}

export default withTranslation()(ThreatHighlights);
