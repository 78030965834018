import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { withTranslation } from 'react-i18next';

import PageTitle from 'Layout/Components/PageTitle';
import ReactTable from "react-table";
import { getReportByThreat } from "API/DlpAPI";
import { toast } from "react-toastify";
import { Card, CardBody } from "reactstrap";

class ComplianceViolations extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      violations: [],
      page: 0,
      totalPages: 1
    }

    this.getViolations.bind(this);
  }

  componentDidMount() {
    this.getViolations(0);
  }

  getViolations(pageIndex) {
    getReportByThreat(pageIndex + 1).then(result => {
      this.setState({
        violations: result.malicious || [],
        page: result.page > 0 ? result.page - 1 : 0,
        totalPages: result.totalPages
      });
    }).catch(error => {
      console.error("Error getting Compliance Violations", error);
      toast["error"](this.props.t('errors.loading', { element: 'Compliance Violations', message: error.message }));
    });
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("dlp.violations.title")}
          subheading={t("dlp.violations.title-description")}
          icon="pe-7s-lock icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Card className="main-card mb-3">
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: t("dlp.violations.date"),
                    accessor: 'message.originDate'
                  },
                  {
                    Header: t("dlp.violations.sender"),
                    accessor: 'message.originEmailAddress'
                  },
                  // {
                  //   Header: t("dlp.violations.sender_ip"),
                  //   accessor: 'message.originSourceIP'
                  // },
                  {
                    Header: t("dlp.violations.recipient"),
                    accessor: 'message.receiver'
                  },
                  {
                    Header: t("dlp.violations.subject"),
                    accessor: 'message.subject'
                  },
                  // {
                  //   Header: t("dlp.violations.expression_ids"),
                  //   accessor: '',
                  // },
                  // {
                  //   Header: t("dlp.violations.policies_ids"),
                  //   accessor: '',
                  // },
                  {
                    Header: t("dlp.violations.violations"),
                    accessor: 'violations',
                    Cell: (prop) => {
                      const violations = prop.row.violations;
                      return (
                        <div className="d-block w-100 text-center">
                          {Object.keys(violations)}
                        </div>
                      )
                    }
                  }
                ]}
                data={this.state.violations}
                page={this.state.page}
                pages={this.state.totalPages}
                defaultPageSize={10}
                showPageSizeOptions={false}
                sortable={false}
                manual
                onFetchData={(state, instance) => {
                  this.getViolations(state.page);
                }}
                className="-striped -highlight" />
            </CardBody>
          </Card>
        </CSSTransitionGroup>
      </Fragment >
    )
  }
}

export default withTranslation()(ComplianceViolations);