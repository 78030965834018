

export function toDate(dateString) {
  if (dateString) {
    return new Date(dateString);
  }
  return null;
}

function fallbackCopy(text) {
  return new Promise((resolve, reject) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
      resolve(msg);
    } catch (err) {
      console.error('Fallback: Unable to copy text', err);
      reject(err);
    }

    document.body.removeChild(textArea);
  });
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    return fallbackCopy(text);
  }
  return navigator.clipboard.writeText(text);
}

export function parseEmailLinks(htmlString) {
  return new Promise((resolve, reject) => {
    const urlRegex = /href\s*=\s*(['"])(https?:\/\/.+?)\1/ig;
    let link;
    const list = [];
    while ((link = urlRegex.exec(htmlString)) !== null) {
      if (!list.includes(link[2])) {
        list.push(link[2]);
      }
    }
    resolve(list);
  });
}

export function encodeAsBase64(text) {
  try {
    return btoa(encodeURIComponent(text).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode('0x' + p1);
      }));
  } catch (err) {
    console.error('Unable to encode text.', err);
    return text;
  }
}
