import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input, Nav, NavItem, NavLink
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ReactTable from "react-table";
import classnames from 'classnames';

import {createSMTPConfig, getDefaultSMTPConfig} from "../../../API/PhishingAPI";
import SMTPSendTestEmailModal from './SMTPSendTestEmailModal';

class SMTPConfigModal extends React.Component{
  constructor(props){
    super(props);
    console.log("Constructor called SMTPConfigModal", this.props.defaultConfig)
    this.state = {
      modal: this.props.open,
      from_name:"",
      from_email:"",
      isEdit: this.props.smtpConfig ? true:false,
      smtp_config: {
        id:"",
        name:"",
        from_address:"",
        ...this.props.defaultConfig},
      /*smtp_config:{
        id:"",
        name:"",
        interface_type: "SMTP",
        ignore_cert_errors: false,
        from_address:"",
        host:"",
        username:"",
        password:"",
        headers:[]
      },*/
      header:{},
      errors:{},
      activeTab: 1
    }
    this.toggle = this.toggle.bind(this);
    this.handleChangeForHeader = this.handleChangeForHeader.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitHeader = this.submitHeader.bind(this);
    this.submitSMTPConfig = this.submitSMTPConfig.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleNameAndEmail = this.handleNameAndEmail.bind(this);
  }
  componentDidMount(){
    if(this.state.isEdit){
      const {id, modified_date, ...smtpConfig} = this.props.smtpConfig;
      if(!this.props.copiedVersion){
          smtpConfig['id'] = id;
      }
      
      const address = smtpConfig.from_address;
      const name = address.substring(0, address.indexOf('<'));
      let lastIndex=address.length;
      if(address.indexOf('>') != -1){
        lastIndex = address.indexOf('>');
      }
      const email = address.substring(address.indexOf('<')+1, lastIndex);
      this.setState({
        from_name: name,
        from_email: email,
        smtp_config: smtpConfig
      })
    }
    this.setState({
      activeTab: 1
    })
  }



  handleNameAndEmail(event){
    const key = event.target.name;
    const val = event.target.value;
    this.setState({
      [key]: val
    }, () => {
      this.state.smtp_config.from_address = this.state.from_name+"<"+this.state.from_email+">";
    });
  }

  handleChangeForHeader(event){
    const key = event.target.name;
    const value = event.target.type === 'checkbox'
      ? event.target.checked : event.target.value;
    this.setState(updater => ({
      header: {
        ...updater.header,
        [key]: value
      }
    }));
  }
  handleChange(event){
    const key = event.target.name;
    const value = event.target.type === 'checkbox'
      ? event.target.checked : event.target.value;
    this.setState(updater => ({
      smtp_config: {
        ...updater.smtp_config,
        [key]: value
      }
    }));
  }
  submitHeader(){
    this.setState(updater => ({
      smtp_config:{
        ...updater.smtp_config,
        headers:[...updater.smtp_config.headers, this.state.header]
      },
      //For some reason need to set the object variable to empty only then it empty the field.
      header:{
        key:"",
        value:""
      }
    }));
  }

  handleValidation(){
    var fields = this.state.smtp_config;
    var errors = {};
    var formIsValid = true;

    //Name cannot be empty
    if(!fields["name"]){
      formIsValid = false;
      errors["name"] = "Profile Name cannot be empty";
    }

    /*if(!fields["from_name"]){
      formIsValid = false;
      errors["from_name"] = "From Name cannot be empty";
    }*/

    if(!this.state.from_email){
      formIsValid = false;
      errors["from_email"] = "From email cannot be empty";
    }

    /*if(!fields["from_address"]){
      formIsValid = false;
      errors["from_address"] = "From Address cannot be empty";
    }*/

    if(!fields["host"]){
      formIsValid = false;
      errors["host"] = "Host cannot be empty";
    }

    if(!fields["username"]){
      formIsValid = false;
      errors["username"] = "Username cannot be empty";
    }

    if(!fields["password"]){
      formIsValid = false;
      errors["password"] = "Password cannot be empty";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  submitSMTPConfig(){
    if(!this.handleValidation()){
      return;
    }
    this.state.smtp_config.from_address = this.state.from_name+"<"+this.state.from_email+">";
    const promise = createSMTPConfig(this.state.smtp_config);
    promise
      .then(result => {
        this.toggle();
        this.props.onSubmit();
      }).catch(error => {
        const messageKey = this.state.isEdit ? 'errors.updating' : 'errors.creating';
        toast["error"](this.props.t(messageKey, { element: 'SMTP Config', message: error.message }));
      })
  }

  toggleTab(tab){
    this.setState({
      activeTab: tab
    });
  }

  render(){
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
    <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
      onClick={() => this.toggle()}>
      <i className="lnr-plus-circle btn-icon-wrapper"> </i>
      {t('smtp_config.actions.create')}
    </Button>;

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
          <ModalHeader toggle={this.toggle}>{t('smtp_config.actions.create')}</ModalHeader>
          <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink className={classnames({ active: this.state.activeTab == '1' })} onClick={() => this.toggleTab('1')}>General</NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink className={classnames({ active: this.state.activeTab == '2' })} onClick={() => this.toggleTab('2')}>Advanced</NavLink>
            </NavItem> */}
          </Nav>
          {/* {this.state.activeTab == '1'? */}
            <Fragment>
            <FormGroup>
              <Label>Name:</Label>
              <Input type="text" name="name" id="name" placeholder={t('smtp_config.placeholder.name')} value={this.state.smtp_config.name} onChange={this.handleChange}/>
              <span style={{color: "red"}}>{this.state.errors["name"]}</span>
            </FormGroup>
            <FormGroup>
              <Label>Spoofing Name:</Label>
              <Input type="text" name="from_name" id="from_name" value={this.state.from_name}
              placeholder={t('smtp_config.placeholder.from_name')} onChange={this.handleNameAndEmail} />
              <span style={{color: "red"}}>{this.state.errors["from_name"]}</span>
            </FormGroup>
            <FormGroup>
              <Label>Spoofing Email:</Label>
              <Input type="email" name="from_email" id="from_email" value={this.state.from_email}
              placeholder={t('smtp_config.placeholder.spoofing_from_email')} onChange={this.handleNameAndEmail} />
              <span style={{color: "red"}}>{this.state.errors["from_email"]}</span>
            </FormGroup>
            </Fragment>
            {/* :
            <Fragment>
              <FormGroup>
              <Label>Interface Type:</Label>
              <Input type="text" name="type" id="type" value={this.state.smtp_config.interface_type}
              onChange={this.handleChange} disabled/>
            </FormGroup>
            <FormGroup>
              <Label>Host:</Label>
              <Input type="text" name="host" id="host" value={this.state.smtp_config.host}
              placeholder={t('smtp_config.placeholder.host')} onChange={this.handleChange}/>
              <span style={{color: "red"}}>{this.state.errors["host"]}</span>
            </FormGroup>
            <FormGroup>
              <Label>Username:</Label>
              <Input type="text" name="username" id="username" value={this.state.smtp_config.username}
              placeholder={t('smtp_config.placeholder.username')} onChange={this.handleChange}/>
              <span style={{color: "red"}}>{this.state.errors["username"]}</span>
            </FormGroup>
            <FormGroup>
              <Label>Password:</Label>
              <Input type="password" name="password" id="password" value={this.state.smtp_config.password}
              placeholder={t('smtp_config.placeholder.password')} onChange={this.handleChange}/>
              <span style={{color: "red"}}>{this.state.errors["password"]}</span>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" name="ignore_cert_errors" checked={this.state.smtp_config.ignore_cert_errors} onChange={this.handleChange}/>{' '}
                  {t('smtp_config.ignore_certificate_errors')}
              </Label>
            </FormGroup>
            <Form onSubmit={(e) => { e.preventDefault(); this.submitHeader(); }}>
              <FormGroup row className="mt-3">
                  <Col sm={4} >
                    <Input type="text" name="key" id="key" placeholder={t('smtp_config.placeholder.x-custom-header')}
                    value={this.state.header.key} onChange={this.handleChangeForHeader}/>
                  </Col>
                  <Col sm={4}>
                    <Input type="text" name="value" id="value"
                    value={this.state.header.value} onChange={this.handleChangeForHeader}/>
                  </Col>
                  <Col sm={4}>
                    <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="primary" onClick={this.submitHeader}>
                      <i className="lnr-plus-circle btn-icon-wrapper"> </i>
                      {t('smtp_config.actions.add_custom_header')}
                    </Button>
                  </Col>
              </FormGroup>
              <FormGroup>
              {this.state.smtp_config.headers && this.state.smtp_config.headers.length > 0 && <ReactTable
                columns={[
                  {
                    Header: t("smtp_config.header"),
                    accessor : 'key'
                  },
                  {
                    Header: t("smtp_config.value"),
                    accessor : 'value'
                  },
                  {
                    / * TODO: Replace inline style class with CSS class * /
                    Header: "Actions",
                    Cell: ({row}) => (
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button className="mb-2 mr-2 btn-icon btn-shadow" size="md" color="success" onClick={() => {this.state.smtp_config.headers.pop(row._original); this.forceUpdate();} }>
                          <i className="pe-7s-trash btn-icon-wrapper mx-auto"> </i>
                        </Button>
                      </div>
                    )
                  }
                ]}
                  data={this.state.smtp_config.headers}
                  showPageSizeOptions={false}
                  defaultPageSize={10}
                  pageSize={this.state.smtp_config.headers > 10 ? 10 : this.state.smtp_config.headers.length}
                  className="-striped -highlight"
                  />
                }
              </FormGroup>
              </Form>
            </Fragment>

          }           */}
             <FormGroup>
                <SMTPSendTestEmailModal smtpConfig={this.state.smtp_config}/>
              </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('smtp_config.actions.cancel')}</Button>
            <Button color="primary" onClick={this.submitSMTPConfig}>{t('smtp_config.actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }

  toggle(){
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      from_name:"",
      from_email:"",
      smtp_config: {
        id:"",
        name:"",
        from_address:"",
        ...this.props.defaultConfig},
      /*smtp_config:{
        name:"",
        interface_type: "SMTP",
        ignore_cert_errors: false,
        from_address:"",
        host:"",
        username:"",
        password:"",
        headers:[]
      },*/
      header:{},
      activeTab: 1
    });
  }
}
SMTPConfigModal.defaultProps = {
  open: false,
  showOpenButton: true
}
export default withTranslation()(SMTPConfigModal)
