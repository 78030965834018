import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { CampaignContext } from '../CampaignContext';
import { Multiselect } from 'react-widgets';

class DNSInfoModal extends React.Component {

  static contextType = CampaignContext;

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      DnsInfo: {
        DomainName: "",
        IP: "",
        NameServerOrganization: "",
        NameServer: []
      },
      nameServerData: []
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCreateNameServer = this.handleCreateNameServer.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      DnsInfo: {
        DomainName: "",
        IP: "",
        NameServerOrganization: "",
        NameServer: []
      },
      nameServerData: []
    });
  }

  handleChange(event) {
    const obj = event.target || event;
    this.setState(updater => ({
      DnsInfo: {
        ...updater.DnsInfo,
        [obj.name]: obj.value
      }
    }));
  }

  handleCreateNameServer(name) {
    const { nameServerData } = this.state;
    const nameServer = this.state.DnsInfo.NameServer;
    this.setState({
      nameServerData: [...nameServerData, name]
    })
    this.handleChange({ name: "NameServer", value: [...nameServer, name] })
  }

  submit() {
    this.context.addDNSInfo(this.state.DnsInfo);
    this.toggle();
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button className={`mb-2 mr-2 btn-icon btn-shadow btn-heading-position`}
        size="sm" color="primary" onClick={() => this.toggle()}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('campaign.dns-info.actions.add-dns')}
      </Button>;

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{t('campaign.dns-info.actions.add-dns')}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <FormGroup row>
                <Label for="DomainName" sm={2}>{t('campaign.dns-info.domain-name')}</Label>
                <Col sm={10}>
                  <Input type="text" name="DomainName" id="DomainName"
                    value={this.state.DnsInfo.DomainName} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="IP" sm={2}>{t('campaign.dns-info.ip')}</Label>
                <Col sm={10}>
                  <Input type="text" name="IP" id="IP"
                    value={this.state.DnsInfo.IP} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="NameServerOrganization" sm={2}>{t('campaign.dns-info.name-server-organization')}</Label>
                <Col sm={10}>
                  <Input type="text" name="NameServerOrganization" id="NameServerOrganization"
                    value={this.state.DnsInfo.NameServerOrganization} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="NameServer" sm={2}>{t('campaign.dns-info.name-server')}</Label>
                <Col sm={10}>
                  <Multiselect
                    data={this.state.nameServerData}
                    value={this.state.DnsInfo.NameServer}
                    allowCreate='onFilter'
                    onCreate={keyword => this.handleCreateNameServer(keyword)}
                    onChange={value => this.handleChange({ name: "NameServer", value: value })}
                  />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>{t('actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

DNSInfoModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(DNSInfoModal);
