import { trackPromise } from 'react-promise-tracker';
import { getAccessToken } from 'context/AuthContext';
import User from 'context/User';

const baseUrlAPI = process.env.REACT_APP_API_URL;

async function requestHeaders() {
  const accessToken = await getAccessToken(User.VERITAS);

  const requestHeaders = new Headers();
  requestHeaders.append('Accept', 'application/json');
  requestHeaders.append('Content-Type', 'application/json');
  if (accessToken) {
    requestHeaders.append("Authorization", `Bearer ${accessToken}`);
    requestHeaders.append("Provider", `${User.VERITAS}`);
  }
  return requestHeaders;
}

function handleResult(value) {
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
}

export async function handleResponse(response, isBlob = false) {
  let result;
  if (isBlob) {
    result = await response.blob();
  } else {
    result = handleResult(await response.text());
  }
  if (response.ok) {
    return result;
  }
  // handle error
  return Promise.reject({
    code: response.status,
    message: result && result.message ? result.message : response.statusText
  });
}

export async function fetchWithTimeout(resource, options = {}) {
  const { timeout = process.env.REACT_APP_REQUEST_TIMEOUT } = options;
  const controller = new AbortController();
  const timer = setTimeout(() => controller.abort(), timeout);
  try {
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal  
    });
    clearTimeout(timer);
    return response;
  } catch (error) {
    if (error.name === "AbortError") {
      throw new Error('Request Timeout');
    }
    throw error;
  }
}

export async function doGet(url, baseUrl = baseUrlAPI) {
  const headers = await requestHeaders();
  const response = await trackPromise(fetchWithTimeout(baseUrl + url, {
    method: 'GET',
    headers: headers
  }));
  return await handleResponse(response);
}

export async function doGetBlob(url, baseUrl = baseUrlAPI) {
  const headers = await requestHeaders();
  const response = await trackPromise(fetchWithTimeout(baseUrl + url, {
    method: 'GET',
    headers: headers,
    timeout: process.env.REACT_APP_REPORT_REQUEST_TIMEOUT
  }));
  return await handleResponse(response, true);
}

export async function doPost(url, body, baseUrl = baseUrlAPI) {
  const headers = await requestHeaders();
  const response = await trackPromise(fetchWithTimeout(baseUrl + url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body)
  }));
  return await handleResponse(response);
}

export async function doPut(url, baseUrl = baseUrlAPI) {
  const headers = await requestHeaders();
  const response = await trackPromise(fetchWithTimeout(baseUrl + url, {
    method: 'PUT',
    headers: headers
  }));
  return await handleResponse(response);
}

export async function doDelete(url, baseUrl = baseUrlAPI) {
  const headers = await requestHeaders();
  const response = await trackPromise(fetchWithTimeout(baseUrl + url, {
    method: 'DELETE',
    headers: headers
  }));
  return await handleResponse(response);
}

export function getDomain() {
  const user = User.getUserInfo();
  return user ? user.domain : null;
}

export function getProvider() {
  const user = User.getUserInfo();
  return user ? user.provider : null;
}

export function getPhishingAPIKey(){
  const user = User.getUserInfo();
  return user ? user.phishingAPIKey : null;
}

export default class BaseAPI {

  buildURL(url) {
    return baseUrlAPI + url;
  }

  /**
   * Build the header for the request. 
   * @param {*} extraHeaders Optional object with additional values to be included in the headers. It overrides the headers, if already exists. 
   * @returns an object Headers
   */
  async requestHeaders(extraHeaders = null) {
    const requestHeaders = new Headers();
    requestHeaders.append('Accept', 'application/json');
    requestHeaders.append('Content-Type', 'application/json');
    
    const accessToken = await getAccessToken(User.VERITAS);
    if (accessToken) {
      requestHeaders.append("Authorization", `Bearer ${accessToken}`);
      requestHeaders.append("Provider", `${User.VERITAS}`);
    }

    if (extraHeaders) {
      for (const header in extraHeaders) {
        requestHeaders.delete(header);
        requestHeaders.append(header, extraHeaders[header]);
      }
    }
    return requestHeaders;
  }

  async handleResponse(response) {
    const result = await response.text();
    try {
      return JSON.parse(result);
    } catch (error) {
      // ignore, response is a plain text
    }
    return result;
  }

  async handleResponseError(response) {
    let result = await response.text();
    try {
      result = JSON.parse(result);
    } catch (error) {
      // ignore, response is a plain text
    }
    return Promise.reject({
      code: response.status,
      message: result && result.message ? result.message : response.statusText
    });
  }

  handleBody(body) {
    if (body) {
      return JSON.stringify(body);
    }
    return null;
  }

  async doGet(url, header = null) {
    return trackPromise(
      fetchWithTimeout(this.buildURL(url), {
        method: 'GET',
        headers: await this.requestHeaders(header)
      }).then(response => {
        if (response.ok) {
          return this.handleResponse(response);
        }
        return this.handleResponseError(response);
      })
    );
  }

  async doPost(url, body, header = null) {
    return trackPromise(
      fetchWithTimeout(this.buildURL(url), {
        method: 'POST',
        headers: await this.requestHeaders(header),
        body: this.handleBody(body)
      }).then(response => {
        if (response.ok) {
          return this.handleResponse(response);
        }
        return this.handleResponseError(response);
      })
    );
  }

  async doPut(url, body, header = null) {
    return trackPromise(
      fetchWithTimeout(this.buildURL(url), {
        method: 'PUT',
        headers: await this.requestHeaders(header),
        body: this.handleBody(body)
      }).then(response => {
        if (response.ok) {
          return this.handleResponse(response);
        }
        return this.handleResponseError(response);
      })
    );
  }

  async doDelete(url, header = null) {
    return trackPromise(
      fetchWithTimeout(this.buildURL(url), {
        method: 'DELETE',
        headers: await this.requestHeaders(header),
      }).then(response => {
        if (response.ok) {
          return this.handleResponse(response);
        }
        return this.handleResponseError(response);
      })
    );
  }
}
