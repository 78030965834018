import React, { Fragment } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {
    Col, Row, Button, Form, FormGroup, Input, Label
} from 'reactstrap';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import { AuthContext } from 'context/AuthContext';
import UserLoginAPI from 'API/UserLoginAPI';

class Register extends React.Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            password: "",
            phonenumber: "",
            company: "",
            captcha: null,
            // agree: true
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCaptcha = this.handleCaptcha.bind(this);
        this.goBack = this.goBack.bind(this);
        this.doRegister = this.doRegister.bind(this);
    }

    handleChange(event) {
        const key = event.target.name;
        const value = event.target.type === 'checkbox'
            ? event.target.checked : event.target.value;
        this.setState({
            [key]: value
        });
    }

    handleCaptcha(value) {
        this.setState({captcha: value});
    }

    goBack() {
        let history = this.props.history;
        history.push('/');
        this.context.doLogout(); // clear context
    }

    doRegister() {
        const { name, email, password, phonenumber, company, captcha } = this.state;
        if (captcha && name && email && password && company && phonenumber) {
            UserLoginAPI.createUser(name, email, password, company, phonenumber).then(result => {
                if (result) {
                    toast["success"](this.props.t('register.success'));
                    this.setState({ captcha: null }); // reset captcha
                }
            }).catch(error => {
                console.error("Error on register", error);
                toast["error"](this.props.t('register.error'));
            });
        } else {
            if (!name || !email || !password || !phonenumber || !company) {
                toast["warning"](this.props.t('messages.fill_all_fields'));
            // }else if (!agree) {
            //     toast["warning"](this.props.t('register.agree_message'));
            } else if (!captcha) {
                toast["warning"](this.props.t('register.captcha_message'));
            }
        }
    }

    render() {
        const { t, i18n } = this.props;

        const disabled = !this.state.captcha;

        if (this.context.error) {
            toast["error"](this.context.error);
        }

        return (
            <Fragment>
                <main class="bg-white signup">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 signup-side" data-aos="fade-up">
                            <div class="row mt-5">
                                <div class="col"></div>
                                <div class="col-lg-3 col-md-3 col-sm-3">
                                    <a href="/"><img src="/imgs/logo.png" alt="" class="img-fluid" /></a>
                                </div>
                                <div class="col"></div>
                            </div>

                            <div class="row mt-3">
                                <div class="col"></div>
                                <div class="col-lg-10 col-md-10 col-sm-10">
                                    <p class="text-center my-3">{t("register.text")}</p>
                                    <Form onSubmit={(e) => { e.preventDefault(); }} autoComplete="off">
                                        <fieldset>
                                            <Input type="text" name="name" id="name"
                                                placeholder={t('register.name')} maxLength="50"
                                                value={this.state.name} onChange={this.handleChange} />
                                        </fieldset>
                                        <fieldset>
                                            <Input type="email" name="email" id="email"
                                                placeholder={t('register.email')} maxLength="100"
                                                value={this.state.email} onChange={this.handleChange} />
                                        </fieldset>
                                        <fieldset>
                                            <Input type="text" name="company" id="company"
                                                placeholder={t('register.company')} maxLength="50"
                                                value={this.state.company} onChange={this.handleChange} />
                                        </fieldset>
                                        <fieldset>
                                            <Input type="password" name="password" id="password"
                                                placeholder={t('register.password')} maxlength="50"
                                                value={this.state.password} onChange={this.handleChange} />
                                        </fieldset>
                                        <fieldset>
                                            <Input type="tel" name="phonenumber" id="phonenumber"
                                                placeholder={t('register.phonenumber')} maxlength="50"
                                                value={this.state.phonenumber} onChange={this.handleChange} />

                                        </fieldset>
                                        <fieldset class="check">
                                            {/* <Input type="checkbox" name="agree" id="agree" class="form-check-input"
                                                checked={this.state.agree} onChange={this.handleChange} /> */}
                                            <Label>
                                                <Trans i18nKey="register.agreement">
                                                    By signing up, I accept the
                                                    <a href="https://www.emailveritas.com/terms-service" target="_blank">Terms of Service</a>
                                                    <a href="https://www.emailveritas.com/privacy-policy" target="_blank">Privacy Policy</a>
                                                </Trans>
                                            </Label>
                                        </fieldset>
                                        <fieldset class="text-center">
                                            <ReCAPTCHA sitekey={this.context.siteKey} onChange={this.handleCaptcha} />
                                        </fieldset>
                                        <fieldset>
                                            <Input type='submit' color="primary" size="lg"
                                                onClick={this.doRegister} disabled={disabled}
                                                value={t("register.actions.create")} />
                                        </fieldset>
                                    </Form>
                                </div>
                                <div class="col"></div>
                            </div>

                            <div class="row mb-4">
                                <div class="col-12 px-4">
                                    <p class="text-center">
                                        {t('register.or')}
                                    </p>
                                </div>
                            </div>

                            <div class="row my-4">
                                <div class="col"></div>
                                <div class="col-10 px-4">
                                    <a href="#" onClick={this.context.loginMicrosoft} disabled={disabled}>
                                        <div class="row login-microsoft">
                                            <div class="col-lg-6 col-sm-10 text-center">
                                                <p class="my-3 ps-5">
                                                    <img src="/imgs/microsoft.png" alt={t("register.actions.login_microsoft")} className="me-3" />
                                                    {t("register.actions.login_microsoft")}
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col"></div>
                            </div>
                            <div class="row my-4">
                                <div class="col"></div>
                                <div class="col-10 px-4">
                                    <a href="#" onClick={this.context.loginGoogle} disabled={disabled}>
                                        <div class="row login-microsoft">
                                            <div class="col-lg-6 col-sm-10 text-center">
                                                <p class="my-3 ps-5">
                                                    <img src="/imgs/google.png" alt={t("register.actions.login_google")} className="me-3" />
                                                    {t("register.actions.login_google")}
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col"></div>
                            </div>

                            <div class="row my-4">
                                <div class="col"></div>
                                <div class="col-10 px-4">
                                    <p>{t('register.have_account')} <a href="#" onClick={this.context.loginEmailPassword}>{t('register.actions.login')}</a></p>
                                </div>
                                <div class="col"></div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12 bg-default">
                            <section class="about signup-why">
                                <h3 data-aos="fade-up">Protect yourself and your company against:</h3>

                                <div class="row icon-box" data-aos="fade-up">
                                    <div class="col-auto me-3">
                                        <div class="icon"><img src="/imgs/icons/interruption-verde-sec.png" alt="high accuracy" class="img-icon" /></div>
                                    </div>
                                    <div class="col">
                                        <h4 class="title">Business interruption</h4>
                                        <p class="description">Lorem ipsum dolor, sit amet consectetur adipisicing elit. </p>
                                    </div>
                                </div>

                                <div class="row icon-box" data-aos="fade-up">
                                    <div class="col-auto me-3">
                                        <div class="icon"><img src="/imgs/icons/chart-down-verde-sec.png" alt="high accuracy" class="img-icon" /></div>
                                    </div>
                                    <div class="col">
                                        <h4 class="title">Financial Losses</h4>
                                        <p class="description">Lorem ipsum dolor, sit amet consectetur adipisicing elit. </p>
                                    </div>
                                </div>

                                <div class="row icon-box" data-aos="fade-up">
                                    <div class="col-auto me-3">
                                        <div class="icon"><img src="/imgs/icons/bad-reputation-verde-sec.png" alt="high accuracy" class="img-icon" /></div>
                                    </div>
                                    <div class="col">
                                        <h4 class="title">Brand reputation cost</h4>
                                        <p class="description">Lorem ipsum dolor, sit amet consectetur adipisicing elit. </p>
                                    </div>
                                </div>

                                <div class="row icon-box" data-aos="fade-up">
                                    <div class="col-auto me-3">
                                        <div class="icon"><img src="/imgs/icons/data-loss-verde-sec.png" alt="high accuracy" class="img-icon" /></div>
                                    </div>
                                    <div class="col">
                                        <h4 class="title">Data Loss</h4>
                                        <p class="description">Lorem ipsum dolor, sit amet consectetur adipisicing elit. </p>
                                    </div>
                                </div>

                                <div class="row icon-box" data-aos="fade-up">
                                    <div class="col-auto me-3">
                                        <div class="icon"><img src="/imgs/icons/leaking-verde-sec.png" alt="high accuracy" class="img-icon" /></div>
                                    </div>
                                    <div class="col">
                                        <h4 class="title">Leaking confidential information</h4>
                                        <p class="description">Lorem ipsum dolor, sit amet consectetur adipisicing elit. </p>
                                    </div>
                                </div>

                                <div class="row icon-box" data-aos="fade-up">
                                    <div class="col-auto me-3">
                                        <div class="icon"><img src="/imgs/icons/law-book-verde-sec.png" alt="high accuracy" class="img-icon" /></div>
                                    </div>
                                    <div class="col">
                                        <h4 class="title">GDPR compliance</h4>
                                        <p class="description">Lorem ipsum dolor, sit amet consectetur adipisicing elit. </p>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
};

export default withTranslation()(Register);