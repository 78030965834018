import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import ReactTable from "react-table";
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import { ThreatContext } from '../ThreatContext';
import { createBlacklist } from '../../../API/BlacklistAPI';
import EmailDetails from '../EmailDetails';
import RenderInWindow from 'Layout/Components/RenderInWindow';
import InfoPopover from 'Layout/Components/InfoPopover';

class MessageOriginTable extends React.Component {

  static contextType = ThreatContext

  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      modalState: {
        decision: "",
        message: {},
        threats: []
      },
      sourceIP: '',
      smtpIPs: []
    }

    this.blacklistSource = this.blacklistSource.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  blacklistSource(ipUrl) {
    if (ipUrl) {
      createBlacklist(ipUrl).then(result => {
        toast["success"](this.props.t('threat_intelligence.messages.success_create_blacklist'));
      }).catch(error => {
        console.error(error);
        toast["error"](this.props.t('errors.creating', { element: 'Blacklist', message: error.message }));
      });
    } else {
      toast["warning"](this.props.t('messages.unable_to_create', {
        element: 'blacklist',
        message: this.props.t('messages.empty_field', { field: "Sender IP" })
      }));
    }
  }

  toggleModal(open) {
    this.setState({ openModal: open })
  }

  render() {
    const { t, i18n, history } = this.props;

    return (
      <Card className="main-card mb-3">
        <CardBody>
          <div>
            <RenderInWindow open={this.state.openModal} setOpen={this.toggleModal} title={t(t("threat_intelligence.email_details.title"))}>
              <EmailDetails state={this.state.modalState} />
            </RenderInWindow>
            <InfoPopover id="table-information" small body={t('threat_intelligence.origin.table-information')} />
          </div>
          <ReactTable
            columns={[
              {
                Header: t("threat_intelligence.origin.sent_date"),
                accessor: 'message.originDate',
                Cell: (prop) => {
                  const updated = new Date(prop.value).toLocaleString();
                  return (
                    <div className="d-block w-100 text-left">
                      {updated}
                    </div>
                  )
                }
              },
              {
                Header: t("threat_intelligence.origin.sender"),
                accessor: 'message.originEmailAddress'
              },
              {
                Header: t("threat_intelligence.origin.sender_ip"),
                accessor: 'message.originSourceIP'
              },
              {
                Header: t("threat_intelligence.origin.recipient"),
                accessor: 'message.receiver'
              },
              {
                Header: t("threat_intelligence.origin.subject"),
                accessor: 'message.subject'
              },
              // {
              //   Header: t("threat_intelligence.origin.threats"),
              //   accessor: 'threats',
              //   Cell: prop => (
              //     <span>
              //       {prop.value ? prop.value.map(value => `${value}; `) : ''}
              //     </span>
              //   )
              // },
              {
                Header: t("threat_intelligence.origin.classification"),
                accessor: 'decision',
                // Cell: prop => <span title={prop}>{prop.value}</span>
              }
            ]}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onMouseEnter: (e) => {
                    if (rowInfo.row.threats && rowInfo.row.threats.length > 0) {
                      e.currentTarget.setAttribute("title", `Threats: ${rowInfo.row.threats.toString()}`)
                    }
                  },
                  onClick: (e) => {
                    if (e.target.tagName.toLowerCase() === 'button') {
                      return; // Ignore clicks on buttons
                    }
                    this.setState({
                      openModal: true,
                      modalState: {
                        decision: rowInfo.original.decision,
                        message: rowInfo.original.messageDetails,
                        threats: rowInfo.original.threats
                      }
                    });               
                  },
                  style: {
                    cursor: 'pointer'
                  }
                }
              }
              return {}
            }}
            data={this.context.messagesOrigin}
            page={this.context.page}
            pages={this.context.totalPages}
            defaultPageSize={10}
            showPageSizeOptions={false}
            sortable={false}
            manual
            onFetchData={(state, instance) => {
              this.context.getThreatsUser(state.page);
            }}
            className="-striped -highlight" />
        </CardBody>
      </Card >
    );
  }
}

export default withRouter(withTranslation()(MessageOriginTable));