import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Tabs from 'react-responsive-tabs';
import { withTranslation } from "react-i18next";

import DashboardContent from './DashboardContent';
import DashboardProvider from './DashboardContext';
import DashboardActions from './DashboardActions';

class DashboardSelection extends React.Component {

  getTabsContent() {
    return [
      {
        title: '1 Day',
        content: <DashboardContent period="1" />
      },
      {
        title: '7 Days',
        content: <DashboardContent period="7" />
      },
      {
        title: '30 Days',
        content: <DashboardContent period="30" />
      },
      {
        title: 'All',
        content: <DashboardContent period="All" />
      }
    ];
  }

  getTabs() {
    return this.getTabsContent().map((tab, index) => ({
      title: tab.title,
      getContent: () => tab.content,
      key: index,
    }));
  }

  render() {

    const { t, i18n, match: { params } } = this.props;

    return (
      <Fragment>
        <DashboardProvider domain={params.domain}>
          <DashboardActions />
          <Tabs tabsWrapperClass="card-header"
            transform={false} showInkBar={true}
            items={this.getTabs()} />
        </DashboardProvider>
      </Fragment>
    )
  }
}

export default withRouter(withTranslation()(DashboardSelection))
