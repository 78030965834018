import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input, FormCheck, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';
import moment from 'moment';
import PhishingCustomSelect from '../Campaign/PhishingCustomSelect'
import { getSMTPConfigList } from "../../../API/PhishingAPI"

class SimulationsLaunchScreenForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      campaign: {
        url: "http://recursohumanos.com.br",
        launch_date: moment().add(10, "minutes"),
        send_by_date: moment().add(70, "minutes")
      },
      errors: {},
      smtpConfigList: {}
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.launchSimulation = this.launchSimulation.bind(this);
    this.loadSMTPConfig = this.loadSMTPConfig.bind(this);
    this.handleChangeForPhishingSelect = this.handleChangeForPhishingSelect.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.parseDate = this.parseDate.bind(this);
  }

  componentDidMount() {
    this.loadSMTPConfig();
  }

  handleChange(event) {
    const key = event.target.name;
    const value = event.target.type === 'checkbox'
      ? event.target.checked : event.target.value;
    this.setState(updater => ({
      campaign: {
        ...updater.campaign,
        [key]: value
      }
    }));
  }

  handleChangeForPhishingSelect(event) {
    const key = event.target.name;
    var value;
    if (event.target.multiple) {
      value = Array.from(event.target.selectedOptions, option => {
        var obj = {};
        obj["name"] = option.value;
        return obj;
      });
    } else {
      value = { name: event.target.value };
    }
    this.setState(updater => ({
      campaign: {
        ...updater.campaign,
        [key]: value
      }
    }));
  }

  handleCheckbox(event) {
    this.setState({
      configure_spam_filter: event.target.checked
    })
  }

  handleValidation() {
    var fields = this.state.campaign;
    var errors = {};
    var formIsValid = true;

    //Reset the errors
    this.setState({ errors: null });

    //Name cannot be empty
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Cannot be empty";
    }

    if (!fields["url"]) {
      formIsValid = false;
      errors["url"] = "URL cannot be empty";
    }

    if (!fields["launch_date"]) {
      formIsValid = false;
      errors["launch_date"] = "Launch date cannot be empty";
    }

    if (!fields["launch_date"].isValid()) {
      formIsValid = false;
      errors["launch_date"] = "Launch date is Invalid";
    }

    if (!fields["send_by_date"].isValid()) {
      formIsValid = false;
      errors["send_by_date"] = "Send by Date is Invalid";
    }

    if (!fields["smtp"] || Object.keys(fields["smtp"]).length === 0) {
      formIsValid = false;
      errors["smtp"] = "SMTP config can not be empty";
    }

    if (!this.state.configure_spam_filter) {
      formIsValid = false;
      errors["spam_filter"] = "Please check it before launching the simulation";
    }

    console.log("Form", fields);
    console.log("Form valid", formIsValid);
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleDateChange(selectedDate, key) {
    this.setState(updater => ({
      campaign: {
        ...updater.campaign,
        [key]: selectedDate
      }
    }));
    if (key === 'launch_date') {
      const sendDate = moment(selectedDate).add(1, "hours");
      this.handleDateChange(sendDate, 'send_by_date');
    }
  }

  parseDate(date) {
    if (date && (moment.isMoment(date) || moment.isDate(date))) {
      return new Date(date.valueOf()).toISOString();
    }
    return date; // not able to format
  }

  loadSMTPConfig() {
    getSMTPConfigList()
      .then(res => {
        res.forEach((item, i) => {
          item.modified_date = (new Date(item.modified_date)).toLocaleString(undefined, { dateStyle: "full", timeStyle: "medium" })
        });
        this.setState({
          smtpConfigList: res
        });
      }).catch(error => {
        // TODO: Handling is pending when there will be some issue getting data.
      });
  }

  launchSimulation() {
    if (this.handleValidation()) {
      const campaign = { ... this.state.campaign };
      // format date only on launching
      campaign.launch_date = this.parseDate(campaign.launch_date);
      campaign.send_by_date = this.parseDate(campaign.send_by_date);
      console.log(campaign);
      this.props.onLaunch(campaign);
    } else {
      console.log("Some issue while validating form");
    }
  }

  render() {
    return (
      <Fragment>
        <FormGroup>
          <Label>Simulation Name:</Label>
          <Input type="text" name="name" id="name" onChange={this.handleChange} />
          <span style={{ color: "red" }}>{this.state.errors["name"]}</span>
        </FormGroup>
        <FormGroup>
          <Label>Landing Page URL:</Label>
          <Input type="text" name="url" id="url" onChange={this.handleChange} value={this.state.campaign.url} />
          <span style={{ color: "red" }}>{this.state.errors["url"]}</span>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col sm={6}>
              <Label for="launch_date">Launch Date:</Label>
              <Datetime name="launch_date" value={this.state.campaign.launch_date} utc={true} onChange={(data) => this.handleDateChange(data, "launch_date")} />
              <span style={{ color: "red" }}>{this.state.errors["launch_date"]}</span>
            </Col>
            <Col sm={6}>
              <Label>Send Emails By(Optional):</Label>
              <Datetime name="send_by_date" value={this.state.campaign.send_by_date} utc={true} onChange={(data) => this.handleDateChange(data, "send_by_date")} />
              <span style={{ color: "red" }}>{this.state.errors["send_by_date"]}</span>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Label>Sending Profile:</Label>
          <PhishingCustomSelect name="smtp" data={this.state.smtpConfigList}
            selected={this.state.campaign.smtp} onChange={this.handleChangeForPhishingSelect} />
          <span style={{ color: "red" }}>{this.state.errors["smtp"]}</span>
        </FormGroup>
        <FormGroup check className="mb-3">
          <Label check>
            <Input type="checkbox" value={this.state.configure_spam_filter} onChange={this.handleCheckbox} />{' '}
            Configure your spam filter, <a href="https://emailveritas.freshdesk.com/support/solutions/folders/66000133594" target="_blank">check our support</a> for tips and tricks.
          </Label><br />
          <span style={{ color: "red" }}>{this.state.errors["spam_filter"]}</span>
        </FormGroup>
        <Row>
          <Col md={{ size: 3, offset: 9 }} >
            <FormGroup>
              <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
                onClick={() => { this.launchSimulation() }} style={{ "width": "160px" }}>
                Launch Simulation
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Fragment>
    )
  }
}
export default withTranslation()(SimulationsLaunchScreenForm)
