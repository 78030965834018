import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Button } from 'reactstrap';
import { withTranslation } from "react-i18next";

import { DashboardContext } from './DashboardContext';
import { downloadCSV } from "Application/Helpers/FileHelper";

class DashboardActions extends React.Component {

  static contextType = DashboardContext

  render() {
    const { t, i18n, period } = this.props;

    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="2">
            <Button color="primary" className="mr-1" 
                    onClick={() => downloadCSV(this.context.exportReport(), `${this.context.dashboard_type}_dashboard_summary.csv`)}>
              {t("dashboards.export-csv")}
            </Button> 
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withTranslation()(DashboardActions);

