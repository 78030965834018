import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import update from 'immutability-helper';

import { CampaignContext } from '../CampaignContext';

class WhoisInfoModal extends React.Component {

  static contextType = CampaignContext;

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      WhoisInfo: {
        RegistryDataContactEmail: '',
        Registrant: {
          Phone: '',
          Country: '',
          Organization: '',
          Name: '',
          Street: '',
          City: '',
          Province: '',
          PostalCode: '',
          Fax: '',
          Email: ''
        }
      }
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      WhoisInfo: {
        RegistryDataContactEmail: '',
        Registrant: {
          Phone: '',
          Country: '',
          Organization: '',
          Name: '',
          Street: '',
          City: '',
          Province: '',
          PostalCode: '',
          Fax: '',
          Email: ''
        }
      }
    });
  }

  handleChange(event) {
    const obj = event.target || event;
    const path = obj.name.split(".");
    const name = path.pop();
    const value = obj.type === 'checkbox' || obj.value === 'select-one'
      ? obj.checked : obj.value;
    this.setState(updater => (
      update(updater, path.reduceRight((obj, next) => ({ [next]: obj }), { [name]: { $set: value } })))
    );
  }

  submit() {
    this.context.addWhoisInfo(this.state.WhoisInfo);
    this.toggle();
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button className={`mb-2 mr-2 btn-icon btn-shadow btn-heading-position`}
        size="sm" color="primary" onClick={() => this.toggle()}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('campaign.whois-info.actions.add-whois')}
      </Button>;

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{t('campaign.whois-info.actions.add-whois')}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <FormGroup row>
                <Label for="WhoisInfo.RegistryDataContactEmail" sm={2}>{t('campaign.whois-info.data-contact-email')}</Label>
                <Col sm={10}>
                  <Input type="email" name="WhoisInfo.RegistryDataContactEmail" id="WhoisInfo.RegistryDataContactEmail"
                    value={this.state.WhoisInfo.RegistryDataContactEmail} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <h3 className="form-heading pt-3 pb-3 float-left">
                    {t('campaign.whois-info.registrant.title')}
                  </h3>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="WhoisInfo.Registrant.Phone" sm={2}>{t('campaign.whois-info.registrant.phone')}</Label>
                <Col sm={10}>
                  <Input type="text" name="WhoisInfo.Registrant.Phone" id="WhoisInfo.Registrant.Phone"
                    value={this.state.WhoisInfo.Registrant.Phone} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="WhoisInfo.Registrant.Country" sm={2}>{t('campaign.whois-info.registrant.country')}</Label>
                <Col sm={10}>
                  <Input type="text" name="WhoisInfo.Registrant.Country" id="WhoisInfo.Registrant.Country"
                    value={this.state.WhoisInfo.Registrant.Country} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="WhoisInfo.Registrant.Organization" sm={2}>{t('campaign.whois-info.registrant.organization')}</Label>
                <Col sm={10}>
                  <Input type="text" name="WhoisInfo.Registrant.Organization" id="WhoisInfo.Registrant.Organization"
                    value={this.state.WhoisInfo.Registrant.Organization} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="WhoisInfo.Registrant.Name" sm={2}>{t('campaign.whois-info.registrant.name')}</Label>
                <Col sm={10}>
                  <Input type="text" name="WhoisInfo.Registrant.Name" id="WhoisInfo.Registrant.Name"
                    value={this.state.WhoisInfo.Registrant.Name} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="WhoisInfo.Registrant.Street" sm={2}>{t('campaign.whois-info.registrant.street')}</Label>
                <Col sm={10}>
                  <Input type="text" name="WhoisInfo.Registrant.Street" id="WhoisInfo.Registrant.Street"
                    value={this.state.WhoisInfo.Registrant.Street} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="WhoisInfo.Registrant.City" sm={2}>{t('campaign.whois-info.registrant.city')}</Label>
                <Col sm={10}>
                  <Input type="text" name="WhoisInfo.Registrant.City" id="WhoisInfo.Registrant.City"
                    value={this.state.WhoisInfo.Registrant.City} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="WhoisInfo.Registrant.Province" sm={2}>{t('campaign.whois-info.registrant.province')}</Label>
                <Col sm={10}>
                  <Input type="text" name="WhoisInfo.Registrant.Province" id="WhoisInfo.Registrant.Province"
                    value={this.state.WhoisInfo.Registrant.Province} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="WhoisInfo.Registrant.PostalCode" sm={2}>{t('campaign.whois-info.registrant.postal-code')}</Label>
                <Col sm={10}>
                  <Input type="text" name="WhoisInfo.Registrant.PostalCode" id="WhoisInfo.Registrant.PostalCode"
                    value={this.state.WhoisInfo.Registrant.PostalCode} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="WhoisInfo.Registrant.Fax" sm={2}>{t('campaign.whois-info.registrant.fax')}</Label>
                <Col sm={10}>
                  <Input type="text" name="WhoisInfo.Registrant.Fax" id="WhoisInfo.Registrant.Fax"
                    value={this.state.WhoisInfo.Registrant.Fax} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="WhoisInfo.Registrant.Email" sm={2}>{t('campaign.whois-info.registrant.email')}</Label>
                <Col sm={10}>
                  <Input type="email" name="WhoisInfo.Registrant.Email" id="WhoisInfo.Registrant.Email"
                    value={this.state.WhoisInfo.Registrant.Email} onChange={this.handleChange} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>{t('actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

WhoisInfoModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(WhoisInfoModal);
