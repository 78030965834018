import { trackPromise } from 'react-promise-tracker';
import { fetchWithTimeout, getDomain,handleResponse, getPhishingAPIKey} from './BaseAPI.js';


const servicesUrl = process.env.REACT_APP_CONSOLE_URL;
const goPhishingURL = process.env.REACT_APP_GO_PHISHING_URL;

function requestHeaders(moreHeaders) {
  const requestHeaders = new Headers();
  requestHeaders.append('Accept', 'application/json');
  requestHeaders.append('Content-Type', 'application/json');
  if(moreHeaders && moreHeaders["domain"]){
    requestHeaders.append('domain', moreHeaders["domain"]);
  } else{
    requestHeaders.append('domain', getPhishingAPIKey());
  }
  //console.log('Domain is', getDomain());
  return requestHeaders;
}
function requestHeaderMod(){
  console.log("Entered into requestHeader Modified");
  const requestHeaders = new Headers();
  requestHeaders.append('Accept', 'application/json');
  requestHeaders.append('Content-Type', 'application/json');
  //requestHeaders.append('authorization',`Bearer ${getPhishingAPIKey()}`)
  return requestHeaders;
}
function appendAPIKey(url, key){
  if(key){
    return url+"?api_key="+key;
  }
  return url+"?api_key="+getPhishingAPIKey();
}
function doGet(url, key) {
  url = appendAPIKey(url, key)
  let requestParams = {
    'method': 'GET',
    'dataType': 'json',
    headers: requestHeaderMod()
  };
  return trackPromise(
    fetchWithTimeout(url, requestParams).then(response => {
      return handleResponse(response);
    })
  );
}

function doGetBlob(url, moreHeader) {
  let requestParams = {
    method: 'GET',
    headers: requestHeaders(moreHeader)
  };
  return trackPromise(
    fetchWithTimeout(url, requestParams).then(response => {
      return handleResponse(response, true);
    })
  );
}

function doPost(url, data) {
  console.log("Entered into doPost" + data.url);
  url = appendAPIKey(url)
  return trackPromise(
    fetchWithTimeout(url, {
      method: 'POST',
      headers: requestHeaderMod(),
      body: JSON.stringify(data)
    }).then(response => {
      return handleResponse(response);
    })
  );
}
function doPut(url, data) {
  console.log("Entered into doPost" + data.url);
  return trackPromise(
    fetchWithTimeout(url, {
      method: 'PUT',
      headers: requestHeaders(),
      body: JSON.stringify(data)
    }).then(response => {
      return handleResponse(response);
    })
  );
}
function doDelete(url) {
  return trackPromise(
    fetchWithTimeout(url, {
      method: 'DELETE',
      headers: requestHeaders()
    }).then(response => {
      return handleResponse(response);
    })
  );
}


export function getwebsitecontent(url){
  return doPost(`${goPhishingURL}/import/site`,
    {
      "url": url
    });
}

export async function getlandingpages(headers){
  if(headers && headers["domain"]){
    let key = await fetchPhishingAPIKey(headers["domain"]);
    return doGet(`${goPhishingURL}/pages/`, key.apikey); 
  }else{
    return doGet(`${goPhishingURL}/pages/`);
  }
}

export function createLandingPage(landingPage){
  var url = `${goPhishingURL}/pages/`;
  if(!landingPage.id){
    console.log('Entered into new landing page creation mode');
    delete landingPage.id;
    return doPost(url, landingPage)
  }
  console.log('Entered into edit landing page creation mode');
  //var id = landingPage.id;
  //var url = url+id;
  return doPut(`${servicesUrl}/phishing_v1/landingpage/create`, landingPage);
}

export function deleteLandingPage(id){
  return doDelete(`${servicesUrl}/phishing_v1/landingpage/delete?id=${id}`);
}

export async function getEmailTemplateList(header){
  if(header && header["domain"]){
    let key = await fetchPhishingAPIKey(header["domain"]); 
    return doGet(`${goPhishingURL}/templates/`, key.apikey);
  }else{
    return doGet(`${goPhishingURL}/templates/`);
  }
}

export function createEmailTemplate(emailTemplate){
  const emailTemplateClone = {...emailTemplate};
  var url = `${goPhishingURL}/templates/`;
  if(emailTemplateClone.modified_date){
    delete emailTemplateClone.modified_date;
  }
  if(!emailTemplateClone.id){
    delete emailTemplateClone.id;
    return doPost(url, emailTemplateClone);
  }
  url = `${servicesUrl}/phishing_v1/emailtemplate/create`;
  return doPut(url, emailTemplateClone);
}

export function deleteEmailTemplate(id){
  return doDelete(`${servicesUrl}/phishing_v1/emailtemplate/delete?id=${id}`);
}

export function importEmailTemplate(data){
  return doPost(`${goPhishingURL}/import/email`, data);
}

export function getGroupList(){
  return doGet(`${goPhishingURL}/groups/`);
}

export function createGroup(group){
  const groupClone = {...group};
  var url = `${goPhishingURL}/groups/`;
  if(group.modified_date){
    delete group.modified_date;
  }
  if(!group.id){
    delete group.id;
    return doPost(url, group);
  }
  return doPut(`${servicesUrl}/phishing_v1/usersandgroups/create`, group);
}

export function deleteGroup(group_id){
  return doDelete(`${servicesUrl}/phishing_v1/usersandgroups/delete?group_id=${group_id}`);
}

export function getSMTPConfigList(){
  return doGet(`${goPhishingURL}/smtp/`);
}

export function createSMTPConfig(smtp_config){
  const smtpConfigClone = {...smtp_config};
  var url = `${goPhishingURL}/smtp/`;
  if(!smtpConfigClone.id){
    delete smtpConfigClone.id;
    return doPost(url, smtpConfigClone);
  }
  return doPut(`${servicesUrl}/phishing_v1/smtpconfig/create`, smtpConfigClone);
}

export function deleteSMTPConfig(smtpConfig_id){
  return doDelete(`${servicesUrl}/phishing_v1/smtpconfig/delete?smtpConfig_id=${smtpConfig_id}`);
}

export function getDefaultSMTPConfig(){
  return doGet(`${servicesUrl}/phishing_v1/smtpconfig/default`);
}

export function sendEmail(emailInfo){
  return doPost(`${goPhishingURL}/util/send_test_email`, emailInfo);
}

export function getCampaignList(){
  return doGet(`${goPhishingURL}/campaigns/summary`);
}

export function createPhishingCampaign(campaign){
  const campaignClone = {...campaign};
  var url = `${goPhishingURL}/campaigns/`;
  if(!campaignClone.id){
    delete campaignClone.id;
    return doPost(url, campaignClone);
  }
  return doPut(`${servicesUrl}/phishing_v1/campaign/create`, campaignClone);
}

export function fetchCampaignDetails(campaignId){
  return doGet(`${goPhishingURL}/campaigns/${campaignId}`)
}

export function deletePhishingCampaign(campaignId){
  return doDelete(`${servicesUrl}/phishing_v1/campaign/delete?campaignId=${campaignId}`)
} 
export function uploadFile(data){
  return trackPromise(
    fetchWithTimeout(`${servicesUrl}/phishing_v1/usersandgroups/upload`, {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: data
    }).then(response => {
      return handleResponse(response);
    })
  );
}

export function fetchCampaignTimelineDetails(campaignId){
  return doGet(`${goPhishingURL}/campaigns/${campaignId}/results`);
}

export function markCampaignAsComplete(campaignId){
  return doGet(`${goPhishingURL}/campaigns/${campaignId}/complete`);
}

export function downloadPhishingCampaignReport(campaignId){
  return doGetBlob(`${servicesUrl}/phishing_v1/campaign/getreport?campaignId=${campaignId}`);
}
export function fetchPhishingAPIKey(domain){
  let requestParams = {
    'method': 'GET',
    'dataType': 'json'
  };
  return trackPromise(
    fetchWithTimeout(`${servicesUrl}/phishing_v1/campaign/key?domain=${domain}`, requestParams).then(response => {
      return handleResponse(response);
    })
  );
}
