import React from "react";
import { withTranslation } from "react-i18next";
import {
  msalInstance, loginRequest, getAccessTokenMicrosoft, apiTokenRequest
} from "./microsoftProvider";
import { gapiInstance } from "./googleProvider"
import User from "./User";
import { getUserRoles } from "API/UsersAPI";
import { getContact } from "API/ConsoleServicesAPI";
import UserLoginAPI from "API/UserLoginAPI";
import { fetchPhishingAPIKey } from "API/PhishingAPI";

export const AuthContext = React.createContext();
export const AuthConsumer = AuthContext.Consumer;

class AuthProvider extends React.Component {

  // Authentication status
  NOT_AUTHENTICATED = "NOT_AUTHENTICATED";
  AUTHENTICATED = "AUTHENTICATED";
  LOADING = "LOADING";
  EMAIL_LOGIN = "EMAIL_LOGIN";
  EMAIL_REGISTER = "EMAIL_REGISTER";
  EMAIL_CONFIRM = "EMAIL_CONFIRM";
  FORGOT_PASSWORD = "FORGOT_PASSWORD";
  RESET_PASSWORD = "RESET_PASSWORD";

  constructor(props) {
    super(props);

    this.state = {
      siteKey: process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY,
      user: {},
      status: this.NOT_AUTHENTICATED,
      error: ''
    };

    this.loginMicrosoft = this.loginMicrosoft.bind(this);
    this.loginGoogle = this.loginGoogle.bind(this);
    this.loginEmailPassword = this.loginEmailPassword.bind(this);
    this.registerEmailPassword = this.registerEmailPassword.bind(this);
    this.doLoginEmailPassword = this.doLoginEmailPassword.bind(this);
    this.confirmEmail = this.confirmEmail.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.requestUserInfo = this.requestUserInfo.bind(this);
    this.onLoginFailure = this.onLoginFailure.bind(this);
    this.getLoginFailureMessage = this.getLoginFailureMessage.bind(this);
    this.doLogout = this.doLogout.bind(this);
    this.emailAuth = this.emailAuth.bind(this);
    this.emailAuthRoute = this.emailAuthRoute.bind(this);
    this.inProgress = this.inProgress.bind(this);
    this.authenticated = this.authenticated.bind(this);
    this.loginAsUser = this.loginAsUser.bind(this);
  }

  componentDidMount() {
    const user = User.getUserInfo();
    if (user && this.state.user !== user) {
      console.log('User is authenticated.');
      this.setState({
        user: user,
        status: this.AUTHENTICATED,
        error: ''
      });
    }
  }

  async loginMicrosoft() {
    this.setState({
      status: this.LOADING
    });
    try {
      const result = await msalInstance.loginPopup(loginRequest);
      // console.debug('Login Microsoft result', result);
      const account = result.account;
      if (account) {
        await this.onLoginSuccess(account.name, account.username, account.tenantId, User.MICROSOFT);
      } else {
        throw new Error("Unable to get Microsoft Account information.");
      }
    } catch (error) {
      this.onLoginFailure(User.MICROSOFT, error);
    }
  }

  loginGoogle() {
    this.setState({
      status: this.LOADING
    });
    gapiInstance.auth2.signIn().then(result => {
      // console.debug('Login Google result', result);
      let profile = result.getBasicProfile();
      if (profile) {
        this.onLoginSuccess(profile.getName(), profile.getEmail(), null, User.GOOGLE);
      } else {
        throw new Error("Unable to get Google Profile information.");
      }
    }).catch(error => {
      this.onLoginFailure(User.GOOGLE, error);
    });
  }

  async loginAsUser(name, email) {
    try {
      const accessToken = await getAccessToken(User.VERITAS);
      const result = await UserLoginAPI.token(email, accessToken, User.VERITAS);
      if (result && result.cod === "200" && result.jwt_token) {
        sessionStorage.setItem(VERITAS_TOKEN_KEY, result.jwt_token);
      } else {
        throw Error(result);
      }
      await this.onLoginSuccess(name, email, null, User.VERITAS);
    } catch (error) {
      console.error("Unable to login as user.", error);
      throw new Error("Unable to login as user " + email);
    }
  }

  loginEmailPassword() {
    this.setState({
      status: this.EMAIL_LOGIN
    });
  }

  registerEmailPassword() {
    this.setState({
      status: this.EMAIL_REGISTER
    });
  }

  forgotPassword() {
    this.setState({
      status: this.FORGOT_PASSWORD
    });
  }

  doLoginEmailPassword(email, password) {
    this.setState({
      status: this.LOADING
    });
    UserLoginAPI.login(email, password).then(result => {
      if (result && result.cod === "200" && result.data != null) {
        this.onLoginSuccess(result.data.name, result.data.email, null, User.VERITAS)
          .then(_ => {
            if (this.state.status === this.AUTHENTICATED) {
              sessionStorage.setItem(VERITAS_TOKEN_KEY, result.data.token);
            }
          });
      } else {
        throw new Error(result.data || "Unable to get EmailVeritas Login information.");
      }
    }).catch(error => {
      this.onLoginFailure(User.VERITAS, error);
    });
  }

  confirmEmail() {
    this.setState({
      status: this.EMAIL_CONFIRM
    });
  }

  resetPassword() {
    this.setState({
      status: this.RESET_PASSWORD
    });
  }

  async onLoginSuccess(name, email, tenant, provider) {
    try {
      console.log(`Sucessful login using ${provider}.`);
      // Exchange token, if needed
      await this.exchangeToken(provider, email);
      // Store user without roles
      const user = new User(name, email, tenant, null, provider, null);
      let key = await fetchPhishingAPIKey(user.domain);
      user.phishingAPIKey = key.apikey;
      User.setUserInfo(user);
      // Retrieve the user roles and store all info
      user.roles = await this.requestUserInfo();
      User.setUserInfo(user);
      this.setState({
        user: user,
        status: this.AUTHENTICATED,
        error: ''
      });
    } catch (error) {
      this.doLogout();
      this.onLoginFailure(provider, error);
    }
  }

  async exchangeToken(provider, email) {
    try {
      switch (provider) {
        case User.MICROSOFT:
        case User.GOOGLE:
          const token = await getAccessToken(provider, email);
          const result = await UserLoginAPI.token(email, token, provider);
          if (result && result.cod === "200" && result.jwt_token) {
            sessionStorage.setItem(VERITAS_TOKEN_KEY, result.jwt_token);
          } else {
            throw Error(result);
          }
          break;
        default:
          // console.warn("Unable to exchange token for provider", user.provider);
          break;
      }
    } catch (error) {
      console.error("Unable to exchange token.", error);
      throw new Error("Unable to exchange token for provider " + provider);
    }
  }

  async requestUserInfo() {
    try {
      const result = await getUserRoles();
      if (!result || !result.rules || result.rules.length <= 0) {
        throw new Error('NO_RULES');
      }
      return result.rules;
    } catch (error) {
      console.error("Error retriveing user info.", error);
      const contact = await getContact();
      if (error.code === 401) {
        throw new Error(this.props.t('login.unauthorized', { contact: contact.email }));
      } else if (error.message === 'NO_RULES') {
        throw new Error(this.props.t('login.misconfigured', { contact: contact.email }));
      }
      throw new Error(this.props.t('login.error'));
    }
  }

  onLoginFailure(provider, error) {
    console.error(`Error on login using ${provider}.`, error);
    this.setState({
      user: '',
      status: this.NOT_AUTHENTICATED,
      error: this.getLoginFailureMessage(provider, error)
    });
    User.clearUserInfo();
  }

  getLoginFailureMessage(provider, error) {
    switch (provider) {
      case "Microsoft":
        return error.errorMessage;
      case "Google":
        return gapiInstance.errorMessage(error);
      default:
        return error.message;
    }
  }

  doLogout() {
    const user = this.state.user;
    const provider = user ? user.provider : null;
    switch (provider) {
      case User.MICROSOFT:
        const logoutRequest = {
          account: msalInstance.getAccountByUsername(user.email)
        };
        msalInstance.logoutRedirect(logoutRequest);
        break;
      case User.GOOGLE:
        gapiInstance.auth2.signOut();
        break;
      case User.VERITAS:
        sessionStorage.removeItem(VERITAS_TOKEN_KEY);
        break;
      default:
        console.warn("Unable to logout provider:", provider);
        break;
    }
    this.setState({
      user: '',
      status: this.NOT_AUTHENTICATED,
      error: ''
    });
    User.clearUserInfo();
  }

  emailAuth() {
    return this.state.status === this.EMAIL_LOGIN
      || this.state.status === this.EMAIL_REGISTER
      || this.state.status === this.EMAIL_CONFIRM
      || this.state.status === this.FORGOT_PASSWORD
      || this.state.status === this.RESET_PASSWORD;
  }

  emailAuthRoute() {
    switch (this.state.status) {
      case this.EMAIL_REGISTER:
        return "register";
      case this.FORGOT_PASSWORD:
        return "password";
      case this.EMAIL_CONFIRM:
        return "confirm-email";
      case this.RESET_PASSWORD:
        return "reset-password";
      case this.EMAIL_LOGIN:
      default:
        return "login";
    }
  }

  inProgress() {
    return this.state.status === this.LOADING;
  }

  authenticated() {
    return this.state.status === this.AUTHENTICATED;
  }

  render() {
    return (
      <AuthContext.Provider value={
        {
          loginMicrosoft: this.loginMicrosoft,
          loginGoogle: this.loginGoogle,
          loginAsUser: this.loginAsUser,
          loginEmailPassword: this.loginEmailPassword,
          registerEmailPassword: this.registerEmailPassword,
          forgotPassword: this.forgotPassword,
          confirmEmail: this.confirmEmail,
          resetPassword: this.resetPassword,
          doLoginEmailPassword: this.doLoginEmailPassword,
          doLogout: this.doLogout,
          emailAuth: this.emailAuth,
          emailAuthRoute: this.emailAuthRoute,
          inProgress: this.inProgress,
          authenticated: this.authenticated,
          siteKey: this.state.siteKey,
          user: this.state.user,
          error: this.state.error
        }
      }>
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

export default withTranslation()(AuthProvider)

// EmailVeritas session token key
const VERITAS_TOKEN_KEY = "eVeritasToken"

/**
 * Get the access token based on the authentication provider.
 * @return array composed of provider and token or null if token not found.
 */
export async function getAccessToken(provider, email) {
  let token = null;
  switch (provider) {
    case User.MICROSOFT:
      token = await getAccessTokenMicrosoft(email, apiTokenRequest);
      break;
    case User.GOOGLE:
      const googleUser = gapiInstance.auth2.currentUser.get();
      // Use ID Token for authentication validation 
      token = googleUser.getAuthResponse().id_token;
      break;
    case User.VERITAS:
      token = sessionStorage.getItem(VERITAS_TOKEN_KEY);
      break;
    default:
      console.warn("Invalid Provider: ", provider);
      break;
  }
  return token;
}
