import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import { DropdownList } from 'react-widgets';
import { withTranslation } from 'react-i18next';

import { AccountContext } from './AccountContext';

class AcountModal extends React.Component {

  static contextType = AccountContext;

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      account: {
        name: "",
        email: "",
        department: ""
      }
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    if (this.context.isEdit()) {
      const { id, domain, status, name, email, department } = this.context.account;
      this.setState({
        account: { id, domain, status, name, email, department }
      })
    }
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      account: {
        name: "",
        email: "",
        department: ""
      }
    });
    this.context.openAccount(); // clear context account
  }

  handleChange(event) {
    const obj = event.target || event;
    this.setState(updater => ({
      account: {
        ...updater.account,
        [obj.name]: obj.value
      }
    }));
  }

  submit() {
    if (this.context.isEdit()) {
      this.context.updateAccount(this.state.account);
    } else {
      this.context.createAccount(this.state.account);
    }
    this.toggle();
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg"
        color="success" onClick={() => this.toggle(true)}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('accounts.actions.create')}
      </Button>;

    const title = this.context.isEdit() ? t('accounts.actions.edit') : t('accounts.actions.create')

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <FormGroup row>
                <Label for="name" sm={2}>{t('accounts.name')}</Label>
                <Col sm={10}>
                  <Input type="text" name="name" id="name"
                    value={this.state.account.name} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="address" sm={2}>{t('accounts.email')}</Label>
                <Col sm={10}>
                  <Input type="email" name="email" id="email"
                    value={this.state.account.email} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="address" sm={2}>{t('accounts.department')}</Label>
                <Col sm={10}>
                  <DropdownList
                    name="department" id="department"
                    data={this.context.departments}
                    textField="department"
                    valueField="department"
                    value={this.state.account.department}
                    allowCreate="onFilter"
                    onChange={(val) => this.handleChange({ name: 'department', value: val.department })} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>{t('actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

AcountModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(AcountModal);
