import React, { Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';

class ConfirmAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      confirmation: ""
    };
    this.toggle = this.toggle.bind(this);
    this.handleConfirmationChange = this.handleConfirmationChange.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleConfirmationChange(event) {
    this.setState({
      confirmation: event.target.value
    });
  }

  confirm() {
    if (this.props.confirmation && this.props.confirmation !== this.state.confirmation) {
      return;
    }
    if (typeof this.props.onConfirm === 'function') {
      this.props.onConfirm();
    }
    this.toggle();
  }

  render() {
    const { children, btnClassName, btnSize, color, title, message, confirmation, disabled } = this.props;

    const confirmationField = confirmation ?
      <Input type="text" name="organization.name" id="organization.name"
        value={this.state.confirmation} onChange={this.handleConfirmationChange} /> : ""

    return (
      <Fragment>
        <Button className={btnClassName} size={btnSize} color={color} disabled={disabled}
          onClick={() => this.toggle(false)}>
          {children}
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            {message}
            {confirmationField}
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>Cancel</Button>
            <Button color={color} onClick={this.confirm}>{children}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default ConfirmAction;
