import React, { Fragment } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
  Row, Col, Card, CardBody, Button, Label, Input,
} from 'reactstrap';
import ReactTable from 'react-table';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import SearchBox from 'Layout/Components/SearchBox';

import PageTitle from '../../Layout/Components/PageTitle';

import { AccountContext } from "../Accounts/AccountContext"
import { verify, authenticate, listAccounts, saveAccounts } from 'API/OutlookAPI';

const SelectTable = checkboxHOC(ReactTable);

class MSConfigs extends React.Component {

  static contextType = AccountContext;

  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
      name: "",
      department: "",
      filterStatus: [
        {name: 'active', checked: false},
        {name: 'suspended', checked: true},
        {name: 'not registered', checked: true}
      ],
      page: 0,
      totalPages: 1,
      loadedPages: [],
      total: 0,
      pageSize: 10,
      skiptoken: "",
      accounts: [],
      registering: false,
      selection: new Map(),
      selectAll: false
    };

    this.checkAuth = this.checkAuth.bind(this);
    this.requestAdminConsent = this.requestAdminConsent.bind(this);
    this.loadOutlookAccounts = this.loadOutlookAccounts.bind(this);
    this.registerAccounts = this.registerAccounts.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.filterAccounts = this.filterAccounts.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
  }

  componentDidMount() {
    this.checkAuth();
  }

  componentDidUpdate(prevProps, prevState) {
    const {authenticated, name, department, filterStatus, page, loadedPages, registering, pageSize} = this.state;
    if ((prevState.authenticated !== authenticated || prevState.name !== name || prevState.department !== department 
        || !prevState.filterStatus.every((status, index) => status.name === filterStatus[index].name && status.checked === filterStatus[index].checked)
        || prevState.page !== page || prevState.registering !== registering || prevState.pageSize !== pageSize)
        && authenticate && !loadedPages.includes(page) && !registering) {
      this.loadOutlookAccounts();
    }
  }

  checkAuth() {
    verify().then(result => {
      this.setState({ authenticated: true });
    }).catch(error => {
      this.setState({ authenticated: false });
      if ("popup_window_error" === error.errorCode) {
        toast["warning"](this.props.t('organizations.messages.error-blocking-popup'));
      }
      toast["warning"](this.props.t('organizations.messages.provider-not-authorizated', { provider: 'Microsoft' }));
    });
  }

  requestAdminConsent() {
    authenticate().then(result => {
      toast["success"](this.props.t('messages.success_update', { element: 'Admin Consent' }));
      this.setState({ authenticated: true });
    }).catch(error => {
      this.setState({ authenticated: false });
      if ("popup_window_error" === error.errorCode) {
        toast["warning"](this.props.t('organizations.messages.error-blocking-popup'));
      } else {
        toast["error"](this.props.t('errors.updating', { element: 'Admin Consent', message: error.message }));
      }
    });
  }

  loadOutlookAccounts() {
    const {name, department, filterStatus, pageSize, skiptoken, page, total} = this.state;
    const selectedStatus = filterStatus.filter(status => status.checked).map(status => status.name);
    listAccounts(name, department, selectedStatus, pageSize, skiptoken)
      .then(result => {
        // filter out accounts without email and already registered in the API and OutlookService
        // const accountsVal = result.accounts.filter(account => {
        //   const index = this.context.accounts.findIndex(evAccount => evAccount.email === account.email);
        //   return account.email !== null && account.email !== '' && (index < 0 || account.status !== 'active');
        // });
        const totalVal = result.total > 0 ? result.total : total;
        this.setState({
          accounts: this.state.accounts.concat(result.accounts), 
          total: totalVal,
          skiptoken: result.skiptoken,
          totalPages: Math.ceil(totalVal / result.top),
          loadedPages: this.state.loadedPages.concat([page])
        });
      }).catch(error => {
        console.error("Error getting Outlook Accounts", error);
        toast["error"](this.props.t('errors.loading', { element: 'Outlook Accounts', message: error.message }));
      });
  }

  async registerAccounts() {
    try {
      this.setState({
        registering: true
      });

      const selectedAccounts = Array.from(this.state.selection.values());
      // Create accounts on API
      const accounts = selectedAccounts.filter(account => { // filter out already registered accounts
        return this.context.accounts.findIndex(evAccount => evAccount.email === account.email) < 0;
      }).map(account => {
        return {
          email: account.email,
          name: account.name,
          department: account.department,
          status: 'active'
        };
      });
      await this.context.createAccounts(accounts);

      // Save selected accounts on Outlook
      const accountsOutlook = selectedAccounts.filter(account => { // filter out active accounts
        const index = this.context.accounts.findIndex(evAccount => evAccount.email === account.email);
        return account.email !== null && account.email !== '' && (index < 0 || account.status !== 'active');
      });
      const result = await saveAccounts(accountsOutlook);
      if (result !== null && result !== "") {
        toast["warning"](this.props.t('messages.unable_to_save', { element: 'Outlook Accounts', message: result }));
      }
    } catch (error) {
      console.error("Error registering Outlook Accounts", error);
      toast["error"](this.props.t('errors.saving', { element: 'Outlook Accounts', message: error.message }));
    } finally {
      this.setState({
        registering: false,
        page: 0,
        loadedPages: [],
        totalPages: 1,
        total: 0,
        skiptoken: "",
        accounts: [],
      });
    }
  }

  handleChangeFilter(status) {
    const filterStatus = this.state.filterStatus;
    this.setState({
      filterStatus: filterStatus.map(el => {
        if (el.name === status.name) {
          return status;
        }
        return el;
      }),
      page: 0,
      loadedPages: [],
      totalPages: 1,
      total: 0,
      skiptoken: "",
      accounts: []
    });
  }

  filterAccounts(values) {
    this.setState({
      name: values[0],
      department: values[1],
      page: 0,
      loadedPages: [],
      totalPages: 1,
      total: 0,
      skiptoken: "",
      accounts: []
    });
  }

  toggleSelection(key, shift, row) {
    let { selectAll, selection } = this.state;
    if (selection.has(row.email)) { // if key exists, remove it
      selection.delete(row.email);
    } else { // it doesn't exist, add it
      selection.set(row.email, row);
    }
    if (selection.size > 0) {
      // get the internals of ReactTable and get the sortedData
      const wrappedInstance = this.selectTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // adjust selectAll flag accordingly 
      selectAll = currentRecords.length === selection.size;
    }
    this.setState({ selectAll: selectAll, selection: selection });
  }

  toggleAll() {
    const selectAll = this.state.selectAll ? false : true;
    const selection = new Map();
    if (selectAll) {
      // get the internals of ReactTable and get the sortedData
      const wrappedInstance = this.selectTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // push all the IDs onto the selection array
      currentRecords.forEach(item => {
        if (item._original && item._original.status !== 'active') { // does not select active users
          selection.set(item._original.email, item._original);
        }
      });
    }
    this.setState({ selectAll: selectAll, selection: selection });
  }

  onPageChange(page) {
    this.setState({
      page: page 
    });
  }

  onPageSizeChange(newPageSize) {
    this.setState({
      page: 0,
      loadedPages: [],
      totalPages: 1,
      total: 0,
      skiptoken: "",
      accounts: [],
      pageSize: newPageSize 
    });
  }

  render() {
    const { t, i18n } = this.props;

    const customSelectInputComponent = props => {
      return (
        <input
          type={props.selectType || 'checkbox'}
          aria-label={`${props.checked ? 'Un-select':'Select'} row with id:${props.id}` }
          checked={props.checked}
          id={props.id}
          onClick={e => {
            const { shiftKey } = e
            e.stopPropagation()
            props.onClick(props.id, shiftKey, props.row)
          }}
          onChange={() => {}}
          disabled={props.row.status === 'active'} // disable selection for active users
        />
      )
    }

    return (
      <Fragment>
        <PageTitle
          heading={t("organizations.msconfig.title")}
          icon="pe-7s-cloud icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <SearchBox placeholders={[t('organizations.msconfig.filter-account'), t('organizations.msconfig.filter-department')]} 
                    doSearch={this.filterAccounts} />
                  {this.state.filterStatus.map((status, index) => (
                    <Label check className='custom-label-check' key={`check-box-${index}`}>
                      <Input type="checkbox" name={status.name} id={`filterStatus-${index}`}
                        checked={status.checked} onChange={() => this.handleChangeFilter({...status, checked: !status.checked})} />{' '}
                      {status.name}
                    </Label>
                  ))}
                  <Button className="mb-2 mr-2 btn-icon btn-shadow" color="success" size="lg"
                    onClick={this.registerAccounts} disabled={this.state.registering}>
                    <i className="lnr-cloud-sync btn-icon-wrapper"> </i>
                    {t("organizations.actions.sync-accounts")}
                  </Button>
                  <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color={this.state.authenticated ? "secondary" : "danger"}
                    onClick={this.requestAdminConsent}>
                    <i className="lnr-license btn-icon-wrapper"> </i>
                    {t("organizations.actions.admin-consent")}
                  </Button>
                  <Label>{t("organizations.messages.admin-consent-text")}</Label>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <h3 className="form-heading pt-3 pb-3">{t("organizations.msconfig.non-registered-accounts")}</h3>
                  <SelectTable
                    columns={[
                      {
                        Header: t("accounts.email"),
                        accessor: 'email',
                        id: 'email'
                      },
                      {
                        Header: t("accounts.name"),
                        accessor: 'name',
                        id: 'name'
                      },
                      {
                        Header: t("accounts.department"),
                        accessor: 'department',
                        id: 'department',
                      },
                      {
                        Header: t("accounts.status"),
                        accessor: 'status',
                        id: 'status',
                      }
                    ]}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            if (e.target.tagName.toLowerCase() === 'button') {
                              return; // Ignore clicks on buttons
                            }
                          },
                          style: {
                            cursor: 'pointer'
                          }
                        }
                      }
                      return {}
                    }}
                    data={this.state.accounts}
                    page={this.state.page}
                    pages={this.state.totalPages}
                    pageSize={this.state.pageSize}
                    onPageSizeChange={(newPageSize) => this.onPageSizeChange(newPageSize)}
                    // showPageSizeOptions={false}
                    sortable={false}
                    onPageChange={(page) => this.onPageChange(page)}
                    showPageJump={false}
                    manual={false}
                    ref={r => (this.selectTable = r)}
                    selectAll={this.state.selectAll}
                    isSelected={key => this.state.selection.has(key)}
                    toggleSelection={this.toggleSelection}
                    toggleAll={this.toggleAll}
                    SelectInputComponent={props => customSelectInputComponent(props)}
                    keyField="email"
                    className="-striped -highlight" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup >
      </Fragment >
    );
  }
}

export default withTranslation()(MSConfigs);