import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { toDate } from "../Helpers/TextHelper";
import { saveBlogPost } from "../../API/BlogPostsAPI";
import { toast } from 'react-toastify';
import { Multiselect } from 'react-widgets';

import { AuthContext } from 'context/AuthContext';
import { toBase64 } from 'Application/Helpers/FileHelper';

class BlogPostModal extends React.Component {

  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      isEdit: this.props.blogPost && this.props.blogPost.id ? true : false,
      blogPost: this.initBlog(),
      file: null,
      fileName: '',
      tagsData: []
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCreateTag = this.handleCreateTag.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.submit = this.submit.bind(this);
  }

  initBlog(author = "") {
    return {
      by: author,
      content: "",
      id: null,
      image64: "",
      imagename: "",
      language: "",
      meta_description: "",
      meta_keywords: "",
      meta_title: "",
      tags: [],
      title: "",
      updated: new Date(),
      url: ""
    }
  }

  componentDidMount() {
    if (this.state.isEdit) {
      const blogPost = this.props.blogPost;
      blogPost.updated = toDate(blogPost.updated); // adjust date
      this.setState({
        blogPost: blogPost
      })
    }
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      blogPost: this.initBlog(this.context.user.name),
      file: null,
      fileName: ''
    });
  }

  handleChange(event) {
    const obj = event.target || event;
    const key = obj.name;
    const value = obj.type === 'checkbox' ? obj.checked : obj.value;
    this.setState(updater => ({
      blogPost: {
        ...updater.blogPost,
        [key]: value
      }
    }));
  }

  handleCreateTag(tag) {
    const { tagsData } = this.state;
    const tags = this.state.blogPost.tags;
    this.setState({
      scopeUserData: [...tagsData, tag]
    })
    this.handleChange({
      name: "tags", value: [...tags, tag]
    })
  }

  async handleFile(event) {
    // Read the image file
    const file = event.target.files[0];
    console.log(file);
    toBase64(file).then(content => {
      this.setState(updater => ({
        fileName: file.name,
        blogPost: {
          ...updater.blogPost,
          image64: content,
          imagename: file.name
        }
      }));
    }).catch(error => {
      console.error("Error reading image file", error);
      toast["error"](this.props.t('errors.file_read_error'));
    });
  }

  submit() {
    const blog = this.state.blogPost;

    // adjust date format
    if (!blog.updated instanceof Date || typeof blog.updated === 'number') {
      blog.updated = new Date(blog.updated).toISOString();
    }

    saveBlogPost(blog).then(result => {
      if (typeof this.props.onSubmitCallback === 'function') {
        this.props.onSubmitCallback();
        this.toggle();
      }
    }).catch(error => {
      console.error("Error submiting blog", error);
      const messageKey = this.state.isEdit ? 'errors.updating' : 'errors.creating';
      toast["error"](this.props.t(messageKey, { element: 'Blog', message: error.message }));
    });
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
        onClick={() => this.toggle()}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('blog.actions.create')}
      </Button>;

    const title = this.state.isEdit ? t('blog.actions.edit') : t('blog.actions.create')

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}
          size='xl' scrollable={true}>
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <FormGroup row>
                <Label for="title" sm={2}>{t('blog.blog_title')}</Label>
                <Col sm={10}>
                  <Input type="text" name="title" id="title"
                    value={this.state.blogPost.title} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="content" sm={2}>{t('blog.content')}</Label>
                <Col sm={10}>
                  <Input type="textarea" name="content" id="content"
                    value={this.state.blogPost.content} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="url" sm={2}>{t('blog.url')}</Label>
                <Col sm={10}>
                  <Input type="text" name="url" id="url"
                    value={this.state.blogPost.url} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="language" sm={2}>{t('blog.language')}</Label>
                <Col sm={10}>
                  <Input type="select" name="language" id="language"
                    value={this.state.blogPost.language} onChange={this.handleChange}>
                    <option></option>
                    <option>en</option>
                    <option>pt</option>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="image" sm={2}>{t('blog.image')}</Label>
                <Col sm={8}>
                  <Input id="image" type="text" readOnly={true}
                    value={this.state.fileName} placeholder={t('blog.image_placeholder')} />
                </Col>
                <Col sm={2}>
                  <Input id="file" type="file" hidden accept="image/*"
                    value={this.state.file} onChange={this.handleFile} />
                  <Button color="primary" className="mb-2 mr-2"
                    onClick={() => document.getElementById('file').click()} >
                    {t('blog.actions.addImage')}
                  </Button>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="imagename" sm={2}>{t('blog.imagename')}</Label>
                <Col sm={10}>
                  <Input type="text" name="imagename" id="imagename"
                    value={this.state.blogPost.imagename} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="meta_description" sm={2}>{t('blog.meta_description')}</Label>
                <Col sm={10}>
                  <Input type="text" name="meta_description" id="meta_description"
                    value={this.state.blogPost.meta_description} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="meta_keywords" sm={2}>{t('blog.meta_keywords')}</Label>
                <Col sm={10}>
                  <Input type="text" name="meta_keywords" id="meta_keywords"
                    value={this.state.blogPost.meta_keywords} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="meta_title" sm={2}>{t('blog.meta_title')}</Label>
                <Col sm={10}>
                  <Input type="text" name="meta_title" id="meta_title"
                    value={this.state.blogPost.meta_title} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="tags" sm={2}>{t('blog.tags')}</Label>
                <Col sm={10}>
                  <Multiselect
                    data={this.state.tagsData}
                    value={this.state.blogPost.tags}
                    allowCreate='onFilter'
                    onCreate={keyword => this.handleCreateTag(keyword)}
                    onChange={value => this.handleChange({ name: "tags", value: value })}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="by" sm={2}>{t('blog.by')}</Label>
                <Col sm={10}>
                  <Input type="text" name="by" id="by"
                    value={this.state.blogPost.by} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="updated" sm={2}>{t('blog.updated')}</Label>
                <Col sm={10}>
                  <DatePicker id="updated" className="form-control"
                    selected={this.state.blogPost.updated} dateFormat="yyyy-MM-dd h:mm:ss aa"
                    onChange={date => this.handleChange({ name: "updated", value: date })}
                    readOnly={true} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>{t('actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

BlogPostModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(BlogPostModal);
