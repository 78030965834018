import BaseAPI from '../BaseAPI';
import User from 'context/User';

const servicesUrl = process.env.REACT_APP_CONSOLE_URL;

class ConsoleAPI extends BaseAPI {

  buildURL(url) {
    return servicesUrl + url;
  }

  getNav(path) {
    return this.doGet(`/dashboard/nav/${path}`);
  }

  getAppsMenu() {
    return this.doGet(`/dashboard/nav/apps`);
  }

  reportError(error, errorInfo) {
    const user = User.getUserInfo();
    const email = user ? user.email : "anonymous";
    return this.doPost(`/dashboard/logs/${email}`, { error: error, errorInfo: errorInfo });
  }

}

export default new ConsoleAPI();