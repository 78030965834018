import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, Button, CardBody } from 'reactstrap';
import { withTranslation } from "react-i18next";
import PageTitle from 'Layout/Components/PageTitle';
import consoleAPI from 'API/Console/ConsoleAPI';

class AppSelection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      appsMenu: []
    };
  }

  componentDidMount() {
    consoleAPI.getAppsMenu().then(result => {
      this.setState({
        appsMenu: result
      })
    });
  }

  render() {
    const { t, i18n, history } = this.props;

    const apps = () => {
      let itens = []; 
      this.state.appsMenu.forEach(app => {
        let iconClass = app.icon + " btn-icon-wrapper btn-icon-lg mb-3";
        itens.push(
          <Col sm="6" key={"key-" + app.icon}>
            <Button className='btn-icon-vertical btn-square btn-transition' outline color="link" 
            onClick={() => history.push(app.to)}>
              <i className={iconClass}> </i>
              {app.label}
            </Button>
          </Col>);
      });
      return itens;
    };

    return (
      <Fragment>
        <PageTitle
          heading={t(`dashboards.selection`)}
          icon="pe-7s-keypad icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <div className="grid-menu">
                    <Row className="no-gutters">
                      {apps()}
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withRouter(withTranslation()(AppSelection))
