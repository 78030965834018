import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Form, Input, Label
} from 'reactstrap';
import { toast } from 'react-toastify';
import { AuthContext } from 'context/AuthContext';

class Login extends React.Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.doLogin = this.doLogin.bind(this);
    }

    handleChange(event) {
        const key = event.target.name;
        const value = event.target.value;
        this.setState({
            [key]: value
        });
    }

    doLogin() {
        try {
            const { email, password } = this.state;
            if (email && password) {
                this.context.doLoginEmailPassword(email, password);
            } else {
                toast["warning"](this.props.t('messages.fill_all_fields'));
            }
        } catch (error) {
            console.error("Error on login", error);
            toast["error"](error.message);
        }
    }

    render() {
        const { t, i18n } = this.props;

        console.debug('RENDER');
        if (this.context.error) {
            console.debug('ERROR');
            toast["error"](this.context.error);
        }

        return (
            <Fragment>
                <div className="contact-us">
                    <section id="banner-default-login" data-aos="zoom-out"
                        className="banner-default-pages d-flex flex-column justify-content-center align-items-center banner-login">
                    </section>

                    <main id="main">
                        <section className="login">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-sm-12 mt-4 mt-lg-0"
                                        data-aos="fade-up" data-aos-delay="300">
                                        <div className="box-input">
                                            <div className="row">
                                                <div className="col"></div>
                                                <div className="col-6 mb-4">
                                                    <a href="/">
                                                        <img src="/imgs/logo.png" alt="" className="img-fluid" />
                                                    </a>
                                                </div>
                                                <div className="col"></div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <Form onSubmit={(e) => { e.preventDefault(); }} autoComplete="off">
                                                        <fieldset>
                                                            <Label for="email">{t('login.email')}</Label>
                                                            <Input type="email" name="email" id="email" placeholder={t('login.email_placeholder')}
                                                                value={this.state.email} onChange={this.handleChange} />
                                                        </fieldset>
                                                        <fieldset>
                                                            <Label for="password">{t('login.password')}</Label>
                                                            <Input type="password" name="password" id="password" placeholder={t('login.password_placeholder')}
                                                                value={this.state.password} onChange={this.handleChange} />
                                                        </fieldset>
                                                        <fieldset>
                                                            <Input type='submit' color="primary" size="lg" onClick={this.doLogin}
                                                                value={t("login.actions.login")} />
                                                        </fieldset>
                                                    </Form>
                                                </div>
                                                <div className="col-12 text-center">
                                                    <a href="#" className="link-forgot" onClick={this.context.forgotPassword}>
                                                        {t('login.actions.forgot_password')}
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="row my-4">
                                                <div className="col-12 px-4">
                                                    <a href="#" onClick={this.context.loginMicrosoft}>
                                                        <div className="row login-microsoft">
                                                            <div className="col-12">
                                                                <p className="mt-3">
                                                                    <img src="/imgs/microsoft.png" alt={t("login.actions.login_microsoft")} className="me-3" />
                                                                    {t("login.actions.login_microsoft")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="row my-4">
                                                <div className="col-12 px-4">
                                                    <a href="#" onClick={this.context.loginGoogle}>
                                                        <div className="row login-microsoft">
                                                            <div className="col-12">
                                                                <p className="mt-3">
                                                                    <img src="/imgs/google.png" alt={t("login.actions.login_google")} className="me-3" />
                                                                    {t("login.actions.login_google")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                    </div>
                                </div>
                                <div className="row mt-5" data-aos-delay="300">
                                    <div className="col-12 text-center">
                                        <p>{t('login.dont_have_account')} <a href="#" onClick={this.context.registerEmailPassword}>{t('login.actions.create_account')}</a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>

                    <section className="text-center">
                        {t("login.copyright")}
                    </section>
                </div>
            </Fragment>
        )
    }
};

export default withTranslation()(Login);