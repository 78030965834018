import React, {Fragment} from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, CardTitle, Input, Button, Spinner } from 'reactstrap';

import PageTitle from '../../../Layout/Components/PageTitle';
import { withTranslation } from 'react-i18next';
import LaunchCampaignDetailsTable from './LaunchCampaignDetailsTable';
import EmailStatDoughnutChart from './EmailStatDoughnutChart';
import EmailSuccessLineGraph from './EmailSuccessLineGraph';
import {getCampaignList} from "../../../API/PhishingAPI";

class PhishingDashboard extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      campaignList: {},
      email_sent_labels: ["", "Email Sent"],
      email_opened_labels: ["", "Email Opened"],
      email_clicked_labels: ["", "Email Clicked"],
      submitted_data_labels: ["", "Submitted Data"],
      email_reported_labels: ["", "Email Reported"]
    }
    this.getPercentageBetweenTwoValue = this.getPercentageBetweenTwoValue.bind(this);
  }

  componentDidMount(){
    getCampaignList()
      .then(res => {
        res.campaigns.forEach((item, i) => {
          item.formatted_created_date = (new Date(item.created_date)).toLocaleString(undefined, {dateStyle:"full", timeStyle:"medium"})
        });
        this.setState({
          campaignList : res.campaigns,
          isLoaded : true
        });
        console.log("Data received!!");
    }).catch(error => {
      console.error("Error requesting data", error);
    });
  }

  getPercentageBetweenTwoValue(num, den){
    if(den == 0){
      return 0;
    }
    var per =  (num/den)*100 | 0;
    if(per > 100){
      return 100;
    }
    return per;
  }
  render(){
    const { t, i18n } = this.props;
    const total = Array.isArray(this.state.campaignList) ?  this.state.campaignList.reduce((a, campaign) => a = a+campaign.stats.total, 0): 0;
    const email_sent_value = Array.isArray(this.state.campaignList) ?  this.state.campaignList.reduce((a, campaign) => a = a+campaign.stats.sent, 0): 0;
    const email_sent = [total - email_sent_value, email_sent_value];
    const email_opened_value = Array.isArray(this.state.campaignList) ?  this.state.campaignList.reduce((a, campaign) => a = a+campaign.stats.opened, 0): 0;
    const email_opened = [email_sent_value - email_opened_value > 0 ? email_sent_value - email_opened_value: 0, email_opened_value];
    const email_clicked_value = Array.isArray(this.state.campaignList) ?  this.state.campaignList.reduce((a, campaign) => a = a+campaign.stats.clicked, 0): 0;
    const email_clicked = [email_opened_value - email_clicked_value > 0 ? email_opened_value - email_clicked_value : 0, email_clicked_value];
    const submitted_data_value = Array.isArray(this.state.campaignList) ?  this.state.campaignList.reduce((a, campaign) => a = a+campaign.stats.submitted_data, 0): 0;
    const submitted_data = [total - submitted_data_value, submitted_data_value];
    const email_reported_value = Array.isArray(this.state.campaignList) ?  this.state.campaignList.reduce((a, campaign) => a = a+campaign.stats.email_reported, 0): 0;
    const email_reported = [total - email_reported_value, email_reported_value];

    const phishing_overall_success = Array.isArray(this.state.campaignList) ? this.state.campaignList
      .map(campaign => {
         var phishing_success = {};
         phishing_success['x'] = new Date(campaign.created_date);
         phishing_success['y'] = Math.floor((campaign.stats.clicked / campaign.stats.total) * 100);
         return phishing_success;
    }): [];
    //console.log("Phishing Overall Success",phishing_overall_success);
    return (
      <Fragment>
        <PageTitle
          heading={t("phishing_dashboard.title")}
          icon="pe-7s-display1 icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col>
              <EmailSuccessLineGraph data={phishing_overall_success} label="Phishing Success Overview"/>
            </Col>
          </Row>
          <Row>
              <Col sm={4}>
                <EmailStatDoughnutChart data={email_sent} labels={this.state.email_sent_labels} label="Email Sent" per={this.getPercentageBetweenTwoValue(email_sent_value, total)}/>
              </Col>
              <Col sm={4}>
                <EmailStatDoughnutChart data={email_opened} labels={this.state.email_opened_labels} label="Email Opened" per={this.getPercentageBetweenTwoValue(email_opened_value,email_sent_value)}/>
              </Col>
              <Col sm={4}>
                <EmailStatDoughnutChart data={email_clicked} labels={this.state.email_clicked_labels} label="Email Clicked" per={this.getPercentageBetweenTwoValue(email_clicked_value ,email_opened_value)}/>
              </Col>
          </Row>
          <Row>
              <Col sm={4}>
                <EmailStatDoughnutChart data={submitted_data} labels={this.state.submitted_data_labels} label="Submitted Data" per={this.getPercentageBetweenTwoValue(submitted_data_value ,email_clicked_value)}/>
              </Col>
              <Col sm={4}>
                <EmailStatDoughnutChart data={email_reported} labels={this.state.email_reported_labels} label="Email Reported" per={this.getPercentageBetweenTwoValue(email_reported_value, total)}/>
              </Col>
              <Col sm={4}>
              </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>{t("phishing_dashboard.recent_campaigns")}</CardTitle>
                  <LaunchCampaignDetailsTable campaignList={this.state.campaignList} isLoaded={this.state.isLoaded}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}
export default withTranslation()(PhishingDashboard);
