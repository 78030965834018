import React, { Fragment } from "react";
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Input
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { readAsDataURL } from 'Application/Helpers/FileHelper';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

class ImageUploader extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      cropper: null,
      file: null,
      image: null,
      imageName: ''
    };

    this.toggle = this.toggle.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.onCropperInit = this.onCropperInit.bind(this);
    this.save = this.save.bind(this);
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal
    });
  }

  async handleFile(event) {
    // Read the image file
    const file = event.target.files[0];
    readAsDataURL(file).then(content => {
      this.setState({
        imageName: file.name,
        image: content
      });
    }).catch(error => {
      console.error("Error reading image file", error);
      toast["error"](this.props.t('errors.file_read_error'));
    });
  }

  onCropperInit(cropper) {
    this.setState({
      cropper: cropper
    })
  }

  save() {
    const imgData = this.state.cropper.getCroppedCanvas().toDataURL();
    if (typeof this.props.onSave === 'function') {
      this.props.onSave(this.state.imageName, imgData); // call onSave when save
    }
    this.toggle();
  }

  render() {

    const { t, i18n, title, buttonLabel, className, buttonClass, aspectRatio, accept } = this.props;

    return (
      <Fragment>
        <Button className={`mb-2 mr-2 btn-icon btn-shadow ${buttonClass}`} color="primary"
          onClick={() => this.toggle()}>
          <i className="lnr-upload btn-icon-wrapper"> </i>
          {buttonLabel}
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={className} size="lg">
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <FormGroup row>
                <Col sm={6}>
                  <Input id="imageName" name="imageName" type="text" placeholder={t("messages.upload_image")}
                    value={this.state.imageName} readOnly={true} />
                </Col>
                <Col sm={6}>
                  <Input id="file" type="file" hidden accept={accept}
                    value={this.state.file} onChange={this.handleFile} />
                  <Button color="primary" className="mb-2 mr-2"
                    onClick={() => document.getElementById('file').click()} >
                    {t('actions.upload')}
                  </Button>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <Cropper
                    src={this.state.image}
                    style={{ height: 400, width: "100%" }}
                    // Cropper.js options
                    viewMode={1}
                    aspectRatio={aspectRatio}
                    guides={false}
                    autoCropArea={1}
                    cropBoxResizable={false}
                    onInitialized={this.onCropperInit} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.cancel')}</Button>
            <Button color="primary" onClick={this.save}>{t('actions.save')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default withTranslation()(ImageUploader);