import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Form, Input, Label
} from 'reactstrap';
import { toast } from 'react-toastify';
import { AuthContext } from 'context/AuthContext';
import UserLoginAPI from 'API/UserLoginAPI';

class ForgotPassword extends React.Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            block: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.sendPassword = this.sendPassword.bind(this);
    }

    handleChange(event) {
        const key = event.target.name;
        const value = event.target.value;
        this.setState({
            [key]: value
        });
    }

    sendPassword() {
        const { email } = this.state;
        if (email) {
            UserLoginAPI.forgotPassword(email).then(result => {
                if (result) {
                    toast["success"](this.props.t('password.confirm'));
                    this.setState({ block: true });
                }
            }).catch(error => {
                console.error("Error on password request", error);
                toast["error"](this.props.t('password.error'));
            });
        }
    }

    render() {
        const { t, i18n } = this.props;

        if (this.context.error) {
            toast["error"](this.context.error);
        }

        return (
            <Fragment>
                <div className="contact-us">
                    <section id="banner-default-login" data-aos="zoom-out"
                        className="banner-default-pages d-flex flex-column justify-content-center align-items-center banner-login">
                    </section>

                    <main id="main">
                        <section className="login">
                            <div className="container">
                                <div className="row">
                                    <div className="col"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12 mt-4 mt-lg-0"
                                        data-aos="fade-up" data-aos-delay="300">
                                        <div className="box-input">
                                            <div className="row">
                                                <div className="col"></div>
                                                <div className="col-6 mb-4">
                                                    <a href="/">
                                                        <img src="/imgs/logo.png" alt="" className="img-fluid" />
                                                    </a>
                                                </div>
                                                <div className="col"></div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 text-center pt-4">
                                                    <p>{t('password.text')}</p>
                                                </div>
                                                <div className="col-12">
                                                    <Form onSubmit={(e) => { e.preventDefault(); }} autoComplete="off">
                                                        <fieldset className="mt-0">
                                                            <Label for="email">{t('password.email')}</Label>
                                                            <Input type="email" name="email" id="email"
                                                                placeholder={t('password.email_placeholder')}
                                                                value={this.state.email} onChange={this.handleChange} />
                                                        </fieldset>
                                                        <fieldset>
                                                            <Input type='submit' color="primary" size="lg"
                                                                onClick={this.sendPassword} disabled={this.state.block}
                                                                value={t("password.actions.send")} />
                                                        </fieldset>
                                                    </Form>
                                                </div>

                                                <div className="col-12 text-center">
                                                    <a href="#" onClick={this.context.loginEmailPassword} className="link-forgot">{t('password.actions.return')}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                    </div>
                                </div>

                                <div className="row mt-5" data-aos-delay="300">
                                    <div className="col-12 text-center">
                                        <p>{t('login.dont_have_account')} <a href="#" onClick={this.context.registerEmailPassword}>{t('login.actions.create_account')}</a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>

                    <section className="text-center">
                        {t("login.copyright")}
                    </section>
                </div>
            </Fragment>
        )
    }
};

export default withTranslation()(ForgotPassword);