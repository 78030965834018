import React from "react";
import QuarantineProvider from './QuarantineContext';
import QuarantineList from './QuarantineList';
import Quarantine from './Quarantine';

function withContext(Component) {
  class WithContext extends React.Component {
    render() {
      const providerMessageId = this.props.location && this.props.location.state
        && this.props.location.state.providerMessageId;
      return (
        <QuarantineProvider providerMessageId={providerMessageId}>
          <Component />
        </QuarantineProvider>
      );
    }
  }
  return WithContext;
}

const QuarantineDetail = withContext(Quarantine);

export {
  QuarantineDetail
}

export default QuarantineList;