import React, { Fragment } from 'react';
import {
  Col, FormGroup, Label, Input, Row, Button, Modal, ModalHeader, ModalBody, Form, ModalFooter
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

class EmbededUrlModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      EmbededUrl: {
        Url: '',
        RedirectUrl: '',
        LinkDisplayUrl: ''
      }
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      EmbededUrl: {
        Url: '',
        RedirectUrl: '',
        LinkDisplayUrl: ''
      }
    });
  }

  handleChange(event) {
    const obj = event.target || event;
    this.setState(updater => ({
      EmbededUrl: {
        ...updater.EmbededUrl,
        [obj.name]: obj.value
      }
    }));
  }

  submit() {
    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(this.state.EmbededUrl);
    }
    this.toggle();
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button className={`mb-2 mr-2 btn-icon btn-shadow btn-heading-position`}
        size="sm" color="primary" onClick={() => this.toggle()}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('campaign.message-info.actions.add-embeded-url')}
      </Button>;

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{t('campaign.message-info.actions.add-embeded-url')}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <FormGroup row>
                <Label for="Url" sm={2}>{t('campaign.message-info.embeded-urls.url')}</Label>
                <Col sm={10}>
                  <Input type="text" name="Url" id="Url"
                    value={this.state.EmbededUrl.Url} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="RedirectUrl" sm={2}>{t('campaign.message-info.embeded-urls.redirect_url')}</Label>
                <Col sm={10}>
                  <Input type="text" name="RedirectUrl" id="RedirectUrl"
                    value={this.state.EmbededUrl.RedirectUrl} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="LinkDisplayUrl" sm={2}>{t('campaign.message-info.embeded-urls.link_display_url')}</Label>
                <Col sm={10}>
                  <Input type="text" name="LinkDisplayUrl" id="LinkDisplayUrl"
                    value={this.state.EmbededUrl.LinkDisplayUrl} onChange={this.handleChange} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>{t('actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment >
    );
  }
}

EmbededUrlModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(EmbededUrlModal);
