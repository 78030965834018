import React, { Fragment } from 'react';
import {
  Button, Card, CardBody, CardTitle, Col, Row, CardText
} from 'reactstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import ReactTable from 'react-table';
import PageTitle from '../../Layout/Components/PageTitle';
import { getResume } from '../../API/ReportsAPI';

class UserReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resume: []
    };
  }

  componentDidMount() {
    getResume().then(result => {
      if (result) {
        this.setState({
          resume: result
        });
      }
    }).catch(error => {
      console.error("Error getting Resume Report", error);
      toast["error"](this.props.t('errors.loading', { element: 'Resume Report', message: error.message }));
    });
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("reports.resume.title")}
          icon="pe-7s-note2 icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12" lg="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardText>{t("reports.resume.text")}</CardText>
                  <ReactTable
                    columns={[
                      {
                        Header: t("reports.resume.email"),
                        accessor: 'email'
                      },
                      {
                        Header: t("reports.resume.seven_days"),
                        accessor: 'seven_days'
                      },
                      {
                        Header: t("reports.resume.total"),
                        accessor: 'total'
                      }
                    ]}
                    defaultPageSize={10}
                    data={this.state.resume}
                    className="-striped -highlight" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
}

export default withTranslation()(UserReport);