import React from 'react';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import L from 'leaflet';
import polylineDecorator from 'leaflet-polylinedecorator' // add feature to leaflet
import awesomeMarkers from 'leaflet.awesome-markers' // add feature to leaflet
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css'
import { getGeolocation } from '../../../API/ConsoleServicesAPI';

class FootprintMap extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      error_message: ''
    }

    this.mapRef = React.createRef();

    this.buildRoute = this.buildRoute.bind(this);
    this.displayMessage = this.displayMessage.bind(this);
  }

  componentDidMount() {
    this.buildRoute(this.props.smtpIPs);
  }

  componentDidUpdate(prevProps) {
    if (this.props.smtpIPs !== prevProps.smtpIPs) {
      this.buildRoute(this.props.smtpIPs);
    }
  }

  buildRoute(traceIPs) {
    if (!traceIPs || traceIPs.length === 0) {
      toast["warning"](this.props.t('threat_intelligence.messages.no_trace_ips'));
      this.displayMessage(this.props.t('threat_intelligence.messages.no_trace_ips'))
      return;
    }
    this.displayMessage(''); // clean any previous message
    getGeolocation(traceIPs).then(result => {
      let positions = result.geoips.filter(loc => !loc.error).map(loc => {
        return [`${loc.ip} ${loc.country}`, loc.latitude, loc.longitude]
      });
      this.drawMap(positions);
    }).catch(error => {
      console.error("Error getting Email", error);
      toast["error"](this.props.t('errors.loading', { element: 'Email Path', message: error.message }));
    });
  }

  displayMessage(message) {
    this.setState({
      error_message: message
    });
  }

  drawMap(points) {
    if (!this.map) { // Load map only once
      const mapEl = this.mapRef.current;
      this.map = L.map(mapEl, {
        center: [points[0][1], points[0][2]],
        zoom: 5,
        layers: [
          L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
          }),
        ]
      });
    }

    if (this.layer) { // Clear map layer
      this.map.removeLayer(this.layer);
    }
    this.layer = L.layerGroup().addTo(this.map);

    // Create the start and end markers
    L.AwesomeMarkers.Icon.prototype.options.prefix = 'ion';
    let startMarker = L.AwesomeMarkers.icon({
      icon: 'star',
      markerColor: 'red',
      shape: 'circle',
    });
    let endMarker = L.AwesomeMarkers.icon({
      icon: 'home',
      markerColor: 'green',
      shape: 'circle',
    });

    // Draw the markers and lines
    var markers = [];
    for (var i = 0; i < points.length; i++) {
      markers.push([points[i][1], points[i][2]]);

      if (markers.length === 1) {
        new L.marker(markers[i], { icon: startMarker })
          .bindPopup(points[i][0])
          .addTo(this.layer);
      } else if (markers.length === points.length) {
        new L.marker(markers[i], { icon: endMarker })
          .bindPopup(points[i][0])
          .addTo(this.layer);
      } else {
        new L.circleMarker(markers[i], { color: 'green', radius: 8 })
          .bindPopup(points[i][0])
          .addTo(this.layer);
      }

      if (this.polyline) {
        this.map.removeLayer(this.polyline);
      }

      this.polyline = L.polyline(markers, {}).addTo(this.layer);
      this.decorator = L.polylineDecorator(this.polyline, {
        patterns: [
          {
            offset: '100%',
            repeat: 0,
            symbol: L.Symbol.arrowHead({ pixelSize: 15, polygon: false, pathOptions: { stroke: true } })
          }
        ]
      }).addTo(this.layer);
    }

    // Adjust map position
    this.map.fitBounds(markers);
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <div id="map" ref={this.mapRef} style={{ width: '100%', height: '450px' }}>
        {this.state.error_message}
      </div>
    );
  }
}

export default withTranslation()(FootprintMap);
