import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { withTranslation } from 'react-i18next';
import { Col, Label, Row, Input } from "reactstrap";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { PaymentsContext } from "./PaymentsContext";
import { getLicense } from "API/LicenseAPI";
import { getOrganization } from "API/OrganizationsAPI";
import { getPaymentPlans } from "API/ConsoleServicesAPI";
import ErrorBoundary from "ErrorBoundary";

class PaypalButton extends React.Component {

  static contextType = PaymentsContext;

  constructor(props) {
    super(props);

    this.state = {
      clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
      plans: [],
      organization: {},
      contact: {
        name: '',
        email: ''
      },
      planId: '',
      planPeriod: '',
      price: 0,
      quantity: 0,
      total: 0,
      organizationReady: false,
      paymentDone: false
    };

    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handlePlanChange = this.handlePlanChange.bind(this);
    this.updateValues = this.updateValues.bind(this);
    this.loadOrganization = this.loadOrganization.bind(this);
    this.savePayment = this.savePayment.bind(this);
  }

  componentDidMount() {
    getPaymentPlans().then(result => {
      this.setState({
        plans: result
      });
    }).catch(error => {
      console.error("Error getting Payment Plans", error);
      toast["error"](this.props.t('errors.loading', { element: 'Payment Plans', message: error.message }));
    });
  }

  handleQuantityChange(event) {
    const quantity = event.target.value;
    this.updateValues(this.state.planId, quantity);
  }

  handlePlanChange(event) {
    const planId = event.target.value;
    this.updateValues(planId, this.state.quantity);
  }

  updateValues(planId, quantity) {
    let price = 0;
    let total = 0;
    let period = 0;
    if (planId) {
      const plan = this.state.plans.find(el => el.id === planId);
      price = this.getPrice(plan, quantity).toFixed(2);
      total = (price * quantity).toFixed(2);
      period = this.getPeriod(plan);
    }
    this.setState({
      planId: planId,
      planPeriod: period,
      price: price,
      quantity: quantity,
      total: total
    });
  }

  getPrice(plan, quantity) {
    if (!plan || !quantity) {
      return 0;
    }
    const tiers = plan.billing_cycles[0].pricing_scheme.tiers;
    const tier = tiers.find(el => {
      const start_quantity = parseFloat(el.starting_quantity);
      const end_quantity = el.ending_quantity ? parseFloat(el.ending_quantity) : null;
      return quantity >= start_quantity && (!end_quantity || quantity <= end_quantity)
    })
    return tier ? parseFloat(tier.amount.value) : 0;
  }

  getPeriod(plan) {
    if (!plan) {
      return 0;
    }
    const frequency = plan.billing_cycles[0].frequency;
    if (!frequency) {
      return 0;
    }
    return frequency.interval_unit === 'YEAR' ? 12 : 1;
  }

  loadOrganization() {
    Promise.all([
      getOrganization(this.context.domain),
      getLicense(this.context.isAdmin ? this.context.domain : null)
    ]).then(values => {
      const license = values[1];
      this.setState({
        organization: values[0],
        contact: values[0].contact && values[0].contact[0],
        quantity: license.number,
        organizationReady: true
      })
    }).catch(error => {
      console.error("Error getting Organization", error);
      toast["error"](this.props.t('errors.loading', { element: 'Organization', message: error.message }));
    });
  }

  savePayment(paymentData) {
    console.debug("savePayment", paymentData);
    const now = new Date();
    const end = new Date();
    end.setMonth(now.getMonth() + this.state.planPeriod);
    const payment = {
      billed_amount: this.state.total,
      cancelDate: null,
      discount: 0.0,
      domain: this.state.organization.domain,
      email: this.state.contact.email,
      expireDate: end,
      id: null,
      invoice_link: "",
      idPlan: this.state.planId,
      paymentDate: now,
      paypalid: paymentData.subscriptionID,
      idPaymentPaypal: null,
      price: this.state.price,
      quantity: this.state.quantity,
      startDate: now,
      status: "waiting payment",
      total: this.state.total,
      type: "Paypal"
    };
    console.log("Saving payment", payment);
    this.context.savePayment(payment);
    this.setState({ paymentDone: true });
  }

  render() {
    const { t, i18n } = this.props;

    if (!this.context.isAdmin && this.context.paymentsReady && !this.state.organizationReady) {
      this.loadOrganization();
    }

    const showButton = !this.context.isAdmin && this.context.paymentsReady
      && !this.state.paymentDone && this.state.organizationReady;

    const email = this.state.contact.email;
    const names = this.state.contact.name.split(' ');

    const optionPlans = this.state.plans.map(element => {
      return <option key={element.id} value={element.id}>{element.name}</option>;
    });

    const paypalButtons = showButton &&
    <ErrorBoundary><PayPalScriptProvider options={{
        "client-id": this.state.clientId,
        "vault": true,
        "intent": "subscription"
      }}>
        <PayPalButtons
          onClick={() => {
            if (!this.state.planId) {
              toast["warning"](this.props.t('payments.messages.select_plan'));
              return false;
            }
            if (this.state.quantity <= 0) {
              toast["warning"](this.props.t('payments.messages.set_quantity'));
              return false;
            }
          }}
          createSubscription={(data, actions) =>
            actions.subscription.create({
              plan_id: this.state.planId,
              quantity: this.state.quantity,
              subscriber: {
                name: {
                  given_name: names[0],
                  surname: names[names.length - 1]
                },
                email_address: email
              },
              application_context: {
                brand_name: 'EmailVeritas',
                locale: i18n.language,
                shipping_preference: "NO_SHIPPING",
                user_action: "SUBSCRIBE_NOW",
                payment_method: {
                  payer_selected: "PAYPAL",
                  payee_preferred: "IMMEDIATE_PAYMENT_REQUIRED"
                },
              }
            })
          }
          onApprove={(data, actions) => {
            this.savePayment(data);
            toast["success"](this.props.t('payments.messages.payment_success'));
            return actions.order.capture().then(function (details) {
              console.log('Transaction completed by ', details);
            });
          }}
          onError={(error) => {
            console.error("Error during payment", error);
            toast["error"](this.props.t('payments.messages.payment_error'));
          }}
          style={{ layout: "horizontal" }}
        />
      </PayPalScriptProvider></ErrorBoundary>;

    const paymentLabel = this.state.paymentDone &&
      <Label>{this.props.t('payments.messages.payment_in_process')}</Label>;

    return (
      <Fragment>
        <Row>
          <Col md="8">
            <Fragment>
              <Row>
                <Col md={12}>
                  <h3 className="form-heading pt-3 pb-3 float-left">
                    {t("payments.info")}:
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label className="mr-sm-2">{t("payments.name")}</Label>
                  <Input type="text" value={this.state.contact.name} readOnly={true}></Input>
                </Col>
                <Col md={6}>
                  <Label className="mr-sm-2">{t("payments.email")}</Label>
                  <Input type="text" value={this.state.contact.email} readOnly={true}></Input>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Label for="planId" className="mr-sm-2">{t("payments.plan")}</Label>
                  <Input type="select" name="planId" id="planId"
                    value={this.state.planId} onChange={this.handlePlanChange}
                    readOnly={this.state.paymentDone}>
                    <option></option>
                    {optionPlans}
                  </Input>
                </Col>
                <Col md={3}>
                  <Label className="mr-sm-2">{t("payments.price")}</Label>
                  <Input type="text" value={this.state.price} readOnly={true}></Input>
                </Col>
                <Col md={3}>
                  <Label className="mr-sm-2">{t("payments.quantity")}</Label>
                  <Input type="number" step="1" min="0" name="quantity" id="quantity"
                    value={this.state.quantity} onChange={this.handleQuantityChange}
                    readOnly={this.state.paymentDone}></Input>
                </Col>
                <Col md={3}>
                  <Label className="mr-sm-2">{t("payments.total")}</Label>
                  <Input type="text" value={this.state.total} readOnly={true}></Input>
                </Col>
              </Row>
            </Fragment>
          </Col>
          <Col md="4">
            {paypalButtons || paymentLabel}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default withTranslation()(PaypalButton);
