import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import SearchBox from '../../Layout/Components/SearchBox';
import { getClassifications } from 'API/ReportsAPI';
import { downloadXLSX } from 'Application/Helpers/FileHelper';

class ActionsMenu extends React.Component {

  report() {
    getClassifications().then(result => {
      const reportDate = new Date().toISOString();
      downloadXLSX(result, `phishingDetector-${reportDate.split('T')[0]}.xlsx`);
    }).catch(error => {
      console.error("Error getting XLSX Report", error);
      toast["error"](this.props.t('errors.loading', { element: 'Report', message: error.message }));
    })
  }

  render() {
    const { t, i18n, doSearch, location, history } = this.props;

    const source = location.pathname.split("/")[1];

    return (
      <Fragment>
        <SearchBox placeholder={t('organizations.actions.search')} doSearch={doSearch} />
        <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
          onClick={() => history.push(`/${source}/organization`)}>
          <i className="lnr-plus-circle btn-icon-wrapper"> </i>
          {t('organizations.actions.create')}
        </Button>
        <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
          onClick={() => this.report()}>
          <i className="lnr-chart-bars btn-icon-wrapper"> </i>
          {t('organizations.actions.report')}
        </Button>
      </Fragment>
    );
  }
}

export default withRouter(withTranslation()(ActionsMenu));