import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

class ImportSiteModal extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      modal: this.props.open,
      url: ""
    }
    this.toggle = this.toggle.bind(this);
    this.import_website = this.import_website.bind(this);
  }
  import_website(url){
    this.props.importSite(url);
    this.toggle();
  }
  render(){
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
    <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
    onClick={this.toggle}>
      <i className="lnr-earth btn-icon-wrapper"> </i>
      {t('landing_page.import_site')}
    </Button>;

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{t('landing_page.import_site')}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="url">{t('landing_page.url')}</Label>
              <Input type="text" name="url" id="url"
              value={this.state.url} onChange={(event) => this.setState({url: event.target.value})}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('landing_page.actions.cancel')}</Button>
            <Button color="primary" onClick={() => this.import_website(this.state.url)}>{t('landing_page.actions.import')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal
    });
  }
}
ImportSiteModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(ImportSiteModal);
