import React, {Fragment} from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, CardTitle, Input, Button, Spinner, Alert } from 'reactstrap';

import PageTitle from '../../../Layout/Components/PageTitle';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import SMTPConfigModal from './SMTPConfigModal';
import SMTPConfigTable from './SMTPConfigTable'

import {getSMTPConfigList , deleteSMTPConfig, getDefaultSMTPConfig} from "../../../API/PhishingAPI";

class SMTPConfig extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      smtpConfigList: [],
      isLoaded: false,
      smtpConfig:{},
      openEdit: false,
      errorFetchingData: false,
      error:"",
      defaultConfig:{}
    }
    this.loadSMTPConfigList = this.loadSMTPConfigList.bind(this);
    this.addPolicyCallback = this.addPolicyCallback.bind(this);
    this.onEditOrCopyCallback = this.onEditOrCopyCallback.bind(this);
    this.onSubmitCallback = this.onSubmitCallback.bind(this);
    this.deleteSmtpConfig = this.deleteSmtpConfig.bind(this);
  }

  componentDidMount(){
    this.loadSMTPConfigList();
    getDefaultSMTPConfig()
        .then(res => {
          console.log(res);
          this.setState({
            defaultConfig: res
          });
        })
        .catch(error => {
            console.log("Error while fetching SMTP Config!!");
        });
  }

  onSubmitCallback(){
    this.loadSMTPConfigList();
  }

  // TODO: Add timeout to this call and display proper messages upon failure.
  loadSMTPConfigList(){
    this.setState({
      isLoaded: false
    });
    // TODO(Refactor): Date format should be handled in it's own method
    getSMTPConfigList()
      .then(res => {
        res.forEach((item, i) => {
          item.modified_date = (new Date(item.modified_date)).toLocaleString(undefined, {dateStyle:"full", timeStyle:"medium"})
        });
        this.setState({
          smtpConfigList : res,
          isLoaded : true
        });
    }).catch(error => {
      console.log("Error while fetching data for SMTPConfig",error);
      this.setState({
        errorFetchingData: true,
        error: "Error fetching data"
      });
    });
  }

  onEditOrCopyCallback(smtpConfig, copiedVersion){
    this.setState({
      openEdit: true,
      smtpConfig: smtpConfig,
      copiedVersion: copiedVersion
    })
  }

  deleteSmtpConfig(e){
    const id = e._original.id;
    console.log('Id received is '+id);
    deleteSMTPConfig(id)
      .then(res => {
        console.log(res);
        this.loadSMTPConfigList();
      }).catch(error => {
        toast["error"](this.props.t("deleting", { element: 'SMTP Config', message: error.message }));
      })
  }

  addPolicyCallback(){
    this.setState({
      openEdit: false,
      smtpConfig: {}
    });
  }
  render(){
    const { t, i18n } = this.props;

    const editSmtpConfig = this.state.openEdit &&
      <SMTPConfigModal open={true} showOpenButton={false} onClose={this.addPolicyCallback}
      smtpConfig={this.state.smtpConfig} copiedVersion={this.state.copiedVersion} onSubmit={this.onSubmitCallback}/>;

    return(
      <Fragment>
      <PageTitle
        heading={t("smtp_config.title")}
        icon="pe-7s-config icon-gradient bg-primary"
      />
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Row>
          <Col md="12" sm="12">
            <Card className="main-card mb-3">
              <CardBody>
                <SMTPConfigModal onSubmit={this.onSubmitCallback} onClose={this.addPolicyCallback} defaultConfig={this.state.defaultConfig}/>
                {editSmtpConfig}
                {this.state.errorFetchingData ? <Alert color="danger">{this.state.error}</Alert> :
                <SMTPConfigTable smtpConfigList={this.state.smtpConfigList} isLoaded={this.state.isLoaded}
                onDelete={this.deleteSmtpConfig} onEditOrCopy={this.onEditOrCopyCallback}/>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  )
  }
}

export default withTranslation()(SMTPConfig)
