import React from 'react';
import {
    Button, PopoverBody, PopoverHeader, UncontrolledPopover
} from 'reactstrap';

class InfoPopover extends React.Component {
    render() {
        const { id, header, body, placement, small, mid } = this.props;
        const popoverHeader = header ? <PopoverHeader>{header}</PopoverHeader> : '';
        const wrapperClass = small ? "custom-info" : "";
        let btnClass = "btn-icon-only";
        if (small) {
            btnClass += " mb-1 ml-1";
        } else if (mid) {
            btnClass += " mb-2 btn-icon";
        } else {
            btnClass += " mb-3 btn-icon"
        }
        return (
            <span className={wrapperClass}>
                <Button className={btnClass} color="link" id={'Popover-' + id}>
                    <i className="pe-7s-info btn-icon-wrapper"> </i>
                </Button>
                <UncontrolledPopover placement={placement} trigger="legacy" fade={false} target={'Popover-' + id}>
                    {popoverHeader}
                    <PopoverBody><div style={{ whiteSpace: 'pre-wrap' }}>{body}</div></PopoverBody>
                </UncontrolledPopover>
            </span>
        );
    }
}

export default InfoPopover;