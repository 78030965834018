import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { Col, Row, FormGroup, Button } from 'reactstrap';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import { CampaignContext } from '../CampaignContext';
import MessageInfoModal from './MessageInfoModal';
import ConfirmAction from 'Layout/Components/ConfirmAction';

class MessageInfo extends React.Component {

  static contextType = CampaignContext;

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <Row>
          <Col md={12}>
            <h3 className="form-heading pt-3 pb-3 float-left">
              {t('campaign.message-info.title')}
            </h3>
            <MessageInfoModal showOpenButton={!this.context.isEdit()} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ReactTable
              columns={[
                {
                  Header: t("campaign.message-info.subject"),
                  accessor: 'Subject'
                },
                {
                  Header: t("campaign.message-info.to"),
                  accessor: 'To'
                },
                {
                  Header: t("campaign.message-info.department"),
                  accessor: 'Department'
                },
                {
                  Header: t("campaign.message-info.from-email"),
                  accessor: 'FromEmailAddress'
                },
                {
                  Header: t("campaign.message-info.from-name"),
                  accessor: 'FromName'
                },
                {
                  Header: t("campaign.message-info.origin.title"),
                  columns: [
                    {
                      Header: t("campaign.message-info.origin.domain"),
                      accessor: 'OriginDomain'
                    },
                    {
                      Header: t("campaign.message-info.origin.ip"),
                      accessor: 'OriginSourceIP'
                    },
                    {
                      Header: t("campaign.message-info.origin.date"),
                      accessor: d => {
                        const date = moment(d.OriginDate);
                        if (date.isValid()) {
                          return moment(d.OriginDate)
                            .local()
                            .format("DD-MM-YYYY hh:mm:ss a")
                        }
                        return ''
                      },
                      id: "OriginDate"
                    }
                  ]
                },
                {
                  Header: t("actions.title"),
                  id: 'actions',
                  accessor: 'Subject',
                  width: 200,
                  Cell: (prop) => (
                    <div className="d-block w-100 text-center">
                      <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                        message={t('messages.confirm_remove', { object: prop.value })}
                        onConfirm={() => this.context.removeMessageInfo(prop.index)}
                        disabled={this.context.isEdit()}>
                        {t("campaign.message-info.actions.remove")}
                      </ConfirmAction>
                    </div>
                  )
                }
              ]}
              data={this.context.campaign.MessageInfo}
              defaultPageSize={10}
              filterable={false}
              className="-striped -highlight"
            />
          </Col>
        </Row>
      </Fragment >
    )
  }
}

export default withRouter(withTranslation()(MessageInfo));