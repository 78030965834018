import React from "react";
import OrganizationProvider from './OrganizationContext'
import Organization from './Organization'

function withContext(Component) {
  class WithContext extends React.Component {
    render() {
      const { match: { params } } = this.props;
      return (
        <OrganizationProvider domain={params.domain}>
          <Component />
        </OrganizationProvider>
      );
    }
  }
  return WithContext;
}

export default withContext(Organization);
