import React, { Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactTable from "react-table";
import { withTranslation } from "react-i18next";

import { getPredefined } from "API/DlpAPI";
import { toast } from "react-toastify";

class PolicySelector extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      policies: []
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    getPredefined().then(result => {
      this.setState({
        policies: result
      });
    }).catch(error => {
      console.error("Error getting Policies", error);
      toast["error"](this.props.t('errors.loading', { element: 'Policies', message: error.message }));
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  onSelectPolicy(selected) {
    if (typeof this.props.onSelectCallback === 'function') {
      this.props.onSelectCallback(selected);
    }
    this.toggle();
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = <Button className="mb-2 mr-2 btn-icon btn-shadow" size="sm" color="success"
      onClick={() => this.toggle()}>
      <i className="lnr-magnifier btn-icon-wrapper"> </i>
      {t('dlp.policies.actions.select')}
    </Button>;

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{t('dlp.policies.title')}</ModalHeader>
          <ModalBody>
            <ReactTable
              columns={[
                {
                  Header: t("dlp.policies.description"),
                  accessor: 'description',
                },
                {
                  Header: t("dlp.policies.expression"),
                  accessor: 'expression'
                }
              ]}
              getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    onClick: (e) => {
                      if (e.target.tagName.toLowerCase() === 'button') {
                        return; // Ignore clicks on buttons
                      }
                      this.onSelectPolicy(rowInfo.original)
                    },
                    style: {
                      cursor: 'pointer'
                    }
                  }
                }
                return {}
              }}
              data={this.state.policies}
              defaultPageSize={10}
              className="-striped -highlight" />
          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}

export default withTranslation()(PolicySelector);
