import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { withTranslation } from 'react-i18next';

import PageTitle from '../../Layout/Components/PageTitle';
import InfoPopover from "Layout/Components/InfoPopover";
import { IncidentContext } from "./IncidentContext";
import moment from "moment";

const SelectTable = selectTableHOC(ReactTable);

class Incidents extends React.Component {

  static contextType = IncidentContext;

  constructor(props) {
    super(props);

    this.state = {
      selection: new Map(),
      selectAll: false
    }

    this.closeIncidents = this.closeIncidents.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
  }

  closeIncidents(action) {
    const incidents = Array.from(this.state.selection.values())
      .map(incident => {
        incident.resolvedas = action;
        return incident
      });
    this.context.closeIncidents(incidents);
  }

  toggleSelection(key, shift, row) {
    let { selectAll, selection } = this.state;

    if (selection.has(row.id)) { // if key exists, remove it
      selection.delete(row.id);
    } else { // it doesn't exist, add it
      selection.set(row.id,
        {
          providerMessageId: row.details.messageDetails.providerMessageId,
          resolvedas: ""
        }
      );
    }

    if (selection.size > 0) {
      // get the internals of ReactTable and get the sortedData
      const wrappedInstance = this.selectTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // adjust selectAll flag accordingly 
      selectAll = currentRecords.length === selection.size;
    }

    this.setState({ selectAll, selection });
  }

  toggleAll() {
    const selectAll = this.state.selectAll ? false : true;
    const selection = new Map();
    if (selectAll) {
      // get the internals of ReactTable and get the sortedData
      const wrappedInstance = this.selectTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // push all the IDs onto the selection array
      currentRecords.forEach(item => {
        if (item._original) {
          selection.set(item._original.id,
            {
              providerMessageId: item._original.details.messageDetails.providerMessageId,
              resolvedas: ""
            }
          );
        }
      });
    }
    this.setState({ selectAll, selection });
  };

  render() {
    const { t, i18n, history } = this.props;

    const action = (action, label, color) => {
      return (
        <Button color={color} className="mr-1"
          disabled={this.state.selection.size === 0}
          onClick={() => this.closeIncidents(action)}>
          {label}
        </Button>
      );
    }

    return (
      <Fragment>
        <PageTitle
          heading={t("incidents.title")}
          subheading={t("incidents.title-description")}
          icon="pe-7s-helm icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  {action("phishing", t("incidents.actions.phishing"), "danger")}
                  {action("spam", t("incidents.actions.spam"), "warning")}
                  {action("legitimate", t("incidents.actions.legitimate"), "primary")}
                  {action("ignore", t("incidents.actions.ignore"), "primary")}
                  <InfoPopover id="incidents-instructions" mid body={t('incidents.actions.information')} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>

                  <SelectTable
                    keyField="id"
                    columns={[
                      {
                        Header: t("incidents.sender"),
                        accessor: 'details.messageDetails.originEmailAddress'
                      },
                      {
                        Header: t("incidents.subject"),
                        accessor: 'details.messageDetails.subject'
                      },
                      {
                        Header: t("incidents.category"),
                        accessor: 'userClassification'
                      },
                      {
                        Header: t("incidents.reported_at"),
                        accessor: d => {
                          const date = moment(d.openDate);
                          if (date.isValid()) {
                            return moment(d.openDate)
                              .local()
                              .format("DD-MM-YYYY hh:mm:ss a")
                          }
                          return ''
                        },
                        id: "openDate"
                      },
                      {
                        Header: t("incidents.reported_by"),
                        accessor: 'details.messageDetails.account'
                      },
                      {
                        Header: t("incidents.phishingSimulator"),
                        accessor: 'details.phishingSimulator',
                        Cell: prop => (
                          <span className={ prop.value? 'color-red' : '' }>
                            {prop.value? "yes" : "no"}
                          </span>
                        )
                      },
                    ]}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            if (e.target.tagName.toLowerCase() === 'button') {
                              return; // Ignore clicks on buttons
                            }
                            history.push({
                              pathname: '/organization/phishing_detector/incident',
                              state: { providerMessageId: rowInfo.original.details.messageDetails.providerMessageId }
                            })
                          },
                          style: {
                            cursor: 'pointer'
                          }
                        }
                      }
                      return {}
                    }}
                    data={this.context.incidents}
                    ref={r => (this.selectTable = r)}
                    selectAll={this.state.selectAll}
                    isSelected={key => this.state.selection.has(key)}
                    toggleAll={this.toggleAll}
                    toggleSelection={this.toggleSelection}
                    defaultPageSize={10}
                    className="-striped -highlight" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment >
    )
  }
}

export default withRouter(withTranslation()(Incidents));
