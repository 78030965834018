import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import moment from 'moment';

import { Col, Row, FormGroup, Button, Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import { CampaignContext } from './CampaignContext';
import PageTitle from 'Layout/Components/PageTitle';

import MessageInfoModal from './CampaignDetails/MessageInfoModal';
import ConfirmAction from 'Layout/Components/ConfirmAction';

class CampaignMessages extends React.Component {

  static contextType = CampaignContext;

  constructor(props) {
    super(props);

    this.saveCampaign = this.saveCampaign.bind(this);
  }

  saveCampaign() {
    this.context.createCampaign();
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("campaigns.actions.create")}
          icon="pe-7s-speaker icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Card className="main-card mb-3">
            <CardBody>
              <Row>
                <Col md={12}>
                  <MessageInfoModal />
                </Col>
                <Col md={12}>
                  <ReactTable
                    columns={[
                      {
                        Header: t("campaign.message-info.subject"),
                        accessor: 'Subject'
                      },
                      {
                        Header: t("campaign.message-info.to"),
                        accessor: 'To'
                      },
                      {
                        Header: t("campaign.message-info.department"),
                        accessor: 'Department'
                      },
                      {
                        Header: t("campaign.message-info.from-email"),
                        accessor: 'FromEmailAddress'
                      },
                      {
                        Header: t("campaign.message-info.from-name"),
                        accessor: 'FromName'
                      },
                      {
                        Header: t("campaign.message-info.origin.title"),
                        columns: [
                          {
                            Header: t("campaign.message-info.origin.domain"),
                            accessor: 'OriginDomain'
                          },
                          {
                            Header: t("campaign.message-info.origin.ip"),
                            accessor: 'OriginSourceIP'
                          },
                          {
                            Header: t("campaign.message-info.origin.date"),
                            accessor: d => {
                              const date = moment(d.OriginDate);
                              if (date.isValid()) {
                                return moment(d.OriginDate)
                                  .local()
                                  .format("DD-MM-YYYY hh:mm:ss a")
                              }
                              return ''
                            },
                            id: "OriginDate"
                          }
                        ]
                      },
                      {
                        Header: t("actions.title"),
                        id: 'actions',
                        accessor: 'Subject',
                        width: 200,
                        Cell: (prop) => (
                          <div className="d-block w-100 text-center">
                            <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                              message={t('messages.confirm_remove', { object: prop.value })}
                              onConfirm={() => this.context.removeMessageInfo(prop.index)}>
                              {t("campaign.message-info.actions.remove")}
                            </ConfirmAction>
                          </div>
                        )
                      }
                    ]}
                    data={this.context.campaign.MessageInfo}
                    defaultPageSize={10}
                    filterable={false}
                    className="-striped -highlight"
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <Button color="primary" onClick={this.saveCampaign}>{t('actions.save')}</Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withRouter(withTranslation()(CampaignMessages));