import React, { Fragment } from 'react';
import {
  Col, FormGroup, Label, Input, Row, Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ConfirmAction from 'Layout/Components/ConfirmAction';
import ReactTable from 'react-table';
import EmbededUrlModal from './EmbededUrlModal';

class EmbededUrls extends React.Component {

  constructor(props) {
    super(props);

    this.addEmbededUrl = this.addEmbededUrl.bind(this);
    this.removeEmbededUrl = this.removeEmbededUrl.bind(this);
  }

  addEmbededUrl(embededUrl) {
    if (typeof this.props.onAddEmbededUrl === 'function') {
      this.props.onAddEmbededUrl(embededUrl);
    }
  }

  removeEmbededUrl(index) {
    if (typeof this.props.onRemoveEmbededUrl === 'function') {
      this.props.onRemoveEmbededUrl(index);
    }
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <Row>
          <Col md={12}>
            <h3 className="form-heading pt-3 pb-3 float-left">
              {t('campaign.message-info.embeded-urls.title')}
            </h3>
            <EmbededUrlModal onSubmit={this.addEmbededUrl} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ReactTable
              columns={[
                {
                  Header: t("campaign.message-info.embeded-urls.url"),
                  accessor: 'Url'
                },
                {
                  Header: t("campaign.message-info.embeded-urls.redirect_url"),
                  accessor: 'RedirectUrl'
                },
                {
                  Header: t("campaign.message-info.embeded-urls.link_display_url"),
                  accessor: 'LinkDisplayUrl'
                },
                {
                  Header: t("actions.title"),
                  id: 'actions',
                  accessor: 'Url',
                  width: 200,
                  Cell: (prop) => (
                    <div className="d-block w-100 text-center">
                      <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                        message={t('messages.confirm_remove', { object: prop.value })}
                        onConfirm={() => this.removeEmbededUrl(prop.index)}>
                        {t("campaign.message-info.actions.remove")}
                      </ConfirmAction>
                    </div>
                  )
                }
              ]}
              data={this.props.EmbededUrls}
              showPageSizeOptions={false}
              showPaginationBottom={false}
              minRows={4}
              filterable={false}
              className="-striped -highlight"
            />
          </Col>
        </Row>
      </Fragment >
    );
  }
}

export default withTranslation()(EmbededUrls);
