import React, { Fragment } from 'react';
import {
  Button, Card, CardBody,
  Form, FormGroup, Input, Col, Row, Label
} from 'reactstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import BootstrapTable from 'react-bootstrap-table-next';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import PageTitle from '../../Layout/Components/PageTitle';
import ConfirmAction from '../../Layout/Components/ConfirmAction';
import { createPredefined, deletePredefined, getPredefined } from 'API/DlpAPI';

class RegexTemplates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expressions: [],
      description: "",
      expression: "",
      id: null,
      testPass: "",
      testFail: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.add = this.add.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    this.fillTable();
  }

  fillTable() {
    getPredefined().then(result => {
      this.setState({
        expressions: result,
        expression: "",
        description: "",
        id: null,
        testPass: "",
        testFail: ""
      });
    }).catch(error => {
      console.error("Error getting Expressions", error);
      toast["error"](this.props.t('errors.loading', {
        element: 'Predefined Expressions',
        message: error.message
      }));
    });
  }

  handleChange(event) {
    const obj = event.target || event;
    const value = obj.type === 'checkbox'
      ? obj.checked : obj.value;
    this.setState({
      [obj.name]: value
    })
  }

  add() {
    if (this.state.description && this.state.expression && this.state.testFail && this.state.testPass) {
      createPredefined(this.state.description, this.state.expression, 
        this.state.id, this.state.testFail, this.state.testPass)
        .then(result => this.fillTable())
        .catch(error => {
          console.error("Error creating Expressions", error);
          toast["error"](this.props.t('errors.creating', {
            element: 'Predefined Expressions',
            message: error.message
          }));
        });
    } else {
      toast["warning"](this.props.t('messages.unable_to_create', {
        element: 'predefined expression',
        message: this.props.t('messages.fill_all_fields')
      }));
    }
  }

  delete(id) {
    deletePredefined(id)
      .then(result => this.fillTable())
      .catch(error => {
        console.error("Error deleting Expressions", error);
        toast["error"](this.props.t('errors.deleting', {
          element: 'Predefined Expressions',
          message: error.message
        }));
      });
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("dlp.predefined.title")}
          icon="pe-7s-lock icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12" lg="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <Form onSubmit={(e) => { e.preventDefault(); this.add(); }}>
                    <Row form>
                      <Col md={4}>
                        <FormGroup>
                          <Input type="text" name="expression" id="expression"
                            value={this.state.expression} onChange={this.handleChange}
                            placeholder={t("dlp.predefined.expression_placeholder")} />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Input type="text" name="description" id="description"
                            value={this.state.description} onChange={this.handleChange}
                            placeholder={t("dlp.predefined.description_placeholder")} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={4}>
                        <FormGroup>
                          <Input type="text" name="testPass" id="testPass"
                            value={this.state.testPass} onChange={this.handleChange}
                            placeholder={t("dlp.predefined.test_pass_placeholder")} />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Input type="text" name="testFail" id="testFail"
                            value={this.state.testFail} onChange={this.handleChange}
                            placeholder={t("dlp.predefined.test_fail_placeholder")} />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Button color="success" className="mb-2 mr-2"
                            onClick={() => this.add()}>
                            {t("dlp.predefined.actions.create")}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={this.state.expressions}
                    columns={[
                      {
                        dataField: 'expression',
                        text: t("dlp.predefined.expression")
                      },
                      {
                        dataField: 'description',
                        text: t("dlp.predefined.description")
                      },
                      {
                        dataField: 'testPass',
                        text: t("dlp.predefined.test_pass")
                      },
                      {
                        dataField: 'testFail',
                        text: t("dlp.predefined.test_fail")
                      },
                      {
                        dataField: 'id',
                        text: t("dlp.predefined.actions.title"),
                        isDummyField: true,
                        formatter: (cell, row, index) => {
                          return (
                            <div>
                              <div className="d-block w-100 text-center">
                                <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                                  message={t('messages.confirm_delete', { element: 'predefined expression', object: row.expression })}
                                  onConfirm={() => this.delete(row.id)}>{t("dlp.predefined.actions.delete")}</ConfirmAction>
                              </div>
                            </div>
                          );
                        }
                      }
                    ]} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
}

export default withTranslation()(RegexTemplates);