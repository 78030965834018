import React, { Fragment } from "react";
import {
  Row, Col, Card, CardBody, CardTitle,
  Label, Button
} from 'reactstrap';
import BootstrapTable from "react-bootstrap-table-next";
import { withTranslation } from 'react-i18next';
import { toast } from "react-toastify";

import { createListOfBlacklist } from "API/BlacklistAPI";

class MessageLinks extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: []
    };

    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
  }

  handleOnSelect(row, isSelect) {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row.url]
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== row.url)
      }));
    }
  }

  handleOnSelectAll(isSelect, rows) {
    const urls = rows.map(r => r.url);
    if (isSelect) {
      this.setState(() => ({
        selected: urls
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  }

  blacklistUrls(urls) {
    createListOfBlacklist(urls).then(result => {
      toast["success"](this.props.t('incidents.messages.success_create_list_blacklist'));
    }).catch(error => {
      console.error(error);
      toast["error"](this.props.t('errors.creating', { element: 'Blacklist', message: error.message }));
    });
  }

  render() {
    const { t, i18n } = this.props;

    const { emailLinks } = this.props;

    const links = emailLinks.map(link => {
      return { url: link }
    });

    return (
      <Card className="main-card mb-3">
        <CardBody>
          <Button className="mb-2 mr-2" color="danger"
            onClick={() => this.blacklistUrls(this.state.selected)}
            disabled={this.state.selected.length === 0} >
            {t("incidents.actions.blacklist_link")}
          </Button>
          <BootstrapTable
            bootstrap4
            keyField="url"
            data={links}
            bordered={false}
            columns={[
              {
                dataField: 'url',
                text: t("incidents.url")
              }
            ]}
            selectRow={{
              mode: 'checkbox',
              clickToSelect: true,
              selected: this.state.selected,
              onSelect: this.handleOnSelect,
              onSelectAll: this.handleOnSelectAll
            }}
          />
        </CardBody>
      </Card>
    )
  }
}

export default withTranslation()(MessageLinks);
