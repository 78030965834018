import React, { Fragment } from 'react';
import {
  Card, CardBody, CardTitle, Row, Col
} from 'reactstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';
import PageTitle from '../../Layout/Components/PageTitle';

class CampaignDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: []
    };
  }

  componentDidMount() {
    // const campaigns = getCampaignDetails(); // TODO

    // this.setState({
    //   campaigns: campaigns,
    // });
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("threat_intelligence.campaign_details.title")}
          icon="pe-7s-look icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12" lg="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    data={this.state.campaigns}
                    columns={[
                      {
                        Header: t("threat_intelligence.campaign_details.start"),
                        accessor: 'start_date'
                      },
                      {
                        Header: t("threat_intelligence.campaign_details.status"),
                        accessor: 'status'
                      },
                      {
                        Header: t("threat_intelligence.campaign_details.target_dep"),
                        accessor: 'target.departments'
                      },
                      {
                        Header: t("threat_intelligence.campaign_details.target_recip"),
                        accessor: 'target.recipients'
                      },
                      {
                        Header: t("threat_intelligence.campaign_details.target_number"),
                        accessor: 'target.number'
                      },
                      {
                        Header: t("threat_intelligence.campaign_details.title"),
                        accessor: 'title'
                      },
                      {
                        Header: t("threat_intelligence.campaign_details.sending_ip"),
                        accessor: 'sending.ip'
                      },
                      {
                        Header: t("threat_intelligence.campaign_details.sending_org"),
                        accessor: 'sending.organization'
                      },
                      {
                        Header: t("threat_intelligence.campaign_details.attachment"),
                        accessor: 'attachments'
                      }
                    ]}
                    defaultPageSize={5}
                    className="-striped -highlight" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
}

export default withTranslation()(CampaignDetails);