import React from "react";
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";
import PostalMime from 'postal-mime';

import { parseEmailLinks } from "../Helpers/TextHelper";

import { getQuarantinedMessage } from "API/QuarantineAPI";
import gatewayAPI from "API/GatewayAPI";
import { getGeolocation } from 'API/ConsoleServicesAPI';

export const QuarantineContext = React.createContext();
export const QuarantineConsumer = QuarantineContext.Consumer

class QuarantineProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      providerMessageId: "",
      quarantine: {},
      originalClassification: "",
      userClassification: "",
      message: {},
      threats: [],
      campaign: {},
      origin: [],
      emlDecoded: "",
      emailHeaders: new Map(),
      emailBody: "",
      emailAttachments: [],
      emailLinks: []
    }

    this.loadQuarantine = this.loadQuarantine.bind(this);
    this.loadGeolocalization = this.loadGeolocalization.bind(this);
    this.parseEML = this.parseEML.bind(this);
    this.releaseQuarantine = this.releaseQuarantine.bind(this);
  }

  componentDidMount() {
    this.loadQuarantine(this.props.providerMessageId);
  }

  loadQuarantine(providerMessageId) {
    getQuarantinedMessage(providerMessageId).then(result => {
      if (!result) {
        throw new Error("");
      }
      const { messageDetails, threats } = result.details;

      this.setState({
        loaded: true,
        providerMessageId: providerMessageId,
        quarantine: result,
        originalClassification: result.originalClassification,
        userClassification: result.userClassification,
        message: messageDetails,
        threats: threats,
        campaign: {}
      });

      this.loadGeolocalization(messageDetails.originSourceIP);
      this.parseEML(result.eml);
    }).catch(error => {
      console.error("Error getting Quarantine", error);
      toast["error"](this.props.t('errors.loading', { element: 'Quarantine', message: error.message }));
    });
  }

  loadGeolocalization(ip) {
    if (!ip) {
      console.error("No IP to get Geo");
      return;
    }
    getGeolocation([ip]).then(result => {
      this.setState({
        origin: result.geoips[0]
      });
    }).catch(error => {
      console.error("Error getting Origin Geolocation", error);
      toast["error"](this.props.t('errors.loading', { element: 'Origin Geolocation', message: error.message }));
    });
  }

  parseEML(eml) {
    if (!eml) {
      console.error("No EML to parse");
      return;
    }
    const emlDecoded = atob(eml);
    this.setState({
      emlDecoded: emlDecoded
    });
    const parser = new PostalMime();
    parser.parse(emlDecoded).then(result => {
      this.setState({
        emailHeaders: new Map(result.headers.map((obj) => [obj.key, obj.value])),
        emailBody: result.html ? result.html : result.text,
        emailAttachments: result.attachments
      });
      parseEmailLinks(result.html).then(links => {
        this.setState({
          emailLinks: links
        });
      })
    }).catch(err => {
      console.error("Error parsing email", err);
    });
  }

  async releaseQuarantine(resolution) {
    try {
      const { quarantine, providerMessageId  } = this.state;
      await gatewayAPI.releaseQuarantine(providerMessageId, quarantine.eml, resolution);
      toast["success"](this.props.t('quarantines.messages.success_release'));
    } catch (error) {
      console.error("Error releasing Quarantine", error);
      toast["error"](this.props.t('quarantines.messages.error_releasing', { message: error.message }));
    }
  }

  render() {
    return (
      <QuarantineContext.Provider value={
        {
          loaded: this.state.loaded,
          originalClassification: this.state.originalClassification,
          userClassification: this.state.userClassification,
          message: this.state.message,
          threats: this.state.threats,
          campaign: this.state.campaign,
          origin: this.state.origin,
          emlDecoded: this.state.emlDecoded,
          emailHeaders: this.state.emailHeaders,
          emailBody: this.state.emailBody,
          emailAttachments: this.state.emailAttachments,
          emailLinks: this.state.emailLinks,
          loadQuarantine: this.loadQuarantine,
          releaseQuarantine: this.releaseQuarantine
        }
      }>
        {this.props.children}
      </QuarantineContext.Provider>
    )
  }
}

export default withRouter(withTranslation()(QuarantineProvider))
