import React, { Fragment } from "react";
import { Progress } from 'reactstrap';

class TargetGraph extends React.Component {

    render() {
        return (
            <Fragment>
                <div className="card mb-3 widget-content">
                    <div className="widget-content-outer">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <div className="widget-heading">
                                    {this.props.heading}
                                </div>
                                <div className="widget-subheading">
                                    {this.props.subheading}
                                </div>
                            </div>
                            <div className="widget-content-right">
                                <div className="widget-numbers text-secondary">
                                    {this.props.value}
                                </div>
                            </div>
                        </div>
                        <div className="widget-progress-wrapper">
                            <Progress className="progress-bar-sm"
                                color={`ev-${this.props.color}`}
                                value={this.props.percent} />
                            <div className="progress-sub-label">
                                <div className="sub-label-left">

                                </div>
                                <div className="sub-label-right">
                                    {this.props.percent} %
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default TargetGraph;