import React, { Fragment } from 'react';
import {
  Col, FormGroup, Label, Input, Row, Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ReactTable from 'react-table';

class Top10Words extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      words: this.initWords()
    }
    this.updateWord = this.updateWord.bind(this);
    this.renderEditable = this.renderEditable.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.words !== prevProps.words) {
      this.setState({
        words: this.initWords(this.props.words)
      });
    }
  }

  initWords(objectWords = null) {
    const words = [];
    // Try to get words from object
    if (objectWords) {
      for (const key in objectWords) {
        if (objectWords.hasOwnProperty(key)) {
          console.log("add", key)
          words.push({ word: key, value: objectWords[key] })
        }
      }
    }
    // complement the words array
    const length = words.length;
    for (let i = 0; i < 10 - length; i++) {
      console.log("create", i)
      words.push({ word: '', value: '' })
    }
    return words;
  }

  updateWord(words) {
    const wordsObject = {};
    words.forEach(word => {
      if (word.word && word.value) {
        wordsObject[word.word] = word.value;
      }
    });
    if (typeof this.props.onUpdateWords === 'function') {
      this.props.onUpdateWords(wordsObject);
    }
  }

  renderEditable(cellInfo) {
    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const words = [...this.state.words];
          words[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          this.updateWord(words);
          this.setState({ words });
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.words[cellInfo.index][cellInfo.column.id]
        }}
      />
    );
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <Row>
          <Col md={12}>
            <h3 className="form-heading pt-3 pb-3 float-left">
              {this.props.title}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ReactTable
              columns={[
                {
                  Header: t("campaign.message-info.word"),
                  accessor: 'word',
                  Cell: this.renderEditable
                },
                {
                  Header: t("campaign.message-info.value"),
                  accessor: 'value',
                  Cell: this.renderEditable
                }
              ]}
              data={this.state.words}
              showPageSizeOptions={false}
              showPaginationBottom={false}
              defaultPageSize={10}
              minRows={10}
              filterable={false}
              className="-striped -highlight"
            />
          </Col>
        </Row>
      </Fragment >
    );
  }
}

export default withTranslation()(Top10Words);
