import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import SearchBox from 'Layout/Components/SearchBox';
import { CampaignContext } from './CampaignContext';

class ActionsMenu extends React.Component {

  static contextType = CampaignContext;

  render() {
    const { t, i18n, doSearch, history } = this.props;

    return (
      <Fragment>
        <SearchBox placeholder={t('campaigns.actions.search')} doSearch={doSearch} />
        <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
          onClick={() => history.push('/admin/campaign_create')}>
          <i className="lnr-pencil btn-icon-wrapper"> </i>
          {t('campaigns.actions.create')}
        </Button>
        <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
          onClick={() => history.push('/admin/campaign')}>
          <i className="lnr-plus-circle btn-icon-wrapper"> </i>
          {t('campaigns.actions.add')}
        </Button>
      </Fragment>
    );
  }
}

export default withRouter(withTranslation()(ActionsMenu));