import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input, FormCheck
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {importEmailTemplate} from "../../../API/PhishingAPI";
import InfoPopover from 'Layout/Components/InfoPopover';


class ImportEmailTemplateModal extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      modal: this.props.open,
      import_email:{
        content:"",
        convert_links:true
      }
    }
    this.toggle = this.toggle.bind(this);
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(event) {
    const key = event.target.name;
    console.log('Key is '+key);
    const value = event.target.type === 'checkbox'
      ? event.target.checked : event.target.value;
    this.setState(updater => ({
      import_email: {
        ...updater.import_email,
        [key]: value
      }
    }));
  }

  submit(){
    console.log(this.state.import_email);
    const promise = importEmailTemplate(this.state.import_email);
    promise
      .then(res => {
        console.log(res);
        this.toggle();
        this.props.onSubmit(res.html, res.subject)
      }).catch(error => {

      });
  }

  render(){

    const { t, i18n } = this.props;
    const openImportEmailButton = this.props.showImportEmailButton && <span>
    <Button color="primary" className="mb-2 mr-2 btn-icon btn-shadow" size="lg"   onClick={() => this.toggle()}>
      <i className="pe-7s-mail btn-icon-wrapper"> </i>
      {t('email_template.import_email.title')}
    </Button><InfoPopover id="import-instructions" body={t('email_template.import_email.instructions')} /></span>;

    return (
      <Fragment>
        {openImportEmailButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>{t('email_template.import_email.title')}</ModalHeader>
            <ModalBody>
              <Form onSubmit={(e) => { e.preventDefault(); }}>
                <FormGroup>
                  <Label>Copy and paste the .eml content of an e-mail:</Label>
                  <Input type="textarea" name="content" id="content" value={this.state.import_email.content} onChange={this.handleChange}/>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="convert_links" checked={this.state.import_email.convert_links} onChange={this.handleChange}/>{' '}
                      {t('email_template.import_email.convert_links')}
                  </Label>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="link" onClick={this.toggle}>{t('email_template.actions.cancel')}</Button>
              <Button color="primary" onClick={this.submit}>Save</Button>{' '}
            </ModalFooter>
        </Modal>
      </Fragment>
    )
  }

  toggle(){
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal
    })
  }
}

ImportEmailTemplateModal.defaultProps = {
  open: false,
  showImportEmailButton: true
}

export default withTranslation()(ImportEmailTemplateModal);
