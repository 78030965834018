import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody } from 'reactstrap';
import ReactTable from "react-table";
import { toast } from 'react-toastify';
import matchSorter from 'match-sorter'
import { withTranslation } from "react-i18next";

import PageTitle from '../../Layout/Components/PageTitle';
import ActionsMenu from './ActionsMenu';

import { getPartners } from '../../API/PartnersAPI';

class Partners extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      partners: [],
      filtered: [],
      filterAll: ""
    };

    this.fillTable = this.fillTable.bind(this);
    this.searchPartner = this.searchPartner.bind(this);
    this.addPartnerCallback = this.addPartnerCallback.bind(this);
  }

  componentDidMount() {
    this.fillTable();
  }

  fillTable() {
    getPartners().then(response => {
      this.setState({
        partners: response
      });
    }).catch(error => {
      console.error("Error getting Partners", error);
      toast["error"](this.props.t('errors.loading', { element: 'Partners', message: error.message }));
    });
  }

  searchPartner(name) {
    this.setState({
      filtered: [{ id: 'name', value: name }],
      filterAll: name
    });
  }

  addPartnerCallback() {
    this.fillTable();
  }

  render() {
    const { t, i18n, history } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("partners.title")}
          icon="pe-7s-global icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <ActionsMenu addPartnerCallback={this.addPartnerCallback} doSearch={this.searchPartner} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    columns={[
                      {
                        Header: t("partners.partner.title"),
                        columns: [
                          {
                            Header: t("partners.partner.name"),
                            accessor: 'name',
                            id: 'name',
                            filterMethod: (filter, rows) => {
                              const result = matchSorter(rows, filter.value, {
                                keys: [
                                  "name",
                                  "domain",
                                ], threshold: matchSorter.rankings.WORD_STARTS_WITH
                              });
                              return result;
                            },
                            filterAll: true
                          },
                          {
                            Header: t("partners.partner.domain"),
                            accessor: 'domain'
                          },
                          {
                            Header: t("partners.partner.address"),
                            accessor: 'address'
                          }
                        ]
                      },
                      {
                        Header: t("partners.contact.title"),
                        columns: [
                          {
                            Header: t("partners.contact.name"),
                            accessor: 'contact[0].name'
                          },
                          {
                            Header: t("partners.contact.address"),
                            accessor: 'contact[0].address'
                          },
                          {
                            Header: t("partners.contact.phone"),
                            accessor: 'contact[0].phone'
                          }
                        ]
                      }
                    ]}
                    data={this.state.partners}
                    defaultPageSize={10}
                    filterable={false}
                    filtered={this.state.filtered}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                    className="-striped -highlight"
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            if (e.target.tagName.toLowerCase() === 'button') {
                              return; // Ignore clicks on buttons
                            }
                            history.push(`/admin/partner/${rowInfo.original.domain}`)
                          },
                          style: {
                            cursor: 'pointer'
                          }
                        }
                      }
                      return {}
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment >
    )
  }
}

export default withRouter(withTranslation()(Partners))