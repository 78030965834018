import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

import PaymentModal from './PaymentModal';
import { PaymentsContext } from './PaymentsContext';
import PaymentsTable from './PaymentsTable';

class PaymentsModal extends React.Component {

  static contextType = PaymentsContext;

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      openNew: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    if (!this.state.modal) {
      this.context.loadPayments();
    }
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button color="primary" onClick={() => this.toggle()}>
        {t('payments.actions.list')}
      </Button>;

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}
          size='xl' scrollable={true}>
          <ModalHeader toggle={this.toggle}>{t('payments.title')}</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="3">
                <PaymentModal showOpenButton={true} open={false}
                  onClose={() => this.setState({ openNew: false })} />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="12">
                <PaymentsTable pageSize={5} showDetail={true} showStatus={true} />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.close')}</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

PaymentsModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(PaymentsModal);
