import { doGet, doPost, doDelete, getDomain } from "./BaseAPI";

export function getBlacklist(domain = null) {
  if (!domain) {
    return doGet(`/organization/blackList/domain`); // organization
  }
  return doGet(`/organization/blackList/domain/${domain}`); // admin
}

export function getAccountBlacklist(account){
  return doGet(`/admin/client/blacklist/${account}`)
}

export function createBlacklist(url) {
  return doPost("/organization/blackList/create", {
    domain: getDomain(),
    ip_url: url
  });
}

export function createListOfBlacklist(urls) {
  const domain = getDomain();
  return doPost("/organization/blackList/createlist",
    urls.map(url => {
      return {
        domain: domain,
        ip_url: url
      }
    }));
}

export function deleteBlacklist(id) {
  return doDelete(`/organization/blackList/delete/${id}`);
}

export function deleteAccountBlacklist(account, email) {
  return doDelete(`/admin/client/blacklist/${account}/${email}`);
}
