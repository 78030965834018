import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';

const campaignColumnMapping = [
  {
    Header: "phishing_campaign.name",
    accessor: 'name',
    Cell: ({ row }) => {
      return <Link className="nav-link" to={`phishing_campaign/${row._original.id}`}>{row._original.name}</Link>;
    }
  },
  {
    Header: "phishing_campaign.created_date",
    accessor: 'formatted_created_date',
  },
  {
    Header: "phishing_campaign.status",
    Cell: ({ row }) => {
      const status = row._original.status;
      switch (status) {
        case "Completed":
          return <Badge color="success">{status}</Badge>;
        case "In progress":
          return <Badge color="primary">{status}</Badge>;
        case "Queued":
          return <Badge color="info">{status}</Badge>;
        default:
          return status;
      }
    }
  }
];

export const activeCampaignTableConfiguration = {
  columns: campaignColumnMapping,
  editOptions: {
    copy: true,
    del: true
  }
}

export const archivedCampaignTableConfiguration = {
  columns: campaignColumnMapping,
  editOptions: {
    copy: true
  }
}
