import * as msal from "@azure/msal-browser";
import { v4 as uuidv4 } from "uuid";

// Msal Configurations
const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_AUTH_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: process.env.REACT_APP_MICROSOFT_AUTH_REDIRECT
  },
  cache: {
    cacheLocation: "localStorage"
  }
};
export const msalInstance = new msal.PublicClientApplication(msalConfig);

// Authentication Request Parameters
export const loginRequest = {
  scopes: process.env.REACT_APP_MICROSOFT_AUTH_SCOPE_LOGIN.split(",")
};
export const apiTokenRequest = {
  scopes: [
    process.env.REACT_APP_MICROSOFT_AUTH_SCOPE_ACCESS_API
  ]
};
export const outlookTokenRequest = {
  scopes: [
    process.env.REACT_APP_MICROSOFT_AUTH_SCOPE_ACCESS_OUTLOOK
  ]
};

const adminConsent = {
  clientId: process.env.REACT_APP_MICROSOFT_AUTH_OUTLOOK_CLIENT_ID,
  redirectUrl: process.env.REACT_APP_MICROSOFT_AUTH_ADMIN_CONSENT_REDIRECT
}

/**
 * Get the access token from Microsoft provider
 */
export async function getAccessTokenMicrosoft(email, tokenRequest, interactive = false) {
  tokenRequest.account = msalInstance.getAccountByUsername(email);
  let accessToken = null;

  if (!interactive) { // request token silently, by default
    console.debug('Acquire Token Microsoft silently');
    try {
      const result = await msalInstance.acquireTokenSilent(tokenRequest);
      accessToken = result.accessToken;
    } catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        console.warn("Unable to adquire token silently, trying it interactively.");
        interactive = true; // try the interactive approach
      } else {
        console.error("Unable to adquire token silently.");
        throw error;
      }
    }
  }

  if (interactive) { // request token interactively, if needed
    console.debug('Acquire Token Microsoft interactively');
    try {
      const result = await msalInstance.acquireTokenPopup(tokenRequest);
      accessToken = result.accessToken;
    } catch (error) {
      console.error("Unable to adquire token interactively.");
      throw error;
    }
  }

  // console.debug('Acquire Token Microsoft result', accessToken);
  return accessToken;
}

export async function acquireAdminConsent(user) {
  console.debug('Acquire Admin Consent');

  const state = uuidv4();
  const url = `https://login.microsoftonline.com/${user.tenant}/adminconsent`;
  const queryString = `?client_id=${adminConsent.clientId}&state=${state}` +
    `&redirect_uri=${adminConsent.redirectUrl}&scope=${outlookTokenRequest.scopes}`;
  const name = "adminConsent"

  const popupWindow = openSizedPopup(url + queryString, name);
  if (!popupWindow) {
    console.error("Error opening popup window.");
    throw msal.BrowserAuthError.createEmptyWindowCreatedError();
  }
  if (popupWindow.focus) {
    popupWindow.focus();
  }
  
  try {
    const ok = await monitorAdminConsent(popupWindow, user.tenant, state);

    popupWindow.close();

    return ok;
  } catch (error) {
    console.error("Error adquiring admin consent.", error);
    popupWindow.close();
    return Promise.reject(error);
  }
}

const POPUP_WIDTH = 483;
const POPUP_HEIGHT = 600;

function openSizedPopup(urlNavigate, popupName) {
  const winLeft = window.screenLeft ? window.screenLeft : window.screenX;
  const winTop = window.screenTop ? window.screenTop : window.screenY;

  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const left = Math.max(0, ((width / 2)) - (POPUP_WIDTH / 2) + winLeft);
  const top = Math.max(0, ((height / 2)) - (POPUP_HEIGHT / 2) + winTop);

  return window.open(urlNavigate, popupName, `width=${POPUP_WIDTH}, height=${POPUP_HEIGHT}, top=${top}, left=${left}, scrollbars=yes`);
}

const TIMEOUT = 500;
const POLLING_INTERVAL_MS = 50;

function monitorAdminConsent(contentWindow, tenant, state) {
  return new Promise((resolve, reject) => {

    const maxTicks = TIMEOUT / POLLING_INTERVAL_MS;
    let ticks = 0;

    console.debug("monitorAdminConsent polling started");

    const intervalId = setInterval(() => {
      if (contentWindow.closed) {
        console.error("monitorAdminConsent window closed");
        clearInterval(intervalId);
        reject(msal.BrowserAuthError.createUserCancelledError());
        return;
      }

      let href;
      try {
        href = contentWindow.location.href;
      } catch (e) { }

      if (!href || href === "about:blank") {
        return;
      }

      ticks++;

      let params = new URLSearchParams(contentWindow.location.search);
      if (href && paramsAreValid(params, tenant, state)) {
        console.debug("monitorAdminConsent consent adquired");
        clearInterval(intervalId);
        resolve({ "admin_consent": true });
      } else if (params.has("error")) {
        console.error("monitorAdminConsent error returned");
        clearInterval(intervalId);
        reject(new msal.AuthError(params.get("error"), params.get("error_description")));
      } else if (ticks > maxTicks) {
        console.error("monitorAdminConsent unable to acquire consent, timing out");
        clearInterval(intervalId);
        reject(msal.BrowserAuthError.createMonitorPopupTimeoutError());
      }
    }, POLLING_INTERVAL_MS);
  });
}

function paramsAreValid(params, tenant, state) {
  return "True" === params.get("admin_consent") && tenant === params.get("tenant")
    && state === params.get("state");
}
