import React from 'react';
import {
  CardBody, Badge
} from 'reactstrap';
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';

class LaunchCampaignDetailsTable extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      campaignList: this.props.campaignList,
      isLoaded: this.props.isLoaded
    }
  }
  componentWillReceiveProps(props){
    this.setState({
      campaignList: props.campaignList,
      isLoaded: props.isLoaded
    })
  }
  render(){
    const { t, i18n } = this.props;
    return(
      <CardBody>
      {this.state.isLoaded ? <ReactTable
      columns={[
        {
          Header: t("phishing_dashboard.name"),
          accessor : 'name',
          style: { "overflowX": "scroll" },
          Cell: ({row}) => (
            <Link className="nav-link" to={`phishing_simulator/phishing_campaign/${row._original.id}`}>{row._original.name}</Link>
          )
        },
        {
          Header: t("phishing_dashboard.created_date"),
          accessor : 'formatted_created_date',
        },
        {
          Header: t("phishing_dashboard.sent"),
          accessor : 'stats.sent',
        },
        {
          Header: t("phishing_dashboard.opened"),
          accessor : 'stats.opened',
        },
        {
          Header: t("phishing_dashboard.clicked"),
          accessor : 'stats.clicked',
        },
        {
          Header: t("phishing_dashboard.submitted_date"),
          accessor : 'stats.submitted_data',
        },
        {
          Header: t("phishing_dashboard.email_reported"),
          accessor : 'stats.email_reported',
        },
        {
          Cell: ({row}) => {
            const status = row._original.status;
            switch(status){
                case "Completed":
                  return <Badge color="success">{status}</Badge>;
                case "In progress":
                  return <Badge color="primary">{status}</Badge>;
                case "Queued":
                  return <Badge color="info">{status}</Badge>;
                default:
                  return status;
            }
          }
        }
      ]}
      data={this.state.campaignList}
      showPageSizeOptions={false}
      defaultPageSize={10}
      pageSize={this.state.campaignList.length > 10 ? 10 : this.state.campaignList.length}
      className="-striped -highlight"
      /> : <div><Spinner color="primary" /> Data is loading!!!</div>}
    </CardBody>
    )
  }
}

export default withTranslation()(LaunchCampaignDetailsTable);
