import React from 'react';
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';
import { Input, Button } from 'reactstrap';

class PhishingDataTable extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      columnMapping: this.props.config.columns,
      selectionColumn: this.props.config.selectionColumn,
      editOptions: this.props.config.editOptions,
      defaultOptions : function createOptions(options){
        if(!options){
          return
        }
        const {edit, copy, del} = options;
        return {
          Header: "Actions",
          Cell: ({row}) => (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              {edit && <Button className="mb-2 mr-2 btn-icon btn-shadow" size="md" color="success" onClick={() => props.onEditOrCopy(row._original)}>
                <i className="lnr-pencil btn-icon-wrapper mx-auto"> </i>
              </Button>}
              {copy && <Button className="mb-2 mr-2 btn-icon btn-shadow" size="md" color="success" onClick={() => props.onEditOrCopy(row._original, true)}>
                <i className="pe-7s-copy-file btn-icon-wrapper mx-auto"> </i>
              </Button>}
              {del && <Button className="mb-2 mr-2 btn-icon btn-shadow" size="md" color="success" onClick={() => props.onDelete(row)}>
                <i className="pe-7s-trash btn-icon-wrapper mx-auto"> </i>
              </Button>}
            </div>
          )
        }
      },
        selectionOption: {
          Cell: ({row}) => (
            <div>
                <Input type="radio" id="selection" name="selection"  className="ml-2" 
                  onClick={() => props.onSelect(row._original)} checked={row._original.name == this.props.selectedValue}/>
            </div>
          ),
          width: 50
        }
    }
    this.generateColumns = this.generateColumns.bind(this);
  }

  generateColumns(){
    const reactTableColumn = []
    const { t, i18n } = this.props;
    this.state.columnMapping.forEach(c => {
      const col = {}
      for (const [key, value] of Object.entries(c)){
        if(key == "Header"){
            col['Header'] =  t(value)
        } else{
          col[key] = value
        }
      }
      reactTableColumn.push(col)
    })
    if(this.state.selectionColumn){
      reactTableColumn.unshift(this.state.selectionOption)
    }
    if(this.state.editOptions){
      reactTableColumn.push(this.state.defaultOptions(this.state.editOptions))
    }
    return reactTableColumn;
  }

  render(){
    const { t, i18n } = this.props;
    const reactTableColumn = this.generateColumns();
    const data = Array.isArray(this.props.data)? this.props.data:[];
    //console.log("The data is",data);
    //console.log("React column is",reactTableColumn);
    return (
      <ReactTable
        columns = {reactTableColumn}
        data = {data}
        showPageSizeOptions={false}
        pageSize={data.length > 10 ? 10: data.length}
        className="-striped -highlight"
      />
    )
  }
}
export default withTranslation()(PhishingDataTable);
