import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import LoadingIndicator from './LoadingIndicator';

class PageTitle extends React.Component {

  render() {
    let {
      enablePageTitleIcon,
      enablePageTitleSubheading,
      heading,
      icon,
      subheading,
      actions,
      info
    } = this.props;

    return (
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className={cx("page-title-icon", { 'd-none': !enablePageTitleIcon })}>
              <i className={icon} />
            </div>
            <div>
              {heading}
              <div className={cx("page-title-subheading", { 'd-none': !enablePageTitleSubheading })}>
                {subheading}
              </div>
            </div>
            <div>
              {info}
            </div>
            <div className="page-title-loading">
              <LoadingIndicator />
            </div>
          </div>
          <div className="page-title-actions">
            {actions}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
  enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);