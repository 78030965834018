import React, { Component }  from 'react';
const etReactTableConfiguration = {
  columns: [
    {
      Header: "email_template.name",
      accessor : "name"
    },
    {
      Header: "email_template.last_modified_date",
      accessor : "modified_date",
    }
  ],
  selectionColumn: true,
  editOptions: false
}

export const etReactTableConfiguration_ForTemplateScreen = {
  columns: [
    {
      Header: "email_template.name",
      accessor : "name"
    },
    {
      Header: "email_template.last_modified_date",
      accessor : "modified_date",
    }
  ],
  selectionColumn: false,
  editOptions: {
    edit: true,
    copy: true,
    del: true
  }
}

export const etReactTableConfiguration_ForImportScreen = {
  columns: [
    {
      Header: "email_template.name",
      accessor : "name"
    },
    {
      Header: "email_template.last_modified_date",
      accessor : "modified_date",
    }
  ],
  editOptions: {
    copy: true
  }
}
export default etReactTableConfiguration;
