import { trackPromise } from 'react-promise-tracker';
import { fetchWithTimeout, getDomain, handleResponse } from './BaseAPI';

function requestHeaders() {
  const requestHeaders = new Headers();
  requestHeaders.append('Accept', 'application/json');
  requestHeaders.append('Content-Type', 'application/json');
  return requestHeaders;
}

function doGet(url) {
  return trackPromise(
    fetchWithTimeout(url, {
      method: 'GET',
      headers: requestHeaders()
    }).then(response => {
      return handleResponse(response);
    })
  );
}

function doPost(url, data) {
  console.log("Entered into doPost" + data.url);
  return trackPromise(
    fetchWithTimeout(url, {
      method: 'POST',
      headers: requestHeaders(),
      body: JSON.stringify(data)
    }).then(response => {
      return handleResponse(response);
    })
  );
}

export const servicesUrl = process.env.REACT_APP_CONSOLE_URL;

export function getContact() {
  const domain = getDomain();
  if (domain) {
    return doGet(`${servicesUrl}/contact/${domain}`);
  }
  return null;
}

export function getGeolocation(ipArray) {
  return doGet(`${servicesUrl}/geo/${ipArray.join(",")}`);
}

export function getWhowasBalance() {
  return doGet(`${servicesUrl}/whowas`);
}

export function getPaymentPlans() {
  return doGet(`${servicesUrl}/payment/plans`);
}

// Campaigns Data

export function getCampaign(campaignId) {
  return doGet(`${servicesUrl}/campaign?campaignId=${campaignId}`);
}

export function getCampaignRange(campaignId) {
  return doGet(`${servicesUrl}/campaign/range?campaignId=${campaignId}`);
}

export function getRegistrantCampaign(campaignId) {
  return doGet(`${servicesUrl}/campaign/registrant?campaignId=${campaignId}`);
}

export function getSimilarCampaign(campaignId1, campaignId2) {
  return doGet(`${servicesUrl}/campaign/similar?campaignId1=${campaignId1}&campaignId2=${campaignId2}`);
}

export function getLocalProximity(campaignId, query) {
  return doGet(`${servicesUrl}/campaign/proximity?campaignId=${campaignId}&${query}`);
}

// Campaigns Detection

export function listCampaigns() {
  return doGet(`${servicesUrl}/campaignDetection/list`);
}

export function getCampaignDetails(key) {
  return doGet(`${servicesUrl}/campaignDetection/get/${key}`);
}

export function createCampaign(emails) {
  return doPost(`${servicesUrl}/campaignDetection/create`,
    { emails: emails });
}

export function addCampaigns(new_campaigns, validate = true) {
  return doPost(`${servicesUrl}/campaignDetection/add`,
    { new_campaigns: new_campaigns, validate: validate });
}

export function updateCampaign(key, updates) {
  return doPost(`${servicesUrl}/campaignDetection/update`,
    { key: key, updates: updates });
}

export function deleteCampaigns(keys) {
  return doPost(`${servicesUrl}/campaignDetection/delete`,
    { keys: keys });
}

export function visualizeCampaigns(features, feature_type, campaign_keys, tokenize, threshold) {
  return doPost(`${servicesUrl}/campaignDetection/visualize`,
    {
      features: features,
      feature_type: feature_type,
      campaign_keys: campaign_keys,
      tokenize: tokenize,
      threshold: threshold
    });
}

// Partner logo upload

export function uploadPartnerLogo(domain, logoName, logo) {
  return doPost(`${servicesUrl}/partner/logo`,
    {
      "domain": domain,
      "logoName": logoName,
      "logo": logo
    });
}