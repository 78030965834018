import React,{Fragment} from 'react';
import {
  Card, CardBody, Button
} from 'reactstrap';
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';

class UsersAndGroupsTable extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      groupList: this.props.groupList,
      isLoaded: this.props.isLoaded
    }
  }

  componentWillReceiveProps(props){
    console.log('Properties received');
    this.setState({
      groupList: props.groupList,
      isLoaded: props.isLoaded
    });
  }

  render(){
    const { t, i18n } = this.props;
    return (
      <CardBody>
      {this.state.isLoaded ? <ReactTable
      columns={[
        {
          Header: t("users_and_groups.name"),
          accessor : 'name'
        },
        {
          Header: t("users_and_groups.last_modified_date"),
          accessor : 'modified_date',
        },
        {
          Header: t("users_and_groups.number_of_targets"),
          accessor : 'targets',
          Cell: ({ value }) => value.length
        },
        {
          /* TODO: Replace inline style class with CSS class */
          Header: "Actions",
          Cell: ({row}) => (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Button className="mb-2 mr-2 btn-icon btn-shadow" size="md" color="success" onClick={() => this.props.onEdit(row._original)}>
                <i className="lnr-pencil btn-icon-wrapper mx-auto"> </i>
              </Button>
              <Button className="mb-2 mr-2 btn-icon btn-shadow" size="md" color="success" onClick={() => this.props.onDelete(row)}>
                <i className="pe-7s-trash btn-icon-wrapper mx-auto"> </i>
              </Button>
            </div>
          )
        }]}
      data={this.state.groupList}
      showPageSizeOptions={false}
      defaultPageSize={10}
      pageSize={this.state.groupList.length > 10 ? 10: this.state.groupList.length}
      className="-striped -highlight"
      /> : <div><Spinner color="primary" /> Data is loading!!!</div>}
    </CardBody>)
  }
}

export default withTranslation()(UsersAndGroupsTable);
