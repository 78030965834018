import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Nav from './Nav/VerticalNavWrapper';
import HeaderLogo from './HeaderLogo';

import {
    setEnableMobileMenu
} from '../Reducers/ThemeOptions';
import { withRouter } from 'react-router-dom';

class AppSidebar extends React.Component {

    constructor(props) {
        super(props);

        this.toggleMobileSidebar = this.toggleMobileSidebar.bind(this);
    }

    toggleMobileSidebar() {
        let { enableMobileMenu, setEnableMobileMenu } = this.props;
        setEnableMobileMenu(!enableMobileMenu);
    }

    render() {
        let {
            backgroundColor,
            enableBackgroundImage,
            enableSidebarShadow,
            backgroundImage,
            backgroundImageOpacity,
            navigation
        } = this.props;

        return (
            <Fragment>
                <div className="sidebar-mobile-overlay" onClick={this.toggleMobileSidebar} />
                <CSSTransitionGroup
                    component="div"
                    className={cx("app-sidebar", backgroundColor, { 'sidebar-shadow': enableSidebarShadow })}
                    transitionName="SidebarAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={1500}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <HeaderLogo />
                    <PerfectScrollbar>
                        <div className="app-sidebar__inner">
                            <Nav content={navigation} />
                        </div>
                    </PerfectScrollbar>
                    <div className={cx("app-sidebar-bg", backgroundImageOpacity)} style={{
                        backgroundImage: enableBackgroundImage ? `url(${backgroundImage})` : null
                    }}>
                    </div>
                </CSSTransitionGroup>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
    enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    backgroundColor: state.ThemeOptions.backgroundColor,
    backgroundImage: state.ThemeOptions.backgroundImage,
    backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
});

const mapDispatchToProps = dispatch => ({
    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppSidebar));