import React from "react";
import { toast } from "react-toastify";
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";

import { toDate } from "../../Helpers/TextHelper";
import {
  getPartner, updatePartner, createPartner
} from "../../../API/PartnersAPI";
import { getDomain } from "../../../API/BaseAPI";
import { uploadPartnerLogo } from "API/ConsoleServicesAPI";

export const PartnerContext = React.createContext();
export const PartnerConsumer = PartnerContext.Consumer;

class PartnerProvider extends React.Component {

  constructor(props) {
    super(props);

    const path = props.location.pathname;
    const isAdmin = path.startsWith('/admin');

    this.state = {
      isAdmin: isAdmin,
      domain: isAdmin ? this.props.domain : getDomain(),
      partner: {},
      contacts: [],
      contact: {}
    }

    this.isEdit = this.isEdit.bind(this);
    this.isEditContact = this.isEditContact.bind(this);
    this.createPartner = this.createPartner.bind(this);
    this.updatePartner = this.updatePartner.bind(this);
    this.addContact = this.addContact.bind(this);
    this.openContact = this.openContact.bind(this);
    this.editContact = this.editContact.bind(this);
    this.delContact = this.delContact.bind(this);
    this.logoURL = this.logoURL.bind(this);
    this.uploadLogo = this.uploadLogo.bind(this);
  }

  componentDidMount() {
    if (this.state.domain) { // editing a partner?
      getPartner(this.state.domain).then(response => {
        const partner = response;
        partner.created = toDate(partner.created); // Adjust date
        this.setState({
          partner: partner,
          contacts: partner.contact
        })
      }).catch(error => {
        console.error("Error getting Partner", error);
        toast["error"](this.props.t('errors.loading', { element: 'Partner', message: error.message }));
      })
    }
  }

  isEdit() {
    return this.state.partner.id ? true : false;
  }

  isEditContact() {
    return this.state.contact.email ? true : false;
  }

  async createPartner(partner) {
    try {
      partner = await createPartner(partner);
      partner.created = toDate(partner.created); // Adjust date
      this.setState({
        partner: partner
      });
      toast["success"](this.props.t('messages.success_create', { element: 'Partner' }));
    } catch (error) {
      console.error("Error saving Partner", error);
      toast["error"](this.props.t('errors.creating', { element: 'Partner', message: error.message }));
    }
  }

  updatePartner(partner) {
    updatePartner(partner).then(partner => {
      partner.created = toDate(partner.created); // Adjust date
      this.setState({
        partner: partner
      });
      toast["success"](this.props.t('messages.success_update', { element: 'Partner' }));
    }).catch(error => {
      console.error("Error saving Partner", error);
      toast["error"](this.props.t('errors.updating', { element: 'Partner', message: error.message }));
    });
  }

  addContact(contact) {
    if (contact) {
      this.setState(updater => {
        const contacts = updater.contacts.concat(contact);
        return {
          contacts: contacts
        }
      });
      toast["warning"](this.props.t('partners.contact.messages.contacts_changed'));
    }
  }

  openContact(contact = {}) {
    this.setState({
      contact: contact
    })
  }

  editContact(contact) {
    if (contact) {
      this.setState(updater => { // Override the contact
        let contacts = updater.contacts.filter(c => c.email !== contact.email).concat(contact);
        return {
          contacts: contacts
        }
      });
      toast["warning"](this.props.t('partners.contact.messages.contacts_changed'));
    }
  }

  delContact(contact) {
    if (contact) {
      this.setState(updater => {
        const contacts = updater.contacts.filter(c => c.email !== contact.email);
        return {
          contacts: contacts
        }
      });
      toast["warning"](this.props.t('partners.contact.messages.contacts_changed'));
    }
  }

  logoURL() {
    const logoName = this.state.partner.domain
      ? this.state.partner.domain + ".png" : "logo_veritas.png";
    return process.env.REACT_APP_LOGOS_ROOT + "/" + logoName;
  }

  uploadLogo(logoName, logoData) {
    if (this.state.partner.domain) {
      logoName = this.state.partner.domain + "." +  logoName.split(".")[1];
      logoData = logoData.replace(/^data:.+;base64,/, ''); // upload image as base64 
      uploadPartnerLogo(this.state.partner.domain, logoName, logoData).then(result => {
        toast["success"](this.props.t('messages.success_upload', { element: 'Partner logo' }));
      }).catch(error => {
        console.error("Error uploading Partner logo", error);
        toast["error"](this.props.t('errors.uploading', { element: 'Partner logo', message: error.message }));
      });
    } else {
      toast["warning"](this.props.t('partners.partner.messages.logo_no_domain'));
    }
  }

  render() {
    return (
      <PartnerContext.Provider value={
        {
          domain: this.state.domain,
          partner: this.state.partner,
          contacts: this.state.contacts,
          contact: this.state.contact,
          isAdmin: this.state.isAdmin,
          isEdit: this.isEdit,
          isEditContact: this.isEditContact,
          createPartner: this.createPartner,
          updatePartner: this.updatePartner,
          addContact: this.addContact,
          openContact: this.openContact,
          editContact: this.editContact,
          delContact: this.delContact,
          logoURL: this.logoURL,
          uploadLogo: this.uploadLogo
        }
      }>
        {this.props.children}
      </PartnerContext.Provider>
    )
  }
}

export default withRouter(withTranslation()(PartnerProvider));
