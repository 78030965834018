import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import PageTitle from '../../Layout/Components/PageTitle';
import { FootprintMap } from '../ThreatIntelligence/EmailDetails';

import ThreatHighlights from "./ThreatHighlights";
import { IncidentContext } from "./IncidentContext";
import { MessageHeader, MessageBody, MessageLinks } from "../EmailAnalysis";
import MessageAttachments from "Application/EmailAnalysis/MessageAttachments";

class Incident extends React.Component {

  static contextType = IncidentContext;

  render() {
    const { t, i18n } = this.props;

    const { loaded, message } = this.context;

    const emailPath = loaded
      ? <FootprintMap originIP={message.originSourceIP} smtpIPs={message.smtpIPs} />
      : "";

    return (
      <Fragment>
        <PageTitle
          heading={t("incidents.title_detail")}
          icon="pe-7s-helm icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="6" sm="12">
              <ThreatHighlights />
            </Col>
            <Col md="6" sm="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>{t("incidents.email_path")}</CardTitle>
                  <Row form>
                    <Col md={12}>
                      {emailPath}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <MessageHeader loaded={this.context.loaded}
                message={this.context.message}
                emailHeaders={this.context.emailHeaders} 
                incident={this.context.incident}/>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <MessageLinks emailLinks={this.context.emailLinks} />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <MessageBody loaded={this.context.loaded}
                emailBody={this.context.emailBody}
                message={this.context.message}
                emlDecoded={this.context.emlDecoded} />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <MessageAttachments emailAttachments={this.context.emailAttachments} />
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment >
    )
  }
}

export default withTranslation()(Incident);
