import React, { Fragment } from 'react';
import { Spinner } from "reactstrap";
import { promiseTrackerHoc } from "react-promise-tracker";

class LoadingIndicator extends React.Component {
  render() {
    const loading = this.props.promiseInProgress && <Spinner color="primary" size="md" />;
    return (
      <Fragment>{loading}</Fragment>
    )
  }
}

export default promiseTrackerHoc(LoadingIndicator);
