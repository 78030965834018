import { doGet, doPost, doDelete, getDomain } from "./BaseAPI";

export function getWhitelist(domain = null) {
  if (!domain) {
    return doGet(`/organization/whiteList/domain`); // organization
  }
  return doGet(`/organization/whiteList/domain/${domain}`); // admin
}

export function createWhitelist(url) {
  return doPost("/organization/whiteList/create", {
    domain: getDomain(),
    ip_url: url
  });
}

export function deleteWhitelist(id) {
  return doDelete(`/organization/whiteList/delete/${id}`);
}
