export default class CountryHelper {

  // Map of countries: Map<code, name>
  static countries;

  /**
   * Return the available countries as a Map<code, name>.
   */
  static getCountries() {
    if (!this.countries || this.countries.size === 0) {
      // Convert the array to a map in the first call
      this.countries = new Map(this.country_data.map(val => [val.code, val.country]));
    }
    return this.countries;
  }

  /**
   * Get a country by its code.
   */
  static getCountry(code) {
    let country = this.getCountries().get(code.toUpperCase());
    return country || code;
  }


  static country_data = [
    {
      "id": 1,
      "code": "AF",
      "country": "Afghanistan",
    },
    {
      "id": 2,
      "code": "AL",
      "country": "Albania",
    },
    {
      "id": 3,
      "code": "DZ",
      "country": "Algeria",
    },
    {
      "id": 4,
      "code": "AS",
      "country": "American Samoa",
    },
    {
      "id": 5,
      "code": "AD",
      "country": "Andorra",
    },
    {
      "id": 6,
      "code": "AO",
      "country": "Angola",
    },
    {
      "id": 7,
      "code": "AI",
      "country": "Anguilla",
    },
    {
      "id": 8,
      "code": "AQ",
      "country": "Antarctica",
    },
    {
      "id": 9,
      "code": "AG",
      "country": "Antigua And Barbuda",
    },
    {
      "id": 10,
      "code": "AR",
      "country": "Argentina",
    },
    {
      "id": 11,
      "code": "AM",
      "country": "Armenia",
    },
    {
      "id": 12,
      "code": "AW",
      "country": "Aruba",
    },
    {
      "id": 13,
      "code": "AU",
      "country": "Australia",
    },
    {
      "id": 14,
      "code": "AT",
      "country": "Austria",
    },
    {
      "id": 15,
      "code": "AZ",
      "country": "Azerbaijan",
    },
    {
      "id": 16,
      "code": "BS",
      "country": "Bahamas",
    },
    {
      "id": 17,
      "code": "BH",
      "country": "Bahrain",
    },
    {
      "id": 18,
      "code": "BD",
      "country": "Bangladesh",
    },
    {
      "id": 19,
      "code": "BB",
      "country": "Barbados",
    },
    {
      "id": 20,
      "code": "BY",
      "country": "Belarus",
    },
    {
      "id": 21,
      "code": "BE",
      "country": "Belgium",
    },
    {
      "id": 22,
      "code": "BZ",
      "country": "Belize",
    },
    {
      "id": 23,
      "code": "BJ",
      "country": "Benin",
    },
    {
      "id": 24,
      "code": "BM",
      "country": "Bermuda",
    },
    {
      "id": 25,
      "code": "BT",
      "country": "Bhutan",
    },
    {
      "id": 26,
      "code": "BO",
      "country": "Bolivia, Plurinational State Of",
    },
    {
      "id": 27,
      "code": "BQ",
      "country": "Bonaire, Sint Eustatius And Saba",
    },
    {
      "id": 28,
      "code": "BA",
      "country": "Bosnia And Herzegowina",
    },
    {
      "id": 29,
      "code": "BW",
      "country": "Botswana",
    },
    {
      "id": 30,
      "code": "BV",
      "country": "Bouvet Island",
    },
    {
      "id": 31,
      "code": "BR",
      "country": "Brazil",
    },
    {
      "id": 32,
      "code": "IO",
      "country": "British Indian Ocean Territory",
    },
    {
      "id": 33,
      "code": "BN",
      "country": "Brunei Darussalam",
    },
    {
      "id": 34,
      "code": "BG",
      "country": "Bulgaria",
    },
    {
      "id": 35,
      "code": "BF",
      "country": "Burkina Faso",
    },
    {
      "id": 36,
      "code": "BI",
      "country": "Burundi",
    },
    {
      "id": 37,
      "code": "KH",
      "country": "Cambodia",
    },
    {
      "id": 38,
      "code": "CM",
      "country": "Cameroon",
    },
    {
      "id": 39,
      "code": "CA",
      "country": "Canada",
    },
    {
      "id": 40,
      "code": "CV",
      "country": "Cape Verde",
    },
    {
      "id": 41,
      "code": "KY",
      "country": "Cayman Islands",
    },
    {
      "id": 42,
      "code": "CF",
      "country": "Central African Republic",
    },
    {
      "id": 43,
      "code": "TD",
      "country": "Chad",
    },
    {
      "id": 44,
      "code": "CL",
      "country": "Chile",
    },
    {
      "id": 45,
      "code": "CN",
      "country": "China",
    },
    {
      "id": 46,
      "code": "CX",
      "country": "Christmas Island",
    },
    {
      "id": 47,
      "code": "CC",
      "country": "Cocos (Keeling) Islands",
    },
    {
      "id": 48,
      "code": "CO",
      "country": "Colombia",
    },
    {
      "id": 49,
      "code": "KM",
      "country": "Comoros",
    },
    {
      "id": 50,
      "code": "CG",
      "country": "Congo",
    },
    {
      "id": 51,
      "code": "CD",
      "country": "Congo, The Democratic Republic Of The",
    },
    {
      "id": 52,
      "code": "CK",
      "country": "Cook Islands",
    },
    {
      "id": 53,
      "code": "CR",
      "country": "Costa Rica",
    },
    {
      "id": 54,
      "code": "CI",
      "country": "Cote D'Ivoire",
    },
    {
      "id": 55,
      "code": "HR",
      "country": "Croatia (Local Name: Hrvatska)",
    },
    {
      "id": 56,
      "code": "CU",
      "country": "Cuba",
    },
    {
      "id": 57,
      "code": "CW",
      "country": "Curaçao",
    },
    {
      "id": 58,
      "code": "CY",
      "country": "Cyprus",
    },
    {
      "id": 59,
      "code": "CZ",
      "country": "Czechia",
    },
    {
      "id": 60,
      "code": "DK",
      "country": "Denmark",
    },
    {
      "id": 61,
      "code": "DJ",
      "country": "Djibouti",
    },
    {
      "id": 62,
      "code": "DM",
      "country": "Dominica",
    },
    {
      "id": 63,
      "code": "DO",
      "country": "Dominican Republic",
    },
    {
      "id": 64,
      "code": "EC",
      "country": "Ecuador",
    },
    {
      "id": 65,
      "code": "EG",
      "country": "Egypt",
    },
    {
      "id": 66,
      "code": "SV",
      "country": "El Salvador",
    },
    {
      "id": 67,
      "code": "GQ",
      "country": "Equatorial Guinea",
    },
    {
      "id": 68,
      "code": "ER",
      "country": "Eritrea",
    },
    {
      "id": 69,
      "code": "EE",
      "country": "Estonia",
    },
    {
      "id": 70,
      "code": "SZ",
      "country": "Eswatini",
    },
    {
      "id": 71,
      "code": "ET",
      "country": "Ethiopia",
    },
    {
      "id": 72,
      "code": "FK",
      "country": "Falkland Islands (Malvinas)",
    },
    {
      "id": 73,
      "code": "FO",
      "country": "Faroe Islands",
    },
    {
      "id": 74,
      "code": "FJ",
      "country": "Fiji",
    },
    {
      "id": 75,
      "code": "FI",
      "country": "Finland",
    },
    {
      "id": 76,
      "code": "FR",
      "country": "France",
    },
    {
      "id": 77,
      "code": "GF",
      "country": "French Guiana",
    },
    {
      "id": 78,
      "code": "PF",
      "country": "French Polynesia",
    },
    {
      "id": 79,
      "code": "TF",
      "country": "French Southern Territories",
    },
    {
      "id": 80,
      "code": "GA",
      "country": "Gabon",
    },
    {
      "id": 81,
      "code": "GM",
      "country": "Gambia",
    },
    {
      "id": 82,
      "code": "GE",
      "country": "Georgia",
    },
    {
      "id": 83,
      "code": "DE",
      "country": "Germany",
    },
    {
      "id": 84,
      "code": "GH",
      "country": "Ghana",
    },
    {
      "id": 85,
      "code": "GI",
      "country": "Gibraltar",
    },
    {
      "id": 86,
      "code": "GR",
      "country": "Greece",
    },
    {
      "id": 87,
      "code": "GL",
      "country": "Greenland",
    },
    {
      "id": 88,
      "code": "GD",
      "country": "Grenada",
    },
    {
      "id": 89,
      "code": "GP",
      "country": "Guadeloupe",
    },
    {
      "id": 90,
      "code": "GU",
      "country": "Guam",
    },
    {
      "id": 91,
      "code": "GT",
      "country": "Guatemala",
    },
    {
      "id": 92,
      "code": "GG",
      "country": "Guernsey",
    },
    {
      "id": 93,
      "code": "GN",
      "country": "Guinea",
    },
    {
      "id": 94,
      "code": "GW",
      "country": "Guinea-Bissau",
    },
    {
      "id": 95,
      "code": "GY",
      "country": "Guyana",
    },
    {
      "id": 96,
      "code": "HT",
      "country": "Haiti",
    },
    {
      "id": 97,
      "code": "HM",
      "country": "Heard And Mc Donald Islands",
    },
    {
      "id": 98,
      "code": "VA",
      "country": "Holy See",
    },
    {
      "id": 99,
      "code": "HN",
      "country": "Honduras",
    },
    {
      "id": 100,
      "code": "HK",
      "country": "Hong Kong",
    },
    {
      "id": 101,
      "code": "HU",
      "country": "Hungary",
    },
    {
      "id": 102,
      "code": "IS",
      "country": "Iceland",
    },
    {
      "id": 103,
      "code": "IN",
      "country": "India",
    },
    {
      "id": 104,
      "code": "ID",
      "country": "Indonesia",
    },
    {
      "id": 105,
      "code": "IR",
      "country": "Iran (Islamic Republic Of)",
    },
    {
      "id": 106,
      "code": "IQ",
      "country": "Iraq",
    },
    {
      "id": 107,
      "code": "IE",
      "country": "Ireland",
    },
    {
      "id": 108,
      "code": "IM",
      "country": "Isle Of Man",
    },
    {
      "id": 109,
      "code": "IL",
      "country": "Israel",
    },
    {
      "id": 110,
      "code": "IT",
      "country": "Italy",
    },
    {
      "id": 111,
      "code": "JM",
      "country": "Jamaica",
    },
    {
      "id": 112,
      "code": "JP",
      "country": "Japan",
    },
    {
      "id": 113,
      "code": "JE",
      "country": "Jersey",
    },
    {
      "id": 114,
      "code": "JO",
      "country": "Jordan",
    },
    {
      "id": 115,
      "code": "KZ",
      "country": "Kazakhstan",
    },
    {
      "id": 116,
      "code": "KE",
      "country": "Kenya",
    },
    {
      "id": 117,
      "code": "KI",
      "country": "Kiribati",
    },
    {
      "id": 118,
      "code": "KP",
      "country": "Korea, Democratic People'S Republic Of",
    },
    {
      "id": 119,
      "code": "KR",
      "country": "Korea, Republic Of",
    },
    {
      "id": 120,
      "code": "KW",
      "country": "Kuwait",
    },
    {
      "id": 121,
      "code": "KG",
      "country": "Kyrgyzstan",
    },
    {
      "id": 122,
      "code": "LA",
      "country": "Lao People'S Democratic Republic",
    },
    {
      "id": 123,
      "code": "LV",
      "country": "Latvia",
    },
    {
      "id": 124,
      "code": "LB",
      "country": "Lebanon",
    },
    {
      "id": 125,
      "code": "LS",
      "country": "Lesotho",
    },
    {
      "id": 126,
      "code": "LR",
      "country": "Liberia",
    },
    {
      "id": 127,
      "code": "LY",
      "country": "Libya",
    },
    {
      "id": 128,
      "code": "LI",
      "country": "Liechtenstein",
    },
    {
      "id": 129,
      "code": "LT",
      "country": "Lithuania",
    },
    {
      "id": 130,
      "code": "LU",
      "country": "Luxembourg",
    },
    {
      "id": 131,
      "code": "MO",
      "country": "Macao",
    },
    {
      "id": 132,
      "code": "MG",
      "country": "Madagascar",
    },
    {
      "id": 133,
      "code": "MW",
      "country": "Malawi",
    },
    {
      "id": 134,
      "code": "MY",
      "country": "Malaysia",
    },
    {
      "id": 135,
      "code": "MV",
      "country": "Maldives",
    },
    {
      "id": 136,
      "code": "ML",
      "country": "Mali",
    },
    {
      "id": 137,
      "code": "MT",
      "country": "Malta",
    },
    {
      "id": 138,
      "code": "MH",
      "country": "Marshall Islands",
    },
    {
      "id": 139,
      "code": "MQ",
      "country": "Martinique",
    },
    {
      "id": 140,
      "code": "MR",
      "country": "Mauritania",
    },
    {
      "id": 141,
      "code": "MU",
      "country": "Mauritius",
    },
    {
      "id": 142,
      "code": "YT",
      "country": "Mayotte",
    },
    {
      "id": 143,
      "code": "MX",
      "country": "Mexico",
    },
    {
      "id": 144,
      "code": "FM",
      "country": "Micronesia, Federated States Of",
    },
    {
      "id": 145,
      "code": "MD",
      "country": "Moldova, Republic Of",
    },
    {
      "id": 146,
      "code": "MC",
      "country": "Monaco",
    },
    {
      "id": 147,
      "code": "MN",
      "country": "Mongolia",
    },
    {
      "id": 148,
      "code": "ME",
      "country": "Montenegro",
    },
    {
      "id": 149,
      "code": "MS",
      "country": "Montserrat",
    },
    {
      "id": 150,
      "code": "MA",
      "country": "Morocco",
    },
    {
      "id": 151,
      "code": "MZ",
      "country": "Mozambique",
    },
    {
      "id": 152,
      "code": "MM",
      "country": "Myanmar",
    },
    {
      "id": 153,
      "code": "NA",
      "country": "Namibia",
    },
    {
      "id": 154,
      "code": "NR",
      "country": "Nauru",
    },
    {
      "id": 155,
      "code": "NP",
      "country": "Nepal",
    },
    {
      "id": 156,
      "code": "NL",
      "country": "Netherlands",
    },
    {
      "id": 157,
      "code": "NC",
      "country": "New Caledonia",
    },
    {
      "id": 158,
      "code": "NZ",
      "country": "New Zealand",
    },
    {
      "id": 159,
      "code": "NI",
      "country": "Nicaragua",
    },
    {
      "id": 160,
      "code": "NE",
      "country": "Niger",
    },
    {
      "id": 161,
      "code": "NG",
      "country": "Nigeria",
    },
    {
      "id": 162,
      "code": "NU",
      "country": "Niue",
    },
    {
      "id": 163,
      "code": "NF",
      "country": "Norfolk Island",
    },
    {
      "id": 164,
      "code": "MK",
      "country": "North Macedonia, Republic Of",
    },
    {
      "id": 165,
      "code": "MP",
      "country": "Northern Mariana Islands",
    },
    {
      "id": 166,
      "code": "NO",
      "country": "Norway",
    },
    {
      "id": 167,
      "code": "OM",
      "country": "Oman",
    },
    {
      "id": 168,
      "code": "PK",
      "country": "Pakistan",
    },
    {
      "id": 169,
      "code": "PW",
      "country": "Palau",
    },
    {
      "id": 170,
      "code": "PS",
      "country": "Palestine, State Of",
    },
    {
      "id": 171,
      "code": "PA",
      "country": "Panama",
    },
    {
      "id": 172,
      "code": "PG",
      "country": "Papua New Guinea",
    },
    {
      "id": 173,
      "code": "PY",
      "country": "Paraguay",
    },
    {
      "id": 174,
      "code": "PE",
      "country": "Peru",
    },
    {
      "id": 175,
      "code": "PH",
      "country": "Philippines",
    },
    {
      "id": 176,
      "code": "PN",
      "country": "Pitcairn",
    },
    {
      "id": 177,
      "code": "PL",
      "country": "Poland",
    },
    {
      "id": 178,
      "code": "PT",
      "country": "Portugal",
    },
    {
      "id": 179,
      "code": "PR",
      "country": "Puerto Rico",
    },
    {
      "id": 180,
      "code": "QA",
      "country": "Qatar",
    },
    {
      "id": 181,
      "code": "RE",
      "country": "Reunion",
    },
    {
      "id": 182,
      "code": "RO",
      "country": "Romania",
    },
    {
      "id": 183,
      "code": "RU",
      "country": "Russian Federation",
    },
    {
      "id": 184,
      "code": "RW",
      "country": "Rwanda",
    },
    {
      "id": 185,
      "code": "BL",
      "country": "Saint Barthélemy",
    },
    {
      "id": 186,
      "code": "SH",
      "country": "Saint Helena, Ascension And Tristan Da Cunha",
    },
    {
      "id": 187,
      "code": "KN",
      "country": "Saint Kitts And Nevis",
    },
    {
      "id": 188,
      "code": "LC",
      "country": "Saint Lucia",
    },
    {
      "id": 189,
      "code": "MF",
      "country": "Saint Martin (French Part)",
    },
    {
      "id": 190,
      "code": "PM",
      "country": "Saint Pierre And Miquelon",
    },
    {
      "id": 191,
      "code": "VC",
      "country": "Saint Vincent And The Grenadines",
    },
    {
      "id": 192,
      "code": "WS",
      "country": "Samoa",
    },
    {
      "id": 193,
      "code": "SM",
      "country": "San Marino",
    },
    {
      "id": 194,
      "code": "ST",
      "country": "Sao Tome And Principe",
    },
    {
      "id": 195,
      "code": "SA",
      "country": "Saudi Arabia",
    },
    {
      "id": 196,
      "code": "SN",
      "country": "Senegal",
    },
    {
      "id": 197,
      "code": "RS",
      "country": "Serbia",
    },
    {
      "id": 198,
      "code": "SC",
      "country": "Seychelles",
    },
    {
      "id": 199,
      "code": "SL",
      "country": "Sierra Leone",
    },
    {
      "id": 200,
      "code": "SG",
      "country": "Singapore",
    },
    {
      "id": 201,
      "code": "SX",
      "country": "Sint Maarten (Dutch Part)",
    },
    {
      "id": 202,
      "code": "SK",
      "country": "Slovakia",
    },
    {
      "id": 203,
      "code": "SI",
      "country": "Slovenia",
    },
    {
      "id": 204,
      "code": "SB",
      "country": "Solomon Islands",
    },
    {
      "id": 205,
      "code": "SO",
      "country": "Somalia",
    },
    {
      "id": 206,
      "code": "ZA",
      "country": "South Africa",
    },
    {
      "id": 207,
      "code": "GS",
      "country": "South Georgia And The South Sandwich Islands",
    },
    {
      "id": 208,
      "code": "SS",
      "country": "South Sudan",
    },
    {
      "id": 209,
      "code": "ES",
      "country": "Spain",
    },
    {
      "id": 210,
      "code": "LK",
      "country": "Sri Lanka",
    },
    {
      "id": 211,
      "code": "SD",
      "country": "Sudan",
    },
    {
      "id": 212,
      "code": "SR",
      "country": "Suriname",
    },
    {
      "id": 213,
      "code": "SJ",
      "country": "Svalbard And Jan Mayen Islands",
    },
    {
      "id": 214,
      "code": "SE",
      "country": "Sweden",
    },
    {
      "id": 215,
      "code": "CH",
      "country": "Switzerland",
    },
    {
      "id": 216,
      "code": "SY",
      "country": "Syrian Arab Republic",
    },
    {
      "id": 217,
      "code": "TW",
      "country": "Taiwan, Province Of China",
    },
    {
      "id": 218,
      "code": "TJ",
      "country": "Tajikistan",
    },
    {
      "id": 219,
      "code": "TZ",
      "country": "Tanzania, United Republic Of",
    },
    {
      "id": 220,
      "code": "TH",
      "country": "Thailand",
    },
    {
      "id": 221,
      "code": "TL",
      "country": "Timor-Leste",
    },
    {
      "id": 222,
      "code": "TG",
      "country": "Togo",
    },
    {
      "id": 223,
      "code": "TK",
      "country": "Tokelau",
    },
    {
      "id": 224,
      "code": "TO",
      "country": "Tonga",
    },
    {
      "id": 225,
      "code": "TT",
      "country": "Trinidad And Tobago",
    },
    {
      "id": 226,
      "code": "TN",
      "country": "Tunisia",
    },
    {
      "id": 227,
      "code": "TR",
      "country": "Turkey",
    },
    {
      "id": 228,
      "code": "TM",
      "country": "Turkmenistan",
    },
    {
      "id": 229,
      "code": "TC",
      "country": "Turks And Caicos Islands",
    },
    {
      "id": 230,
      "code": "TV",
      "country": "Uganda",
    },
    {
      "id": 231,
      "code": "UA",
      "country": "Ukraine",
    },
    {
      "id": 232,
      "code": "AE",
      "country": "United Arab Emirates",
    },
    {
      "id": 233,
      "code": "GB",
      "country": "United Kingdom Of Great Britain And Northern Ireland*",
    },
    {
      "id": 234,
      "code": "UM",
      "country": "United States Minor Outlying Islands",
    },
    {
      "id": 235,
      "code": "UY",
      "country": "Uruguay",
    },
    {
      "id": 236,
      "code": "UZ",
      "country": "Uzbekistan",
    },
    {
      "id": 237,
      "code": "VU",
      "country": "Vanuatu",
    },
    {
      "id": 238,
      "code": "VE",
      "country": "Venezuela, Bolivarian Republic Of",
    },
    {
      "id": 239,
      "code": "VN",
      "country": "Viet Nam",
    },
    {
      "id": 240,
      "code": "VG",
      "country": "Virgin Islands (British)",
    },
    {
      "id": 241,
      "code": "VI",
      "country": "Virgin Islands (U.S.)",
    },
    {
      "id": 242,
      "code": "WF",
      "country": "Wallis And Futuna Islands",
    },
    {
      "id": 243,
      "code": "EH",
      "country": "Western Sahara",
    },
    {
      "id": 244,
      "code": "YE",
      "country": "Yemen",
    },
    {
      "id": 245,
      "code": "ZM",
      "country": "Zambia",
    },
    {
      "id": 246,
      "code": "ZW",
      "country": "Zimbabwe",
    },
    {
      "id": 247,
      "code": "AX",
      "country": "Åland Islands",
    },
    {
      "id": 248,
      "code": "TV",
      "country": "Tuvalu",
    },
    {
      "id": 249,
      "code": "US",
      "country": "United States Of America",
    },
  ];
}