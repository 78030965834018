import { doPost } from "./BaseAPI";

export function verifyEml(account, eml) {
  return doPost("/verify/file", {
    account: account,
    eml: eml
  });
}

export function sendEmail(email_template) {
  return doPost("/physher/send", {
    fromEmail: email_template.from_email,
    fromName: email_template.from_name,
    html: email_template.body_html,
    subject: email_template.subject,
    to: email_template.to_emails
  }, process.env.REACT_APP_API6_URL);
}
