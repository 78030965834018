import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import PageTitle from '../../Layout/Components/PageTitle';

class SenderIdentification extends React.Component {

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("threat_hunting.sender_identification.title")}
          icon="pe-7s-target icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
            
        </CSSTransitionGroup>
      </Fragment >
    )
  }
}

export default withTranslation()(SenderIdentification);