import { doDelete, doGet, doPost } from "./BaseAPI";

const blogUrlAPI = process.env.REACT_APP_BLOG_URL;

export function getBlogPosts(lang) {
  return doGet(`/blog/language/${lang}`, blogUrlAPI);
}

export function saveBlogPost(blogPost) {
  return doPost("/blog/", blogPost, blogUrlAPI);
}

export function deleteBlogPost(id) {
  return doDelete(`/blog/${id}`, blogUrlAPI)
}
