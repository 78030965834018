import React from "react";
import Accounts from "./Accounts";
import AccountProvider from "./AccountContext"
import AccountBlacklist from "./AccountBlacklist";

export {
  AccountBlacklist
};

function withContext(Component) {
  class WithContext extends React.Component {
    render() {
      return (
        <AccountProvider>
          <Component />
        </AccountProvider>
      );
    }
  }
  return WithContext;
}

export default withContext(Accounts);
