import React, { Fragment } from "react";
import {
  Row, Col, Card,
  Label, Button, Input
} from 'reactstrap';
import Tabs from 'react-responsive-tabs';
import BootstrapTable from "react-bootstrap-table-next";
import { withTranslation } from 'react-i18next';
import { toast } from "react-toastify";

import { createBlacklist } from "API/BlacklistAPI";
import { AuthContext } from "context/AuthContext";

class MessageHeader extends React.Component {

  static contextType = AuthContext;

  getMapAsArray(map) {
    return Array.from(map.entries())
      .map(entry => {
        let val = entry[1];
        if (Array.isArray(val)) {
          val = val.join("; ");
        } else if (val.text) {
          val = val.text;
        } else if (val.value) {
          if (val.params) {
            const params = Object.entries(val.params);
            val = val.value + "; " + params.map(param => {
              return `${param[0]}=${param[1]}`
            }).join("; ");
          } else {
            val = val.value;
          }
        }
        return { headerKey: entry[0], headerValue: val.toString() }
      });
  }

  getRawJson(value) {
    const jsonObj = JSON.parse(JSON.stringify(value))
    delete jsonObj['eml'];
    delete jsonObj['rawEml'];
    return JSON.stringify(jsonObj, null, 4);
  }

  blacklistUrl(url) {
    createBlacklist(url).then(result => {
      toast["success"](this.props.t('incidents.messages.success_create_blacklist'));
    }).catch(error => {
      console.error(error);
      toast["error"](this.props.t('errors.creating', { element: 'Blacklist', message: error.message }));
    });
  }

  render() {
    const { t, i18n } = this.props;

    const { loaded, message, emailHeaders, incident } = this.props;
         
    const headerTabs = () => {
      const to = emailHeaders ? emailHeaders.get('to') : "";
      const cc = emailHeaders ? emailHeaders.get('cc') : "";
      const tabs = [
      {
        title: t("incidents.message_header"),
        getContent: () =>
          <Fragment>
            <Row form>
              <Col md={12}>
                <Label className="mr-sm-2">{t("incidents.account")}:</Label>
                <Label>{message.account}</Label>
              </Col>
              <Col md={6}>
                <Label className="mr-sm-2">{t("incidents.sender")}:</Label>
                <Label>{message.originEmailAddress}</Label>&nbsp;&nbsp;&nbsp;
                </Col>
              <Col md={6}>
                <Button className="mb-2 mr-2" color="danger" disabled={!loaded}
                  onClick={() => this.blacklistUrl(message.originEmailAddress)} >
                  {t("incidents.actions.blacklist_sender")}
                </Button>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("incidents.sender_ip")}:</Label>
                <Label>{message.originSourceIP}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("incidents.to")}:</Label>
                <Label>{to}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("incidents.cc")}:</Label>
                <Label>{cc}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("incidents.recipient")}:</Label>
                <Label>{message.receiver}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("incidents.sent_date")}:</Label>
                <Label>{message.originDate}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("incidents.subject")}:</Label>
                <Label>{message.subject}</Label>
              </Col>
            </Row>
          </Fragment>,
        key: 'header_tab_0',
      }
    ];
    if (emailHeaders) {
      const rawHeaders = this.getMapAsArray(emailHeaders);
      tabs.push(
        {
          title: t("incidents.message_header_raw"),
          getContent: () =>
            <BootstrapTable
              bootstrap4
              keyField="headerKey"
              data={rawHeaders}
              bordered={false}
              columns={[
                {
                  dataField: 'headerKey',
                  text: t("incidents.header_key")
                },
                {
                  dataField: 'headerValue',
                  text: t("incidents.header_value")
                }
              ]} />,
          key: 'header_tab_1',
        }
      );
    }
    const user = this.context.user;
    if (incident && user && user.roles.includes('EmailVeritas Admin')) {
      const rawJson = this.getRawJson(incident);
      tabs.push(
        {
          title: t("incidents.message_json"),
          getContent: () =>
            <Fragment>
              <Row form>
                <Col md={12}>
                  <Input type="textarea" id="json" readOnly={true} value={rawJson}
                    rows={rawJson.split(/\r\n|\r|\n/).length} />
                </Col>
              </Row>
            </Fragment>,
          key: 'header_tab_2',
        }
      )
    }
    return tabs;
  }

    return (
      <Card className="main-card mb-3 card-tabs">
        <Tabs tabsWrapperClass="card-title" transform={false}
          showInkBar={true} items={headerTabs()} />
      </Card>
    )
  }
}

export default withTranslation()(MessageHeader);
