import { doGet, doPost } from "./BaseAPI";

export function getContactMessages() {
  return doGet(`/contactmessage/all`);
}

export function createContactMessage(message) {
  return doPost("/contactmessage/create", message);
}

export function updateContactMessage(message) {
  return doPost("/contactmessage/update", message);
}
