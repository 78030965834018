import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, CardTitle, Button, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { getCampaign, getRegistrantCampaign } from '../../../API/ConsoleServicesAPI'

import PageTitle from '../../../Layout/Components/PageTitle';
import RegistrantGraph from "./RelevantGraph";

class RelevantAnalysis extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      loading: false,
      campaignId: '',
      campaign: {},
      registrant: []
    }

    this.getCampaign = this.getCampaign.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  getCampaign() {
    if (!this.state.campaignId) {
      toast["warning"](this.props.t('threat_hunting.campaign_analysis.empty_id_error'));
      return;
    }
    this.setState({
      loading: true,
      campaign: {},
      registrant: []
    });
    Promise.all([getCampaign(this.state.campaignId), getRegistrantCampaign(this.state.campaignId)])
      .then(values => {
        if (values[0].errorMessage) {
          console.warn("Error message received", values[0]);
          toast["warning"](this.props.t('threat_hunting.relevant_analysis.find_campaign_error', { message: values[0].errorMessage }));
          return;
        }
        if (!values[1].data.length) {
          toast["info"](this.props.t('threat_hunting.relevant_analysis.no_related_registrants'));
        }
        this.setState({
          campaign: values[0].data,
          registrant: values[1].data,
          loading: false
        })
      }).catch(error => {
        console.error("Error loading campaign", error);
        toast["error"](this.props.t('errors.loading', { element: 'Campaign', message: error.message }));
        this.setState({
          loading: false
        })
      })
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      campaignId: value
    });
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("threat_hunting.relevant_analysis.title")}
          icon="pe-7s-target icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="6" sm="12">
              <Card className="main-card mb-3">
                <CardBody>
                  {t("threat_hunting.relevant_analysis.info_text")}
                </CardBody>
              </Card>
            </Col>
            <Col md="6" sm="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>{t("threat_hunting.relevant_analysis.action_text")}</CardTitle>
                  <Input type="text" name="campnum" id="campnum"
                    value={this.state.campaignId} onChange={this.handleChange} /><br />
                  <Button color="primary" className="mb-2 mr-2" onClick={() => this.getCampaign()}>
                    {t("threat_hunting.relevant_analysis.action.find_campaign")}
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="12" sm="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <RegistrantGraph campaignId={this.state.campaignId} campaign={this.state.campaign} registrant={this.state.registrant} loading={this.state.loading} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withTranslation()(RelevantAnalysis);