import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
  Row, Col, Card, CardBody, Label, Input
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

import PageTitle from '../../Layout/Components/PageTitle';

import PaymentsTable from "./PaymentsTable";
import { PaymentsContext } from "./PaymentsContext";

class Payments extends React.Component {

  static contextType = PaymentsContext;

  constructor(props) {
    super(props);

    this.state = {
      status: 'active'
    };

    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  componentDidMount() {
    if (this.context.isAdmin) {
      this.context.loadPaymentsAdminReport(this.state.status);
    } else {
      this.context.loadPayments();
    }
  }

  handleChangeStatus(event) {
    const value = event.target.value;
    this.setState({
      'status': value
    });
    this.context.loadPaymentsAdminReport(value);
  }

  render() {
    const { t, i18n } = this.props;

    const actions = this.context.isAdmin &&
      <Fragment>
        <Label for="status" sm={2}>{t('payments.status')}</Label>
        <Col sm={2}>
          <Input type="select" name="status" id="status"
            value={this.state.status} onChange={this.handleChangeStatus}>
            <option>active</option>
            <option>expired</option>
          </Input>
        </Col>
      </Fragment>;

    return (
      <Fragment>
        <PageTitle
          heading={t("payments.payment_history")}
          icon="pe-7s-cash icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  {actions}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <PaymentsTable showDetail={false}
                showStatus={!this.context.isAdmin} />
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withTranslation()(Payments);