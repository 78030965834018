import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import ResizeDetector from 'react-resize-detector';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import ProtectedRoute from './ProtectedRoute';
import AuthApp from './AuthApp';
import ServicesApp from './ServicesApp';
import OrganizationApp from './OrganizationApp';
import PartnerApp from './PartnerApp';
import UserApp from './UserApp';
import { AuthContext } from 'context/AuthContext';
import Loading from 'Layout/Components/Loading';

class App extends React.Component {

  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false
    };
  }

  componentDidMount() {
    const pathName = this.props.location.pathname;
    if (pathName.startsWith('/auth/confirm-email')) {
      this.context.confirmEmail();
    } else if (pathName.startsWith('/auth/reset-password')) {
      this.context.resetPassword();
    }
  }

  navigateToUserHome() {
    const {location, history } = this.props;
    const pathName = location.pathname;
    if (pathName.startsWith('/admin') || pathName.startsWith('/organization')
      || pathName.startsWith('/partner') || pathName.startsWith('/user')) {
      return; // its already inside the app
    }
    const user = this.context.user;
    if (user.roles.includes('EmailVeritas Admin')) {
      history.push('/admin/services');
    } else if (user.roles.includes('Organization Admin')) {
      history.push('/organization');
    } else if (user.roles.includes('Partner Admin')) {
      history.push('/partner');
    } else if (user.roles.includes('User')) {
      history.push('/user/dashboard');
    } else {
      toast["error"](this.props.t('login.error'));
      this.context.doLogout();
    }
  }

  navigateToAuth() {
    const pathName = this.props.location.pathname;
    if (pathName.startsWith('/auth')) {
      return; // its already inside the app
    }
    let history = this.props.history;
    history.push(`/auth/${this.context.emailAuthRoute()}`);
  }

  render() {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props;

    if (this.context.authenticated()) {
      return (
        <ResizeDetector handleWidth render={({ width }) => (
          <Fragment>
            <div className={cx(
              "app-container app-theme-" + colorScheme,
              { 'fixed-header': enableFixedHeader },
              { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
              { 'fixed-footer': enableFixedFooter },
              { 'closed-sidebar': enableClosedSidebar || width < 1250 },
              { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
              { 'sidebar-mobile-open': enableMobileMenu },
              { 'body-tabs-shadow-btn': enablePageTabsAlt },
            )}>
              <Fragment>
                <Switch>
                  <ProtectedRoute path={'/admin'} role="EmailVeritas Admin" component={ServicesApp} />
                  <ProtectedRoute path={'/organization'} role="Organization Admin" component={OrganizationApp} />
                  <ProtectedRoute path={'/partner'} role="Partner Admin" component={PartnerApp} />
                  <ProtectedRoute path={'/user'} role="User" component={UserApp} />
                </Switch>
                {this.navigateToUserHome()}
              </Fragment>
            </div>
            <ToastContainer />
          </Fragment>
        )}
        />
      )
    } else if (this.context.inProgress()) {
      return (<Loading />)
    } else {
      return (
        <Fragment>
          <Switch>
            <Route path={'/auth'}>
              <AuthApp />
            </Route>
          </Switch>
          {this.navigateToAuth()}
        </Fragment>
      )
    }
  }
}

const mapStateToProp = state => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt
});

export default connect(mapStateToProp)(withRouter(withTranslation()(App)));
