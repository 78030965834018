import { doGet, doPost, doDelete, getDomain } from "./BaseAPI";

export function getGateways() {
    return doGet(`/admin/gatewayserver/domain`);
}

export function createGateway(gatewayURL) {
    return doPost("/admin/gatewayserver/create", {
        domain: getDomain(),
        url: gatewayURL
    });
}

export function deleteGateway(id) {
    return doDelete(`/admin/gatewayserver/delete/${id}`);
}
