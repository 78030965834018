import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input, FormCheck, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';
import moment from 'moment';
import { getEmailTemplateList, getlandingpages, getSMTPConfigList, getGroupList, createPhishingCampaign } from "../../../API/PhishingAPI";
import PhishingCustomSelect from "./PhishingCustomSelect";

class CampaignModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.open,
      isEdit: this.props.campaign ? true : false,
      isCopied: this.props.isCopied,
      campaign: {
        name: "",
        page: {},
        template: {},
        url: "",
        launch_date: moment().add(10, "minutes"),
        send_by_date: moment().add(70, "minutes"),
        smtp: {},
        groups: []
      },
      emailTemplateList: [],
      landingPageList: [],
      smtpConfigList: [],
      groupList: [],
      errors: {}
    }
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.loadEmailTemplate = this.loadEmailTemplate.bind(this);
    this.loadLandingPage = this.loadLandingPage.bind(this);
    this.loadSMTPConfig = this.loadSMTPConfig.bind(this);
    this.loadGroupList = this.loadGroupList.bind(this);
    this.handleChangeForPhishingSelect = this.handleChangeForPhishingSelect.bind(this);
    this.submit = this.submit.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.parseDate = this.parseDate.bind(this);
  }

  componentDidMount() {
    if (this.state.isEdit) {
      if (this.state.isCopied) {
        const { id, name, ...rest } = this.props.campaign;
        this.setState({
          campaign: {
            ...rest,
            name: "Copy of " + name,
            launch_date: moment().add(10, "minutes"),
            send_by_date: moment().add(70, "minutes"),
          }
        })
      } else {
        this.setState({
          campaign: this.props.campaign
        });
      }
    }
    this.loadEmailTemplate();
    this.loadLandingPage();
    this.loadSMTPConfig();
    this.loadGroupList();
  }

  handleChange(event) {
    const key = event.target.name;
    const value = event.target.type === 'checkbox'
      ? event.target.checked : event.target.value;
    this.setState(updater => ({
      campaign: {
        ...updater.campaign,
        [key]: value
      }
    }));
  }

  handleDateChange(selectedDate, key) {
    this.setState(updater => ({
      campaign: {
        ...updater.campaign,
        [key]: selectedDate
      }
    }));
    if (key === 'launch_date') {
      const sendDate = moment(selectedDate).add(1, "hours");
      this.handleDateChange(sendDate, 'send_by_date');
    }
  }

  parseDate(date) {
    if (date && (moment.isMoment(date) || moment.isDate(date))) {
      return new Date(date.valueOf()).toISOString();
    }
    return date; // not able to format
  }

  handleChangeForPhishingSelect(event) {
    const key = event.target.name;
    var value;
    if (event.target.multiple) {
      value = Array.from(event.target.selectedOptions, option => {
        var obj = {};
        obj["name"] = option.value;
        return obj;
      });
    } else {
      value = { name: event.target.value };
    }
    this.setState(updater => ({
      campaign: {
        ...updater.campaign,
        [key]: value
      }
    }));
  }

  loadEmailTemplate() {
    getEmailTemplateList()
      .then(res => {
        res.forEach((item, i) => {
          item.modified_date = (new Date(item.modified_date)).toLocaleString(undefined, { dateStyle: "full", timeStyle: "medium" })
        });
        this.setState({
          emailTemplateList: res
        });
      }).catch(error => {
        // TODO: Handling is pending when there will be some issue getting data.
      });
  }

  loadLandingPage() {
    getlandingpages()
      .then(res => {
        res.forEach((item, i) => {
          item.modified_date = (new Date(item.modified_date)).toLocaleString(undefined, { dateStyle: "full", timeStyle: "medium" })
        });
        this.setState({
          landingPageList: res
        });
      }).catch(error => {
        // TODO: Handling is pending when there will be some issue getting data.
      });
  }

  loadSMTPConfig() {
    getSMTPConfigList()
      .then(res => {
        res.forEach((item, i) => {
          item.modified_date = (new Date(item.modified_date)).toLocaleString(undefined, { dateStyle: "full", timeStyle: "medium" })
        });
        this.setState({
          smtpConfigList: res
        });
      }).catch(error => {
        // TODO: Handling is pending when there will be some issue getting data.
      });
  }

  loadGroupList() {
    getGroupList()
      .then(res => {
        console.log(res);
        res.forEach((item, i) => {
          item.modified_date = (new Date(item.modified_date)).toLocaleString(undefined, { dateStyle: "full", timeStyle: "medium" })
        });
        this.setState({
          groupList: res
        });
      }).catch(error => {
        // TODO: Handling is pending when there will be some issue getting data.
      });
  }

  handleValidation() {
    var fields = this.state.campaign;
    var errors = {};
    var formIsValid = true;
    //Reset the errors
    this.setState({ errors: null });

    //Name cannot be empty
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Cannot be empty";
    }

    if (!fields["url"]) {
      formIsValid = false;
      errors["url"] = "URL cannot be empty";
    }

    if (!fields["launch_date"]) {
      formIsValid = false;
      errors["launch_date"] = "Launch date cannot be empty";
    }

    if (!fields["smtp"] || Object.keys(fields["smtp"]).length === 0) {
      formIsValid = false;
      errors["smtp"] = "SMTP config can not be empty";
    }

    if (!fields["page"] || Object.keys(fields["page"]).length === 0) {
      formIsValid = false;
      errors["page"] = "Landing Page can not be empty";
    }

    if (!fields["template"] || Object.keys(fields["template"]).length === 0) {
      formIsValid = false;
      errors["template"] = "Email template can not be empty";
    }

    if (!fields["groups"] || !Array.isArray(fields["groups"]) || fields["groups"].length == 0) {
      formIsValid = false;
      errors["groups"] = "Select at least one group";
    }

    if (!fields["launch_date"].isValid()) {
      formIsValid = false;
      errors["launch_date"] = "Launch date is Invalid";
    }

    if (!fields["send_by_date"].isValid()) {
      formIsValid = false;
      errors["send_by_date"] = "Send by Date is Invalid";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      campaign: {
        name: "",
        page: {},
        template: {},
        url: "",
        launch_date: moment().add(10, "minutes"),
        send_by_date: moment().add(70, "minutes"),
        smtp: {},
        groups: []
      },
      errors: {}
    })
  }

  submit() {
    if (!this.handleValidation()) {
      return;
    }
    const campaign = { ... this.state.campaign };
    // format date only on launching
    campaign.launch_date = this.parseDate(campaign.launch_date);
    campaign.send_by_date = this.parseDate(campaign.send_by_date);
    console.log(campaign);
    createPhishingCampaign(campaign)
      .then(result => {
        this.toggle();
        this.props.onSubmit();
      }).catch(error => {
        const messageKey = this.state.isEdit ? 'errors.updating' : 'errors.creating';
        toast["error"](this.props.t(messageKey, { element: 'Campaign', message: error.message }));
      });
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
        onClick={() => this.toggle()}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('phishing_campaign.actions.create')}
      </Button>;

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
          <ModalHeader toggle={this.toggle}>{t('phishing_campaign.actions.create')}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Name:</Label>
              <Input type="text" name="name" id="name" value={this.state.campaign.name} onChange={this.handleChange} />
              <span style={{ color: "red" }}>{this.state.errors["name"]}</span>
            </FormGroup>
            <FormGroup>
              <Label>Email Template:</Label>
              <PhishingCustomSelect name="template" data={this.state.emailTemplateList}
                selected={this.state.campaign.template} onChange={this.handleChangeForPhishingSelect} />
              <span style={{ color: "red" }}>{this.state.errors["template"]}</span>
            </FormGroup>
            <FormGroup>
              <Label>Landing Page Template:</Label>
              <PhishingCustomSelect name="page" data={this.state.landingPageList}
                selected={this.state.campaign.page} onChange={this.handleChangeForPhishingSelect} />
              <span style={{ color: "red" }}>{this.state.errors["page"]}</span>
            </FormGroup>
            <FormGroup>
              <Label>Landing Page URL:</Label>
              <Input type="text" name="url" id="url" value={this.state.campaign.url} onChange={this.handleChange} />
              <span style={{ color: "red" }}>{this.state.errors["url"]}</span>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm={6}>
                  <Label for="launch_date">Launch Date:</Label>
                  <Datetime name="launch_date" value={this.state.campaign.launch_date} utc={true} onChange={(data) => this.handleDateChange(data, "launch_date")} />
                  <span style={{ color: "red" }}>{this.state.errors["launch_date"]}</span>
                </Col>
                {/*TODO: date is not able to copied while selecting copy button.*/}
                <Col sm={6}>
                  <Label>Send Emails By(Optional):</Label>
                  <Datetime name="send_by_date" value={this.state.campaign.send_by_date} utc={true} onChange={(data) => this.handleDateChange(data, "send_by_date")} />
                  <span style={{ color: "red" }}>{this.state.errors["send_by_date"]}</span>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Label>Sending Profile:</Label>
              <PhishingCustomSelect name="smtp" data={this.state.smtpConfigList}
                selected={this.state.campaign.smtp} onChange={this.handleChangeForPhishingSelect} />
              <span style={{ color: "red" }}>{this.state.errors["smtp"]}</span>
            </FormGroup>
            <FormGroup>
              <Label>Groups:</Label>
              <PhishingCustomSelect name="groups" data={this.state.groupList}
                onChange={this.handleChangeForPhishingSelect} multiple={true} />
              <span style={{ color: "red" }}>{this.state.errors["groups"]}</span>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('phishing_campaign.actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>{t('phishing_campaign.actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }
}

CampaignModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(CampaignModal)
