export default class LanguageHelper {

  // Map of languages: Map<code, iso_name>
  static languages;

  /**
   * Return the available languages as a Map<code, iso_name>.
   */
  static getLanguages() {
    if (!this.languages || this.languages.size === 0) {
      // Convert the array to a map in the first call
      this.languages = new Map(this.langArray.map(lang => [lang.code, lang.iso_name]));
    }
    return this.languages;
  }

  /**
   * Get a language by its code.
   */
  static getLanguage(code) {
    let lang = this.getLanguages().get(code);
    return lang || code;
  }

  // List of languages
  static langArray = [
    {
      "iso_name": "Abkhazian",
      "name": "аҧсуа бызшәа - аҧсшәа",
      "code": "ab"
    },
    {
      "iso_name": "Afar",
      "name": "Afaraf",
      "code": "aa"
    },
    {
      "iso_name": "Afrikaans",
      "name": "Afrikaans",
      "code": "af"
    },
    {
      "iso_name": "Akan",
      "name": "Akan",
      "code": "ak"
    },
    {
      "iso_name": "Albanian",
      "name": "Shqip",
      "code": "sq"
    },
    {
      "iso_name": "Amharic",
      "name": "አማርኛ",
      "code": "am"
    },
    {
      "iso_name": "Arabic",
      "name": "العربية",
      "code": "ar"
    },
    {
      "iso_name": "Aragonese",
      "name": "aragonés",
      "code": "an"
    },
    {
      "iso_name": "Armenian",
      "name": "Հայերեն",
      "code": "hy"
    },
    {
      "iso_name": "Assamese",
      "name": "অসমীয়া",
      "code": "as"
    },
    {
      "iso_name": "Avaric",
      "name": "авар мацӀ - магӀарул мацӀ",
      "code": "av"
    },
    {
      "iso_name": "Avestan",
      "name": "avesta",
      "code": "ae"
    },
    {
      "iso_name": "Aymara",
      "name": "aymar aru",
      "code": "ay"
    },
    {
      "iso_name": "Azerbaijani",
      "name": "azərbaycan dili",
      "code": "az"
    },
    {
      "iso_name": "Bambara",
      "name": "bamanankan",
      "code": "bm"
    },
    {
      "iso_name": "Bashkir",
      "name": "башҡорт теле",
      "code": "ba"
    },
    {
      "iso_name": "Basque",
      "name": "euskara - euskera",
      "code": "eu"
    },
    {
      "iso_name": "Belarusian",
      "name": "беларуская мова",
      "code": "be"
    },
    {
      "iso_name": "Bengali",
      "name": "বাংলা",
      "code": "bn"
    },
    {
      "iso_name": "Bihari languages",
      "name": "भोजपुरी",
      "code": "bh"
    },
    {
      "iso_name": "Bislama",
      "name": "Bislama",
      "code": "bi"
    },
    {
      "iso_name": "Bosnian",
      "name": "bosanski jezik",
      "code": "bs"
    },
    {
      "iso_name": "Breton",
      "name": "brezhoneg",
      "code": "br"
    },
    {
      "iso_name": "Bulgarian",
      "name": "български език",
      "code": "bg"
    },
    {
      "iso_name": "Burmese",
      "name": "ဗမာစာ",
      "code": "my"
    },
    {
      "iso_name": "Catalan - Valencian",
      "name": "català - valencià",
      "code": "ca"
    },
    {
      "iso_name": "Chamorro",
      "name": "Chamoru",
      "code": "ch"
    },
    {
      "iso_name": "Chechen",
      "name": "нохчийн мотт",
      "code": "ce"
    },
    {
      "iso_name": "Chichewa - Chewa - Nyanja",
      "name": "chiCheŵa - chinyanja",
      "code": "ny"
    },
    {
      "iso_name": "Chinese",
      "name": "中文 (Zhōngwén) - 汉语 - 漢語",
      "code": "zh"
    },
    {
      "iso_name": "Chuvash",
      "name": "чӑваш чӗлхи",
      "code": "cv"
    },
    {
      "iso_name": "Cornish",
      "name": "Kernewek",
      "code": "kw"
    },
    {
      "iso_name": "Corsican",
      "name": "corsu - lingua corsa",
      "code": "co"
    },
    {
      "iso_name": "Cree",
      "name": "ᓀᐦᐃᔭᐍᐏᐣ",
      "code": "cr"
    },
    {
      "iso_name": "Croatian",
      "name": "hrvatski jezik",
      "code": "hr"
    },
    {
      "iso_name": "Czech",
      "name": "čeština - český jazyk",
      "code": "cs"
    },
    {
      "iso_name": "Danish",
      "name": "dansk",
      "code": "da"
    },
    {
      "iso_name": "Divehi - Dhivehi - Maldivian",
      "name": "ދިވެހި",
      "code": "dv"
    },
    {
      "iso_name": "Dutch - Flemish",
      "name": "Nederlands - Vlaams",
      "code": "nl"
    },
    {
      "iso_name": "Dzongkha",
      "name": "རྫོང་ཁ",
      "code": "dz"
    },
    {
      "iso_name": "English",
      "name": "English",
      "code": "en"
    },
    {
      "iso_name": "Esperanto",
      "name": "Esperanto",
      "code": "eo"
    },
    {
      "iso_name": "Estonian",
      "name": "eesti - eesti keel",
      "code": "et"
    },
    {
      "iso_name": "Ewe",
      "name": "Eʋegbe",
      "code": "ee"
    },
    {
      "iso_name": "Faroese",
      "name": "føroyskt",
      "code": "fo"
    },
    {
      "iso_name": "Fijian",
      "name": "vosa Vakaviti",
      "code": "fj"
    },
    {
      "iso_name": "Finnish",
      "name": "suomi - suomen kieli",
      "code": "fi"
    },
    {
      "iso_name": "French",
      "name": "français - langue française",
      "code": "fr"
    },
    {
      "iso_name": "Fulah",
      "name": "Fulfulde - Pulaar - Pular",
      "code": "ff"
    },
    {
      "iso_name": "Galician",
      "name": "Galego",
      "code": "gl"
    },
    {
      "iso_name": "Georgian",
      "name": "ქართული",
      "code": "ka"
    },
    {
      "iso_name": "German",
      "name": "Deutsch",
      "code": "de"
    },
    {
      "iso_name": "Greek - Modern (1453–)",
      "name": "ελληνικά",
      "code": "el"
    },
    {
      "iso_name": "Guarani",
      "name": "Avañe'ẽ",
      "code": "gn"
    },
    {
      "iso_name": "Gujarati",
      "name": "ગુજરાતી",
      "code": "gu"
    },
    {
      "iso_name": "Haitian - Haitian Creole",
      "name": "Kreyòl ayisyen",
      "code": "ht"
    },
    {
      "iso_name": "Hausa",
      "name": "(Hausa) هَوُسَ",
      "code": "ha"
    },
    {
      "iso_name": "Hebrew",
      "name": "עברית",
      "code": "he"
    },
    {
      "iso_name": "Herero",
      "name": "Otjiherero",
      "code": "hz"
    },
    {
      "iso_name": "Hindi",
      "name": "हिन्दी - हिंदी",
      "code": "hi"
    },
    {
      "iso_name": "Hiri Motu",
      "name": "Hiri Motu",
      "code": "ho"
    },
    {
      "iso_name": "Hungarian",
      "name": "magyarul",
      "code": "hu"
    },
    {
      "iso_name": "Interlingua (International Auxiliary Language Association)",
      "name": "Interlingua",
      "code": "ia"
    },
    {
      "iso_name": "Indonesian",
      "name": "Bahasa Indonesia",
      "code": "id"
    },
    {
      "iso_name": "Interlingue - Occidental",
      "name": "(originally:) Occidental - (after WWII:) Interlingue",
      "code": "ie"
    },
    {
      "iso_name": "Irish",
      "name": "Gaeilge",
      "code": "ga"
    },
    {
      "iso_name": "Igbo",
      "name": "Asụsụ Igbo",
      "code": "ig"
    },
    {
      "iso_name": "Inupiaq",
      "name": "Iñupiaq - Iñupiatun",
      "code": "ik"
    },
    {
      "iso_name": "Ido",
      "name": "Ido",
      "code": "io"
    },
    {
      "iso_name": "Icelandic",
      "name": "Íslenska",
      "code": "is"
    },
    {
      "iso_name": "Italian",
      "name": "Italiano",
      "code": "it"
    },
    {
      "iso_name": "Inuktitut",
      "name": "ᐃᓄᒃᑎᑐᑦ",
      "code": "iu"
    },
    {
      "iso_name": "Japanese",
      "name": "日本語 (にほんご)",
      "code": "ja"
    },
    {
      "iso_name": "Javanese",
      "name": "ꦧꦱꦗꦮ - Basa Jawa",
      "code": "jv"
    },
    {
      "iso_name": "Kalaallisut - Greenlandic",
      "name": "kalaallisut - kalaallit oqaasii",
      "code": "kl"
    },
    {
      "iso_name": "Kannada",
      "name": "ಕನ್ನಡ",
      "code": "kn"
    },
    {
      "iso_name": "Kanuri",
      "name": "Kanuri",
      "code": "kr"
    },
    {
      "iso_name": "Kashmiri",
      "name": "कश्मीरी - كشميري‎",
      "code": "ks"
    },
    {
      "iso_name": "Kazakh",
      "name": "қазақ тілі",
      "code": "kk"
    },
    {
      "iso_name": "Central Khmer",
      "name": "ខ្មែរ - ខេមរភាសា - ភាសាខ្មែរ",
      "code": "km"
    },
    {
      "iso_name": "Kikuyu - Gikuyu",
      "name": "Gĩkũyũ",
      "code": "ki"
    },
    {
      "iso_name": "Kinyarwanda",
      "name": "Ikinyarwanda",
      "code": "rw"
    },
    {
      "iso_name": "Kirghiz - Kyrgyz",
      "name": "Кыргызча - Кыргыз тили",
      "code": "ky"
    },
    {
      "iso_name": "Komi",
      "name": "коми кыв",
      "code": "kv"
    },
    {
      "iso_name": "Kongo",
      "name": "Kikongo",
      "code": "kg"
    },
    {
      "iso_name": "Korean",
      "name": "한국어",
      "code": "ko"
    },
    {
      "iso_name": "Kurdish",
      "name": "Kurdî - کوردی‎",
      "code": "ku"
    },
    {
      "iso_name": "Kuanyama - Kwanyama",
      "name": "Kuanyama",
      "code": "kj"
    },
    {
      "iso_name": "Latin",
      "name": "latine - lingua latina",
      "code": "la"
    },
    {
      "iso_name": "Luxembourgish - Letzeburgesch",
      "name": "Lëtzebuergesch",
      "code": "lb"
    },
    {
      "iso_name": "Ganda",
      "name": "Luganda",
      "code": "lg"
    },
    {
      "iso_name": "Limburgan - Limburger - Limburgish",
      "name": "Limburgs",
      "code": "li"
    },
    {
      "iso_name": "Lingala",
      "name": "Lingála",
      "code": "ln"
    },
    {
      "iso_name": "Lao",
      "name": "ພາສາລາວ",
      "code": "lo"
    },
    {
      "iso_name": "Lithuanian",
      "name": "lietuvių kalba",
      "code": "lt"
    },
    {
      "iso_name": "Luba-Katanga",
      "name": "Kiluba",
      "code": "lu"
    },
    {
      "iso_name": "Latvian",
      "name": "latviešu valoda",
      "code": "lv"
    },
    {
      "iso_name": "Manx",
      "name": "Gaelg - Gailck",
      "code": "gv"
    },
    {
      "iso_name": "Macedonian",
      "name": "македонски јазик",
      "code": "mk"
    },
    {
      "iso_name": "Malagasy",
      "name": "fiteny malagasy",
      "code": "mg"
    },
    {
      "iso_name": "Malay",
      "name": "Bahasa Melayu - بهاس ملايو‎",
      "code": "ms"
    },
    {
      "iso_name": "Malayalam",
      "name": "മലയാളം",
      "code": "ml"
    },
    {
      "iso_name": "Maltese",
      "name": "Malti",
      "code": "mt"
    },
    {
      "iso_name": "Maori",
      "name": "te reo Māori",
      "code": "mi"
    },
    {
      "iso_name": "Marathi",
      "name": "मराठी",
      "code": "mr"
    },
    {
      "iso_name": "Marshallese",
      "name": "Kajin M̧ajeļ",
      "code": "mh"
    },
    {
      "iso_name": "Mongolian",
      "name": "Монгол хэл",
      "code": "mn"
    },
    {
      "iso_name": "Nauru",
      "name": "Dorerin Naoero",
      "code": "na"
    },
    {
      "iso_name": "Navajo - Navaho",
      "name": "Diné bizaad",
      "code": "nv"
    },
    {
      "iso_name": "North Ndebele",
      "name": "isiNdebele",
      "code": "nd"
    },
    {
      "iso_name": "Nepali",
      "name": "नेपाली",
      "code": "ne"
    },
    {
      "iso_name": "Ndonga",
      "name": "Owambo",
      "code": "ng"
    },
    {
      "iso_name": "Norwegian Bokmål",
      "name": "Norsk Bokmål",
      "code": "nb"
    },
    {
      "iso_name": "Norwegian Nynorsk",
      "name": "Norsk Nynorsk",
      "code": "nn"
    },
    {
      "iso_name": "Norwegian",
      "name": "Norsk",
      "code": "no"
    },
    {
      "iso_name": "Sichuan Yi - Nuosu",
      "name": "ꆈꌠ꒿ Nuosuhxop",
      "code": "ii"
    },
    {
      "iso_name": "South Ndebele",
      "name": "isiNdebele",
      "code": "nr"
    },
    {
      "iso_name": "Occitan",
      "name": "occitan - lenga d'òc",
      "code": "oc"
    },
    {
      "iso_name": "Ojibwa",
      "name": "ᐊᓂᔑᓈᐯᒧᐎᓐ",
      "code": "oj"
    },
    {
      "iso_name": "Church Slavic - Old Slavonic - Church Slavonic - Old Bulgarian - Old Church Slavonic",
      "name": "ѩзыкъ словѣньскъ",
      "code": "cu"
    },
    {
      "iso_name": "Oromo",
      "name": "Afaan Oromoo",
      "code": "om"
    },
    {
      "iso_name": "Oriya",
      "name": "ଓଡ଼ିଆ",
      "code": "or"
    },
    {
      "iso_name": "Ossetian - Ossetic",
      "name": "ирон æвзаг",
      "code": "os"
    },
    {
      "iso_name": "Punjabi - Panjabi",
      "name": "ਪੰਜਾਬੀ - پنجابی‎",
      "code": "pa"
    },
    {
      "iso_name": "Pali",
      "name": "पालि - पाळि",
      "code": "pi"
    },
    {
      "iso_name": "Persian",
      "name": "فارسی",
      "code": "fa"
    },
    {
      "iso_name": "Polish",
      "name": "język polski - polszczyzna",
      "code": "pl"
    },
    {
      "iso_name": "Pashto - Pushto",
      "name": "پښتو",
      "code": "ps"
    },
    {
      "iso_name": "Portuguese",
      "name": "Português",
      "code": "pt"
    },
    {
      "iso_name": "Quechua",
      "name": "Runa Simi - Kichwa",
      "code": "qu"
    },
    {
      "iso_name": "Romansh",
      "name": "Rumantsch Grischun",
      "code": "rm"
    },
    {
      "iso_name": "Rundi",
      "name": "Ikirundi",
      "code": "rn"
    },
    {
      "iso_name": "Romanian - Moldavian - Moldovan",
      "name": "Română",
      "code": "ro"
    },
    {
      "iso_name": "Russian",
      "name": "русский",
      "code": "ru"
    },
    {
      "iso_name": "Sanskrit",
      "name": "संस्कृतम्",
      "code": "sa"
    },
    {
      "iso_name": "Sardinian",
      "name": "sardu",
      "code": "sc"
    },
    {
      "iso_name": "Sindhi",
      "name": "सिन्धी - سنڌي، سندھی‎",
      "code": "sd"
    },
    {
      "iso_name": "Northern Sami",
      "name": "Davvisámegiella",
      "code": "se"
    },
    {
      "iso_name": "Samoan",
      "name": "gagana fa'a Samoa",
      "code": "sm"
    },
    {
      "iso_name": "Sango",
      "name": "yângâ tî sängö",
      "code": "sg"
    },
    {
      "iso_name": "Serbian",
      "name": "српски језик",
      "code": "sr"
    },
    {
      "iso_name": "Gaelic - Scottish Gaelic",
      "name": "Gàidhlig",
      "code": "gd"
    },
    {
      "iso_name": "Shona",
      "name": "chiShona",
      "code": "sn"
    },
    {
      "iso_name": "Sinhala - Sinhalese",
      "name": "සිංහල",
      "code": "si"
    },
    {
      "iso_name": "Slovak",
      "name": "Slovenčina - Slovenský Jazyk",
      "code": "sk"
    },
    {
      "iso_name": "Slovenian",
      "name": "Slovenski Jezik - Slovenščina",
      "code": "sl"
    },
    {
      "iso_name": "Somali",
      "name": "Soomaaliga - af Soomaali",
      "code": "so"
    },
    {
      "iso_name": "Southern Sotho",
      "name": "Sesotho",
      "code": "st"
    },
    {
      "iso_name": "Spanish - Castilian",
      "name": "Español",
      "code": "es"
    },
    {
      "iso_name": "Sundanese",
      "name": "Basa Sunda",
      "code": "su"
    },
    {
      "iso_name": "Swahili",
      "name": "Kiswahili",
      "code": "sw"
    },
    {
      "iso_name": "Swati",
      "name": "SiSwati",
      "code": "ss"
    },
    {
      "iso_name": "Swedish",
      "name": "Svenska",
      "code": "sv"
    },
    {
      "iso_name": "Tamil",
      "name": "தமிழ்",
      "code": "ta"
    },
    {
      "iso_name": "Telugu",
      "name": "తెలుగు",
      "code": "te"
    },
    {
      "iso_name": "Tajik",
      "name": "тоҷикӣ - toçikī - تاجیکی‎",
      "code": "tg"
    },
    {
      "iso_name": "Thai",
      "name": "ไทย",
      "code": "th"
    },
    {
      "iso_name": "Tigrinya",
      "name": "ትግርኛ",
      "code": "ti"
    },
    {
      "iso_name": "Tibetan",
      "name": "བོད་ཡིག",
      "code": "bo"
    },
    {
      "iso_name": "Turkmen",
      "name": "Türkmen - Түркмен",
      "code": "tk"
    },
    {
      "iso_name": "Tagalog",
      "name": "Wikang Tagalog",
      "code": "tl"
    },
    {
      "iso_name": "Tswana",
      "name": "Setswana",
      "code": "tn"
    },
    {
      "iso_name": "Tonga (Tonga Islands)",
      "name": "Faka Tonga",
      "code": "to"
    },
    {
      "iso_name": "Turkish",
      "name": "Türkçe",
      "code": "tr"
    },
    {
      "iso_name": "Tsonga",
      "name": "Xitsonga",
      "code": "ts"
    },
    {
      "iso_name": "Tatar",
      "name": "татар теле - tatar tele",
      "code": "tt"
    },
    {
      "iso_name": "Twi",
      "name": "Twi",
      "code": "tw"
    },
    {
      "iso_name": "Tahitian",
      "name": "Reo Tahiti",
      "code": "ty"
    },
    {
      "iso_name": "Uighur - Uyghur",
      "name": "ئۇيغۇرچە‎ - Uyghurche",
      "code": "ug"
    },
    {
      "iso_name": "Ukrainian",
      "name": "Українська",
      "code": "uk"
    },
    {
      "iso_name": "Urdu",
      "name": "اردو",
      "code": "ur"
    },
    {
      "iso_name": "Uzbek",
      "name": "Oʻzbek - Ўзбек - أۇزبېك‎",
      "code": "uz"
    },
    {
      "iso_name": "Venda",
      "name": "Tshivenḓa",
      "code": "ve"
    },
    {
      "iso_name": "Vietnamese",
      "name": "Tiếng Việt",
      "code": "vi"
    },
    {
      "iso_name": "Volapük",
      "name": "Volapük",
      "code": "vo"
    },
    {
      "iso_name": "Walloon",
      "name": "Walon",
      "code": "wa"
    },
    {
      "iso_name": "Welsh",
      "name": "Cymraeg",
      "code": "cy"
    },
    {
      "iso_name": "Wolof",
      "name": "Wollof",
      "code": "wo"
    },
    {
      "iso_name": "Western Frisian",
      "name": "Frysk",
      "code": "fy"
    },
    {
      "iso_name": "Xhosa",
      "name": "isiXhosa",
      "code": "xh"
    },
    {
      "iso_name": "Yiddish",
      "name": "ייִדיש",
      "code": "yi"
    },
    {
      "iso_name": "Yoruba",
      "name": "Yorùbá",
      "code": "yo"
    },
    {
      "iso_name": "Zhuang - Chuang",
      "name": "Saɯ cueŋƅ - Saw cuengh",
      "code": "za"
    },
    {
      "iso_name": "Zulu",
      "name": "isiZulu",
      "code": "zu"
    }
  ];
}
