import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody } from 'reactstrap';
import ReactTable from "react-table";
import matchSorter from 'match-sorter';
import { withTranslation } from "react-i18next";
import PageTitle from 'Layout/Components/PageTitle';
import SearchBox from "Layout/Components/SearchBox";
import { AccountContext } from './AccountContext';
import AccountBlacklist from "./AccountBlacklist";

class Accounts extends React.Component {

  static contextType = AccountContext;

  constructor(props) {
    super(props);

    this.state = {
      filtered: [],
      openEdit: false
    };

    this.filterAccount = this.filterAccount.bind(this);
  }

  filterAccount(email) {
    this.setState({
      filtered: [{ id: 'email', value: email }]
    });
  }

  render() {
    const { t, i18n } = this.props;
    
    const balcklistModal = this.state.openEdit &&
      <AccountBlacklist open={true} onClose={() => this.setState({ openEdit: false })} />;
    
      return (
      <Fragment>
        <PageTitle
          heading={t("blacklist.accounts.title")}
          subheading={t("blacklist.accounts.description")}
          icon="pe-7s-users icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <SearchBox placeholder={t('accounts.actions.search')} doSearch={this.filterAccount} />
                  {balcklistModal}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    columns={[
                      {
                        Header: t("accounts.email"),
                        accessor: 'email',
                        id: 'email',
                        filterMethod: (filter, rows) => {
                          const result = matchSorter(rows, filter.value, {
                            keys: [
                              "email",
                              "name"
                            ], threshold: matchSorter.rankings.WORD_STARTS_WITH
                          });
                          return result;
                        },
                        filterAll: true
                      },
                      {
                        Header: t("accounts.name"),
                        accessor: 'name',
                        id: 'name'
                      },
                      {
                        Header: t("accounts.department"),
                        accessor: 'department'
                      },
                      {
                        Header: t("accounts.status"),
                        accessor: 'status'
                      }
                    ]}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            this.context.openAccount(rowInfo.original);
                            this.setState({ openEdit: true })
                          },
                          style: {
                            cursor: 'pointer'
                          }
                        }
                      }
                      return {}
                    }}
                    data={this.context.accounts}
                    defaultPageSize={10}
                    filterable={false}
                    filtered={this.state.filtered}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                    className="-striped -highlight" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withTranslation()(Accounts);
