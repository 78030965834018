import React from "react";
import CampaignProvider from './CampaignContext'
import Campaigns from "./Campaigns";
import CampaignDetails from "./CampaignDetails/CampaignDetails";
import CampaignMessages from "./CampaignMessages";
import VisualizeCampaigns from "./Visualize/VisualizeCampaigns";
import TargetCampaigns from "./Targets/TargetCampaigns";

function withContext(Component, listing) {
  class WithContext extends React.Component {
    render() {
      return (
        <CampaignProvider listing={listing} >
          <Component />
        </CampaignProvider>
      );
    }
  }
  return WithContext;
}

export default withContext(Campaigns, true);

const Campaign = withContext(CampaignDetails, false);
const CreateCampaign = withContext(CampaignMessages, false);
const VisualizeCampaignsCtx = withContext(VisualizeCampaigns, true);

export {
  Campaign,
  CreateCampaign,
  VisualizeCampaignsCtx,
  TargetCampaigns
}
