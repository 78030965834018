import React, { Fragment } from 'react';
import {
  Col, FormGroup, Label, Input, Row, Button, Modal, ModalHeader, ModalBody, Form, ModalFooter
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

class AttachmentModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      Attachment: {
        Name: '',
        Format: '',
        Size: '',
        Md5: '',
        Sha1: '',
        Sha256: '',
        FuzzyHashes: ''
      }
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      Attachment: {
        Name: '',
        Format: '',
        Size: '',
        Md5: '',
        Sha1: '',
        Sha256: '',
        FuzzyHashes: ''
      }
    });
  }

  handleChange(event) {
    const obj = event.target || event;
    this.setState(updater => ({
      Attachment: {
        ...updater.Attachment,
        [obj.name]: obj.value
      }
    }));
  }

  submit() {
    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(this.state.Attachment);
    }
    this.toggle();
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button className={`mb-2 mr-2 btn-icon btn-shadow btn-heading-position`}
        size="sm" color="primary" onClick={() => this.toggle()}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('campaign.message-info.actions.add-attachment')}
      </Button>;

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{t('campaign.message-info.actions.add-attachment')}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <FormGroup row>
                <Label for="Name" sm={2}>{t('campaign.message-info.attachments.name')}</Label>
                <Col sm={10}>
                  <Input type="text" name="Name" id="Name"
                    value={this.state.Attachment.Name} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="Format" sm={2}>{t('campaign.message-info.attachments.format')}</Label>
                <Col sm={10}>
                  <Input type="text" name="Format" id="Format"
                    value={this.state.Attachment.Format} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="Size" sm={2}>{t('campaign.message-info.attachments.size')}</Label>
                <Col sm={10}>
                  <Input type="text" name="Size" id="Size"
                    value={this.state.Attachment.Size} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="Md5" sm={2}>{t('campaign.message-info.attachments.md5')}</Label>
                <Col sm={10}>
                  <Input type="text" name="Md5" id="Md5"
                    value={this.state.Attachment.Md5} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="Sha1" sm={2}>{t('campaign.message-info.attachments.sha1')}</Label>
                <Col sm={10}>
                  <Input type="text" name="Sha1" id="Sha1"
                    value={this.state.Attachment.Sha1} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="Sha256" sm={2}>{t('campaign.message-info.attachments.sha256')}</Label>
                <Col sm={10}>
                  <Input type="text" name="Sha256" id="Sha256"
                    value={this.state.Attachment.Sha256} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="FuzzyHashes" sm={2}>{t('campaign.message-info.attachments.fuzzy_hashes')}</Label>
                <Col sm={10}>
                  <Input type="text" name="FuzzyHashes" id="FuzzyHashes"
                    value={this.state.Attachment.FuzzyHashes} onChange={this.handleChange} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>{t('actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment >
    );
  }
}

AttachmentModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(AttachmentModal);
