import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import { toast } from "react-toastify";
import { withTranslation } from 'react-i18next';

import { createDepartment } from '../../API/DepartmentAPI';
import InfoPopover from 'Layout/Components/InfoPopover';

class DepartmentModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      isRemove: false,
      department: ""
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggle(remove) {
    this.setState({
      modal: !this.state.modal,
      isRemove: remove,
      department: ""
    });
  }

  handleChange(event) {
    const key = event.target.name;
    const value = event.target.value;
    this.setState({
      [key]: value
    });
  }

  submit() {
    createDepartment(this.state.department)
      .then(result => {
        if (typeof this.props.addDepartmentCallback === 'function') {
          this.props.addDepartmentCallback();
          this.toggle();
        }
      }).catch(error => {
        console.error("Error creating Department", error);
        toast["error"](this.props.t('errors.creating', { element: 'Department', message: error.message }));
      });
  }

  render() {
    const { t, i18n } = this.props;

    const title = t("departments.actions.create");

    return (
      <Fragment>
        <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
          onClick={() => this.toggle(false)}>
          <i className="lnr-plus-circle btn-icon-wrapper"> </i>
          {t("departments.actions.create")}
        </Button>
        <InfoPopover id="departments-instructions" body={t('departments.instructions')} />
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <FormGroup row>
                <Label for="name" sm={2}>{t("departments.name")}</Label>
                <Col sm={10}>
                  <Input type="text" name="department" id="department"
                    value={this.state.department} onChange={this.handleChange} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t("actions.cancel")}</Button>
            <Button color="primary" onClick={this.submit}>{t("actions.submit")}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default withTranslation()(DepartmentModal);