import React, { Fragment } from "react";
import { Line } from 'react-chartjs-2';
class EmailSuccessLineGraph extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data: this.props.data,
      label: this.props.label
    }
  }
  componentWillReceiveProps(props){
    this.setState({
      data: props.data
    })
  }
  render(){
    //console.log('Data loaded', this.state.data);
    return(
      <Line
       data = {{
         datasets: [{
            data: this.state.data,
            pointBackgroundColor: 'rgb(240, 91, 79)',
            borderColor:'rgb(240, 91, 79)',
            borderWidth: 2,
            showLine: true,
            fill: 'origin'
          }]
        }}
        options = {{
          legend: false,
          maintainAspectRatio: false,
          title:{
            display: true,
            text: this.state.label
          },
          scales: {
            xAxes: [{
                type: 'time',
                time: {
                    displayFormats: {
                        quarter: 'MMM YYYY'
                    }
                }
            }],
            yAxes: [{
              ticks:{
                suggestedMin: 0,
                suggestedMax: 100,
                stepSize: 50
              }
            }]
          }
        }}
      />
    )
  }
}
export default EmailSuccessLineGraph;
