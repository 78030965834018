import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { CampaignContext } from '../CampaignContext';

class GeolocationInfoModal extends React.Component {

  static contextType = CampaignContext;

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      GeolocationInfo: {
        ASNumber: '',
        ASOrganization: '',
        Country: '',
        City: '',
        Lat: '',
        Lng: '',
        PostalCode: '',
        ISP: ''
      }
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      GeolocationInfo: {
        ASNumber: '',
        ASOrganization: '',
        Country: '',
        City: '',
        Lat: '',
        Lng: '',
        PostalCode: '',
        ISP: ''
      }
    });
  }

  handleChange(event) {
    const obj = event.target || event;
    this.setState(updater => ({
      GeolocationInfo: {
        ...updater.GeolocationInfo,
        [obj.name]: obj.value
      }
    }));
  }

  submit() {
    this.context.addGeolocationInfo(this.state.GeolocationInfo);
    this.toggle();
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button className={`mb-2 mr-2 btn-icon btn-shadow btn-heading-position`}
        size="sm" color="primary" onClick={() => this.toggle()}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('campaign.geo-info.actions.add-geolocation')}
      </Button>;

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{t('campaign.geo-info.actions.add-geolocation')}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <FormGroup row>
                <Label for="ASNumber" sm={2}>{t('campaign.geo-info.number')}</Label>
                <Col sm={10}>
                  <Input type="text" name="ASNumber" id="ASNumber"
                    value={this.state.GeolocationInfo.ASNumber} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="ASOrganization" sm={2}>{t('campaign.geo-info.organization')}</Label>
                <Col sm={10}>
                  <Input type="text" name="ASOrganization" id="ASOrganization"
                    value={this.state.GeolocationInfo.ASOrganization} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="Country" sm={2}>{t('campaign.geo-info.country')}</Label>
                <Col sm={10}>
                  <Input type="text" name="Country" id="Country"
                    value={this.state.GeolocationInfo.Country} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="City" sm={2}>{t('campaign.geo-info.city')}</Label>
                <Col sm={10}>
                  <Input type="text" name="City" id="City"
                    value={this.state.GeolocationInfo.City} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="Lat" sm={2}>{t('campaign.geo-info.lat')}</Label>
                <Col sm={10}>
                  <Input type="text" name="Lat" id="Lat"
                    value={this.state.GeolocationInfo.Lat} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="Lng" sm={2}>{t('campaign.geo-info.lng')}</Label>
                <Col sm={10}>
                  <Input type="text" name="Lng" id="Lng"
                    value={this.state.GeolocationInfo.Lng} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="PostalCode" sm={2}>{t('campaign.geo-info.postal-code')}</Label>
                <Col sm={10}>
                  <Input type="text" name="PostalCode" id="PostalCode"
                    value={this.state.GeolocationInfo.PostalCode} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="ISP" sm={2}>{t('campaign.geo-info.isp')}</Label>
                <Col sm={10}>
                  <Input type="text" name="ISP" id="ISP"
                    value={this.state.GeolocationInfo.ISP} onChange={this.handleChange} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>{t('actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

GeolocationInfoModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(GeolocationInfoModal);
