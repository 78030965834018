import React, { Fragment } from 'react';
import {
  Button, Card, CardBody,
  Form, FormGroup, Input, Col, Row, Spinner
} from 'reactstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import BootstrapTable from 'react-bootstrap-table-next';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import PageTitle from '../../Layout/Components/PageTitle';
import ConfirmAction from '../../Layout/Components/ConfirmAction';

import {
  getWhitelist, createWhitelist, deleteWhitelist
} from '../../API/WhitelistAPI';
import { readFile } from '../Helpers/FileHelper';
import InfoPopover from 'Layout/Components/InfoPopover';

class Whitelist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      whitelist: [],
      url: "",
      file: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.add = this.add.bind(this);
    this.upload = this.upload.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    this.fillTable();
  }

  fillTable() {
    getWhitelist().then(result => {
      this.setState({
        whitelist: result,
        url: ""
      });
    }).catch(error => {
      console.error("Error getting Whitelist", error);
      toast["error"](this.props.t('errors.loading', { element: 'Whitelist', message: error.message }));
    });
  }

  handleChange(event) {
    const key = event.target.name;
    const value = event.target.value;
    this.setState({
      [key]: value
    });
  }

  add() {
    if (this.state.url) {
      createWhitelist(this.state.url).then(result => {
        toast["success"](this.props.t('messages.success_update', { element: 'whitelist' }));
        this.fillTable();
      }).catch(error => {
        console.error("Error creating Whitelist", error);
        toast["error"](this.props.t('errors.creating', { element: 'whitelist', message: error.message }));
      });
    } else {
      toast["warning"](this.props.t('messages.unable_to_create', {
        element: 'whitelist',
        message: this.props.t('messages.empty_field', { field: "URL" })
      }));
    }
  }

  upload() {
    document.getElementById('file').click();
  }

  async handleFile(event) {
    this.setState({
      loading: true
    });

    // Read the eml file
    const content = await readFile(event.target.files[0]).catch(error => {
      console.error("Error reading file", error);
      toast["error"](this.props.t('errors.file_read_error'));
    });
    if (!content) {
      this.setState({
        loading: false
      });
      return;
    }

    // Create the whitelists
    let uploadPromises = [];
    content.split(/\r\n|\n/).forEach(url => {
      if (url) {
        uploadPromises.push(createWhitelist(url))
      }
    });
    Promise.allSettled(uploadPromises).then(values => {
      toast["success"](this.props.t('messages.success_update', { element: 'whitelist' }));
      let errors = values.filter(el => el.status !== 'fulfilled');
      if (errors && errors.length > 0) {
        console.warn("Error uploading whitelists", errors);
        toast["warning"](this.props.t('whitelist.messages.file_uploaded_with_errors', { number: errors.length }));
      }
      this.fillTable();
      this.setState({
        loading: false
      });
    }).catch(error => {
      this.setState({
        loading: false
      });
    });
  }

  delete(whitelist_id) {
    deleteWhitelist(whitelist_id).then(result => {
      toast["success"](this.props.t('messages.success_delete', { element: 'whitelist' }));
      this.fillTable()
    }).catch(error => {
      console.error("Error deleting Whitelist", error);
      toast["error"](this.props.t('errors.deleting', { element: 'whitelist', message: error.message }));
    })
  }

  render() {
    const { t, i18n } = this.props;

    const uploadActionLabel = this.state.loading
      ? <span><Spinner color="white" size="sm" /> {t("whitelist.actions.uploading")}</span>
      : <span>{t("whitelist.actions.upload")}</span>;

    return (
      <Fragment>
        <PageTitle
          heading={t("whitelist.title")}
          subheading={t("whitelist.description")}
          icon="pe-7s-shield icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12" lg="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <Form onSubmit={(e) => { e.preventDefault(); }}>
                    <Row form>
                      <Col md={4}>
                        <FormGroup>
                          <Input type="text" name="url" id="url"
                            value={this.state.url} onChange={this.handleChange}
                            placeholder={t("whitelist.url_placeholder")} />
                          <Input id="file" type="file" hidden accept=".txt,.csv"
                            value={this.state.file} onChange={this.handleFile} />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <Button color="success" className="mb-2 mr-2"
                          onClick={() => this.add()}>
                          {t("whitelist.actions.add")}
                        </Button>
                        <Button color="primary" className="mb-2 mr-2"
                          onClick={() => this.upload()} disabled={this.state.loading}>
                          {uploadActionLabel}
                        </Button>
                        <InfoPopover id="whitelist-instructions" mid body={t('whitelist.actions.information')} />
                      </Col>
                    </Row>
                  </Form>
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={this.state.whitelist}
                    columns={[
                      {
                        dataField: 'ip_url',
                        text: t("whitelist.url")
                      },
                      {
                        dataField: 'id',
                        text: t("whitelist.actions.title"),
                        isDummyField: true,
                        formatter: (cell, row, index) => {
                          return (
                            <div>
                              <div className="d-block w-100 text-center">
                                <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                                  message={t('messages.confirm_delete', { element: 'whitelist', object: row.ip_url })}
                                  onConfirm={() => this.delete(row.id)}>{t("whitelist.actions.delete")}</ConfirmAction>
                              </div>
                            </div>
                          );
                        }
                      }
                    ]} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
}

export default withTranslation()(Whitelist);