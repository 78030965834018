export default class FeaturesHelper {

  // Map of features: Map<type, feature>
  static features = new Map();

  static appendFeature(type, feature) {
    const list = this.features.get(type) || [];
    list.push(feature);
    this.features.set(type, list);
  }

  static async loadFeatures() {
    fetch('/featureTypes.csv')
      .then(response => {
        if (response.status !== 200) {
          console.error('Error loading features.', response);
          return;
        }
        response.text().then(content => {
          content.split(/\r\n|\n/).forEach(register => {
            let values = register.split(",");
            switch (values[1]) {
              case 'cat':
                this.appendFeature('categorical', values[0]);
                break;
              case 'cos':
                this.appendFeature('numerical', values[0]);
                break;
              default:
                this.appendFeature('set', values[0]);
            }
          });
          console.log('Features loaded');
        });
      }).catch(error => {
        console.error("Error loading features", error);
      })
  }

  static async getFeatures(type) {
    if (!this.features || this.features.size === 0) {
      await this.loadFeatures();
    }
    return this.features.get(type) || Promise.reject("Feature type not found.");
  }

}