import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class AppBreadcrumb extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            path: []
        };

        this.setPath = this.setPath.bind(this);
    }

    setPath(pathname) {
        let pathArray = pathname.split("/").filter(val => val.length > 0);
        this.setState({
            path: pathArray
        });
    }

    componentDidMount() {
        this.setPath(this.props.location.pathname)
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setPath(this.props.location.pathname)
        }
    }

    render() {
        const { path } = this.state;
        const items = path.map((val, index) => {
            const last = path.length === (index + 1);
            const ref = path.slice(0, index + 1).reduce((acc, cur) => acc + "/" + cur, "");
            const link = last ? val : <Link to={ref}>{val}</Link>;
            return <BreadcrumbItem key={`link_${index}`} active={last}>{link}</BreadcrumbItem>;
        });

        return (
            <div>
                <Breadcrumb>
                    {items}
                </Breadcrumb>
            </div >
        );
    }
}

export default withRouter(withTranslation()(AppBreadcrumb));