import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
  Row, Col, Card, CardBody, Button,
} from 'reactstrap';
import ReactTable from "react-table";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { withTranslation } from 'react-i18next'
import PageTitle from 'Layout/Components/PageTitle';
import ConfirmAction from "Layout/Components/ConfirmAction";
import { toast } from "react-toastify";
import { searchAccounts, unregisterAccounts } from "API/OutlookAPI";
import SearchBox from "Layout/Components/SearchBox";

const SelectTable = checkboxHOC(ReactTable);

class Accounts extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      account: "",
      accounts: [],
      selection: new Map(),
      selectAll: false
    };

    this.listAccounts = this.listAccounts.bind(this);
    this.updateAccounts = this.updateAccounts.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
  }

  listAccounts(account) {
    this.setState({
      account: account
    });
    searchAccounts(account).then(result => {
      this.setState({
        accounts: result,
        selection: new Map()
      })
    }).catch(error => {
      console.error("Error getting accounts", error);
      toast["error"](this.props.t('errors.loading', { element: 'Accounts', message: error.message }));
    });
  }

  updateAccounts() {
    const emails = Array.from(this.state.selection.values())
      .map(account => account.email);
    unregisterAccounts(emails).then(result => {
      toast["success"](this.props.t('messages.success_update', { element: 'Accounts' }));
      this.listAccounts(this.state.account);
    }).catch(error => {
      console.error("Error updating accounts", error);
      toast["error"](this.props.t('errors.updating', { element: 'Accounts', message: error.message }));
    });
  }

  toggleSelection(key, shift, row) {
    let { selectAll, selection } = this.state;
    if (selection.has(row.email)) { // if key exists, remove it
      selection.delete(row.email);
    } else { // it doesn't exist, add it
      selection.set(row.email, row);
    }
    if (selection.size > 0) {
      // get the internals of ReactTable and get the sortedData
      const wrappedInstance = this.selectTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // adjust selectAll flag accordingly 
      selectAll = currentRecords.length === selection.size;
    }
    this.setState({ selectAll, selection });
  }

  toggleAll() {
    const selectAll = this.state.selectAll ? false : true;
    const selection = new Map();
    if (selectAll) {
      // get the internals of ReactTable and get the sortedData
      const wrappedInstance = this.selectTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // push all the IDs onto the selection array
      currentRecords.forEach(item => {
        if (item._original) {
          selection.set(item._original.email, item._original);
        }
      });
    }
    this.setState({ selectAll, selection });
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("outlook_accounts.title")}
          icon="pe-7s-cloud icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <SearchBox placeholder={t('outlook_accounts.actions.search')} doSearch={this.listAccounts} />
                  <ConfirmAction btnClassName="mb-2 mr-2 btn-icon btn-shadow" color="warning" size="lg" title={t('actions.confirmation')} 
                    message={t('messages.confirm_disable', { object: 'accounts' })} onConfirm={() => this.updateAccounts()}>
                    <i className="lnr-cloud-sync btn-icon-wrapper"> </i>
                    {t("outlook_accounts.actions.disable")}
                  </ConfirmAction>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <SelectTable
                    columns={[
                      {
                        Header: t("outlook_accounts.email"),
                        accessor: 'email',
                        id: 'email'
                      },
                      {
                        Header: t("outlook_accounts.status"),
                        accessor: 'status',
                        id: 'status'
                      }
                    ]}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            if (e.target.tagName.toLowerCase() === 'button') {
                              return; // Ignore clicks on buttons
                            }
                          },
                          style: {
                            cursor: 'pointer'
                          }
                        }
                      }
                      return {}
                    }}
                    keyField="email"
                    data={this.state.accounts}
                    ref={r => (this.selectTable = r)}
                    selectAll={this.state.selectAll}
                    isSelected={key => this.state.selection.has(key)}
                    toggleAll={this.toggleAll}
                    toggleSelection={this.toggleSelection}
                    defaultPageSize={10}
                    className="-striped -highlight" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withTranslation()(Accounts);