import React, { Fragment } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import Dashboard from './Dashboard';
import { MessageOrigin } from './ThreatIntelligence';

import Header from '../Layout/Header';
import AppSidebar from '../Layout/AppSidebar';
import ThemeOptions from '../Layout/ThemeOptions';
import ErrorBoundary from 'ErrorBoundary';

const NavItems = [
  {
    icon: 'pe-7s-display1',
    label: 'User Dashboard',
    to: '/user/dashboard',
  },
  {
    icon: 'pe-7s-look',
    label: 'Threat Intelligence',
    content: [
      {
        label: 'Message Origin',
        to: '/user/messageorigin',
      }
    ]
  },
];

export default function UserApp() {

  let { path } = useRouteMatch();

  return (
    <Fragment>
      <ThemeOptions />
      <Header />
      <div className="app-main">
        <AppSidebar navigation={NavItems} />
        <div className="app-main__outer">
          <div className="app-main__inner"><ErrorBoundary>
            <Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/messageorigin`} component={MessageOrigin} />
            {/* <Route exact path={`${path}/emaildetails`} component={EmailDetails} /> */}
          </ErrorBoundary></div>
        </div>
      </div>
    </Fragment>
  )
}
