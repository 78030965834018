import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col } from 'reactstrap';

import TargetGraph from './TargetGraph';
import BarsGraph from './BarsGraph';
import LinesGraph from './LinesGraph';

import { DashboardContext } from './DashboardContext';

const colors = {
  legitimate: '#76a96c',
  malware: '#7C2933',
  phishing: '#fb4c2f',
  warning: '#F99B25',
  spam: '#ffab45'
};

export default class DashboardContent extends React.Component {

  static contextType = DashboardContext

  render() {
    const { t, i18n, period } = this.props;

    const hasPeriod = this.context.periods.indexOf(period) >= 0;
    
    const totalsByPeriod = hasPeriod && this.context.totals.size > 0
      ? this.context.totals.get(period) : [];
    const totals = totalsByPeriod.map((data, i) => {
      const color = data.title.startsWith('Threats') ? 'phishing' : 'legitimate';
      return (
        <Col md="5" xl="5" key={i}>
          <TargetGraph heading={data.title} subheading={data.comment}
            value={data.value} percent={data.percent}
            color={color} />
        </Col>
      );
    });

    const totalsByCategoryByPeriod = hasPeriod && this.context.totalsByCategory.size > 0
      ? this.context.totalsByCategory.get(period) : [];
    const totalsByCategory = totalsByCategoryByPeriod.map((data, i) => {
      return (
        <Col md="2" xl="2" key={i}>
          <TargetGraph heading={data.title} subheading={data.comment}
            value={data.value} percent={data.percent}
            color={data.title.toLowerCase()} />
        </Col>
      );
    });

    const threatsByDatePeriod = hasPeriod && this.context.threatsByDate.size > 0
      ? this.context.threatsByDate.get(period) : [];

    const threatsByDateBar = {
      labels:[],
      datasets: []
    };
    const threatsByDateLine = {
      labels:[],
      datasets: []
    };
    threatsByDatePeriod.forEach(element => {
      Object.keys(element).forEach(key => {
        if (key === 'date') {
          threatsByDateBar.labels.push(element[key]);
          threatsByDateLine.labels.push(element[key]);
        } else {
          let index = threatsByDateBar.datasets.findIndex(e => e.label === key);
          if (index < 0) {
            threatsByDateBar.datasets.push({
              label: key,
              data: [element[key]],
              maxBarThickness: 50,
              backgroundColor: colors[key.toLowerCase()]
            });
            threatsByDateLine.datasets.push({
              label: key,
              data: [element[key]],
              borderColor: colors[key.toLowerCase()],
              fill: false
            });
          } else {
            threatsByDateBar.datasets[index].data.push(element[key]);
            threatsByDateLine.datasets[index].data.push(element[key]);
          }
        }
      })
    });

    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="1" xl="1">&nbsp;</Col>
            {totals}
            <Col md="1" xl="1">&nbsp;</Col>
          </Row>
          <Row>
            <Col md="1" xl="1">&nbsp;</Col>
            {totalsByCategory}
            <Col md="1" xl="1">&nbsp;</Col>
          </Row>
          <Row>
            <Col md="12" xl="12" style={{height: "300px"}} >
              <BarsGraph data={threatsByDateBar} />
            </Col>
          </Row>
          <Row>
            <Col md="2" xl="2">&nbsp;</Col>
            <Col md="8" xl="8" style={{height: "300px"}}>
              <LinesGraph data={threatsByDateLine} />
            </Col>
            <Col md="2" xl="2">&nbsp;</Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}
