import { doGet, doGetBlob, doPost } from "./BaseAPI";

export function getOverview() {
  return doGet(`/organization/report/overview`);
}

export function getOverviewDomain(domain) {
  return doGet(`/organization/report/overview/${domain}`);
}

export function getOverviewUser() {
  return doGet(`/organization/users/report/overview`);
}

export function getMessagesOrigin(page, pageSize, initial, end, sender, receiver, senderip, classification, subject, reported) {
  let body = {
    pagesize: pageSize || 10,
    initial: initial || null, 
    end: end || null, 
    sender: sender || null, 
    receiver: receiver || null, 
    senderip: senderip || null, 
    classification: classification || null, 
    subject: subject || null,
    reported: reported || null
  };
  return doPost(`/organization/report/messageorigin/${page}`, body);
}

export function getMessagesOriginUser(page) {
  return doGet(`/organization/users/report/messageorigin/${page}`);
}

export function getResume() {
  return doGet(`/organization/report/resume`);
}

export function getPDFReport(domain, period) {
  if (!domain) {
    return doGetBlob(`/organization/report/pdf/${period}`); // Organization
  }
  return doGetBlob(`/organization/report/pdf/domain/${domain}/${period}`); // Admin
}

export function getClassifications() {
  return doGetBlob(`/organization/report/classifications`);
}
