import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { createContactMessage, updateContactMessage } from "../../API/ContactMessagesAPI";
import { toast } from 'react-toastify';

class ContactMessageModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      isEdit: this.props.contactMessage && this.props.contactMessage.id ? true : false,
      contactMessage: {
        lang: "",
        links: "",
        message: "",
        sendto: "",
        subject: ""
      }
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    if (this.state.isEdit) {
      this.setState({
        contactMessage: this.props.contactMessage
      })
    }
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      contactMessage: {
        email: "",
        active: false
      }
    });
  }

  handleChange(event) {
    const key = event.target.name;
    const value = event.target.type === 'checkbox'
      ? event.target.checked : event.target.value;
    this.setState(updater => ({
      contactMessage: {
        ...updater.contactMessage,
        [key]: value
      }
    }));
  }

  submit() {
    const promise = this.state.isEdit
      ? updateContactMessage(this.state.contactMessage)
      : createContactMessage(this.state.contactMessage);
    promise.then(result => {
      if (typeof this.props.onSubmitCallback === 'function') {
        this.props.onSubmitCallback();
        this.toggle();
      }
    }).catch(error => {
      console.error("Error submiting contactMessage", error);
      const messageKey = this.state.isEdit ? 'errors.updating' : 'errors.creating';
      toast["error"](this.props.t(messageKey, { element: 'Contact Message', message: error.message }));
    });
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
        onClick={() => this.toggle()}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('contactMessages.actions.create')}
      </Button>;

    const title = this.state.isEdit ? t('contactMessages.actions.edit') : t('contactMessages.actions.create')

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}
          size='xl' scrollable={true}>
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <FormGroup row>
                <Label for="subject" sm={2}>{t('contactMessages.subject')}</Label>
                <Col sm={10}>
                  <Input type="text" name="subject" id="subject"
                    value={this.state.contactMessage.subject} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="message" sm={2}>{t('contactMessages.message')}</Label>
                <Col sm={10}>
                  <Input type="textarea" name="message" id="message"
                    value={this.state.contactMessage.message} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="sendto" sm={2}>{t('contactMessages.sendto')}</Label>
                <Col sm={10}>
                  <Input type="text" name="sendto" id="sendto"
                    value={this.state.contactMessage.sendto} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="lang" sm={2}>{t('contactMessages.lang')}</Label>
                <Col sm={10}>
                  <Input type="text" name="lang" id="lang"
                    value={this.state.contactMessage.lang} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="links" sm={2}>{t('contactMessages.links')}</Label>
                <Col sm={10}>
                  <Input type="text" name="links" id="links"
                    value={this.state.contactMessage.links} onChange={this.handleChange} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>{t('actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

ContactMessageModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(ContactMessageModal);
