import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Col, Form, FormGroup, Label, Input, Row, Card, CardBody
} from 'reactstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { withTranslation } from 'react-i18next';
import update from 'immutability-helper';
import DatePicker from 'react-datepicker';

import PageTitle from '../../../Layout/Components/PageTitle';

import { PartnerContext } from './PartnerContext';
import Contacts from './Contacts';
import ImageUploader from 'Layout/Components/ImageUploader';

class Partner extends React.Component {

  static contextType = PartnerContext;

  constructor(props) {
    super(props);

    this.state = {
      partner: {
        id: "",
        name: "",
        domain: "",
        partner: "",
        address: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        registrationNumber: "",
        created: "",
      },
      logo: ""
    }

    this.handleChange = this.handleChange.bind(this);
    this.savePartner = this.savePartner.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.context.isEdit() && this.state.partner.id !== this.context.partner.id) {
      this.setState({
        partner: this.context.partner,
        logo: this.context.logoURL()
      })
    }
  }

  handleChange(event) {
    const obj = event.target || event;
    const path = obj.name.split(".");
    const name = path.pop();
    const value = obj.type === 'checkbox' || obj.value === 'select-one'
      ? obj.checked : obj.value;
    this.setState(updater => (
      update(updater, path.reduceRight((obj, next) => ({ [next]: obj }), { [name]: { $set: value } })))
    );
  }

  savePartner() {
    let partner = this.state.partner;
    partner.contact = this.context.contacts; // Update contact(s)
    if (this.context.isEdit()) {
      this.context.updatePartner(partner)
    } else {
      this.context.createPartner(partner)
    }
  }

  render() {
    const { t, i18n } = this.props;

    const title = this.context.isEdit() ? t("partners.actions.edit") : t("partners.actions.create");

    const logoUploadFields = this.context.isAdmin ?
      <Row form>
        <Col md={2}>
          <FormGroup>
            <img name="partner.logo" className="img-thumbnail"
              src={this.state.logo} alt={t("partners.partner.logo")} />
          </FormGroup>
        </Col>
        <Col md={2}>
          <ImageUploader title={t('partners.partner.change_logo')}
            buttonLabel={t('partners.partner.change_logo')}
            aspectRatio={131 / 48} accept="image/png"
            onSave={this.context.uploadLogo} />
        </Col>
      </Row> : "";

    const protectedFields = this.context.isAdmin ?
      <Fragment>
        <Col md={4}>
          <FormGroup>
            <Label for="partner.created">{t('partners.partner.created')}</Label>
            <DatePicker name="partner.created" id="partner.created" className="form-control"
              selected={this.state.partner.created} dateFormat="yyyy-MM-dd h:mm:ss aa"
              readOnly={true} />
          </FormGroup>
        </Col>
      </Fragment> : '';

    return (
      <Fragment>
        <PageTitle heading={title} icon="pe-7s-global icon-gradient bg-primary" />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <h3 className="form-heading pt-3 pb-3">
                    {t('partners.partner.title')}
                  </h3>
                  <Form onSubmit={(e) => { e.preventDefault(); this.savePartner(); }}>
                    {logoUploadFields}
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="partner.name">{t('partners.partner.name')}</Label>
                          <Input type="text" name="partner.name" id="partner.name"
                            value={this.state.partner.name} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="partner.domain">{t('partners.partner.domain')}</Label>
                          <Input type="text" name="partner.domain" id="partner.domain"
                            value={this.state.partner.domain} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="partner.registrationNumber">{t('partners.partner.registrationNumber')}</Label>
                          <Input type="text" name="partner.registrationNumber" id="partner.registrationNumber"
                            value={this.state.partner.registrationNumber} onChange={this.handleChange} disabled={!this.context.isAdmin} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="partner.address">{t('partners.partner.address')}</Label>
                          <Input type="text" name="partner.address" id="partner.address"
                            value={this.state.partner.address} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="partner.city">{t('partners.partner.city')}</Label>
                          <Input type="text" name="partner.city" id="partner.city"
                            value={this.state.partner.city} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="partner.state">{t('partners.partner.state')}</Label>
                          <Input type="text" name="partner.state" id="partner.state"
                            value={this.state.partner.state} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="partner.country">{t('partners.partner.country')}</Label>
                          <Input type="text" name="partner.country" id="partner.country"
                            value={this.state.partner.country} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="partner.postalCode">{t('partners.partner.postalCode')}</Label>
                          <Input type="text" name="partner.postalCode" id="partner.postalCode"
                            value={this.state.partner.postalCode} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      {protectedFields /* CreateOn */}
                    </Row>
                    <Row form>
                      <Col md={12}>
                        <Contacts />
                      </Col>
                    </Row>
                    <Button color="primary" onClick={this.savePartner}>{t('partners.actions.save')}</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup >
      </Fragment >
    );
  }
}

export default withRouter(withTranslation()(Partner));