import React, {Fragment} from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, CardTitle, Input, Button, Spinner, Alert, Nav, NavItem, NavLink } from 'reactstrap';

import PageTitle from '../../../Layout/Components/PageTitle';
import { withTranslation } from 'react-i18next';
import EmailTemplateModal from './EmailTemplateModal';
import EmailTemplateTable from './EmailTemplateTable';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import {getEmailTemplateList, deleteEmailTemplate} from "../../../API/PhishingAPI.js";
import {etReactTableConfiguration_ForTemplateScreen, etReactTableConfiguration_ForImportScreen} from './ETColumnGenerator'
import PhishingDataTable from '../PhishingDataTable'

class EmailTemplate extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      openEdit: false,
      emailTemplateList:[],
      isLoaded: false,
      emailTemplate: {},
      error:false,
      errorFetchingData: "",
      activeTab: 1,
      datasets:{
        1:{
          data: null,
          dataFetchFunc: getEmailTemplateList,
          config: etReactTableConfiguration_ForTemplateScreen
        },
        2:{
          data: null,
          dataFetchFunc: () => getEmailTemplateList({domain: "0.template"}),
          config: etReactTableConfiguration_ForImportScreen
        }
      }
    }

    this.loadEmailTemplateList = this.loadEmailTemplateList.bind(this);
    this.onSubmitCallback = this.onSubmitCallback.bind(this);
    this.deleteEmailTemplate = this.deleteEmailTemplate.bind(this);
    this.addPolicyCallback = this.addPolicyCallback.bind(this);
    this.onEditOrCopyCallback = this.onEditOrCopyCallback.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.setDataForActiveTab = this.setDataForActiveTab.bind(this);
  }

  componentDidMount(){
    this.loadEmailTemplateList();
  }

  onSubmitCallback(){
    this.loadEmailTemplateList();
  }

  onEditOrCopyCallback(emailTemplate, copiedVersion){
    this.setState({
      openEdit: true,
      emailTemplate: emailTemplate,
      copiedVersion: copiedVersion
    })
  }

  loadEmailTemplateList(){
    this.setState({
      isLoaded: false
    });
    // TODO(Refactor): Date format should be handled in it's own method
    const {datasets, activeTab} = this.state;
    datasets[activeTab].dataFetchFunc()
      .then(res => {
        res.forEach((item, i) => {
          item.modified_date = (new Date(item.modified_date)).toLocaleString(undefined, {dateStyle:"full", timeStyle:"medium"})
        });
        this.setDataForActiveTab(res);
        this.setState(updater => ({
          emailTemplateList : res,
          isLoaded : true
        }));
    }).catch(error => {
      console.log("Error while fetching data for Email template",error);
      this.setState({
        errorFetchingData: true,
        error: "Error fetching data"
      })
    });
  }

  deleteEmailTemplate(e){
    const id = e._original.id;
    deleteEmailTemplate(id)
      .then(res => {
        this.setDataForActiveTab();
        this.toggleTab(1);
      }).catch(error => {
        toast["error"](this.props.t("deleting", { element: 'Email Template', message: error.message }));
      })
  }
  addPolicyCallback(){
    const {activeTab} = this.state;
    this.setState(updater => ({
      openEdit: false,
      emailTemplate: {}
    }), () => {
      this.setDataForActiveTab();
      this.toggleTab(1);
    });
  }

  setDataForActiveTab(data){
    const {activeTab} = this.state;
    this.setState(updater => ({
      datasets: {
        ...updater.datasets,
        [activeTab]: {
          ...updater.datasets[activeTab],
          data: data
        }
      }
    }))
  }
  render(){
    const { t, i18n } = this.props;
    const editEmailTemplate = this.state.openEdit &&
      <EmailTemplateModal open={true} showOpenButton={false} onClose={this.addPolicyCallback}
      emailTemplate={this.state.emailTemplate} copiedVersion={this.state.copiedVersion} onSubmit={this.onSubmitCallback}/>;

    const{datasets, activeTab} = this.state;
    const activeData = datasets[activeTab].data;
    const activeConfig = datasets[activeTab].config;
    console.log("the active config is", activeConfig);
    return (
      <Fragment>
        <PageTitle
          heading={t("email_template.title")}
          icon="pe-7s-note icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col>
              <Card className="main-card mb-3">
                <CardBody>
                <EmailTemplateModal onSubmit={this.onSubmitCallback} onClose={this.addPolicyCallback} onSubmit={this.onSubmitCallback} />
                  {editEmailTemplate}
                  {this.state.errorFetchingData ? <Alert color="danger">
                  {this.state.error}
                  </Alert>:<div className="mt-3">
                  <Nav tabs>
                    <NavItem>
                      <NavLink className={classnames({ active: this.state.activeTab == '1' })} onClick={() => this.toggleTab('1')}>My Templates</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: this.state.activeTab == '2' })} onClick={() => this.toggleTab('2')}>Other Templates</NavLink>
                    </NavItem>
                  </Nav>
                  {this.state.isLoaded?
                    <PhishingDataTable key={activeTab} config={activeConfig}  data={activeData} onEditOrCopy={this.onEditOrCopyCallback} onDelete={this.deleteEmailTemplate}/>
                    :<div><Spinner color="primary" /> Data is loading!!!</div>}
                  </div>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
  toggleTab(tabActivatedNum){
    this.setState({
      activeTab: tabActivatedNum
    }, () => {
      const {datasets, activeTab} = this.state;
      if(!datasets[activeTab].data){
        this.loadEmailTemplateList();
      }
    })
  }
}
export default withTranslation()(EmailTemplate);
