import React from "react";
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody
} from 'reactstrap';
import ReactTable from "react-table";

import PaymentModal from "./PaymentModal";
import { PaymentsContext } from "./PaymentsContext";

class PaymentsTable extends React.Component {

  static contextType = PaymentsContext;

  constructor(props) {
    super(props);

    this.state = {
      openModal: false
    };
  }

  render() {
    const { t, i18n } = this.props;

    const paymentModal = this.state.openModal &&
      <PaymentModal showOpenButton={false} open={true}
        onClose={() => this.setState({ openModal: false })} />;

    return (
      <Card className="main-card mb-3">
        <CardBody>
          {paymentModal}
          <ReactTable
            columns={[
              {
                Header: t("payments.domain"),
                accessor: 'domain',
                show: this.context.isAdmin
              },
              {
                Header: t("payments.email"),
                accessor: 'email',
                show: this.context.isAdmin
              },
              {
                Header: t("payments.billed_amount"),
                accessor: 'billed_amount',
                Cell: (prop) => {
                  return (
                    <div className="d-block w-100 text-right">
                      {parseFloat(prop.row.billed_amount).toFixed(2)}
                    </div>
                  )
                }
              },
              {
                Header: t("payments.quantity"),
                accessor: 'quantity',
                Cell: (prop) => {
                  return (
                    <div className="d-block w-100 text-right">
                      {prop.row.quantity}
                    </div>
                  )
                }
              },
              {
                Header: t("payments.payment_date"),
                accessor: 'paymentDate',
                Cell: (prop) => {
                  let paymentDate = '';
                  if (prop.row.paymentDate) {
                    paymentDate = new Date(prop.row.paymentDate).toLocaleDateString();
                  }
                  return (
                    <div className="d-block w-100 text-center">
                      {paymentDate}
                    </div>
                  )
                }
              },
              {
                Header: t("payments.start_date"),
                accessor: 'startDate',
                Cell: (prop) => {
                  let startDate = '';
                  if (prop.row.startDate) {
                    startDate = new Date(prop.row.startDate).toLocaleDateString();
                  }
                  return (
                    <div className="d-block w-100 text-center">
                      {startDate}
                    </div>
                  )
                }
              },
              {
                Header: t("payments.expire_date"),
                accessor: 'expireDate',
                Cell: (prop) => {
                  let expireDate = '';
                  if (prop.row.expireDate) {
                    expireDate = new Date(prop.row.expireDate).toLocaleDateString();
                  }
                  return (
                    <div className="d-block w-100 text-center">
                      {expireDate}
                    </div>
                  )
                }
              },
              {
                Header: t("payments.type"),
                accessor: 'type'
              },
              {
                Header: t("payments.status"),
                accessor: 'status',
                show: this.props.showStatus
              }
            ]}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: (e) => {
                    if (e.target.tagName.toLowerCase() === 'button') {
                      return; // Ignore clicks on buttons
                    }
                    if (this.props.showDetail) {
                      this.context.openPayment(rowInfo.original);
                      this.setState({ openModal: true });
                    }
                  },
                  style: {
                    cursor: 'pointer'
                  }
                }
              }
              return {}
            }}
            data={this.context.payments}
            defaultPageSize={this.props.pageSize}
            filterable={false}
            defaultSorted={[
              {
                id: "paymentDate",
                desc: true
              }
            ]}
            className="-striped -highlight" />
        </CardBody>
      </Card>
    )
  }
}

PaymentsTable.defaultProps = {
  pageSize: 10,
  showDetail: false,
  showStatus: false
}

export default withTranslation()(PaymentsTable);
