import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Form, Input, Label
} from 'reactstrap';
import { toast } from 'react-toastify';
import { AuthContext } from 'context/AuthContext';
import UserLoginAPI from 'API/UserLoginAPI';

class ResetPassword extends React.Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            code: "",
            password: "",
            confirmPassword: "",
            loading: true,
            success: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.doReset = this.doReset.bind(this);
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        if (params.has('email_id') && params.has('code')) {
            this.setState({
                email: params.get('email_id'),
                code: params.get('code'),
                loading: false
            })
        } else {
            toast["error"](this.props.t('password.error'));
            this.setState({ loading: false });
        }
    }

    handleChange(event) {
        const key = event.target.name;
        const value = event.target.value;
        this.setState({
            [key]: value
        });
    }

    doReset() {
        const { email, code, password, confirmPassword } = this.state;
        if (email !== "" && code !== "" && password !== "" && password === confirmPassword) {
            this.setState({ loading: true });
            UserLoginAPI.resetPassword(email, code, password).then(result => {
                if (result) {
                    toast["success"](this.props.t('password.success'));
                    this.setState({ success: true });
                }
            }).catch(error => {
                console.error("Error on password reset", error);
                toast["error"](this.props.t('password.error'));
            });
        } else {
            toast["error"](this.props.t('password.error'));
        }
    }

    render() {
        const { t, i18n } = this.props;

        if (this.context.error) {
            toast["error"](this.context.error);
        }

        const loginLink = this.state.success
            ? <a href="/" className="link-forgot">{t('password.success')}</a>
            : <Input type='submit' color="primary" size="lg"
                onClick={this.doReset} disabled={this.state.loading}
                value={t("password.actions.reset")} />;

        return (
            <Fragment>
                <div className="contact-us">
                    <section id="banner-default-login" data-aos="zoom-out"
                        className="banner-default-pages d-flex flex-column justify-content-center align-items-center banner-login">
                    </section>

                    <main id="main">
                        <section className="login">
                            <div className="container">
                                <div className="row">
                                    <div className="col"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12 mt-4 mt-lg-0"
                                        data-aos="fade-up" data-aos-delay="300">
                                        <div className="box-input">
                                            <div className="row">
                                                <div className="col"></div>
                                                <div className="col-6 mb-4">
                                                    <a href="#">
                                                        <img src="/imgs/logo.png" alt="" className="img-fluid" />
                                                    </a>
                                                </div>
                                                <div className="col"></div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 text-center pt-4">
                                                    <p>{t("password.text_reset")}</p>
                                                </div>
                                                <div className="col-12">
                                                    <Form onSubmit={(e) => { e.preventDefault(); }} autoComplete="off">
                                                        <fieldset className="mt-0">
                                                            <Label for="password">{t('register.password')}</Label>
                                                            <Input type="password" name="password" id="password"
                                                                placeholder={t('register.password_placeholder')}
                                                                value={this.state.password} onChange={this.handleChange} />
                                                        </fieldset>
                                                        <fieldset className="mt-0">
                                                            <Label for="password">{t('register.confirm_password')}</Label>
                                                            <Input type="password" name="confirmPassword" id="confirmPassword"
                                                                placeholder={t('register.confirm_password_placeholder')}
                                                                value={this.state.confirmPassword} onChange={this.handleChange} />
                                                        </fieldset>
                                                        <fieldset>
                                                            {loginLink}
                                                        </fieldset>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>

                    <section className="text-center">
                        {t("login.copyright")}
                    </section>
                </div>
            </Fragment>
        )
    }
};

export default withTranslation()(ResetPassword);