/******** Class to handle user session ********/
export default class User {

  // Providers
  static MICROSOFT = "Microsoft";
  static GOOGLE = "Google";
  static VERITAS = "Veritas";

  constructor(name, email, tenant, roles, provider, phishingAPIKey) {
    this.name = name;
    this.email = email;
    this.tenant = tenant;
    this.roles = roles;
    this.domain = email.indexOf('@') >= 0 ? email.split('@')[1] : "";
    this.provider = provider;
    this.phishingAPIKey = phishingAPIKey;
  }

  static setUserInfo(user) {
    sessionStorage.setItem('userInfo', JSON.stringify(user));
  }

  static getUserInfo() {
    const user = sessionStorage.getItem('userInfo');
    return user ? JSON.parse(user) : null;
  }

  static clearUserInfo() {
    sessionStorage.removeItem('userInfo');
  }
}
