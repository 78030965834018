import React, { Fragment } from "react";
import { Bar } from 'react-chartjs-2';

class BarsGraph extends React.Component {

  render() {
    return (
      <Fragment>
        <Bar
          data={this.props.data}
          options={{
            maintainAspectRatio: false,
            scales: {
              xAxes: [{
                stacked: true
              }],
              yAxes: [{
                stacked: true
              }]
            }
          }}
        />
      </Fragment>
    )
  }
}

export default BarsGraph;