import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody } from 'reactstrap';
import ReactTable from "react-table";
import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";

import PageTitle from '../../Layout/Components/PageTitle';
import ActionsMenu from './ActionsMenu';

import { getOrganizations, getOrganizationsAll, searchOrganizations } from '../../API/OrganizationsAPI';

class Organizations extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isPartner: props.location.pathname.startsWith('/partner'),
      filter: "",
      organizations: [],
      totalPages: 1,
      pageSize: 10,
      page: 0,
      filtered: [],
      filterAll: ""
    };

    this.fillTable = this.fillTable.bind(this);
    this.searchOrganization = this.searchOrganization.bind(this);
    this.addOrganizationCallback = this.addOrganizationCallback.bind(this);
  }

  componentDidMount() {
    this.fillTable(1);
  }

  async fillTable(page) {
    try {
      if (this.state.isPartner) {
        let organizations = await getOrganizationsAll();
        this.setState({
          organizations: organizations,
          totalPages: 1,
          pageSize: 10,
          page: 0
        });
      } else {
        let result = await getOrganizations(page);
        // let organizations = result.content.map(value => {
        //   const organization = value.organizationEntity;
        //   organization.license = value.organizationLicense;
        //   return organization;
        // });
        this.setState({
          organizations: result.content,
          totalPages: result.totalPages,
          pageSize: result.pageable.pageSize,
          page: result.pageable.pageNumber
        });
      }
    } catch (error) {
      console.error("Error listing Organizations", error);
      toast["error"](this.props.t('errors.loading', { element: 'Organizations', message: error.message }));
    }
  }

  async searchOrganization(name) {
    if (!name) {
      return this.fillTable(1);
    }
    try {
      let result = await searchOrganizations(name);
      this.setState({
        filter: name,
        organizations: result.content,
        totalPages: 1,
        pageSize: result.pageable.pageSize,
        page: 0
      });
    } catch (error) {
      console.error("Error searching Organizations", error);
      toast["error"](this.props.t('errors.loading', { element: 'Organizations', message: error.message }));
    }
  }

  addOrganizationCallback() {
    this.fillTable(1);
    this.setState({ filter:"" });
  }

  render() {
    const { t, i18n, history, location } = this.props;

    const source = location.pathname.split("/")[1];

    return (
      <Fragment>
        <PageTitle
          heading={t("organizations.title")}
          icon="pe-7s-cloud icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <ActionsMenu addOrganizationCallback={this.addOrganizationCallback} doSearch={this.searchOrganization} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    columns={[
                      {
                        Header: t("organizations.organization.title"),
                        columns: [
                          {
                            Header: t("organizations.organization.name"),
                            accessor: 'name',
                            id: 'name'
                          },
                          {
                            Header: t("organizations.organization.domain"),
                            accessor: 'domain',
                            id: 'domain'
                          },
                          {
                            Header: t("organizations.organization.address"),
                            accessor: 'address'
                          }
                        ]
                      },
                      {
                        Header: t("organizations.contact.title"),
                        columns: [
                          {
                            Header: t("organizations.contact.name"),
                            accessor: 'contact[0].name'
                          },
                          {
                            Header: t("organizations.contact.address"),
                            accessor: 'contact[0].address'
                          },
                          {
                            Header: t("organizations.contact.phone"),
                            accessor: 'contact[0].phone'
                          }
                        ]
                      },
                      // {
                      //   Header: t("organizations.license.title"),
                      //   columns: [
                      //     {
                      //       Header: t("organizations.license.number"),
                      //       accessor: 'license.number'
                      //     },
                      //     {
                      //       Header: t("organizations.license.status"),
                      //       accessor: 'license.active',
                      //       Cell: ({ column, cell, row }) => (
                      //         <span>
                      //           {row['license.active'] ?
                      //             t("organizations.license.active") :
                      //             t("organizations.license.disabled")}
                      //         </span>
                      //       )
                      //     }
                      //   ]
                      // }
                    ]}
                    data={this.state.organizations}
                    pages={this.state.totalPages}
                    pageSize={this.state.pageSize}
                    page={this.state.page}
                    manual={true}
                    showPageSizeOptions={false}
                    defaultPageSize={10}
                    onPageChange={page => this.fillTable(page + 1) }
                    className="-striped -highlight"
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            if (e.target.tagName.toLowerCase() === 'button') {
                              return; // Ignore clicks on buttons
                            }
                            history.push(`/${source}/organization/${rowInfo.original.domain}`)
                          },
                          style: {
                            cursor: 'pointer'
                          }
                        }
                      }
                      return {}
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment >
    )
  }
}

export default withRouter(withTranslation()(Organizations))