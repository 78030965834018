import React, { Fragment } from "react";
import {
  Row, Col, Card, CardBody, CardTitle,
  Label, Button
} from 'reactstrap';
import BootstrapTable from "react-bootstrap-table-next";
import { withTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { IoMdDownload } from 'react-icons/io';
import { downloadFile } from "Application/Helpers/FileHelper";

class MessageAttachments extends React.Component {

  render() {
    const { t, i18n } = this.props;

    const { emailAttachments } = this.props;

    const attachments = emailAttachments.filter(attachment =>
      attachment.contentDisposition === "attachment"
    ).map(attachment => {
      console.log(attachment);
      return {
        content: attachment.content,
        type: attachment.contentType,
        name: attachment.filename,
        size: attachment.size
      }
    });

    return (
      <Card className="main-card mb-3">
        <CardBody>
          <CardTitle>{t("incidents.attachments")}</CardTitle>
          <BootstrapTable
            bootstrap4
            keyField="name"
            data={attachments}
            bordered={false}
            columns={[
              {
                dataField: 'name',
                text: t("incidents.attachment.name")
              },
              {
                dataField: 'type',
                text: t("incidents.attachment.type")
              },
              {
                dataField: 'size',
                text: t("incidents.attachment.size")
              },
              {
                dataField: 'content',
                text: t("incidents.attachment.download"),
                formatter: (cell, row, index) => {
                  return (
                    <div>
                      <div className="d-block w-100 text-center">
                        <Button className="mb-2 mr-2 btn-icon btn-icon-only" color="success"
                          onClick={() => downloadFile(row.content, row.name, row.type)}>
                          <IoMdDownload />
                        </Button>
                      </div>
                    </div>
                  );
                }
              }
            ]}
          />
        </CardBody>
      </Card>
    )
  }
}

export default withTranslation()(MessageAttachments);
