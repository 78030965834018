import React,{Fragment} from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import PageTitle from '../../../Layout/Components/PageTitle';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import SimulationProgressTimelineBar from './SimulationProgressTimelineBar';
import {
  Row, Col, Card, CardBody, Button, Spinner
} from 'reactstrap';
import { toast } from 'react-toastify';
import PhishingDataTable from '../PhishingDataTable';
// import ModelForConfirmingChoice from '../ModelForConfirmingChoice'
import SimulationsLaunchScreenForm from './SimulationsLaunchScreenForm'
import etReactTableConfiguration from '../EmailTemplate/ETColumnGenerator'
import lpReactTableConfiguration from '../LandingPage/LPColumnGenerator'
import ugReactTableConfiguration from '../UsersAndGroups/UGColumnGenerator'
import {getEmailTemplateList, getlandingpages, getGroupList, createPhishingCampaign} from "../../../API/PhishingAPI";

class Simulations extends React.Component{
  constructor(props){
    super(props);
    console.log("Simulations constructor is called");
    this.state = {
      campaign:{},
      datasets: {
        0: {
          config: etReactTableConfiguration,
          data: null,
          dataFetchFunc: getEmailTemplateList,
          selectedValue: "",
        },
        1: {
          config: lpReactTableConfiguration,
          data: null,
          dataFetchFunc: getlandingpages,
          selectedValue:""
        },
        2:{
          config: ugReactTableConfiguration,
          data: null,
          dataFetchFunc: getGroupList,
          selectedValue:""
        }
      },
      timelineProgressData: [
        {
          name: 'Phishing Email',
          active: true
        },
        {
          name: 'Landing Page',
          active: false
        },
        {
          name: 'Users',
          active: false
        },
        {
          name: 'Launch',
          active: false
        }
      ],
      screenNum: 0,
      totalNumberOfScreens: 4,
      isLoaded:false,
      errorMessage: "",
      errorOccured: false
    }
    this.fetchDataForCurrentScreen = this.fetchDataForCurrentScreen.bind(this);
    this.IncrScreenNum = this.IncrScreenNum.bind(this);
    this.decrScreenNum = this.decrScreenNum.bind(this);
    this.checkIfThereIsNeedToUpdateData = this.checkIfThereIsNeedToUpdateData.bind(this);
    this.activateScreenInTheTimeline = this.activateScreenInTheTimeline.bind(this);
    this.deActivateScreenInTheTimeline = this.deActivateScreenInTheTimeline.bind(this);
    this.onSelectCallback = this.onSelectCallback.bind(this);
    this.launchSimulation = this.launchSimulation.bind(this);
    this.addKeyToCampaignDataFromDatasetSelectedValue = this.addKeyToCampaignDataFromDatasetSelectedValue.bind(this);
    this.isValueSelectedForCurrentScreen = this.isValueSelectedForCurrentScreen.bind(this);
  }
  componentDidMount(){
    this.fetchDataForCurrentScreen();
    console.log(this.props);
  }
  checkIfThereIsNeedToUpdateData(){
    const {datasets, screenNum} = this.state;
    let needToUpdateData = false;
    if(!datasets[screenNum]){
      return needToUpdateData;
    }
    const dataForScreen = datasets[screenNum].data;
    if(dataForScreen == undefined || dataForScreen==null){
      needToUpdateData = true;
      return needToUpdateData;
    }
    return false;
  }
  fetchDataForCurrentScreen(){
    console.log("Screen Number is", this.state.screenNum);
    const {datasets, screenNum} = this.state;
    const dataFetchFunc = datasets[screenNum].dataFetchFunc;
    this.setState({
      isLoaded: false,
      errorMessage: "",
      errorOccured: false
    })
    dataFetchFunc()
      .then(res => {
        res.forEach((item, i) => {
          item.modified_date = (new Date(item.modified_date)).toLocaleString(undefined, {dateStyle:"full", timeStyle:"medium"})
        });
        this.setState(updater => ({
          datasets: {
            ...updater.datasets,
            [screenNum] : {
              ...updater.datasets[screenNum],
              data: res
            }
          },
          error: false
        }), () => console.log(this.state.datasets));
    }).catch(error => {
      console.log("Error while fetching data",error);
      this.setState({
        errorOccured: true,
        errorMessage: "Error fetching data"
      })
    }).finally(() => {
      this.setState({
        isLoaded: true
      })
    });
  }
  IncrScreenNum(){
    const {screenNum, totalNumberOfScreens} = this.state;
    if(!this.isValueSelectedForCurrentScreen()){
      toast["error"]("Select one item from the table");
      return;
    }
    if(screenNum < totalNumberOfScreens-1){
      this.setState({
        screenNum: screenNum+1,
      }, () => {
        this.activateScreenInTheTimeline();
        if(this.checkIfThereIsNeedToUpdateData()){
          this.fetchDataForCurrentScreen()
        }
      })
    }
  }
  decrScreenNum(){
    const {screenNum, totalNumberOfScreens} = this.state;
    if(screenNum >= 1){
      this.setState({
        screenNum: screenNum-1,
      }, () => {
        this.deActivateScreenInTheTimeline();
        if(this.checkIfThereIsNeedToUpdateData()){
          this.fetchDataForCurrentScreen()
        }
      })
    }
  }
  activateScreenInTheTimeline(){
    const {timelineProgressData, screenNum} = this.state;
    timelineProgressData[screenNum].active = true;
    this.setState({
      timelineProgressData: timelineProgressData
    }, () => console.log("Activated screenNum is", timelineProgressData))
  }
  deActivateScreenInTheTimeline(){
    const {timelineProgressData, screenNum} = this.state;
    timelineProgressData[screenNum+1].active = false;
    this.setState({
      timelineProgressData: timelineProgressData
    })
  }
  onSelectCallback(selection){
    const {datasets, screenNum} = this.state;
    datasets[screenNum].selectedValue = selection.name;
    this.setState({
      datasets: datasets
    },() => console.log(datasets))
  }
  addKeyToCampaignDataFromDatasetSelectedValue(campaign, key, value){
    let nameValuePair = {name: value}
    if(key == "groups"){
      nameValuePair = [nameValuePair];
    }
    return {...campaign, [key]: nameValuePair}
  }
  launchSimulation(campaign){
    const {datasets} = this.state;
    campaign = this.addKeyToCampaignDataFromDatasetSelectedValue(campaign, "template", datasets[0].selectedValue);
    campaign = this.addKeyToCampaignDataFromDatasetSelectedValue(campaign, "page", datasets[1].selectedValue);
    campaign = this.addKeyToCampaignDataFromDatasetSelectedValue(campaign, "groups", datasets[2].selectedValue);

    this.setState({
      campaign: campaign
    }, () => {
      console.log("After updating",this.state.campaign);
      const promise = createPhishingCampaign(this.state.campaign);
      promise.then(result => {
        this.props.history.push('/organization/phishing_simulator/phishing_campaign');
      }).catch(error => {
        const messageKey = this.state.isEdit ? 'errors.updating' : 'errors.creating';
        toast["error"](this.props.t(messageKey, { element: 'Campaign', message: error.message }));
      });
    })
  }
  isValueSelectedForCurrentScreen(){
    const {screenNum, datasets} = this.state;
    if(!datasets[screenNum].selectedValue){
      return false;
    }
    return true;
  }
  render(){

    const { t, i18n } = this.props;
    const {datasets} = this.state;
    const currentDataset = datasets[this.state.screenNum];
    const mainScreen = this.state.screenNum == this.state.totalNumberOfScreens-1?
      <SimulationsLaunchScreenForm key="_screenlaunchform_" onLaunch={this.launchSimulation}/>
      :<PhishingDataTable key={this.state.screenNum} config={currentDataset.config}
      data={currentDataset.data} onSelect={this.onSelectCallback} selectedValue={currentDataset.selectedValue}/>;
    return(
      <Fragment>
        <PageTitle
          heading="New Simulation"
          icon="pe-7s-helm icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <SimulationProgressTimelineBar timelineProgressData={this.state.timelineProgressData}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Card className="main-card mb-3">
                <CardBody>
                  {this.state.isLoaded?
                  mainScreen
                  :<div><Spinner color="primary" /> Data is loading!!!</div>}
                  <Row className="mt-2">
                    <Col md={{ size: 3, offset: 4 }}>
                      {this.state.screenNum != 0 && <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
                        onClick={() => this.decrScreenNum()} style={{"width":"160px"}}>
                        <i className="lnr-pointer-left btn-icon-wrapper"> </i>
                          Previous Step
                      </Button>}
                    </Col>
                    <Col md="3">
                      {this.state.screenNum < this.state.totalNumberOfScreens-1 && <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
                        onClick={() => this.IncrScreenNum()} style={{"width":"160px"}} >
                        Next Step{' '}
                        <i className="lnr-pointer-right btn-icon-wrapper"></i>
                      </Button>}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}
export default withTranslation()(Simulations)
