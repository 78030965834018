import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
  Row, Col, Card, CardBody,
} from 'reactstrap';
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';

import PageTitle from 'Layout/Components/PageTitle';
import ConfirmAction from "Layout/Components/ConfirmAction";
import AdminModal from "./AdminModal";

import { getAdmin, deleteAdmin } from "../../API/AdminAPI";
import { toast } from "react-toastify";

class Admins extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      admins: [],
      admin: {},
      openEdit: false
    };

    this.loadAdmins = this.loadAdmins.bind(this);
    this.deleteAdmin = this.deleteAdmin.bind(this);
    this.onSubmitCallback = this.onSubmitCallback.bind(this);
  }

  componentDidMount() {
    this.loadAdmins();
  }

  loadAdmins() {
    getAdmin().then(result => {
      this.setState({
        admins: result
      })
    }).catch(error => {
      console.error("Error getting Admin", error);
      toast["error"](this.props.t('errors.loading', { element: 'Admin', message: error.message }));
    });
  }

  deleteAdmin(email) {
    deleteAdmin(email).then(result => {
      this.loadAdmins();
    }).catch(error => {
      console.error("Error deleting admin", error);
      toast["error"](this.props.t('errors.deleting', { element: 'Admin', message: error.message }));
    });
  }

  onSubmitCallback() {
    this.loadAdmins();
  }

  render() {
    const { t, i18n } = this.props;

    const editAdminModal = this.state.openEdit &&
      <AdminModal showOpenButton={false} open={true} admin={this.state.admin}
        onClose={() => this.setState({ openEdit: false })}
        onSubmitCallback={this.onSubmitCallback} />;

    return (
      <Fragment>
        <PageTitle
          heading={t("admins.title")}
          icon="pe-7s-door-lock icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <AdminModal onSubmitCallback={this.onSubmitCallback} />
                  {editAdminModal}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    columns={[
                      {
                        Header: t("admins.email"),
                        accessor: 'email',
                        id: 'email'
                      },
                      {
                        Header: t("admins.active"),
                        accessor: 'active',
                        Cell: ({ column, cell, row }) => (
                          <span>
                            {row['active'] ?
                              t("admins.active") :
                              t("admins.disabled")}
                          </span>
                        )
                      },
                      {
                        Header: t("admins.actions.title"),
                        id: 'actions',
                        accessor: 'email',
                        width: 300,
                        Cell: (prop) => (
                          <div className="d-block w-100 text-center">
                            <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                              message={t('messages.confirm_delete', { element: 'admin', object: prop.value })}
                              onConfirm={() => this.deleteAdmin(prop.value)}>{t("admins.actions.delete")}</ConfirmAction>
                          </div>)
                      }
                    ]}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            if (e.target.tagName.toLowerCase() === 'button') {
                              return; // Ignore clicks on buttons
                            }
                            this.setState({
                              openEdit: true,
                              admin: rowInfo.original
                            })
                          },
                          style: {
                            cursor: 'pointer'
                          }
                        }
                      }
                      return {}
                    }}
                    data={this.state.admins}
                    defaultPageSize={10}
                    className="-striped -highlight" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withTranslation()(Admins);