import React from "react";
import PaymentsProvider from './PaymentsContext';
import Payments from './Payments';
import PaymentsModal from './PaymentsModal'
import PaypalBilling from "./PaypalBilling";

function withContext(Component) {
  class WithContext extends React.Component {
    render() {
      return (
        <PaymentsProvider domain={this.props.domain} email={this.props.email}>
          <Component />
        </PaymentsProvider>
      );
    }
  }
  return WithContext;
}

const PaymentsReport = withContext(Payments);
const PaymentsAdminModal = withContext(PaymentsModal);
const Billing = withContext(PaypalBilling);

export {
  PaymentsReport,
  PaymentsAdminModal,
  Billing
};
