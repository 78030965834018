import React from "react";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';

import { getUsers } from 'API/UsersAPI';
import {
  deleteAccountBlacklist,
  getAccountBlacklist
} from 'API/BlacklistAPI';

export const AccountContext = React.createContext();
export const AccountConsumer = AccountContext.Consumer;

class AccountProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      account: {},
      blacklist: []
    }

    this.isEdit = this.isEdit.bind(this);
    this.getAccounts = this.getAccounts.bind(this);
    this.openAccount = this.openAccount.bind(this);
    this.getBlacklists = this.getBlacklists.bind(this);
    this.deleteBlacklist = this.deleteBlacklist.bind(this);
  }

  componentDidMount() {
    this.getAccounts();
  }

  isEdit() {
    return this.state.account.id ? true : false;
  }

  getAccounts() {
    getUsers().then(result => {
      this.setState({
        accounts: result
      });
    }).catch(error => {
      console.error("Error getting Accounts", error);
      toast["error"](this.props.t('errors.loading', { element: 'Accounts', message: error.message }));
    });
  }

  openAccount(account = {}) {
    if (account){
      this.setState({
        account: account
      });
      this.getBlacklists(account.email);
    } else {
      this.setState({
        account: {},
        blacklist: []
      });
    }
  }

  getBlacklists(account) {
    getAccountBlacklist(account).then(result => {
      this.setState({
        blacklist: result
      });
    }).catch(error => {
      console.error("Error getting Blacklist", error);
      toast["error"](this.props.t('errors.loading', { element: 'Blacklist', message: error.message }));
    });
  }

  deleteBlacklist(email) {
    const { account } = this.state;
    deleteAccountBlacklist(account.email, email).then(_result => {
      toast["success"](this.props.t('messages.success_delete', { element: 'blacklist' }));
      this.getBlacklists(account.email);
    }).catch(error => {
      console.error("Error deleting Blacklist", error);
      toast["error"](this.props.t('errors.deleting', { element: 'blacklist', message: error.message }));
    })
  }

  render() {
    return (
      <AccountContext.Provider value={
        {
          accounts: this.state.accounts,
          account: this.state.account,
          blacklist: this.state.blacklist,
          isEdit: this.isEdit,
          getAccounts: this.getAccounts,
          openAccount: this.openAccount,
          deleteBlacklist: this.deleteBlacklist
        }
      }>
        {this.props.children}
      </AccountContext.Provider>
    )
  }
}

export default withTranslation()(AccountProvider)
