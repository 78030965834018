import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
  Row, Col, Card, CardBody, Label, Input
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

import PageTitle from '../../Layout/Components/PageTitle';

import { PaymentsContext } from "./PaymentsContext";
import PaypalButton from "./PaypalButton";

class PaypalBilling extends React.Component {

  static contextType = PaymentsContext;

  componentDidMount() {
    if (this.context.isAdmin) {
      this.context.loadPaymentsAdminReport(this.state.status);
    } else {
      this.context.loadPayments();
    }
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("payments.billing")}
          icon="pe-7s-cash icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <PaypalButton />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withTranslation()(PaypalBilling);