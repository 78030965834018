import React, { Fragment } from 'react';
import {
  Col, FormGroup, Label, Input, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { DropdownList, Multiselect } from 'react-widgets';

class MessageInfo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      value: [],
      keywords: [],
      day_of_week: ['', 'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleCreateKeyword = this.handleCreateKeyword.bind(this);
    this.handleChangeKeywords = this.handleChangeKeywords.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.props.MessageInfo.Keywords !== this.state.keywordsData) {
    //   this.setState({
    //     value: this.props.MessageInfo.Keywords,
    //     keywords: this.props.MessageInfo.Keywords
    //   })
    // }
  }

  handleChange(event) {
    const obj = event.target || event;
    if (typeof this.props.onHandleChange === 'function') {
      this.props.onHandleChange({
        name: "MessageInfo." + obj.name,
        value: obj.value
      })
    }
  }

  handleCreateKeyword(keyword) {
    const { keywords, value } = this.state;
    this.setState({
      value: [...value, keyword],
      keywords: [...keywords, keyword]
    })
    this.handleChange({ name: "Keywords", value: [...value, keyword] })
  }

  handleChangeKeywords(value) {
    this.setState({ value })
    this.handleChange({ name: "Keywords", value: value })
  }

  render() {
    const { t, i18n } = this.props;

    const nCols = 6;

    return (
      <Fragment>
        <Row form>
          <Col md={nCols}>
            <FormGroup>
              <Label for="Subject">{t('campaign.message-info.subject')}</Label>
              <Input type="text" name="Subject" id="Subject"
                value={this.props.MessageInfo.Subject} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="To">{t('campaign.message-info.to')}</Label>
              <Input type="text" name="To" id="To"
                value={this.props.MessageInfo.To} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="Department">{t('campaign.message-info.department')}</Label>
              <Input type="text" name="Department" id="Department"
                value={this.props.MessageInfo.Department} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="FromEmailAddress">{t('campaign.message-info.from-email')}</Label>
              <Input type="text" name="FromEmailAddress" id="FromEmailAddress"
                value={this.props.MessageInfo.FromEmailAddress} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="FromName" >{t('campaign.message-info.from-name')}</Label>
              <Input type="text" name="FromName" id="FromName"
                value={this.props.MessageInfo.FromName} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="OriginDomain" >{t('campaign.message-info.origin-domain')}</Label>
              <Input type="text" name="OriginDomain" id="OriginDomain"
                value={this.props.MessageInfo.OriginDomain} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="OriginSourceIP" >{t('campaign.message-info.origin-ip')}</Label>
              <Input type="text" name="OriginSourceIP" id="OriginSourceIP"
                value={this.props.MessageInfo.OriginSourceIP} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="OriginDate" >{t('campaign.message-info.origin-date')}</Label>
              <DatePicker id="OriginDate" className="form-control"
                selected={this.props.MessageInfo.OriginDate} showTimeInput dateFormat="yyyy-MM-dd h:mm:ss aa"
                onChange={date => this.handleChange({ name: "OriginDate", value: date })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="OriginDayOfWeek" >{t('campaign.message-info.origin-day-of-week')}</Label>
              <DropdownList
                id="OriginDayOfWeek"
                data={this.state.day_of_week}
                value={this.props.MessageInfo.OriginDayOfWeek}
                onChange={(val) => this.handleChange({ name: 'OriginDayOfWeek', value: val })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="PhpScript" >{t('campaign.message-info.php-script')}</Label>
              <Input type="text" name="PhpScript" id="PhpScript"
                value={this.props.MessageInfo.PhpScript} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="XMailer" >{t('campaign.message-info.x-mailer')}</Label>
              <Input type="text" name="XMailer" id="XMailer"
                value={this.props.MessageInfo.XMailer} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="List_unsubscribe" >{t('campaign.message-info.list-unsubscribe')}</Label>
              <Input type="text" name="List_unsubscribe" id="List_unsubscribe"
                value={this.props.MessageInfo.List_unsubscribe} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="List_id" >{t('campaign.message-info.list-id')}</Label>
              <Input type="text" name="List_id" id="List_id"
                value={this.props.MessageInfo.List_id} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="EmailLayoutText" >{t('campaign.message-info.email-layout-text')}</Label>
              <Input type="text" name="EmailLayoutText" id="EmailLayoutText"
                value={this.props.MessageInfo.EmailLayoutText} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="EmailLayoutHtml" >{t('campaign.message-info.email-layout-html')}</Label>
              <Input type="text" name="EmailLayoutHtml" id="EmailLayoutHtml"
                value={this.props.MessageInfo.EmailLayoutHtml} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="Language" >{t('campaign.message-info.language')}</Label>
              <Input type="text" name="Language" id="Language"
                value={this.props.MessageInfo.Language} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="Keywords" >{t('campaign.message-info.keywords')}</Label>
              <Multiselect
                data={this.state.keywords}
                value={this.state.value}
                allowCreate='onFilter'
                onCreate={keyword => this.handleCreateKeyword(keyword)}
                onChange={value => this.handleChangeKeywords(value)}
              />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="Text" >{t('campaign.message-info.text')}</Label>
              <Input type="text" name="Text" id="Text"
                value={this.props.MessageInfo.Text} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="MessageId" >{t('campaign.message-info.message_id')}</Label>
              <Input type="text" name="MessageId" id="MessageId"
                value={this.props.MessageInfo.MessageId} onChange={this.handleChange} />
            </FormGroup>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default withTranslation()(MessageInfo);
