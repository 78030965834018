import { doGet, doPost, doDelete, getDomain } from "./BaseAPI";

export function getDepartments() {
  return doGet(`/organization/department/domain`);
}

export function createDepartment(department) {
  return doPost(`/organization/department/create`, {
    domain: getDomain(),
    department: department
  });
}

export function deleteDepartment(department) {
  return doDelete(`/organization/department/delete/${department}`);
}
