import { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

function copyStyles(sourceDoc, targetDoc) {
    Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
      if (styleSheet.href) { // true for stylesheets loaded from a URL
        const newLinkEl = targetDoc.createElement('link');
        newLinkEl.rel = 'stylesheet';
        newLinkEl.href = styleSheet.href;
        targetDoc.head.appendChild(newLinkEl);
      } else if (styleSheet.cssRules) { // true for inline styles
        const newStyleEl = targetDoc.createElement('style');
        newStyleEl.type = styleSheet.type;
        Array.from(styleSheet.cssRules).forEach(cssRule => {
          newStyleEl.appendChild(targetDoc.createTextNode(cssRule.cssText));
        });
        targetDoc.head.appendChild(newStyleEl);
      }
    });
}

const RenderInWindow = ({ open, setOpen, title, children }) => {
    const _window = useRef(null);
    const _container = useRef(null);
    const [ready, setReady] = useState(false);
    
    useEffect(() => {
      // If open, create window and store in ref
      if (open) {
        const width = window.innerWidth - window.innerWidth / 20;
        const height = window.innerHeight - window.innerHeight / 20; 
        _window.current = window.open('', '', `width=${width},height=${height},left=0,top=0`);

        // Save reference to window for cleanup
        const currentWindow = _window.current;
        
        currentWindow.onbeforeunload = () => {
          setReady(false);
          setOpen(false);
        };

        copyStyles(window.document, currentWindow.document);
        currentWindow.document.title = title;
        
        const mainDiv = currentWindow.document.createElement('div');
        mainDiv.classList.add('app-main');
        const innerDiv = currentWindow.document.createElement('div');
        innerDiv.classList.add('app-main__inner');
        mainDiv.appendChild(innerDiv);
        currentWindow.document.body.appendChild(mainDiv);
        _container.current = innerDiv;

        setReady(true);
      } else {
        if(_window.current) {
            _window.current.close();
        }
        
        setReady(false);
      }
    }, [open, setOpen, title]);
  
    return (
      open && ready && createPortal(children, _container.current)
    );
  };

  export default RenderInWindow;