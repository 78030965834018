import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, CardTitle, Input, Button, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PostalMime from 'postal-mime';

import PageTitle from '../../Layout/Components/PageTitle';
import { ThreatHighlights, FootprintMap } from '../ThreatIntelligence/EmailDetails'
import { MessageBody, MessageHeader, MessageLinks } from ".";

import { AuthContext } from "context/AuthContext";
import { readFile } from "../Helpers/FileHelper";
import { encodeAsBase64, parseEmailLinks } from "../Helpers/TextHelper";

import { verifyEml } from '../../API/EmailAPI'
import { getGeolocation } from '../../API/ConsoleServicesAPI';
import MessageAttachments from "./MessageAttachments";
import InfoPopover from "Layout/Components/InfoPopover";

class EmailAnalysis extends React.Component {

  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      loading: false,
      decision: "",
      message: {},
      threats: [],
      incident: {},
      campaign: {},
      origin: [],
      emlDecoded: "",
      emailHeaders: new Map(),
      emailBody: "",
      emailAttachments: [],
      emailLinks: []
    }

    this.upload = this.upload.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }

  upload() {
    document.getElementById('file').click();
  }

  async handleFile(event) {
    this.setState({
      loading: true
    });

    // Read the eml file
    const content = await readFile(event.target.files[0]).catch(error => {
      console.error("Error reading eml file", error);
      toast["error"](this.props.t('errors.file_read_error'));
    });
    if (!content) {
      this.setState({
        loading: false
      });
      return;
    }
    
    const encodedContent = encodeAsBase64(content);
    if (!encodedContent) {
      toast["error"](this.props.t('threat_hunting.email_analysis.messages.file_read_error'));
      this.setState({
        loading: false
      });
      return;
    }

    // Upload the eml file as base64
    const result = await verifyEml(this.context.user.email, encodedContent).catch(error => {
      console.error("Error uploading eml file", error);
      toast["error"](this.props.t('threat_hunting.email_analysis.messages.file_upload_error'));
    });
    if (!result) {
      this.setState({
        loading: false
      });
      return;
    }

    // Fill the page content
    this.setState({
      loaded: true,
      loading: false,
      decision: result.decision,
      message: result.messageDetails,
      threats: result.threats,
      incident: result,
      campaign: {},
      emlDecoded: content
    });

    // Parse the eml file
    const parser = new PostalMime();
    parser.parse(content).then(result => {
      const body =  result.html ? result.html : result.text;
      if (!body) {
        toast["warning"](this.props.t('threat_hunting.email_analysis.messages.parse_email_body'));
      }
      this.setState({
        emailHeaders: new Map(result.headers.map((obj) => [obj.key, obj.value])),
        emailBody: body || "",
        emailAttachments: result.attachments
      });
      parseEmailLinks(result.html).then(links => {
        this.setState({
          emailLinks: links
        });
      })
    }).catch(err => {
      console.error("Error parsing email", err);
    });

    // Get origin geolocation
    if (result.messageDetails.originSourceIP) {
      getGeolocation([result.messageDetails.originSourceIP]).then(result => {
        this.setState({
          origin: result.geoips[0]
        })
      }).catch(error => {
        console.error("Error getting Origin Geolocation", error);
        toast["error"](this.props.t('errors.loading', { element: 'Origin Geolocation', message: error.message }));
      });
    }
  }

  render() {
    const { t, i18n } = this.props;

    const { loaded, loading, decision, message, threats, incident, origin, campaign, file,
      emailHeaders, emailLinks, emailBody, emailAttachments, emlDecoded } = this.state;

    const analyseActionLabel = loading
      ? <span><Spinner color="white" size="sm" /> {t('threat_hunting.email_analysis.messages.analysing')}</span>
      : <span>{t("threat_hunting.email_analysis.actions.analyse")}</span>;

    const emailPath = !loaded || loading ? ""
      : <FootprintMap originIP={message.originSourceIP} smtpIPs={message.smtpIPs} />;

    return (
      <Fragment>
        <PageTitle
          heading={t("threat_hunting.email_analysis.title")}
          subheading={t("threat_hunting.email_analysis.title-description")}
          icon="pe-7s-helm icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12" sm="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>{t("threat_hunting.email_analysis.upload_text")}</CardTitle>
                  <Input id="file" type="file" hidden accept=".eml,.txt"
                    value={file} onChange={this.handleFile} />
                  <Button color="primary" className="mb-2 mr-2"
                    onClick={() => this.upload()} disabled={loading}>
                    {analyseActionLabel}
                  </Button>
                  <InfoPopover id="emailanalysis-instructions" mid body={t('threat_hunting.email_analysis.actions.information')} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="6" sm="12">
              <ThreatHighlights decision={decision} message={message}
                threats={threats} origin={origin} campaign={campaign} />
            </Col>
            <Col md="6" sm="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>{t("threat_hunting.email_analysis.email_path")}</CardTitle>
                  <Row form>
                    <Col md={12}>
                      {emailPath}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <MessageHeader loaded={loaded}
                message={message}
                emailHeaders={emailHeaders} 
                incident={incident} />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <MessageLinks emailLinks={emailLinks} />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <MessageBody loaded={loaded}
                emailBody={emailBody}
                message={message}
                emlDecoded={emlDecoded} />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <MessageAttachments emailAttachments={emailAttachments} />
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment >
    )
  }
}

export default withTranslation()(EmailAnalysis);
