
class GoogleSDK {
  constructor() {
    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          client_id: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
          cookiepolicy: 'single_host_origin',
          scope: 'profile email openid'
        });
      });
    };
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    } (document, 'script', 'google-jssdk'));
  }

  errorMessage(error) {
    switch (error.error) {
      case 'idpiframe_initialization_failed':
        return "Google Auth initialization failed.";
      case 'popup_closed_by_user':
        return "User cancelled the flow.";
      case 'access_denied':
        return "User denied required permissions.";
      case 'immediate_failed':
        return "Unable to autoselect user.";
      default:
        return error.details || "Unidentified failure.";
    }
  }
}

export const gapiInstance = new GoogleSDK();
