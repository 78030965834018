import React from "react";
import { withTranslation } from 'react-i18next';
import * as d3 from 'd3';
import SimilarData from "./SimilarData";

class SimilarGraph extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {}
    }
  }

  createChildrenNode(text, similar) {
    return {
      text: text,
      linkText: [],
      icon: '/imgs/Blue.jpg',
      children: [{
        text: text,
        linkText: similar.filter(el => el.case.toUpperCase() === text.toUpperCase()).map(el => el.description).join(",;"),
        icon: '/imgs/Blue.jpg',
      }]
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.loading) {
      d3.selectAll("svg").remove();
      return;
    }

    if (this.props.loading !== prevProps.loading) { // Show graph when data is loaded
      const campaignIds = this.props.campaignIds;
      const campaigns = this.props.campaigns;
      const similar = this.props.similar.map(id => SimilarData.getSimilarity(id));

      // Root nodes
      let data = {
        text: this.joinTexts(campaignIds[0],
          campaigns[0].generalInfo.campaignDescription.length > 21 ? `${campaigns[0].generalInfo.campaignDescription.substring(0, 21)}...` : campaigns[0].generalInfo.campaignDescription,
          campaigns[0].dnsInfo.map(element => `${element.domainName}/${element.ip}`).join(";")).join(";"),
        icon: '/imgs/input.ico',
        children: [
          this.createChildrenNode(`Whois`, similar),
          this.createChildrenNode(`DNS`, similar),
          this.createChildrenNode(`Geolocation`, similar),
          this.createChildrenNode(`Message`, similar),
          this.createChildrenNode(`General`, similar)
        ]
      };
      data.children[0].children[0].children = [{
        text: this.joinTexts(campaignIds[1],
          campaigns[1].generalInfo.campaignDescription.length > 21 ? `${campaigns[1].generalInfo.campaignDescription.substring(0, 21)}...` : campaigns[1].generalInfo.campaignDescription,
          campaigns[1].dnsInfo.map(element => `${element.domainName}/${element.ip}`).join(";")).join(";"),
        icon: '/imgs/input.ico',
      }];

      this.update(data);
    }
  }

  joinTexts(...text) {
    return text.filter(el => el !== null)
  }

  update(treeData) {
    // set the dimensions and margins of the diagram
    const margin = { top: 0, right: 80, bottom: 0, left: 80 };
    const width = 900 - margin.right - margin.left;
    const height = 900 - margin.top - margin.bottom;

    //  assigns the data to a hierarchy using parent-child relationships
    let root = d3.hierarchy(treeData);

    // declares a tree layout and assigns the size
    let treemap = d3.tree()
      .size([height, width - 50]);

    // maps the node data to the tree layout
    let nodes = treemap(root);

    let couplingChild = nodes.children[0].children[0].children[0];
    couplingChild.x = nodes.x;

    // append the svg obgect to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    this.svg = d3.select("#graph").append("svg")
      .attr("width", width + margin.right + margin.left)
      .attr("height", height + margin.top + margin.bottom)

    let g = this.svg.append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // adds the links between the nodes
    let link = g.selectAll(".link")
      .data(nodes.descendants().slice(1))
      .enter().append("g")
      .attr("class", "link");

    link.append("path")
      .attr("d", (d) => this.diagonal(d));

    link.append("text")
      .attr("transform", function (d) {
        return "translate(" +
          ((d.parent.y + d.y) / 2) + "," +
          ((d.parent.x + d.x) / 2) + ")";
      })
      .attr("x", "-81px")
      .attr("y", "-21px")
      .attr("dy", ".35em")
      .text(function (d) { return d.data.linkText; })
      .call(this.wrapTextNode, 30);

    // adds each node as a group
    let node = g.selectAll(".node")
      .data(nodes.descendants())
      .enter().append("g")
      .attr("class", function (d) {
        return "node" + (d.children ? " node--internal" : " node--leaf");
      })
      .attr("transform", function (d) {
        //let x = d.depth === 3 ? height / 2 : d.x;
        return "translate(" + d.y + "," + d.x + ")";
      });

    // adds the image to the node
    node.append("image")
      .attr("href", function (d) { return d.data.icon; })
      .attr("x", "-21px")
      .attr("y", "-21px")
      .attr("width", "42px")
      .attr("height", "42px");

    node.append("text")
      .attr("dy", ".35em")
      .attr("x", function (d) { return -50 })
      .attr("y", function (d) { return 20 })
      .text(function (d) { return d.data.text; })
      .call(this.wrapTextNode, 30);

    let couplingParent = nodes.children.filter((el, index) => index > 0).map(el => el.children[0]);
    couplingParent.forEach((parent, index) => {
      g.append("g")
        .attr("class", "link")
        .append("path")
        .attr("d", () => this.diagonal({
          x: couplingChild.x,
          y: couplingChild.y,
          parent: {
            x: parent.x,
            y: parent.y
          }
        }));
    });
  }

  diagonal(d) {
    return ("M" + d.y + "," + d.x
      + "C" + d.y + "," + (d.x + d.parent.x) / 2
      + " " + d.parent.y + "," + (d.x + d.parent.x) / 2
      + " " + d.parent.y + "," + d.parent.x);
  }

  wrapTextNode(text, width) {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(';').reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        x = text.attr("x"),
        y = text.attr("y"),
        dy = 0, //parseFloat(text.attr("dy")),
        tspan = text.text(null)
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", dy + "em");
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text.append("tspan")
            .attr("x", x)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  }

  render() {
    return (
      <div id="graph" ></div>
    )
  }
}

export default withTranslation()(SimilarGraph);