import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './config/configureStore';
import AuthProvider from './context/AuthContext';

import './i18n';
import './polyfills';
import './assets/base.scss';

import Loading from './Layout/Components/Loading';
import App from './Application';
import ErrorBoundary from 'ErrorBoundary';

const renderApp = Component => {
  ReactDOM.render(
    <Suspense fallback={<Loading />}>
      <AuthProvider>
        <Provider store={store}>
          <Router>
            <ErrorBoundary>
              <Component />
            </ErrorBoundary>
          </Router>
        </Provider>
      </AuthProvider>
    </Suspense>,
    document.getElementById('root')
  );
};

renderApp(App);

if (module.hot) {
  module.hot.accept('./Application', () => {
    const NextApp = require('./Application').default;
    renderApp(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
