import React, { Component }  from 'react';
const lpReactTableConfiguration = {
  columns: [
    {
      Header: "landing_page.name",
      accessor : "name"
    },
    {
      Header: "landing_page.last_modified_date",
      accessor : "modified_date",
    }
  ],
  selectionColumn: true,
  editOptions: false
}
export const lpReactTableConfiguration_ForLandingPage = {
  columns: [
    {
      Header: "landing_page.name",
      accessor : "name"
    },
    {
      Header: "landing_page.last_modified_date",
      accessor : "modified_date",
    }
  ],
  selectionColumn: false,
  editOptions: {
    edit: true,
    copy: true,
    del: true
  }
}
export const lpReactTableConfiguration_ForImportScreen = {
  columns: [
    {
      Header: "landing_page.name",
      accessor : "name"
    },
    {
      Header: "landing_page.last_modified_date",
      accessor : "modified_date",
    }
  ],
  selectionColumn: false,
  editOptions: {
    copy: true
  }
}
export default lpReactTableConfiguration;
