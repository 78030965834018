import { doGet, doPost, doDelete, getDomain } from "./BaseAPI";

export function getUsers() {
  return doGet(`/organization/users/domain`);
}

export function createUser(user) {
  user.domain = getDomain();
  user.status = user.status || 'active';
  return doPost(`/organization/users/create`, user);
}

export function updateUser(user) {
  return doPost(`/organization/users/create`, user);
}

export function deleteUser(email) {
  return doDelete(`/organization/users/delete/${email}`);
}

export function getUserStatus(email) {
  return doGet(`/organization/users/status/${email}`);
}

export function getUserRoles() {
  return doGet(`/organization/users/rules`);
}

export function isUserAdmin(email) {
  return doGet(`/organization/users/isPrganizationAdmin/${email}`);
}