import { doGet, doPost } from "./BaseAPI";

export function getLicense(domain = null) {
  if (!domain) {
    return doGet(`/organization/license/domain`); // Organization
  }
  return doGet(`/organization/license/domain/${domain}`); // Admin
}

export function updateLicense(license) {
  return doPost("/organization/license/update", license);
}

export function getPhishingTest(domain = null) {
  if (!domain) {
    return doGet(`/organization/phishingtest/domain`); // Organization
  }
  return doGet(`/organization/phishingtest/domain/${domain}`); // Admin
}

export function updatePhishingTest(license) {
  return doPost("/organization/phishingtest/update", license);
}

export function getThreatAnalyzer(domain = null) {
  if (!domain) {
    return doGet(`/organization/threatanalyzer/domain`); // Organization
  }
  return doGet(`/organization/threatanalyzer/domain/${domain}`); // Admin
}

export function updateThreatAnalyzer(license) {
  return doPost("/organization/threatanalyzer/update", license);
}
