import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Col, Form, FormGroup, Label, Input, Row, Card, CardBody
} from 'reactstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { withTranslation } from 'react-i18next';
import update from 'immutability-helper';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';

import PageTitle from 'Layout/Components/PageTitle';
import DnsInfo from './DNSInfos';
import WhoisInfo from './WhoisInfos';
import GeolocationInfo from './GeolocationInfos';
import MessageInfo from './MessageInfos';
import ConfirmAction from 'Layout/Components/ConfirmAction';
import { CampaignContext } from '../CampaignContext';
import { Multiselect } from 'react-widgets';
import WhowasInfos from './WhowasInfos';
import { toDate } from 'Application/Helpers/TextHelper';

class CampaignDetails extends React.Component {

  static contextType = CampaignContext;

  constructor(props) {
    super(props);

    this.state = {
      UpdateOn: '',
      GeneralInfo: {
        CampaignName: '',
        CampaignDescription: '',
        ThreatType: [],
        EndIn: '',
        SourceOfInfo: '',
        StartIn: ''
      },
      Key: '',
      threatTypeData: []
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleCreateThreatType = this.handleCreateThreatType.bind(this);
    this.saveCampaign = this.saveCampaign.bind(this);
    this.deleteCampaign = this.deleteCampaign.bind(this);
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    const campaignId = params.campaignId;
    if (campaignId) {
      this.context.loadCampaign(campaignId).then(campaign => {
        campaign.GeneralInfo.StartIn = toDate(campaign.GeneralInfo.StartIn); // Adjust date
        this.setState({
          UpdateOn: toDate(campaign.UpdateOn),  // Adjust date
          GeneralInfo: campaign.GeneralInfo,
          Key: campaign.Key
        });
      });
    }
  }

  handleChange(event) {
    const obj = event.target || event;
    const path = obj.name.split(".");
    const name = path.pop();
    const value = obj.type === 'checkbox' || obj.value === 'select-one'
      ? obj.checked : obj.value;
    this.setState(updater => (
      update(updater, path.reduceRight((obj, next) => ({ [next]: obj }), { [name]: { $set: value } })))
    );
  }

  handleCreateThreatType(threat) {
    const { threatTypeData } = this.state;
    const threatType = this.state.GeneralInfo.ThreatType;
    this.setState({
      threatTypeData: [...threatTypeData, threat]
    })
    this.handleChange({ name: "GeneralInfo.ThreatType", value: [...threatType, threat] })
  }

  saveCampaign() {
    if (this.context.isEdit()) {
      this.context.updateCampaign(this.state.GeneralInfo);
    } else {
      this.context.addCampaign(this.state.UpdateOn, this.state.GeneralInfo);
    }
  }

  deleteCampaign() {
    if (this.context.campaign.Key) {
      this.context.deleteCampaign(this.context.campaign.Key);
    }
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("campaign.title")}
          icon="pe-7s-speaker icon-gradient bg-primary" />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Form onSubmit={(e) => { e.preventDefault(); this.saveCampaign(); }}>
                <Card className="main-card mb-3">
                  <CardBody>
                    <Row form>
                      <Col md={4}>
                        <FormGroup>
                          <h3 className="form-heading pt-3 pb-3 float-left">
                            {t('campaign.update-on')}
                          </h3>
                          <DatePicker name="UpdateOn" id="UpdateOn" className="form-control"
                            selected={this.state.UpdateOn} showTimeInput dateFormat="yyyy-MM-dd h:mm:ss aa"
                            onChange={date => this.handleChange({ name: "UpdateOn", value: date })}
                            readOnly={this.context.isEdit()} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row form>
                      <Col md={12}>
                        <Card className="main-card mb-3">
                          <CardBody>
                            <Row>
                              <Col md={12}>
                                <h3 className="form-heading pt-3 pb-3 float-left">
                                  {t('campaign.general-info')}
                                </h3>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="GeneralInfo.CampaignName">{t('campaign.campaign-name-type')}</Label>
                                  <Input type="text" name="GeneralInfo.CampaignName" id="GeneralInfo.CampaignName"
                                    value={this.state.GeneralInfo.CampaignName} onChange={this.handleChange} />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="GeneralInfo.CampaignDescription">{t('campaign.campaign-description')}</Label>
                                  <Input type="text" name="GeneralInfo.CampaignDescription" id="GeneralInfo.CampaignDescription"
                                    value={this.state.GeneralInfo.CampaignDescription} onChange={this.handleChange} />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="GeneralInfo.ThreatType">{t('campaign.threat-type')}</Label>
                                  <Multiselect
                                    data={this.state.threatTypeData}
                                    value={this.state.GeneralInfo.ThreatType}
                                    allowCreate='onFilter'
                                    onCreate={keyword => this.handleCreateThreatType(keyword)}
                                    onChange={value => this.handleChange({ name: "GeneralInfo.ThreatType", value: value })}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="GeneralInfo.EndIn">{t('campaign.end-in')}</Label>
                                  <Input type="text" name="GeneralInfo.EndIn" id="GeneralInfo.EndIn"
                                    value={this.state.GeneralInfo.EndIn} onChange={this.handleChange} />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="GeneralInfo.SourceOfInfo">{t('campaign.source-of-info')}</Label>
                                  <Input type="text" name="GeneralInfo.SourceOfInfo" id="GeneralInfo.SourceOfInfo"
                                    value={this.state.GeneralInfo.SourceOfInfo} onChange={this.handleChange} />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="GeneralInfo.StartIn">{t('campaign.start-in')}</Label>
                                  <DatePicker id="GeneralInfo.StartIn" className="form-control"
                                    selected={this.state.GeneralInfo.StartIn} showTimeInput dateFormat="yyyy-MM-dd h:mm:ss aa"
                                    onChange={date => this.handleChange({ name: "GeneralInfo.StartIn", value: date })} />
                                </FormGroup>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <Card className="main-card mb-3">
                          <CardBody>
                            <DnsInfo />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <Card className="main-card mb-3">
                          <CardBody>
                            <WhoisInfo />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <Card className="main-card mb-3">
                          <CardBody>
                            <WhowasInfos />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <Card className="main-card mb-3">
                          <CardBody>
                            <GeolocationInfo />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <Card className="main-card mb-3">
                          <CardBody>
                            <MessageInfo />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <Button color="primary" onClick={this.saveCampaign}>{t('actions.save')}</Button>
                        &nbsp;&nbsp;&nbsp;
                        <ConfirmAction color="danger" title={t('actions.confirmation')}
                          message={t('messages.confirm_delete_element', { element: 'Campaign' })}
                          disabled={!this.context.isEdit()} onConfirm={this.deleteCampaign}>
                          {t("actions.delete")}
                        </ConfirmAction>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </CSSTransitionGroup >
      </Fragment >
    )
  }
}

export default withRouter(withTranslation()(CampaignDetails));