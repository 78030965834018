import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
  Row, Col, Card, CardBody, Input,
} from 'reactstrap';
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';

import PageTitle from 'Layout/Components/PageTitle';
import AdModal from "./AdModal";

import { getAds, deleteAd } from "../../API/AdsAPI";
import { toast } from "react-toastify";
import ConfirmAction from "Layout/Components/ConfirmAction";

class Ads extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      lang: "*",
      ads: [],
      ad: {},
      openEdit: false
    };

    this.loadAds = this.loadAds.bind(this);
    this.onSubmitCallback = this.onSubmitCallback.bind(this);
  }

  componentDidMount() {
    this.loadAds();
  }

  loadAds() {
    getAds(this.state.lang).then(result => {
      this.setState({
        ads: result
      })
    }).catch(error => {
      console.error("Error loading ads", error);
      toast["error"](this.props.t('errors.loading', { element: 'Ads', message: error.message }));
    });
  }

  deleteAd(id) {
    deleteAd(id).then(result => {
      this.loadAds();
    }).catch(error => {
      console.error("Error deleting ad", error);
      toast["error"](this.props.t('errors.deleting', { element: 'Ad', message: error.message }));
    });
  }

  onSubmitCallback() {
    this.loadAds();
  }

  render() {
    const { t, i18n } = this.props;

    const editAdModal = this.state.openEdit &&
      <AdModal showOpenButton={false} open={true} ad={this.state.ad}
        onClose={() => this.setState({ openEdit: false })}
        onSubmitCallback={this.onSubmitCallback} />;

    return (
      <Fragment>
        <PageTitle
          heading={t("ads.title")}
          icon="pe-7s-settings icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <AdModal onSubmitCallback={this.onSubmitCallback} />
                  {editAdModal}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    columns={[
                      {
                        Header: t("ads.ads_title"),
                        accessor: 'title',
                        id: 'title'
                      },
                      {
                        Header: t("ads.language"),
                        accessor: 'language',
                        id: 'language'
                      },
                      {
                        Header: t("ads.client"),
                        accessor: 'client',
                        id: 'client',
                        Cell: (prop) => {
                          return (
                            <div className="d-block w-100 text-center">
                              <Input type="checkbox" id="poc"
                                checked={prop.value} disabled={true} />
                            </div>
                          )
                        }
                      },
                      {
                        Header: t("ads.free"),
                        accessor: 'free',
                        id: 'free',
                        Cell: (prop) => {
                          return (
                            <div className="d-block w-100 text-center">
                              <Input type="checkbox" id="poc"
                                checked={prop.value} disabled={true} />
                            </div>
                          )
                        }
                      },
                      {
                        Header: t("ads.poc"),
                        accessor: 'poc',
                        id: 'poc',
                        Cell: (prop) => {
                          return (
                            <div className="d-block w-100 text-center">
                              <Input type="checkbox" id="poc"
                                checked={prop.value} disabled={true} />
                            </div>
                          )
                        }
                      },
                      {
                        Header: t("ads.startdate"),
                        accessor: 'startdate',
                        id: 'startdate',
                        Cell: (prop) => {
                          let startdate = '';
                          if (prop.value) {
                            startdate = new Date(prop.value).toLocaleString();
                          }
                          return (
                            <div className="d-block w-100 text-center">
                              {startdate}
                            </div>
                          )
                        }
                      },
                      {
                        Header: t("ads.enddate"),
                        accessor: 'enddate',
                        id: 'enddate',
                        Cell: (prop) => {
                          let enddate = '';
                          if (prop.value) {
                            enddate = new Date(prop.value).toLocaleString();
                          }
                          return (
                            <div className="d-block w-100 text-center">
                              {enddate}
                            </div>
                          )
                        }
                      },
                      {
                        Header: t("ads.actions.title"),
                        id: 'actions',
                        accessor: 'title',
                        width: 100,
                        Cell: (prop) => (
                          <div className="d-block w-100 text-center">
                            <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                              message={t('messages.confirm_delete', { element: 'ad', object: prop.value })}
                              onConfirm={() => this.deleteAd(prop.original.id)}>{t("ads.actions.delete")}</ConfirmAction>
                          </div>)
                      }
                    ]}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            if (e.target.tagName.toLowerCase() === 'button') {
                              return; // Ignore clicks on buttons
                            }
                            this.setState({
                              openEdit: true,
                              ad: rowInfo.original
                            })
                          },
                          style: {
                            cursor: 'pointer'
                          }
                        }
                      }
                      return {}
                    }}
                    data={this.state.ads}
                    defaultPageSize={10}
                    className="-striped -highlight" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withTranslation()(Ads);