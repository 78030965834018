import React,{Fragment} from 'react';
import { withTranslation } from 'react-i18next';
import './progressbar.css';

class SimulationProgressTimelineBar extends React.Component{
  constructor(props){
    super(props);
    this.generateListFromData = this.generateListFromData.bind(this);
  }

  generateListFromData(){
    const progressBarHTMLli = []
    if(Array.isArray(this.props.timelineProgressData)){
      this.props.timelineProgressData
        .forEach(ele => progressBarHTMLli.push(`<li>${ele['stageName']}</li>`))
    }
    return progressBarHTMLli;
  }
  render(){
    const {timelineProgressData} = this.props;
    const totalItems = timelineProgressData.length;
	  const numberOfActiveItems = timelineProgressData.filter(item => item.active).length;
	  const progressBarWidth = totalItems > 1 ? (numberOfActiveItems - 1) / (totalItems - 1) * 100 : 0;
    return(
      <div className="timeline-box">
        <div className="timeline">
    			<div className="timeline-progress" style={{ width: `${progressBarWidth}%`}}></div>
    			<div className="timeline-items">
    				{timelineProgressData.map((item, i) => (
    					<div key={i} className={"timeline-item" + (item.active ? ' active' : '')}>
    						<div className="timeline-content">
    							{item.name}
    						</div>
    					</div>
    				))}
    			</div>
  		</div>
    </div>
    )
  }
}
export default SimulationProgressTimelineBar;
