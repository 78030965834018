import React from "react";
import { toast } from "react-toastify";
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import {
  getPayment, getPaymentsDomain,
  getPaymentsReport, makePayment
} from "API/PaymentsAPI";

export const PaymentsContext = React.createContext();
export const PaymentsConsumer = PaymentsContext.Consumer;

class PaymentsProvider extends React.Component {

  constructor(props) {
    super(props);

    const path = this.props.location.pathname;

    this.state = {
      isAdmin: path.startsWith('/admin'),
      domain: '',
      email: '',
      payment: {},
      payments: [],
      paymentsReady: false,
      page: 0,
      totalPages: 1
    }

    this.noActivePayment = this.noActivePayment.bind(this);
    this.loadPaymentsAdminReport = this.loadPaymentsAdminReport.bind(this);
    this.loadPayments = this.loadPayments.bind(this);
    this.loadPayment = this.loadPayment.bind(this);
    this.savePayment = this.savePayment.bind(this);
    this.openPayment = this.openPayment.bind(this);
    this.isEdit = this.isEdit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.domain !== prevProps.domain ||
      this.state.email !== prevProps.email) {
      this.setState({
        domain: this.props.domain,
        email: this.props.email,
      })
    }
  }

  noActivePayment() {
    return this.state.payments.length === 0
      || !this.state.payments.find(payment =>
        payment.status === 'active' || payment.status === 'waiting payment')
  }

  loadPaymentsAdminReport(status) {
    let page = this.state.page + 1;
    getPaymentsReport(page, status).then(result => {
      this.setState({
        payments: result,
        page: page > 0 ? page - 1 : 0,
        totalPages: 1
      });
    }).catch(error => {
      console.error("Error getting Payments", error);
      toast["error"](this.props.t('errors.loading', { element: 'Payments', message: error.message }));
    });
  }

  loadPayments() {
    const promise = this.state.isAdmin
      ? getPaymentsDomain(this.state.domain)
      : getPaymentsDomain()
    promise.then(result => {
      this.setState({
        payments: result,
        paymentsReady: true
      })
    }).catch(error => {
      console.error("Error getting Payments", error);
      toast["error"](this.props.t('errors.loading', { element: 'Payments', message: error.message }));
    })
  }

  loadPayment() {
    getPayment(this.state.email).then(result => {
      this.setState({
        payment: result
      })
    }).catch(error => {
      console.error("Error getting Payment", error);
      toast["error"](this.props.t('errors.loading', { element: 'Payment', message: error.message }));
    })
  }

  savePayment(payment) {
    makePayment(payment).then(result => {
      if (this.isEdit()) {
        toast["success"](this.props.t('messages.success_update', { element: 'Payment' }));
      } else {
        toast["success"](this.props.t('messages.success_create', { element: 'Payment' }));
      }
      this.loadPayments();
    }).catch(error => {
      console.error("Error saving Payment", error);
      if (this.isEdit()) {
        toast["error"](this.props.t('errors.updating', { element: 'Payment', message: error.message }));
      } else {
        toast["error"](this.props.t('errors.creating', { element: 'Payment', message: error.message }));
      }
    })
  }

  openPayment(payment = {}) {
    this.setState({
      payment: payment
    })
  }

  isEdit() {
    return this.state.payment.id ? true : false;
  }

  render() {
    return (
      <PaymentsContext.Provider value={
        {
          isAdmin: this.state.isAdmin,
          domain: this.state.domain,
          email: this.state.email,
          payment: this.state.payment,
          payments: this.state.payments,
          paymentsReady: this.state.paymentsReady,
          page: this.state.page,
          totalPages: this.state.totalPages,
          noActivePayment: this.noActivePayment,
          loadPaymentsAdminReport: this.loadPaymentsAdminReport,
          loadPayments: this.loadPayments,
          loadPayment: this.loadPayment,
          savePayment: this.savePayment,
          openPayment: this.openPayment,
          isEdit: this.isEdit
        }
      }>
        {this.props.children}
      </PaymentsContext.Provider>
    )
  }
}

export default withRouter(withTranslation()(PaymentsProvider));
