import React from "react";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';

import { getUsers, createUser, updateUser, deleteUser } from '../../API/UsersAPI';
import { getDepartments } from '../../API/DepartmentAPI';
import { updateAccountStatus, deleteAccount } from "../../API/OutlookAPI";

export const AccountContext = React.createContext();
export const AccountConsumer = AccountContext.Consumer;

class AccountProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      departments: [],
      accounts: [],
      account: {}
    }

    this.isEdit = this.isEdit.bind(this);
    this.getDepartments = this.getDepartments.bind(this);
    this.getAccounts = this.getAccounts.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.createAccounts = this.createAccounts.bind(this);
    this.openAccount = this.openAccount.bind(this);
    this.updateAccount = this.updateAccount.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
    this.activateAccount = this.activateAccount.bind(this);
    this.suspendAccount = this.suspendAccount.bind(this);
  }

  componentDidMount() {
    this.getAccounts();
    this.getDepartments();
  }

  isEdit() {
    return this.state.account.id ? true : false;
  }

  getDepartments() {
    getDepartments().then(result => {
      this.setState({
        departments: result
      });
    }).catch(error => {
      console.error("Error getting Department", error);
      toast["error"](this.props.t('errors.loading', { element: 'Departments', message: error.message }));
    });
  }

  getAccounts() {
    getUsers().then(result => {
      this.setState({
        accounts: result
      });
    }).catch(error => {
      console.error("Error getting Accounts", error);
      toast["error"](this.props.t('errors.loading', { element: 'Accounts', message: error.message }));
    });
  }

  createAccount(account) {
    createUser({ email: account.email, name: account.name, department: account.department, status: account.status })
      .then(result => {
        toast["success"](this.props.t('messages.success_create', { element: 'Account' }));
        this.getAccounts();
      }).catch(error => {
        console.error("Error creating Account", error);
        toast["error"](this.props.t('errors.creating', { element: 'Account', message: error.message }));
      });
  }

  createAccounts(accounts) {
    let promises = accounts.map(account => createUser(account));
    return Promise.allSettled(promises).then(values => {
      toast["success"](this.props.t('messages.success_update', { element: 'account' }));
      let errors = values.filter(el => el.status !== 'fulfilled');
      if (errors && errors.length > 0) {
        console.warn("Error uploading accounts", errors);
        toast["warning"](this.props.t('accounts.messages.file_uploaded_with_errors', { number: errors.length }));
      }
      this.getAccounts();
    });
  }

  openAccount(account = {}) {
    this.setState({
      account: account
    });
  }

  updateProviderAccountStatus(email, status) {
    updateAccountStatus({email: email, status: status }).then(value => {
      console.log("Provider account status updated");
    }).catch(error => {
      console.error("Error updating Provider Account", error);
    });
  }

  updateAccount(account, success_message_key = 'messages.success_update') {
    updateUser(account).then(_value => {
      toast["success"](this.props.t(success_message_key, { element: 'Account' }));
      this.updateProviderAccountStatus(account.email, account.status);
      this.getAccounts();
    }).catch(error => {
      console.error("Error updating Account", error);
      toast["error"](this.props.t('errors.updating', { element: 'Account', message: error.message }));
    });
  }

  deleteProviderAccount(email) {
    deleteAccount(email).then(_value => {
      console.log("Provider account deleted");
    }).catch(error => {
      console.error("Error deleting Provider Account", error);
    });
  }

  deleteAccount(email) {
    deleteUser(email).then(value => {
      this.deleteProviderAccount(email);
      this.getAccounts();
    }).catch(error => {
      console.error("Error deleting Account", error);
      toast["error"](this.props.t('errors.deleting', { element: 'Account', message: error.message }));
    });
  }

  activateAccount(account) {
    account.status = 'active'
    this.updateAccount(account, 'accounts.messages.success_activated');
  }

  suspendAccount(account) {
    account.status = 'suspended'
    this.updateAccount(account, 'accounts.messages.success_suspended');
  }

  render() {
    return (
      <AccountContext.Provider value={
        {
          departments: this.state.departments,
          accounts: this.state.accounts,
          account: this.state.account,
          isEdit: this.isEdit,
          createAccount: this.createAccount,
          createAccounts: this.createAccounts,
          openAccount: this.openAccount,
          updateAccount: this.updateAccount,
          deleteAccount: this.deleteAccount,
          activateAccount: this.activateAccount,
          suspendAccount: this.suspendAccount
        }
      }>
        {this.props.children}
      </AccountContext.Provider>
    )
  }
}

export default withTranslation()(AccountProvider)
