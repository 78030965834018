import { doGet, doPut, doPost } from "./BaseAPI";

export function getIncidents() {
  return doGet(`/incident/domain`);
}

export function getIncident(messageId) {
  return doGet(`/incident/domain/${messageId}`);
}

export function closeIncident(messageId, resolution) {
  return doPut(`/incident/close/${messageId}/${resolution}`);
}

export function closeListOfIncidents(resolutions) {
  return doPost(`/incident/close`, resolutions);
}
