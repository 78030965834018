import React, { Fragment } from "react";
import {
  Row, Col, Card, CardBody, CardTitle,
  Label, Button, FormGroup
} from 'reactstrap';
import { toast } from 'react-toastify';
import Tabs from 'react-responsive-tabs';
import Switch from "react-switch";
import { withTranslation } from 'react-i18next';

import { downloadText } from "Application/Helpers/FileHelper";
import { copyTextToClipboard } from "Application/Helpers/TextHelper";

class MessageBody extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      displayImages: false
    };

    this.toggleImages = this.toggleImages.bind(this);
    this.displayImages = this.displayImages.bind(this);
  }

  toggleImages(checked) {
    this.setState({ displayImages: checked });
  }

  displayImages(body) {
    if (this.state.displayImages) {
      return body;
    }
    if (body) {
      const imgRegex = /src\s*=\s*(['"])((data:image\/.+?)|(https?:\/\/.+?))\1/ig;
      body = body.replace(imgRegex, "");
      const bgdRegex = /background\s*=\s*(['"])((data:image\/.+?)|(https?:\/\/.+?))\1/ig;
      body = body.replace(bgdRegex, "");
    }
    return body;
  }

  copyBodyToClipboard(eml) {
    copyTextToClipboard(eml).then(result => {
      toast["success"](this.props.t('messages.success_copy', { element: 'EML' }));
    }).catch(error => {
      console.error('Could not copy text to clipboard: ', error);
      toast["error"](this.props.t('errors.copying', { element: 'EML', message: error.message }));
    });
  }

  render() {
    const { t, i18n, loaded, emailBody, message, emlDecoded } = this.props;

    const bodyTabs = [
      {
        title: t("incidents.message_body"),
        getContent: () =>
          <Fragment>
            <Row form>
              <Col md={12}>
                <FormGroup check inline>
                  <Switch onChange={this.toggleImages} checked={this.state.displayImages}
                    height={20} width={40} className="mr-1" disabled={!loaded} />
                  <Label className="form-check-label">{t("incidents.actions.display_images")}</Label>
                </FormGroup>
              </Col>
            </Row>
            <iframe src="about:blank" title="emailBody" srcDoc={this.displayImages(emailBody)}
              sandbox="" style={{ border: 0, width: '100%', height: '500px' }}></iframe>
          </Fragment >,
        key: 'body_tab_0',
      }, {
        title: t("incidents.message_body_raw"),
        getContent: () =>
          <Fragment>
            <Row form>
              <Col md={12}>
                <Button color="primary" className="mr-1" disabled={!loaded}
                  onClick={() => downloadText(emlDecoded, `${message.receiver}_${message.originDate}.txt`)}>
                  {t("incidents.actions.download_body")}
                </Button>
                <Button color="primary" className="mr-1" disabled={!loaded}
                  onClick={() => this.copyBodyToClipboard(emlDecoded)}>
                  {t("incidents.actions.copy_body")}
                </Button>
              </Col>
            </Row>
            <div style={{ width: '100%', height: '500px', overflowY: 'scroll' }}>
              {emailBody}
            </div>,
        </Fragment>,
        key: 'body_tab_1',
      }
    ]

    return (
      <Card className="main-card mb-3 card-tabs">
        <Tabs tabsWrapperClass="card-title" transform={false}
          showInkBar={true} items={bodyTabs} />
      </Card>
    )
  }
}

export default withTranslation()(MessageBody);
