import React, { Fragment } from 'react';
import { Button, Input, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { AccountContext } from './AccountContext';
import { readFile } from 'Application/Helpers/FileHelper';

class CreateAccounts extends React.Component {

  static contextType = AccountContext;

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      account: {
        name: "",
        email: "",
        department: ""
      },
      uploading: false
    };

    this.upload = this.upload.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }

  upload() {
    document.getElementById('file').click();
  }

  async handleFile(event) {
    this.setState({
      uploading: true
    });

    // Read the csv file
    const content = await readFile(event.target.files[0]).catch(error => {
      console.error("Error reading file", error);
      toast["error"](this.props.t('errors.file_read_error'));
    });
    if (!content) {
      this.setState({
        uploading: false
      });
      return;
    }

    const accounts = content.split(/\r\n|\n/).map(register => {
      let values = register.split(";");
      if (values && values.length === 4) { // email; name; department; status
        return {
          email: values[0].trim(),
          name: values[1].trim(),
          department: values[2].trim(),
          status: values[3].trim()
        };
      }
      return null; // filter accouts missing attributes
    }).filter(account => account != null);

    await this.context.createAccounts(accounts);
    
    this.setState({
      uploading: false
    });
  }

  render() {
    const { t, i18n } = this.props;

    const uploadActionLabel = this.state.uploading
      ? <span><Spinner color="white" size="sm" /> {t("accounts.actions.uploading")}</span>
      : <span>{t("accounts.actions.create-multi")}</span>;

    return (
      <Fragment>
        <Input id="file" type="file" hidden accept=".txt,.csv"
          value={this.state.file} onChange={this.handleFile} />
        <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="primary"
          onClick={() => this.upload()} disabled={this.state.uploading}>
          <i className="lnr-upload btn-icon-wrapper"> </i>
          {uploadActionLabel}
        </Button>
      </Fragment>
    );
  }
}

export default withTranslation()(CreateAccounts);
