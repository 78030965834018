import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Col, Row } from 'reactstrap';
import ReactTable from 'react-table';
import { CampaignContext } from '../CampaignContext';
import WhowasRecordModal from './WhowasRecordModal';

class WhowasInfos extends React.Component {

  static contextType = CampaignContext;

  constructor(props) {
    super(props);

    this.state = {
      record: {},
      openModal: false
    };
  }

  render() {
    const { t, i18n } = this.props;

    const recordModal = this.state.openModal &&
      <WhowasRecordModal open={true} record={this.state.record}
        onClose={() => this.setState({ openModal: false })} />;

    return (
      <Fragment>
        <Row>
          <Col md={12}>
            <h3 className="form-heading pt-3 pb-3 float-left">
              {t('campaign.whowas-info.title')}
            </h3>
            {recordModal}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ReactTable
              columns={[
                {
                  Header: t("campaign.whowas-info.administrative_contact"),
                  accessor: 'administrative_contact.full_name'
                },
                {
                  Header: t("campaign.whowas-info.create_date"),
                  accessor: 'create_date'
                },
                {
                  Header: t("campaign.whowas-info.domain_name"),
                  accessor: 'domain_name'
                },
                {
                  Header: t("campaign.whowas-info.domain_registrar"),
                  accessor: 'domain_registrar.registrar_name'
                },
                {
                  Header: t("campaign.whowas-info.status"),
                  accessor: 'domain_status'
                },
                {
                  Header: t("campaign.whowas-info.expirity"),
                  accessor: 'expiry_date'
                }
              ]}
              data={this.context.campaign.WhowasInfo.whois_records}
              showPageSizeOptions={false}
              showPaginationBottom={false}
              minRows={4}
              filterable={false}
              className="-striped -highlight"
              getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    onClick: (e) => {
                      if (e.target.tagName.toLowerCase() === 'button') {
                        return; // Ignore clicks on buttons
                      }
                      this.setState({
                        openModal: true,
                        record: rowInfo.original
                      })
                    },
                    style: {
                      cursor: 'pointer'
                    }
                  }
                }
                return {}
              }}
            />
          </Col>
        </Row>
      </Fragment >
    )
  }
}

export default withRouter(withTranslation()(WhowasInfos));