import React, {Fragment} from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, Button, Spinner, Alert, Nav, NavItem, NavLink } from 'reactstrap';

import PageTitle from '../../../Layout/Components/PageTitle';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';

// import CampaignTable from './CampaignTable';
import CampaignModal from './CampaignModal';
import {activeCampaignTableConfiguration, archivedCampaignTableConfiguration} from './CampaignColumnGenerator'
import {getCampaignList, fetchCampaignDetails, deletePhishingCampaign} from "../../../API/PhishingAPI";
import PhishingDataTable from '../PhishingDataTable'


class Campaign extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      openEdit: false,
      campaignList:[],
      isLoaded: false,
      campaign: {},
      isCopied: false,
      error: "",
      errorFetchingData: false,
      activeTab: 1,
      filteredCampaign: [],
      datasets: {
        1: {
          data: null,
          config: activeCampaignTableConfiguration
        },
        2 : {
          data: null,
          config: archivedCampaignTableConfiguration
        }
      }
    }
    this.loadCampaignList = this.loadCampaignList.bind(this);
    this.onEditOrCopyCallback = this.onEditOrCopyCallback.bind(this);
    this.addPolicyCallback = this.addPolicyCallback.bind(this);
    this.deleteCampaign = this.deleteCampaign.bind(this);
    this.onSubmitCallback = this.onSubmitCallback.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.routeChangeToNewSimulation = this.routeChangeToNewSimulation.bind(this);
    this.extractCampaignFieldsWhichNeeded = this.extractCampaignFieldsWhichNeeded.bind(this);
  }
  componentDidMount(){
    this.loadCampaignList();
  }
  onSubmitCallback(){
    this.toggleTab(1);
    this.loadCampaignList();
  }
  loadCampaignList(){
    this.setState({
      isLoaded: false
    });
    // TODO(Refactor): Date format should be handled in it's own method
    getCampaignList()
      .then(res => {
        res.campaigns.forEach((item, i) => {
          item.formatted_created_date = (new Date(item.created_date)).toLocaleString(undefined, {dateStyle:"full", timeStyle:"medium"})
        });
        this.setState({
          campaignList : res.campaigns,
          isLoaded : true
        });
    }).catch(error => {
      console.log("Error while fetching data for Campaign",error);
      this.setState({
        errorFetchingData: true,
        error: "Error fetching data"
      });
    });
  }
  onEditOrCopyCallback(campaign, copiedVersion){
    const {id} = campaign;
    fetchCampaignDetails(id)
      .then(res => {
        res.page = {'name' : res.page.name};
        res.smtp = {'name' : res.smtp.name};
        res.template = {'name' : res.template.name};
        const campaign = this.extractCampaignFieldsWhichNeeded(res);
        console.log("The campaign is", campaign);
        this.setState({
          openEdit: true,
          campaign: campaign,
          isCopied: copiedVersion ? true : false
        })
      }).catch(error => {
        console.error("Error fetching campaign details");
      })
  }
  deleteCampaign(e){
    const id = e._original.id;
    deletePhishingCampaign(id)
      .then(res => {
        console.log(res);
        this.loadCampaignList();
      }).catch(error => {
        console.error("Error ");
      })
  }
  toggleTab(tab){
    this.setState({
      activeTab: tab
    });
  }
  routeChangeToNewSimulation(){
    this.props.history.push('/organization/phishing_simulator/simulations/'); // TODO check url
  }
  render(){
    if(this.state.isLoaded){
      if(this.state.activeTab =='1'){
        this.state.filteredCampaign = this.state.campaignList.filter(campaign => campaign['status'] != 'Completed');
      } else{
        this.state.filteredCampaign = this.state.campaignList.filter(campaign => campaign['status'] == 'Completed')
      }
    }
    const{datasets, activeTab} = this.state;
    const activeConfig = datasets[activeTab].config;

    const { t, i18n } = this.props;
    const editCampaign = this.state.openEdit &&
      <CampaignModal open={true} showOpenButton={false} onClose={this.addPolicyCallback}
      campaign={this.state.campaign} onSubmit={this.onSubmitCallback} isCopied={this.state.isCopied}/>;

    return (
      <Fragment>
        <PageTitle
          heading="Simulations"
          icon="pe-7s-helm icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col>
              <Card className="main-card mb-3">
                <CardBody>
                  {/*<CampaignModal onSubmit={this.onSubmitCallback} onClose={this.addPolicyCallback} onSubmit={this.onSubmitCallback} />*/}
                  <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
                    onClick={this.routeChangeToNewSimulation}>
                      <i className="lnr-plus-circle btn-icon-wrapper"> </i>
                      New Simulation
                  </Button>

                  {editCampaign}
                  {this.state.errorFetchingData ? <Alert color="danger">
                  {this.state.error}
                  </Alert> : <div className="mt-3"><Nav tabs>
                    <NavItem>
                      <NavLink className={classnames({ active: this.state.activeTab == '1' })} onClick={() => this.toggleTab('1')}>Active Simulations</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: this.state.activeTab == '2' })} onClick={() => this.toggleTab('2')}>Archived Simulations</NavLink>
                    </NavItem>
                  </Nav>

                  {/*<CampaignTable campaignList={this.state.filteredCampaign} isLoaded={this.state.isLoaded}
                  onDelete={this.deleteCampaign} onEditOrCopy={this.onEditOrCopyCallback}/>*/}

                  {this.state.isLoaded?
                    <PhishingDataTable key={activeTab} config={activeConfig}  data={this.state.filteredCampaign} onEditOrCopy={this.onEditOrCopyCallback}
                    onDelete={this.deleteCampaign}/>
                    :<div><Spinner color="primary" /> Data is loading!!!</div>}

                  </div>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }

  addPolicyCallback(){
    this.setState({
      openEdit: false,
      campaign: {},
      isCopied: false
    });
  }
  extractCampaignFieldsWhichNeeded(fullCampaignData){
    var extractCampaignData = {};
    const columnsToExtract = ['id', 'name', 'page', 'template', 'url', 'smtp'];
    for (const key of columnsToExtract){
      extractCampaignData[key] = fullCampaignData[key]
    }
    return extractCampaignData;
  }
}
export default withTranslation()(Campaign)
