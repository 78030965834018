import React, { Fragment } from 'react';
import {
  Col, FormGroup, Label, Input, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

class WhowasContact extends React.Component {

  render() {
    const { t, i18n } = this.props;

    const nCols = 4;
    const readOnly = true;

    return (
      <Fragment>
        <Row>
          <Col md={12}>
            <h3 className="form-heading pt-3 pb-3 float-left">
              {this.props.contact_title}
            </h3>
          </Col>
        </Row>
        <Row form>
          <Col md={nCols}>
            <FormGroup>
              <Label for="contact.full_name">{t('campaign.whowas-info.contact.full_name')}</Label>
              <Input type="text" name="contact.full_name" id="contact.full_name"
                value={this.props.contact.full_name} readOnly={readOnly} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="contact.company_name">{t('campaign.whowas-info.contact.company')}</Label>
              <Input type="text" name="contact.company_name" id="contact.company_name"
                value={this.props.contact.company_name} readOnly={readOnly} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="contact.email_address">{t('campaign.whowas-info.contact.email')}</Label>
              <Input type="text" name="contact.email_address" id="contact.email_address"
                value={this.props.contact.email_address} readOnly={readOnly} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="contact.phone_number">{t('campaign.whowas-info.contact.phone')}</Label>
              <Input type="text" name="contact.phone_number" id="contact.phone_number"
                value={this.props.contact.phone_number} readOnly={readOnly} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="contact.mailing_address">{t('campaign.whowas-info.contact.address')}</Label>
              <Input type="text" name="contact.mailing_address" id="contact.mailing_address"
                value={this.props.contact.mailing_address} readOnly={readOnly} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="contact.city_name">{t('campaign.whowas-info.contact.city')}</Label>
              <Input type="text" name="contact.city_name" id="contact.city_name"
                value={this.props.contact.city_name} readOnly={readOnly} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="contact.state_name">{t('campaign.whowas-info.contact.state')}</Label>
              <Input type="text" name="contact.state_name" id="contact.state_name"
                value={this.props.contact.state_name} readOnly={readOnly} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="contact.zip_code">{t('campaign.whowas-info.contact.zip_code')}</Label>
              <Input type="text" name="contact.zip_code" id="contact.zip_code"
                value={this.props.contact.zip_code} readOnly={readOnly} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="contact.country_name">{t('campaign.whowas-info.contact.country')}</Label>
              <Input type="text" name="contact.country_name" id="contact.country_name"
                value={this.props.contact.country_name} readOnly={readOnly} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="contact.country_code">{t('campaign.whowas-info.contact.country_code')}</Label>
              <Input type="text" name="contact.country_code" id="contact.country_code"
                value={this.props.contact.country_code} readOnly={readOnly} />
            </FormGroup>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default withTranslation()(WhowasContact);
