import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Col, Row, FormGroup, Button } from 'reactstrap';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import { CampaignContext } from '../CampaignContext';
import DNSInfoModal from './DNSInfoModal';
import ConfirmAction from 'Layout/Components/ConfirmAction';

class DNSInfos extends React.Component {

  static contextType = CampaignContext;

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <Row>
          <Col md={12}>
            <h3 className="form-heading pt-3 pb-3 float-left">
              {t('campaign.dns-info.title')}
            </h3>
            <DNSInfoModal showOpenButton={!this.context.isEdit()} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ReactTable
              columns={[
                {
                  Header: t("campaign.dns-info.domain-name"),
                  accessor: 'DomainName'
                },
                {
                  Header: t("campaign.dns-info.ip"),
                  accessor: 'IP'
                },
                {
                  Header: t("campaign.dns-info.name-server-organization"),
                  accessor: 'NameServerOrganization'
                },
                {
                  Header: t("campaign.dns-info.name-server"),
                  accessor: d => {
                    const nameServer = d.NameServer;
                    return nameServer ? nameServer.toString() : ''
                  },
                  id: "NameServer"
                },
                {
                  Header: t("actions.title"),
                  id: 'actions',
                  accessor: 'DomainName',
                  width: 200,
                  Cell: (prop) => (
                    <div className="d-block w-100 text-center">
                      <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                        message={t('messages.confirm_remove', { object: prop.value })}
                        onConfirm={() => this.context.removeDNSInfo(prop.index)}
                        disabled={this.context.isEdit()}>
                        {t("campaign.dns-info.actions.remove")}
                      </ConfirmAction>
                    </div>
                  )
                }
              ]}
              data={this.context.campaign.DnsInfo}
              showPageSizeOptions={false}
              showPaginationBottom={false}
              minRows={4}
              filterable={false}
              className="-striped -highlight"
            />
          </Col>
        </Row>
      </Fragment >
    )
  }
}

export default withRouter(withTranslation()(DNSInfos));