import BaseAPI from './BaseAPI';

class UserLoginAPI extends BaseAPI {

    buildURL(url) {
        return process.env.REACT_APP_CONSOLE_URL + url;
    }

    createUser(name, email, password, organization, phonenumber) {
        return this.doPost(`/user/register`, {
            name: name,
            email: email,
            password: password,
            organization: organization,
            phonenumber: phonenumber
        });
    }

    login(email, password) {
        return this.doPost("/user/login", {
            email: email,
            password: password
        });
    }

    token(email, token, provider) {
        return this.doPost("/user/token", {
            email: email
        }, {Authorization: `Bearer ${token}`, Provider: `${provider}`});
    }

    forgotPassword(email) {
        return this.doGet(`/user/forgotPassword?email=${email}`);
    }

    resetPassword(email, code, password) {
        return this.doPost(`/user/resetPassword`, {
            email: email,
            code: code,
            password: password
        });
    }

    confirmEmail(email, confirmation) {
        return this.doGet(`/user/confirmEmail?email=${email}&confirmation=${confirmation}`);
    }
}

export default new UserLoginAPI();