import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, FormGroup, Label, Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";

import PageTitle from 'Layout/Components/PageTitle';
import { DropdownList } from 'react-widgets';
import NumericInput from 'react-numeric-input';

import { campaignTarget } from 'API/CampaignAPI';
import TargetGraph from './TargetGraph';
import InfoPopover from 'Layout/Components/InfoPopover';

class TargetCampaigns extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      target_data: ['user', 'department', 'organization'],
      target_values: [],
      max_number: 5,
      start_date: '',
      end_date: '',
      loading: false,
      campaigns: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.visualize = this.visualize.bind(this);
  }

  handleChange(event) {
    const obj = event.target || event;
    const value = obj.type === 'checkbox'
      ? obj.checked : obj.value;
    this.setState({
      [obj.name]: value
    })
  }

  visualize() {
    const { target_values, start_date, end_date, max_number } = this.state;
    if (!target_values || !start_date || !end_date || !max_number) {
      toast["warning"](this.props.t('messages.fill_all_fields'));
      return;
    }
    this.setState({
      loading: true
    });
    campaignTarget(target_values, start_date, end_date, max_number)
      .then(value => {
        if (!value || value.length === 0) {
          toast["warning"](this.props.t('errors.no_result', { element: 'Campaign Target' }));  
        }
        this.setState({
          campaigns: value,
          loading: false
        });
      }).catch(error => {
        console.error("Error loading campaign", error);
        toast["error"](this.props.t('errors.loading', { element: 'Campaign', message: error.message }));
        this.setState({
          campaigns: [],
          loading: false
        });
      });
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("campaigns.actions.target")}
          subheading={t("campaigns.actions.target-description")}
          icon="pe-7s-glasses icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <Row>
                    <Col sm={6} md={3}>
                      <FormGroup>
                        <Label for="target" >{t('campaigns.target')}</Label>
                        <DropdownList
                          id="target_values"
                          data={this.state.target_data}
                          value={this.state.target_values}
                          onChange={value => this.handleChange({ name: "target_values", value: value })}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={3}>
                      <FormGroup>
                        <Label for="max_number" >{t('campaigns.max_number')}</Label>
                        <NumericInput id="max_number" className="form-control"
                          value={this.state.max_number} min={0} step={1}
                          onChange={num => this.handleChange({ name: "max_number", value: num })} />
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={3}>
                      <Label for="start_date" >{t('campaigns.from_date')}</Label>
                      <DatePicker id="start_date" className="form-control"
                        selected={this.state.start_date} dateFormat="yyyy-MM-dd"
                        onChange={date => this.handleChange({ name: "start_date", value: date })} />
                    </Col>
                    <Col sm={6} md={3}>
                      <Label for="end_date" >{t('campaigns.to_date')}</Label>
                      <DatePicker id="end_date" className="form-control"
                        selected={this.state.end_date} dateFormat="yyyy-MM-dd"
                        onChange={date => this.handleChange({ name: "end_date", value: date })} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <br />
                      <Button color="primary" onClick={this.visualize}>{t('campaigns.actions.find')}</Button>
                      <InfoPopover id="campaigns-instructions" mid body={t('campaigns.actions.find-information')} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <label>{t("campaigns.information")}</label>
                  <TargetGraph campaigns={this.state.campaigns} loading={this.state.loading} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withRouter(withTranslation()(TargetCampaigns))