import React, { Fragment } from 'react';
import {
  Button, Card, CardBody, CardTitle, Col, Row, CardText
} from 'reactstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import ReactTable from 'react-table';
import PageTitle from '../../Layout/Components/PageTitle';
import { getDomainProximity } from '../../API/DomainProximityAPI';
import InfoPopover from 'Layout/Components/InfoPopover';

class DomainProximity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      domain: '',
      domainProximity: []
    };
  }

  componentDidMount() {
    getDomainProximity().then(result => {
      if (result && result.domain && result.similarity) {
        this.setState({
          domain: result.domain,
          domainProximity: result.similarity
        });
      }
    }).catch(error => {
      console.error("Error getting Domain Proximoty", error);
      toast["error"](this.props.t('errors.loading', { element: 'Domain Proximoty', message: error.message }));
    });
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("threat_intelligence.domain_proximity.title")}
          subheading={t("threat_intelligence.domain_proximity.description")}
          icon="pe-7s-look icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12" lg="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardText>{t("threat_intelligence.domain_proximity.text")}</CardText>
                  <ReactTable
                    columns={[
                      {
                        Header: t("threat_intelligence.domain_proximity.domain"),
                        accessor: 'domain'
                      },
                      {
                        Header: ({data, column}) => (
                          <div>{t("threat_intelligence.domain_proximity.proximity")} <InfoPopover id="proximity-instructions" small body={t('threat_intelligence.domain_proximity.proximity-information')} /></div> 
                        ),
                        accessor: 'value',
                        Cell: ({ column, cell, row }) => (
                          <div> {parseFloat(row.value).toFixed(2)} </div>
                        )
                      }
                    ]}
                    defaultPageSize={5}
                    data={this.state.domainProximity}
                    className="-striped -highlight" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
}

export default withTranslation()(DomainProximity);