import { doGet, doPost, doDelete, getDomain } from "./BaseAPI";

export function getDomains(domain = null) {
  if (!domain) {
    return doGet(`/organization/domains/domain`); // organization
  }
  return doGet(`/organization/domains/domain/${domain}`); // admin
}

export function createDomain(domainregistered) {
  return doPost("/organization/domains/create", {
    domain: getDomain(),
    domainregistered: domainregistered
  });
}

export function deleteDomain(id) {
  return doDelete(`/organization/domains/delete/${id}`);
}
