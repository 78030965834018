import MessageOrigin from "./MessageOrigin";
import MessageOriginMap from "./MessageOriginMap";
import MessageOriginTable from "./MessageOriginTable" ;
import MessageOriginTableUser from "./MessageOriginTableUser";

export default MessageOrigin;

export {
  MessageOriginMap,
  MessageOriginTable,
  MessageOriginTableUser
};
