import React, { Fragment } from "react";
import { Doughnut } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import Chart from 'chart.js';

Chart.pluginService.register({
  beforeDraw: function(chart) {
    if(chart.options.center && chart.options.center.enabled){
      var width = chart.chart.width,
          height = chart.chart.height,
          ctx = chart.chart.ctx;

      ctx.restore();
      var fontSize = (height / 114).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "middle";

      var text = chart.options.center.text,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }
});
class EmailStatDoughnutChart extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data: this.props.data,
      labels: this.props.labels,
      label: this.props.label,
      percentage: this.props.per
    }
  }
  componentWillReceiveProps(props){
    this.setState({
      data: props.data,
      percentage: props.per
    })
  }
  render(){
    var color_labels = ['rgb(0,128,0)', 'rgb(204,255,204)']
    if(colors_assocaited_with_labels[this.state.label]){
      color_labels = colors_assocaited_with_labels[this.state.label]
    }
    return(
      <Doughnut
        data = {{
          labels: this.state.labels,
          datasets: [{
            data: this.state.data,
            backgroundColor: [
              'rgb(221,221,221)',
              color_labels[0]
            ],
            hoverBorderColor: [
              'rgb(246,246,246)',
              color_labels[1]
            ],
            hoverBorderWidth: 7,
          }]
        }}
        options = {{
          cutoutPercentage:75,
          title:{
            display: true,
            text: this.state.label
          },
          plugins: {
            legend: false
          },
            center:{
              enabled: true,
              text: this.state.percentage+"%"
            }
        }}
      />
    )
  }
}
const colors_assocaited_with_labels = {
  'Email Sent': ['rgb(95,214,181)', 'rgb(203,253,238)'],
  'Email Opened': ['rgb(248,192,69)', 'rgb(253,239,206)'],
  'Email Clicked': ['rgb(246,181,67)', 'rgb(252,230,196)'],
  'Submitted Data': ['rgb(239,114,102)','rgb(249,214,211)'],
  'Email Reported': ['rgb(119,225,243)', 'rgb(182,250,237)']
}
export default withTranslation()(EmailStatDoughnutChart);
