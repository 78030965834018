import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
  Row, Col, Card, CardBody
} from 'reactstrap';
import ReactTable from "react-table";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";

import PageTitle from '../../Layout/Components/PageTitle';
import ConfirmAction from '../../Layout/Components/ConfirmAction';
import DepartmentModal from "./DepartmentModal";

import { getDepartments, deleteDepartment } from '../../API/DepartmentAPI';

class Departments extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      departments: []
    };

    this.addDepartmentCallback = this.addDepartmentCallback.bind(this);
    this.deleteDepartment = this.deleteDepartment.bind(this);
  }

  componentDidMount() {
    this.fillTable();
  }

  fillTable() {
    getDepartments().then(result => {
      this.setState({
        departments: result
      });
    }).catch(error => {
      console.error("Error getting Departments", error);
      toast["error"](this.props.t('errors.loading', { element: 'Departments', message: error.message }));
    });
  }

  addDepartmentCallback() {
    toast["success"](this.props.t('messages.success_create', { element: 'Department' }));
    this.fillTable();
  }

  deleteDepartment(department) {
    deleteDepartment(department).then(result => {
      this.fillTable();
    }).catch(error => {
      console.error("Error deleting Department", error);
      toast["error"](this.props.t('errors.deleting', { element: 'Department', message: error.message }));
    });
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("departments.title")}
          subheading={t("departments.description")}
          icon="pe-7s-users icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <DepartmentModal addDepartmentCallback={this.addDepartmentCallback} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    data={this.state.departments}
                    columns={[
                      {
                        Header: t("departments.name"),
                        accessor: 'department'
                      },
                      {
                        Header: t("departments.actions.title"),
                        accessor: 'id',
                        width: 100,
                        Cell: ({ column, cell, row }) => (
                          <div className="d-block w-100 text-center">
                            <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                              message={t('messages.confirm_delete', { element: 'department', object: row.department })}
                              onConfirm={() => this.deleteDepartment(row.department)}>
                              {t("departments.actions.delete")}
                            </ConfirmAction>
                          </div>
                        )
                      }
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment >
    )
  }
}

export default withTranslation()(Departments);
