import { doGet, doPost } from "./BaseAPI";

export function getAddinConfig(domain = null) {
  if (!domain) {
    return doGet(`/organization/addin/domain`); // organization
  }
  return doGet(`/organization/addin/domain/${domain}`); // admin
}

export function updateAddinConfig(configs) {
  return doPost("/organization/addin/update", configs);
}
