import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import PageTitle from 'Layout/Components/PageTitle';
import FootprintMap from './FootprintMap';
import ThreatHighlights from "./ThreatHighlights";
import { getGeolocation } from '../../../API/ConsoleServicesAPI';
import { MessageHeader } from "Application/EmailAnalysis";
import { AuthContext } from "context/AuthContext";

class EmailDetails extends React.Component {

  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      decision: "",
      message: {},
      threats: [],
      incident: {},
      campaign: {},
      origin: []
    }
    this.loadDetails = this.loadDetails.bind(this);
  }

  componentDidMount() {
    if (!this.props.state) {
      toast["error"](this.props.t('threat_intelligence.messages.no_email_details'));
      return;
    }
    const { decision, message, threats, incident } = this.props.state;
    this.loadDetails(decision, message, threats, incident);
  }

  componentDidUpdate(prevProps) {
    if (this.props.state !== prevProps.state) {
      const { decision, message, threats, incident } = this.props.state;
      this.loadDetails(decision, message, threats, incident);
    }
  }

  loadDetails(decision, message, threats, incident) {
    this.setState({
      loaded: true,
      decision: decision,
      message: message,
      threats: threats,
      incident: incident,
      campaign: {}
    });
    if (message.originSourceIP) {
      getGeolocation([message.originSourceIP]).then(result => {
        this.setState({
          origin: result.geoips[0]
        })
      }).catch(error => {
        console.error("Error getting Origin Geolocation", error);
        toast["error"](this.props.t('errors.loading', { element: 'Origin Geolocation', message: error.message }));
      });
    }
  }

  render() {
    const { t, i18n } = this.props;

    const { loaded, decision, message, threats, origin, campaign } = this.state;

    const emailPath = loaded ? <FootprintMap originIP={message.originSourceIP} smtpIPs={message.smtpIPs} /> : "";

    const messageHeader = () => {
      const { incident } = this.state;
      const user = this.context.user;
      if (incident && user && user.roles.includes('EmailVeritas Admin')) {
        return <Row>
          <Col md="12">
            <MessageHeader loaded={loaded}
              message={message}
              incident={incident}/>
          </Col>
        </Row>;
      }
      return <></>;
    }

    return (
      <Fragment>
        <PageTitle
          heading={t("threat_intelligence.email_details.title")}
          icon="pe-7s-look icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="6" sm="12">
              <ThreatHighlights decision={decision} message={message} threats={threats} origin={origin} campaign={campaign} />
            </Col>
            <Col md="6" sm="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>{t("threat_intelligence.email_details.email_path")}</CardTitle>
                  <Row form>
                    <Col md={12}>
                      {emailPath}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {messageHeader()}
        </CSSTransitionGroup>
      </Fragment >
    )
  }
}

export default withTranslation()(EmailDetails);
