import React, { Fragment } from 'react';
import {
  Col, FormGroup, Label, Input, Row, Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ConfirmAction from 'Layout/Components/ConfirmAction';
import ReactTable from 'react-table';
import AttachmentModal from './AttachmentModal';

class Attachments extends React.Component {

  constructor(props) {
    super(props);

    this.addAttachment = this.addAttachment.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
  }

  addAttachment(attachment) {
    if (typeof this.props.onAddAttachment === 'function') {
      this.props.onAddAttachment(attachment);
    }
  }

  removeAttachment(index) {
    if (typeof this.props.onRemoveAttachment === 'function') {
      this.props.onRemoveAttachment(index);
    }
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <Row>
          <Col md={12}>
            <h3 className="form-heading pt-3 pb-3 float-left">
              {t('campaign.message-info.attachments.title')}
            </h3>
            <AttachmentModal onSubmit={this.addAttachment} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ReactTable
              columns={[
                {
                  Header: t("campaign.message-info.attachments.name"),
                  accessor: 'Name'
                },
                {
                  Header: t("campaign.message-info.attachments.format"),
                  accessor: 'Format'
                },
                {
                  Header: t("campaign.message-info.attachments.size"),
                  accessor: 'Size'
                },
                {
                  Header: t("campaign.message-info.attachments.md5"),
                  accessor: 'Md5'
                },
                {
                  Header: t("campaign.message-info.attachments.sha1"),
                  accessor: 'Sha1'
                },
                {
                  Header: t("campaign.message-info.attachments.sha256"),
                  accessor: 'Sha256'
                },
                {
                  Header: t("campaign.message-info.attachments.fuzzy_hashes"),
                  accessor: 'FuzzyHashes'
                },
                {
                  Header: t("actions.title"),
                  id: 'actions',
                  accessor: 'Name',
                  width: 200,
                  Cell: (prop) => (
                    <div className="d-block w-100 text-center">
                      <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                        message={t('messages.confirm_remove', { object: prop.value })}
                        onConfirm={() => this.removeAttachment(prop.index)}>
                        {t("campaign.message-info.actions.remove")}
                      </ConfirmAction>
                    </div>
                  )
                }
              ]}
              data={this.props.Attachments.Files}
              showPageSizeOptions={false}
              showPaginationBottom={false}
              minRows={4}
              filterable={false}
              className="-striped -highlight"
            />
          </Col>
        </Row>
      </Fragment >
    );
  }
}

export default withTranslation()(Attachments);
