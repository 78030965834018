import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Col, Row, FormGroup, Button } from 'reactstrap';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import { CampaignContext } from '../CampaignContext';
import ConfirmAction from 'Layout/Components/ConfirmAction';
import GeolocationInfoModal from './GeolocationInfoModal';

class GeolocationInfo extends React.Component {

  static contextType = CampaignContext;

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <Row>
          <Col md={12}>
            <h3 className="form-heading pt-3 pb-3 float-left">
              {t('campaign.geo-info.title')}
            </h3>
            <GeolocationInfoModal showOpenButton={!this.context.isEdit()} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ReactTable
              columns={[
                {
                  Header: t("campaign.geo-info.number"),
                  accessor: 'ASNumber'
                },
                {
                  Header: t("campaign.geo-info.organization"),
                  accessor: 'ASOrganization'
                },
                {
                  Header: t("campaign.geo-info.country"),
                  accessor: 'Country'
                },
                {
                  Header: t("campaign.geo-info.city"),
                  accessor: 'City'
                },
                {
                  Header: t("campaign.geo-info.lat"),
                  accessor: 'Lat'
                },
                {
                  Header: t("campaign.geo-info.lng"),
                  accessor: 'Lng'
                },
                {
                  Header: t("campaign.geo-info.postal-code"),
                  accessor: 'PostalCode'
                },
                {
                  Header: t("campaign.geo-info.isp"),
                  accessor: 'ISP'
                },
                {
                  Header: t("actions.title"),
                  id: 'actions',
                  accessor: 'ASOrganization',
                  width: 200,
                  Cell: (prop) => (
                    <div className="d-block w-100 text-center">
                      <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                        message={t('messages.confirm_remove', { object: prop.value })}
                        onConfirm={() => this.context.removeGeolocationInfo(prop.index)}
                        disabled={this.context.isEdit()}>
                        {t("campaign.geo-info.actions.remove")}
                      </ConfirmAction>
                    </div>
                  )
                }
              ]}
              data={this.context.campaign.GeolocationInfo}
              showPageSizeOptions={false}
              showPaginationBottom={false}
              minRows={4}
              filterable={false}
              className="-striped -highlight"
            />
          </Col>
        </Row>
      </Fragment >
    )
  }
}

export default withRouter(withTranslation()(GeolocationInfo));