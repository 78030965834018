import { doGet, doPost, doDelete, getDomain } from "./BaseAPI";

export function getRegularExpressions(domain = null) {
  if (!domain) {
    return doGet(`/organization/regularexpression/domain`); // organization
  }
  return doGet(`/organization/regularexpression/domain/${domain}`); // admin
}

export function createRegularExpression(expression, description, testPass, testFail) {
  return doPost("/organization/regularexpression/create", {
    domain: getDomain(),
    expression: expression,
    description: description,
    testPass: testPass,
    testFail: testFail
  });
}

export function deleteRegularExpression(id) {
  return doDelete(`/organization/regularexpression/delete/${id}`);
}
