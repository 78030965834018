import { doGet, doPost } from "./BaseAPI";

export function getPartners() {
  return doGet("/partner/all");
}

export function getPartner(domain = null) {
  if (!domain) {
    return doGet(`/partner/domain`); // Partner
  }
  return doGet(`/partner/domain/${domain}`); // Admin
}

export function getPartnerContact(domain) {
  return doGet(`/partner/contact/${domain}`);
}

export function createPartner(partner) {
  return doPost("/partner/create", partner);
}

export function updatePartner(partner) {
  return doPost("/partner/update", partner);
}
