import BaseAPI, { getDomain } from "./BaseAPI";

class GatewayAPI extends BaseAPI {

    buildURL(url) {
        return process.env.REACT_APP_CONSOLE_URL + url;
    }

    getOrganizationGateways() {
        const domain = getDomain(); // get current domain
        return this.doGet(`/gateway/${domain}`);
    }

    registerOrganizationGateway(gateway) {
        const domain = getDomain(); // get current domain
        return this.doPost(`/gateway`, { domain: domain, gateway: gateway });
    }

    unregisterOrganizationGateway(gatewayId) {
        const domain = getDomain(); // get current domain
        return this.doDelete(`/gateway/${domain}/${gatewayId}`);
    }

    synchronizeGatewayServers() {
        const domain = getDomain(); // get current domain
        return this.doPut(`/gateway/${domain}/sync`);
    }

    releaseQuarantine(messageId, messageEML, resolution) {
        const domain = getDomain(); // get current domain
        return this.doPost(`/gateway/${domain}/release`, {
            messageId: messageId,
            eml: messageEML,
            resolution: resolution
        });
    }

    synchronizeServer(serverId) {
        return this.doPut(`/gatewayServer/${serverId}/sync`);
    }

    healthServer() {
        return this.doGet(`/gatewayServer/health`);
    }

}

export default new GatewayAPI();