import EmailDetails from "./EmailDetails";
import FootprintMap from "./FootprintMap";
import ThreatHighlights from "./ThreatHighlights"

export default EmailDetails;

export {
  FootprintMap,
  ThreatHighlights
};
