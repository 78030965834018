import React, { Fragment } from 'react';
import {
  Card, CardBody, Button, Spinner,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';

class UserTableRow extends React.Component{
    constructor(props){
      super(props);
      this.state = {
        modal: this.props.open,
        timelineData: this.props.timelineData,
        userDetail: this.props.userDetail
      }
      this.toggle = this.toggle.bind(this);
      this.getFullName = this.getFullName.bind(this);
      this.extractTimelineForEmail = this.extractTimelineForEmail.bind(this);
    }
    getFullName(){
      return this.state.userDetail.first_name+' '+this.state.userDetail.last_name;
    }
    extractTimelineForEmail(){
      return this.state.timelineData.filter(singleTimeline => singleTimeline.email == this.state.userDetail.email || singleTimeline.email == "");
    }
    render(){
      const { t, i18n } = this.props;
      const title = t("phishing_campaign.timeline_modal_title")+' '+this.getFullName();
      const timelineForUser = this.extractTimelineForEmail();
      return (
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
            <ModalBody>
              <ReactTable
                columns={[
                  {
                    accessor:'message'
                  },
                  {
                    Cell: ({row}) => (
                      <div>
                        {(new Date(row._original.time)).toLocaleString(undefined, {dateStyle:"full", timeStyle:"medium"})}
                      </div>
                    )
                  }
                ]}
                data={timelineForUser}
                className="-striped -highlight"
                pageSize={timelineForUser.length > 10 ? 10 : timelineForUser.length}
                showPagination={false}
                showHeader = {false}
                hideHeader = {true}
              />
            </ModalBody>
        </Modal>
      )
    }
    toggle(){
      if (this.state.modal && typeof this.props.onClose === 'function') {
        this.props.onClose(); // call onClose when closing
      }
      this.setState({
        modal: !this.state.modal
      })
    }
}
class PhishingCampaignTimelineDetails extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      userList: this.props.userList,
      timelineData: this.props.timelineData,
      userDetail: this.props.userDetail,
      isLoaded: false,
      openModal: false
    }
    this.openCampaignTimelineDetailsForUser = this.openCampaignTimelineDetailsForUser.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  componentWillReceiveProps(props){
    this.setState({
      userList: props.userList,
      isLoaded: props.isLoaded
    })
  }
  openCampaignTimelineDetailsForUser(row){
    this.setState({
      openModal: true,
      userDetail: row._original
    })
  }
  render(){
    const { t, i18n } = this.props;
    const openCampaignTimelineDetailsForUser = this.state.openModal &&
      <UserTableRow open={true} userDetail={this.state.userDetail} timelineData={this.state.timelineData} onClose={this.closeModal} {...this.props}/>;
    return(
      <CardBody>
        {openCampaignTimelineDetailsForUser}
        {this.state.isLoaded ?
          <ReactTable
            columns={[
              {
                Header: t("phishing_campaign.user.first_name"),
                Cell: ({row}) => (
                  <div style={{ cursor: 'pointer'}} onClick={() => this.openCampaignTimelineDetailsForUser(row)}>
                    {row._original.first_name}
                  </div>
                )
              },
              {
                Header: t("phishing_campaign.user.last_name"),
                accessor: "last_name"
              },
              {
                Header: t("phishing_campaign.user.email"),
                accessor: "email"
              },
              {
                Header: "Department",
                accessor: "position"
              },
              {
                Header: t("phishing_campaign.user.status"),
                accessor: "status"
              },
              {
                Header: t("phishing_campaign.user.reported"),
                accessor: "reported"
              }
            ]}
            data = {this.state.userList}
            className="-striped -highlight"
            pageSize={this.state.userList.length > 10 ? 10 : this.state.userList.length}
        /> : <div><Spinner color="primary" /> Data is loading!!!</div>}
      </CardBody>
    );
  }
  closeModal(){
    this.setState({
      openModal: false,
      userDetail: {}
    })
  }
}

export default withTranslation()(PhishingCampaignTimelineDetails);
