import React, { Fragment } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import { Partner } from './Partners';
import Organizations, { Organization } from './Organizations';

import Header from '../Layout/Header';
import AppSidebar from '../Layout/AppSidebar';
import ThemeOptions from '../Layout/ThemeOptions';
import ErrorBoundary from 'ErrorBoundary';

const NavItems = [
  {
    icon: 'pe-7s-display1',
    label: 'Partner Dashboard',
    to: '/partner',
  },
  {
    icon: 'pe-7s-cloud',
    label: 'Manage Organizations',
    to: '/partner/organizations',
  },
];

export default function PartnerApp() {

  let { path } = useRouteMatch();

  return (
    <Fragment>
      <ThemeOptions />
      <Header />
      <div className="app-main">
        <AppSidebar navigation={NavItems} />
        <div className="app-main__outer">
          <div className="app-main__inner"><ErrorBoundary>
            <Route exact path={`${path}`} component={Partner} />
            <Route exact path={`${path}/organizations`} component={Organizations} />
            <Route path={`${path}/organization/:domain`} component={Organization} />
            <Route exact path={`${path}/organization`} component={Organization} />
          </ErrorBoundary></div>
        </div>
      </div>
    </Fragment>
  )
}
