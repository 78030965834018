import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Multiselect } from 'react-widgets';
import { createPolicy } from 'API/DlpAPI';
import { toast } from 'react-toastify';
import PolicySelector from './PolicySelector';
import { getDepartments } from 'API/DepartmentAPI';
import InfoPopover from 'Layout/Components/InfoPopover';

class PolicyModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      isEdit: false,
      policy: this.initPolicy(),
      scopeDepartmentData: [],
      scopeUserData: []
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCreateScopeDepartment = this.handleCreateScopeDepartment.bind(this);
    this.handleCreateScopeUser = this.handleCreateScopeUser.bind(this);
    this.submit = this.submit.bind(this);
    this.onSelectPolicy = this.onSelectPolicy.bind(this);
  }

  initPolicy() {
    return {
      action: "log", // log is default; API uses []
      description: "",
      domain: "",
      expression: "",
      id: null, // used for editing
      scopedepartment: [],
      scopeorganization: false,
      scopeuser: [],
      testFail: "",
      testPass: ""
    }
  }

  componentDidMount() {
    getDepartments().then(result => {
      this.setState({
        scopeDepartmentData: result.map(el => el.department)
      });
    }).catch(error => {
      console.error("Error getting Departments", error);
      toast["error"](this.props.t('errors.loading', { element: 'Departments', message: error.message }));
    });
    if (this.props.policy) {
      const policy = this.props.policy;
      policy.action = policy.action.length > 0 ? policy.action[0] : ''; // API uses []
      this.setState({
        isEdit: true,
        policy: policy
      })
    }
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      policy: this.initPolicy()
    });
  }

  handleChange(event) {
    const obj = event.target || event;
    const key = obj.name;
    const value = obj.type === 'checkbox' ? obj.checked : obj.value;
    this.setState(updater => ({
      policy: {
        ...updater.policy,
        [key]: value
      }
    }));
  }

  handleCreateScopeDepartment(scopeDepartment) {
    const { scopeDepartmentData } = this.state;
    const scopedepartment = this.state.policy.scopedepartment;
    this.setState({
      scopeDepartmentData: [...scopeDepartmentData, scopeDepartment]
    })
    this.handleChange({
      name: "scopedepartment", value: [...scopedepartment, scopeDepartment]
    })
  }

  handleCreateScopeUser(scopeUser) {
    const { scopeUserData } = this.state;
    const scopeuser = this.state.policy.scopeuser;
    this.setState({
      scopeUserData: [...scopeUserData, scopeUser]
    })
    this.handleChange({
      name: "scopeuser", value: [...scopeuser, scopeUser]
    })
  }

  submit() {
    const policy = this.state.policy;
    policy.action = [policy.action] // API uses []
    createPolicy(policy).then(result => {
      this.toggle();
    }).catch(error => {
      console.error("Error creating Policy", error);
      toast["error"](this.props.t('errors.creating', { element: 'Policy', message: error.message }));
    })
  }

  onSelectPolicy(selected) {
    const { description, expression, testPass, testFail } = selected;
    this.setState(updater => ({
      policy: {
        ...updater.policy,
        description: description,
        expression: expression,
        testPass: testPass,
        testFail: testFail,
      }
    }));
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
        onClick={() => this.toggle()}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('dlp.policies.actions.create')}
      </Button>;

    const information = this.props.showOpenButton && <InfoPopover id="policy-instructions" mid body={t('dlp.policies.actions.information')} />;

    const title = this.state.isEdit ? t('dlp.policies.actions.edit') : t('dlp.policies.actions.create')

    const selectTemplate = this.state.isEdit ? ''
      : <PolicySelector onSelectCallback={this.onSelectPolicy} />

    return (
      <Fragment>
        {openButton}
        {information}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <FormGroup row>
                <Col sm={12}>{selectTemplate}</Col>
              </FormGroup>
              <FormGroup row>
                <Label for="description" sm={2}>{t('dlp.policies.description')}</Label>
                <Col sm={10}>
                  <Input type="text" name="description" id="description"
                    value={this.state.policy.description} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="expression" sm={2}>{t('dlp.policies.expression')}</Label>
                <Col sm={10}>
                  <Input type="text" name="expression" id="expression"
                    value={this.state.policy.expression} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="testPass" sm={2}>{t('dlp.policies.test_pass')}</Label>
                <Col sm={10}>
                  <Input type="text" name="testPass" id="testPass"
                    value={this.state.policy.testPass} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="testFail" sm={2}>{t('dlp.policies.test_fail')}</Label>
                <Col sm={10}>
                  <Input type="text" name="testFail" id="testFail"
                    value={this.state.policy.testFail} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="action" sm={2}>{t('dlp.policies.action')}</Label>
                <Col sm={10}>
                  <Input type="select" name="action" id="action"
                    value={this.state.policy.action} onChange={this.handleChange}>
                    <option>log</option>
                    <option>inform</option>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup>
                <h5>{t('dlp.policies.scope')}</h5>
              </FormGroup>
              <FormGroup row>
                <Label for="scopeorganization" sm={3}>{t('dlp.policies.organization')}</Label>
                <Col sm={9}>
                  <Input type="checkbox" name="scopeorganization" id="scopeorganization"
                    checked={this.state.policy.scopeorganization} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="scopeDepartment" sm={2}>{t('dlp.policies.department')}</Label>
                <Col sm={10}>
                  <Multiselect
                    data={this.state.scopeDepartmentData}
                    value={this.state.policy.scopedepartment}
                    allowCreate={false}
                    // onCreate={keyword => this.handleCreateScopeDepartment(keyword)}
                    onChange={value => this.handleChange({ name: "scopedepartment", value: value })}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="scopeAccount" sm={2}>{t('dlp.policies.account')}</Label>
                <Col sm={10}>
                  <Multiselect
                    data={this.state.scopeUserData}
                    value={this.state.policy.scopeuser}
                    allowCreate='onFilter'
                    onCreate={keyword => this.handleCreateScopeUser(keyword)}
                    onChange={value => this.handleChange({ name: "scopeuser", value: value })}
                  />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>{t('actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

PolicyModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(PolicyModal);
