import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import ReactTable from "react-table";
// import selectTableHOC from "react-table/lib/hoc/selectTable";
import { withTranslation } from 'react-i18next';
import { toast } from "react-toastify";

import PageTitle from '../../Layout/Components/PageTitle';
import { getQuarantineList } from "API/QuarantineAPI";

// const SelectTable = selectTableHOC(ReactTable);

class QuarantineList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      messages: [],
      // selection: new Map(),
      // selectAll: false
    }

    this.loadQuarantinedMessages = this.loadQuarantinedMessages.bind(this);
    // this.closeQuarantines = this.closeQuarantines.bind(this);
    // this.toggleSelection = this.toggleSelection.bind(this);
    // this.toggleAll = this.toggleAll.bind(this);
  }

  componentDidMount() {
    this.loadQuarantinedMessages();
  }

  loadQuarantinedMessages() {
    getQuarantineList().then(result => {
      this.setState({
        messages: result
      })
    }).catch(error => {
      console.error("Error getting Quarantined Messages", error);
      toast["error"](this.props.t('errors.loading', { element: 'Quarantined Messages', message: error.message }));
    });
  }

  /*closeQuarantines(action) {
    const messages = Array.from(this.state.selection.values())
      .map(message => {
        message.resolvedas = action;
        return message
      });
    closeQuarantineList(quarantines).then(result => {
      toast["success"](this.props.t('quarantines.messages.success_closing_list'));
      this.loadQuarantinedMessages();
    }).catch(error => {
      console.error("Error closing Quarantine", error);
      toast["error"](this.props.t('quarantines.messages.success_closing_list', { message: error.message }));
    });
  }*/

  /*toggleSelection(key, shift, row) {
    let { selectAll, selection } = this.state;

    if (selection.has(row.id)) { // if key exists, remove it
      selection.delete(row.id);
    } else { // it doesn't exist, add it
      selection.set(row.id,
        {
          providerMessageId: row.details.messageDetails.providerMessageId,
          resolvedas: ""
        }
      );
    }

    if (selection.size > 0) {
      // get the internals of ReactTable and get the sortedData
      const wrappedInstance = this.selectTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // adjust selectAll flag accordingly 
      selectAll = currentRecords.length === selection.size;
    }

    this.setState({ selectAll, selection });
  }*/

  /*toggleAll() {
    const selectAll = this.state.selectAll ? false : true;
    const selection = new Map();
    if (selectAll) {
      // get the internals of ReactTable and get the sortedData
      const wrappedInstance = this.selectTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // push all the IDs onto the selection array
      currentRecords.forEach(item => {
        if (item._original) {
          selection.set(item._original.id,
            {
              providerMessageId: item._original.details.messageDetails.providerMessageId,
              resolvedas: ""
            }
          );
        }
      });
    }
    this.setState({ selectAll, selection });
  };*/

  render() {
    const { t, i18n, history } = this.props;

    /*const action = (action, label, color) => {
      return (
        <Button color={color} className="mr-1"
          disabled={this.state.selection.size === 0}
          onClick={() => this.closeQuarantines(action)}>
          {label}
        </Button>
      );
    }*/

    return (
      <Fragment>
        <PageTitle
          heading={t("quarantines.title")}
          icon="pe-7s-junk icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          {/* <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  {action("false positive", t("quarantines.actions.false_positive"), "primary")}
                  {action("legitimate", t("quarantines.actions.legitimate"), "primary")}
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable //<SelectTable
                    keyField="id"
                    columns={[
                      {
                        Header: t("quarantines.sender"),
                        accessor: 'details.messageDetails.originEmailAddress'
                      },
                      {
                        Header: t("quarantines.subject"),
                        accessor: 'details.messageDetails.subject'
                      },
                      {
                        Header: t("quarantines.category"),
                        accessor: 'userClassification'
                      },
                      {
                        Header: t("quarantines.reported_at"),
                        accessor: 'openDate'
                      },
                      {
                        Header: t("quarantines.reported_by"),
                        accessor: 'details.messageDetails.account'
                      },
                    ]}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            if (e.target.tagName.toLowerCase() === 'button') {
                              return; // Ignore clicks on buttons
                            }
                            history.push({
                              pathname: '/organization/phishing_detector/quarantine',
                              state: { providerMessageId: rowInfo.original.details.messageDetails.providerMessageId }
                            })
                          },
                          style: {
                            cursor: 'pointer'
                          }
                        }
                      }
                      return {}
                    }}
                    data={this.state.messages}
                    //ref={r => (this.selectTable = r)}
                    // selectAll={this.state.selectAll}
                    // isSelected={key => this.state.selection.has(key)}
                    // toggleAll={this.toggleAll}
                    // toggleSelection={this.toggleSelection}
                    defaultPageSize={10}
                    className="-striped -highlight" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment >
    )
  }
}

export default withTranslation()(QuarantineList);
