import React, { Fragment } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPassword from './Auth/ForgotPassword';
import ResetPassword from './Auth/ResetPassword';
import ConfirmEmail from './Auth/ConfirmEmail';

export default function AuthApp() {

  const { t, i18n } = useTranslation();

  let { path } = useRouteMatch();

  return (
    <Fragment>
      <Route exact path={`${path}/login`} component={Login} />
      <Route exact path={`${path}/register`} component={Register} />
      <Route exact path={`${path}/password`} component={ForgotPassword} />
      <Route exact path={`${path}/reset-password`} component={ResetPassword} />
      <Route exact path={`${path}/confirm-email`} component={ConfirmEmail} />
      <ToastContainer />
    </Fragment >
  )
}
