import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input, Row, FormFeedback
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ReactTable from "react-table";
import {createGroup, uploadFile} from "../../../API/PhishingAPI";
import DemoCSVTemplate from '../group_template.csv';
import { authenticate, listAccounts } from 'API/OutlookAPI';

class UsersAndGroupsModal extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      modal: this.props.open,
      isEdit: this.props.group ? true:false,
      group:{
        id:"",
        name:"",
        targets:[]
      },
      target:{},
      targets:{},
      removedIndices: [],
      errorsInUserInput:{},
      errors:{}
    }
    this.toggle = this.toggle.bind(this);
    this.handleChangeForUser = this.handleChangeForUser.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.buildUserInfo = this.buildUserInfo.bind(this);
    this.uploadBulkUsers = this.uploadBulkUsers.bind(this);
    this.importADUsers = this.importADUsers.bind(this);
    this.submitGroup = this.submitGroup.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleValidationForUser = this.handleValidationForUser.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.removeUser = this.removeUser.bind(this);
  }
  
  componentDidMount(){
    if(this.state.isEdit){
      console.log(this.props.group.targets);
      this.setState({
        group: this.props.group,
        targets: [...this.props.group.targets]
      })
    }
  }

  buildUserInfo(firstName, lastName, email, position) {
    const userInfo = {};
    userInfo["first_name"] = firstName;
    userInfo["last_name"] = lastName;
    userInfo["email"] = email;
    userInfo["position"] = position;
    return userInfo;
  }

  uploadBulkUsers(event) {
    const files = event.target.files;
    const csvFile= files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileText = e.target.result;
      const allTextLines = fileText.split(/\r\n|\n/);
      console.log(allTextLines);
      const userList = [];
      for (var i = 1; i < allTextLines.length; i++) {
        const rawUserInfo = allTextLines[i].split(',');
        const userInfo = this.buildUserInfo(
          rawUserInfo[0] || "",
          rawUserInfo[1] || "",
          rawUserInfo[2] || "",
          rawUserInfo[3] || "");
        userList.push(userInfo);
      }
      this.setState(previousState => ({
        targets:[...previousState.targets, ...userList]
      }));
    };
    if (csvFile) {
      reader.readAsText(csvFile);
    }
  }

  importADUsers(event) {
    authenticate().then(auth => {
      listAccounts().then(accounts => {
        const userList = [];
        for (const account of accounts) {
          const names = account.name.split[" "];
          const userInfo = this.buildUserInfo(names[0], names.slice(1).join(' '), account.email, account.department);
          userList.push(userInfo);
        }
        this.setState(previousState => ({
          targets:[...previousState.targets, ...userList]
        }));
      })
    }).catch(error => {
      console.error("Error getting Outlook Accounts", error);
      if ("popup_window_error" === error.errorCode) {
        toast["warning"](this.props.t('organizations.messages.error-blocking-popup'));
      } else {
        toast["error"](this.props.t('errors.loading', { element: 'Outlook Accounts', message: error.message }));
      }
    });
  }

  handleChange(event){
    const key = event.target.name;
    const value = event.target.type === 'checkbox'
      ? event.target.checked : event.target.value;
    this.setState(updater => ({
      group: {
        ...updater.group,
        [key]: value
      }
    }));
  }

  handleChangeForUser(event){
    const key = event.target.name;
    const value = event.target.type === 'checkbox'
      ? event.target.checked : event.target.value;
    this.setState(updater => ({
      target: {
        ...updater.target,
        [key]: value
      }
    }));
  }

  handleValidationForUser(){
    var fields = this.state.target;
    var errors = {};
    var formIsValid = true;
    //First Name
    if(!fields["first_name"]){
      formIsValid = false;
      errors["first_name"] = "Cannot be empty";
    }
    //Last Name
    if(!fields["last_name"]){
      formIsValid = false;
      errors["last_name"] = "Cannot be empty";
    }
    //Email
    if(!fields["email"]){
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    }
    if(typeof fields["email"] !== "undefined"){
      let lastAtPos = fields["email"].lastIndexOf('@');
      let lastDotPos = fields["email"].lastIndexOf('.');
      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1
      && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }
    this.setState({errorsInUserInput: errors});
    return formIsValid;
  }

  handleValidation(){
    var fields = this.state.group;
    var errors = {};
    var formIsValid = true;
    if(!fields["name"]){
      formIsValid = false;
      errors["name"] = "Group Name cannot be empty";
    }
    this.setState({errors: errors});
    return formIsValid;
  }

  submitUser(){
    if(!this.handleValidationForUser()){
      return;
    }
    this.setState(previousState => ({
      targets:[...previousState.targets, this.state.target]
      /*group:{
        ...previousState.group,
        targets:[...previousState.group.targets, this.state.target]
      }*/,
      target:{
        first_name:"",
        last_name:"",
        email:"",
        position:""
      },
      errors:{}
    }), () => {console.log(this.state.group.targets);console.log(this.state.target);});
  }

  submitGroup(){
    if(!this.handleValidation()){
      return;
    }
    if(this.state.targets.length === 0){
      toast["error"]("No target specified");
      return
    }
    this.state.group.targets = this.state.targets;
    const promise = createGroup(this.state.group);
    promise
      .then(result => {
        this.toggle();
        this.props.onSubmit();
      }).catch(error => {
        const messageKey = this.state.isEdit ? 'errors.updating' : 'errors.creating';
        toast["error"](this.props.t(messageKey, { element: 'Group', message: error.message }));
      })
  }

  removeUser(row){
    let targets = this.state.targets.filter((ele, index) => index != row._index);
    this.setState({
      targets: targets
    })
  }

  toggle(){
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      group:{
        name:"",
        targets:[]
      },
      target:{},
      targets:[],
      errors:{},
      errorsInUserInput:{}
    });
  }

  render(){
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
    <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success" onClick={() => this.toggle()}>
      <i className="lnr-plus-circle btn-icon-wrapper"> </i>
      {t('users_and_groups.actions.create')}
    </Button>;

    return(
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
          <ModalHeader toggle={this.toggle}>{t('users_and_groups.actions.create')}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>{t('users_and_groups.name')}:</Label>
              <Input type="text" name="name" id="name" placeholder="Group Name" value={this.state.group.name} onChange={this.handleChange}/>
              <span style={{color: "red"}}>{this.state.errors["name"]}</span>
            </FormGroup>
            <FormGroup row>
              <Col sm={6}>
                <Input id="attachmentUpload" type="file" hidden accept=".csv" onChange={this.uploadBulkUsers} />
                <Button className="mb-2 mr-2 btn-icon btn-shadow" size="md" color="primary" onClick={() => document.getElementById("attachmentUpload").click()}>
                  <i className="lnr-plus-circle btn-icon-wrapper"> </i>
                  {t('users_and_groups.actions.import_users')}
                </Button>
                <a id="downloadTemplate" href={DemoCSVTemplate} download="" hidden>_</a>
                <Button color="link" className='mb-2' onClick={() => document.getElementById("downloadTemplate").click()}>
                  {t('users_and_groups.actions.download_template')}
                </Button>
              </Col>
              <Col sm={6}>
                <Button className="mb-2 mr-2 btn-icon btn-shadow" size="md" color="primary" onClick={this.importADUsers}>
                  <i className="lnr-cloud-sync btn-icon-wrapper"> </i>
                  {t('users_and_groups.actions.import_from_ad')}
                </Button>
              </Col>
            </FormGroup>
            <Form onSubmit={(e) => { e.preventDefault(); this.submitUser(); }}>
              <FormGroup row>
                  <Col sm={2}>
                    <Input type="text" name="first_name" id="first_name" placeholder={t('users_and_groups.first_name')}
                    value={this.state.target.first_name} onChange={this.handleChangeForUser}/>
                    <span style={{color: "red"}}>{this.state.errorsInUserInput["first_name"]}</span>
                  </Col>
                  <Col sm={2}>
                    <Input type="text" name="last_name" id="last_name" placeholder={t('users_and_groups.last_name')}
                    value={this.state.target.last_name} onChange={this.handleChangeForUser}/>
                    <span style={{color: "red"}}>{this.state.errorsInUserInput["last_name"]}</span>
                  </Col>
                  <Col sm={3}>
                    <Input type="email" name="email" id="email" placeholder={t('users_and_groups.email')}
                    value={this.state.target.email} onChange={this.handleChangeForUser}/>
                    <span style={{color: "red"}}>{this.state.errorsInUserInput["email"]}</span>
                  </Col>
                  <Col sm={3}>
                    <Input type="text" name="position" id="position" placeholder={t('users_and_groups.department')}
                    value={this.state.target.position} onChange={this.handleChangeForUser}/>
                  </Col>
                  <Col sm={2}>
                    <Button className="mb-2 mr-2 btn-icon btn-shadow" size="md" color="primary" onClick={this.submitUser}>
                      <i className="lnr-plus-circle btn-icon-wrapper"> </i>
                      {t('users_and_groups.actions.add')}
                    </Button>
                  </Col>
              </FormGroup>
            </Form>
            <FormGroup>
            {this.state.targets.length > 0 && <ReactTable
              columns={[
                {
                  Header: t("users_and_groups.user_name_list.first_name"),
                  accessor : 'first_name'
                },
                {
                  Header: t("users_and_groups.user_name_list.last_name"),
                  accessor : 'last_name'
                },
                {
                  Header: t("users_and_groups.user_name_list.email"),
                  accessor : 'email'
                },
                {
                  Header: t("users_and_groups.user_name_list.department"),
                  accessor : 'position'
                },
                {
                  Header: "Actions",
                  Cell: ({row}) => (
                    <div className="d-block w-100 text-center">
                      <Button className="mb-2 mr-2 btn-icon btn-shadow" size="md" color="success" onClick={() => {this.removeUser(row);} }>
                        <i className="pe-7s-trash btn-icon-wrapper mx-auto"> </i>
                      </Button>
                    </div>
                  )
                }
              ]}
                data={this.state.targets}
                showPageSizeOptions={false}
                defaultPageSize={10}
                pageSize={this.state.targets.length > 10 ? 10 : this.state.targets.length}
                className="-striped -highlight card"
                />
              }
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('users_and_groups.actions.cancel')}</Button>
            <Button color="primary" onClick={this.submitGroup}>{t('users_and_groups.actions.save')}</Button>{' '}
          </ModalFooter>
        </Modal>

      </Fragment>
    )
  }
}

UsersAndGroupsModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(UsersAndGroupsModal);
