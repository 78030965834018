import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { PartnerContext } from './PartnerContext';
import ConfirmAction from 'Layout/Components/ConfirmAction';

class ContactModal extends React.Component {

  static contextType = PartnerContext

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      contact: {
        name: "",
        email: "",
        phone: "",
        address: "",
        rule: ""
      }
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    if (this.context.isEditContact()) {
      const { name, email, phone, address, rule } = this.context.contact;
      this.setState({
        contact: { name, email, phone, address, rule }
      })
    }
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      contact: {
        name: "",
        email: "",
        phone: "",
        address: "",
        rule: ""
      }
    });
    this.context.openContact(); // reset context contact 
  }

  handleChange(event) {
    const obj = event.target || event;
    this.setState(updater => ({
      contact: {
        ...updater.contact,
        [obj.name]: obj.value
      }
    }));
  }

  submit() {
    if (this.context.isEditContact()) {
      this.context.editContact(this.state.contact);
    } else {
      this.context.addContact(this.state.contact);
    }
    this.toggle();
  }

  delete() {
    this.context.delContact(this.state.contact);
    this.toggle();
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button className={`mb-2 mr-2 btn-icon btn-shadow ${this.props.buttonClass}`} size="sm" color="primary"
        onClick={() => this.toggle(true)}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('partners.contact.action.create')}
      </Button>;

    const deleteButton = this.context.isEditContact() &&
      <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
        message={t('messages.confirm_delete', { element: 'contact', object: this.state.contact.email })}
        onConfirm={() => this.delete()}>{t("partners.contact.action.delete")}
      </ConfirmAction>;

    const title = this.context.isEditContact() ? t('partners.contact.action.edit') : t('partners.contact.action.create')

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <FormGroup row>
                <Label for="name" sm={2}>{t('partners.contact.name')}</Label>
                <Col sm={10}>
                  <Input type="text" name="name" id="name"
                    value={this.state.contact.name} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="email" sm={2}>{t('partners.contact.email')}</Label>
                <Col sm={10}>
                  <Input type="email" name="email" id="email" readOnly={this.context.isEditContact()}
                    value={this.state.contact.email} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="phone" sm={2}>{t('partners.contact.phone')}</Label>
                <Col sm={10}>
                  <Input type="text" name="phone" id="phone"
                    value={this.state.contact.phone} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="address" sm={2}>{t('partners.contact.address')}</Label>
                <Col sm={10}>
                  <Input type="text" name="address" id="address"
                    value={this.state.contact.address} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="rule" sm={2}>{t('partners.contact.rule')}</Label>
                <Col sm={10}>
                  <Input type="select" name="rule" id="rule"
                    value={this.state.contact.rule} onChange={this.handleChange}>
                    <option></option>
                    <option>admin</option>
                    <option>finance</option>
                  </Input>
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            {deleteButton}
            <Button color="link" onClick={this.toggle}>{t('actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>{t('actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

ContactModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(ContactModal);
