import React from "react";
import { withTranslation } from 'react-i18next';
import * as d3 from 'd3';

class TargetGraph extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {}
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.loading) {
      d3.selectAll("svg").remove();
      return;
    }

    if (this.props.campaigns !== prevProps.campaigns) {
      const campaigns = this.props.campaigns;
      let targets = [];
      campaigns.forEach(c => {
        targets = targets.concat(c.target.filter(t => !targets.includes(t)))
      });

      const data = {
        text: '', // create a fake root to show multiple 'targets' as roots
        children: targets.map(element => {
          return {
            id: element,
            text: `Target: ${element}`,
            children: []
          };
        })
      };

      const links = [];
      campaigns.forEach(element => {
        let origin = element.originSourceIP;
        // let origin = element.originSourceIP.filter((value, index, self) => {
        //   return self.indexOf(value) === index; // get unique values of origin
        // });
        let originComplement = origin.length > 10 ? `, ... (${origin.length} IPs)` : '';
        let campaign = {
          id: element.campaignid,
          text: `Campaign: ${element.campaignid}, Messages: ${element.total}`,
          number: element.total,
          children: [{
            text: `Origin:,${origin.slice(0, 10).join()}${originComplement}`,
            icon: '/imgs/Blue.jpg'
          }]
        };
        element.target.forEach((target, index) => {
          if (index === 0) { // include the campaign as child of first target
            let childIndex = data.children.findIndex(el => el.id === element.target[0]);
            data.children[childIndex].children.push(campaign);
          } else { // more targets? include as extra link
            links.push({ parent: target, child: campaign.id });
          }
        });
      });
      this.update(data, links);
    }
  }

  update(root, additionalLinks) {
    // set the dimensions and margins of the diagram
    const margin = { top: -120, right: 40, bottom: 40, left: 0 };
    const width = 750 - margin.right - margin.left;
    const height = 750 - margin.top - margin.bottom;

    // declares a tree layout and assigns the size
    let treemap = d3.tree().size([height - 100, width - 100]);

    //  assigns the data to a hierarchy using parent-child relationships
    let nodes = d3.hierarchy(root);

    // maps the node data to the tree layout
    nodes = treemap(nodes);

    // append the svg obgect to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    this.svg = d3.select("#graph").append("svg")
      .attr("width", width + margin.right + margin.left)
      .attr("height", height + margin.top + margin.bottom);
    let g = this.svg.append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // adds the links between the nodes
    let link = g.selectAll(".link")
      .data(nodes.descendants().slice(1))
      .enter().append("g")
      .attr("class", "link");

    link.append("path")
      .attr("d", (d) => this.diagonal(d))
      .attr('style', d => {
        return d.depth === 1 ? 'display: none' : ''; // hide the link to the fake root
      });;

    // adds each node as a group
    let node = g.selectAll(".node")
      .data(nodes.descendants())
      .enter().append("g")
      .attr("class", d => {
        return "node" + (d.children ? " node--internal" : " node--leaf");
      })
      .attr("transform", d => {
        return "translate(" + d.x + "," + d.y + ")";
      })
      .attr('style', d => {
        return d.depth === 0 ? 'display: none' : ''; // hide the fake root
      });

    // adds the graphic element to the node
    node.append("circle")
      .attr("r", (d) => d.data.number ? 5 + 10 * Math.log(d.data.number) : 10)
      .style("fill", "#ccc");

    node.append("image")
      .attr("href", d => { return d.data.icon; })
      .attr("x", "-125px")
      .attr("y", "-20px")
      .attr("width", "250px")
      .attr("height", "250px");

    // add the text to the node
    node.append("text")
      .attr("dy", ".35em")
      .attr("x", d => { return d.depth === 1 ? 0 : d.children ? 10 : -120; })
      .attr("y", d => { return d.depth === 1 ? -10 : d.children ? -20 : -15; })
      .text(d => { return d.data.text; })
      .call(this.wrapText, 30);

    // add additional links
    let descendants = nodes.descendants();
    additionalLinks.forEach(el => {
      let parent = descendants.find(node => node.data.id === el.parent);
      let child = descendants.find(node => node.data.id === el.child);
      let couplingLink = g.append("g").attr("class", "link");
      couplingLink.append("path")
        .attr("d", () => this.diagonal({
          x: child.x,
          y: child.y,
          parent: {
            x: parent.x,
            y: parent.y
          }
        }));
    })
  }

  diagonal(d) {
    return ("M" + d.x + "," + d.y
      + "C" + d.x + "," + (d.y + d.parent.y) / 2
      + " " + d.parent.x + "," + (d.y + d.parent.y) / 2
      + " " + d.parent.x + "," + d.parent.y);
  }

  wrapText(text, width) {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(',').reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        x = text.attr("x"),
        y = text.attr("y"),
        dy = 0, //parseFloat(text.attr("dy")),
        tspan = text.text(null)
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", dy + "em");
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text.append("tspan")
            .attr("x", x)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  }

  render() {
    return (
      <div id="graph"></div>
    )
  }
}

export default withTranslation()(TargetGraph);