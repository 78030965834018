import React, { Fragment } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {
    Spinner
} from 'reactstrap';
import { toast } from 'react-toastify';
import UserLoginAPI from 'API/UserLoginAPI';

class ConfirmEmail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        if (params.has('email_id') && params.has('code')) {
            UserLoginAPI.confirmEmail(params.get('email_id'), params.get('code')).then(result => {
                if (result) {
                    toast["success"](this.props.t('confirmEmail.success'));
                }
                this.setState({ loading: false });
            }).catch(error => {
                console.error("Error confirming email", error);
                toast["error"](this.props.t('confirmEmail.error'));
                this.setState({ loading: false });
            });
        } else {
            toast["error"](this.props.t('confirmEmail.error'));
            this.setState({ loading: false });
        }
    }

    render() {
        const { t, i18n } = this.props;

        const loading = this.state.loading
            ? <span><Spinner color="white" size="sm" /> {t("confirmEmail.actions.wait")}</span>
            : <a href="/" className="link-forgot">{t('confirmEmail.actions.login')}</a>;

        return (
            <Fragment>
                <div className="contact-us">
                    <section id="banner-default-login" data-aos="zoom-out"
                        className="banner-default-pages d-flex flex-column justify-content-center align-items-center banner-login">
                    </section>

                    <main id="main">
                        <section className="login">
                            <div className="container">
                                <div className="row">
                                    <div className="col"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12 mt-4 mt-lg-0"
                                        data-aos="fade-up" data-aos-delay="300">
                                        <div className="box-input">
                                            <div className="row">
                                                <div className="col"></div>
                                                <div className="col-6 mb-4">
                                                    <a href="#">
                                                        <img src="/imgs/logo.png" alt="" className="img-fluid" />
                                                    </a>
                                                </div>
                                                <div className="col"></div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 text-center pt-4">
                                                    <p>{t("confirmEmail.text")}</p>
                                                </div>
                                                <div className="col-12">
                                                    {loading}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>

                    <section className="text-center">
                        {t("login.copyright")}
                    </section>
                </div>
            </Fragment>
        )
    }
};

export default withTranslation()(ConfirmEmail);