import React from "react";
import { withTranslation } from 'react-i18next';
import * as d3 from 'd3';
import CountryHelper from "Application/Helpers/CountryHelper";

class RelevantGraph extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {}
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.loading) {
      d3.selectAll("svg").remove();
      return;
    }

    if (this.props.campaign !== prevProps.campaign) {
      const campaignId = this.props.campaignId;
      const campaign = this.props.campaign;
      const registrant = this.props.registrant;
      
      console.log(campaign);

      let data = {
        text: this.joinTexts(campaignId,
          campaign.generalInfo.campaignDescription.length > 21 ? `${campaign.generalInfo.campaignDescription.substring(0, 21)}...` : campaign.generalInfo.campaignDescription,
          campaign.dnsInfo.map(element => `${element.domainName}/${element.ip}`).join(";")).join(";"),
        icon: '/imgs/input.ico',
        children: [{
          text: campaign.dnsInfo.map((element, index) => {
            const regText = Object.entries(element.whoisInfo.registrant).filter(el => el[1] !== null).map(el => `${el[1]}`);
            return `Registrant ${index}: ${regText ? regText.join(",;") : ''}`;
            //return this.joinTexts(Object.entries(element.registrant).filter(el => el[1] !== null).map(el => `${el[1]}`))
          }).filter(element => element[0].length > 0).join(";"),
          icon: '/imgs/registrant.ico',
          linkText: ["registrant"]
        }]
      };
      
      if (registrant[0]) {
        let children = [];
        registrant[0].forEach((element, i) => {
          children.push({
            text: this.joinTexts(
              Object.entries(element.registrant).filter(el => el[1] !== null)
                .map(el => {
                  let key = el[0]; 
                  let val = el[1];
                  if (key === 'country') {
                    let country = CountryHelper.getCountry(val);
                    val = country.length > 25 ? `${country.substring(0, 25)}...` : country;
                  } else if (key === 'email') {
                    val = val.length > 25 ? `${val.substring(0, 25)}...` : val;
                  }
                  return `${key}: ${val}`
                }).join(";")
            ),
            icon: '/imgs/registrant.ico',
            linkText: [`sharing = ${element.sharingDegree.toFixed(2)}`, `same = ${element.sharedParameters.join(",;")}`].join(";")
          });
        });
        data.children[0].children = children;
      }

      this.update(data);
    }
  }

  joinTexts(...text) {
    return text.filter(el => el !== null)
  }

  update(treeData) {
    // set the dimensions and margins of the diagram
    const margin = { top: 40, right: 40, bottom: 40, left: 40 };
    const width = 900 - margin.right - margin.left;
    const height = 900 - margin.top - margin.bottom;

    //  assigns the data to a hierarchy using parent-child relationships
    let root = d3.hierarchy(treeData);

    // declares a tree layout and assigns the size
    let treemap = d3.tree()
      .size([height, width - 50]);

    // maps the node data to the tree layout
    let nodes = treemap(root);

    // append the svg obgect to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    this.svg = d3.select("#graph").append("svg")
      .attr("width", width + margin.right + margin.left)
      .attr("height", height + margin.top + margin.bottom)

    let g = this.svg.append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // adds the links between the nodes
    let link = g.selectAll(".link")
      .data(nodes.descendants().slice(1))
      .enter().append("g")
      .attr("class", "link");

    link.append("path")
      .attr("d", function (d) {
        return "M" + d.x + "," + d.y
          + "C" + d.x + "," + (d.y + d.parent.y) / 2
          + " " + d.parent.x + "," + (d.y + d.parent.y) / 2
          + " " + d.parent.x + "," + d.parent.y;
      });

    link.append("text")
      .attr("transform", function (d) {
        return "translate(" +
          ((d.parent.x + d.x) / 2) + "," +
          ((d.parent.y + d.y) / 2) + ")";
      })
      .attr("x", "-21px")
      .attr("y", "-21px")
      .attr("dy", ".35em")
      .text(function (d) { return d.data.linkText; })
      .call(this.wrapTextNode, 30);

    // adds each node as a group
    let node = g.selectAll(".node")
      .data(nodes.descendants())
      .enter().append("g")
      .attr("class", function (d) {
        return "node" + (d.children ? " node--internal" : " node--leaf");
      })
      .attr("transform", function (d) {
        return "translate(" + d.x + "," + d.y + ")";
      });

    // adds the image to the node
    node.append("image")
      .attr("href", function (d) { return d.data.icon; })
      .attr("x", "-21px")
      .attr("y", "-21px")
      .attr("width", "42px")
      .attr("height", "42px");

    node.append("text")
      .attr("dy", ".35em")
      .attr("x", function (d) { return d.children ? 24 : -50; })
      .attr("y", function (d) { return d.children ? -20 : 20; })
      // .attr("x", function (d) { return 24 })
      // .attr("y", function (d) { return -20 })
      .text(function (d) { return d.data.text; })
      .call(this.wrapTextNode, 30);
  }

  wrapTextNode(text, width) {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(';').reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        x = text.attr("x"),
        y = text.attr("y"),
        dy = 0, //parseFloat(text.attr("dy")),
        tspan = text.text(null)
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", dy + "em");
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text.append("tspan")
            .attr("x", x)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  }

  render() {
    return (
      <div id="graph"></div>
    )
  }
}

export default withTranslation()(RelevantGraph);