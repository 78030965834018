import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import ReactTable from "react-table";
import { withTranslation } from "react-i18next";
import matchSorter from 'match-sorter'

import { AccountContext } from './AccountContext';

import PageTitle from '../../Layout/Components/PageTitle';
import ConfirmAction from '../../Layout/Components/ConfirmAction';
import ActionsMenu from "./ActionsMenu";
import AccountModal from "./AccountModal";

class Accounts extends React.Component {

  static contextType = AccountContext;

  constructor(props) {
    super(props);

    this.state = {
      filtered: [],
      openEdit: false
    };

    this.filterAccount = this.filterAccount.bind(this);
  }

  filterAccount(email) {
    this.setState({
      filtered: [{ id: 'actions', value: email }]
    });
  }

  render() {
    const { t, i18n } = this.props;

    const editAccountModal = this.state.openEdit &&
      <AccountModal showOpenButton={false} open={true}
        onClose={() => this.setState({ openEdit: false })} />;

    return (
      <Fragment>
        <PageTitle
          heading={t("accounts.title")}
          subheading={t("accounts.description")}
          icon="pe-7s-users icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <ActionsMenu doSearch={this.filterAccount} />
                  {editAccountModal}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    columns={[
                      {
                        Header: t("accounts.email"),
                        accessor: 'email',
                        id: 'email'
                      },
                      {
                        Header: t("accounts.name"),
                        accessor: 'name',
                        id: 'name'
                      },
                      {
                        Header: t("accounts.department"),
                        accessor: 'department'
                      },
                      {
                        Header: t("accounts.created"),
                        accessor: 'created',
                        Cell: (prop) => {
                          let created = '';
                          if (prop.row.created) {
                            created = new Date(prop.row.created).toLocaleString();
                          }
                          return (
                            <div className="d-block w-100 text-center">
                              {created}
                            </div>
                          )
                        }
                      },
                      {
                        Header: t("accounts.status"),
                        accessor: 'status'
                      },
                      {
                        Header: t("accounts.actions.title"),
                        id: 'actions',
                        accessor: 'email',
                        width: 300,
                        Cell: (prop) => (
                          <div className="d-block w-100 text-center">
                            <Button className="mb-2 mr-2" color="success"
                              onClick={() => this.context.activateAccount(prop.original)}>{t("accounts.actions.activate")}</Button>
                            <Button className="mb-2 mr-2" color="warning"
                              onClick={() => this.context.suspendAccount(prop.original)}>{t("accounts.actions.suspend")}</Button>
                            <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                              message={t('messages.confirm_delete', { element: 'account', object: prop.value })}
                              onConfirm={() => this.context.deleteAccount(prop.value)}>{t("accounts.actions.delete")}</ConfirmAction>
                          </div>
                        ),
                        filterMethod: (filter, rows) => {
                          const result = matchSorter(rows, filter.value, {
                            keys: [
                              "email",
                              "name"
                            ], threshold: matchSorter.rankings.WORD_STARTS_WITH
                          });
                          return result;
                        },
                        filterAll: true
                      }
                    ]}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            if (e.target.tagName.toLowerCase() === 'button') {
                              return; // Ignore clicks on buttons
                            }
                            this.context.openAccount(rowInfo.original);
                            this.setState({ openEdit: true })
                          },
                          style: {
                            cursor: 'pointer'
                          }
                        }
                      }
                      return {}
                    }}
                    data={this.context.accounts}
                    defaultPageSize={10}
                    filterable={false}
                    filtered={this.state.filtered}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                    className="-striped -highlight" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withTranslation()(Accounts);
