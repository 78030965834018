import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input, FormCheck
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CKEditor from 'ckeditor4-react';
import { createEmailTemplate } from "../../../API/PhishingAPI";
import ImportEmailTemplateModal from './ImportEmailTemplateModal'
import ReactTable from "react-table";
//This CSS file is to set z-index for ck-list as it was less than modal and hence modal was covering ck-mentions.
import './custom_ck.css';

class EmailTemplateModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.open,
      isEdit: this.props.emailTemplate ? true : false,
      emailTemplate: {
        id: "",
        name: "",
        subject: "",
        text: "",
        html: "",
        attachments: []
      },
      add_tracking_image: false,
      someValue: '',
      errors: {}
    }

    this.toggle = this.toggle.bind(this);
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCkEditorInput = this.handleCkEditorInput.bind(this);
    this.updateHtmlAfterImportEmail = this.updateHtmlAfterImportEmail.bind(this);
    this.handleFileUploading = this.handleFileUploading.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleTrackingImageCheckbox = this.handleTrackingImageCheckbox.bind(this);
  }

  componentDidMount() {
    if (this.state.isEdit) {
      const { id, modified_date, ...emailTemplate } = this.props.emailTemplate;
      if (!this.props.copiedVersion) {
        emailTemplate['id'] = id;
      }
      this.setState({
        emailTemplate: emailTemplate
      });
    }
  }

  handleChange(event) {
    const key = event.target.name;
    console.log(key);
    const value = event.target.type === 'checkbox'
      ? event.target.checked : event.target.value;
    this.setState(updater => ({
      emailTemplate: {
        ...updater.emailTemplate,
        [key]: value
      }
    }));
  }

  handleTrackingImageCheckbox(event) {
    const key = event.target.name;
    this.setState({ add_tracking_image: event.target.checked }, () => {
      var { html } = this.state.emailTemplate;
      if (this.state.add_tracking_image) {
        if (html.indexOf("{{.Tracker}}") == -1 &&
          html.indexOf("{{.TrackingUrl}}") == -1) {
          html = html.replace("</body>", "<p>{{.Tracker}}</p></body>")
        }
      } else {
        html = html.replace("<p>{{.Tracker}}</p>", "")
      }
      this.setState(updater => ({
        emailTemplate: {
          ...updater.emailTemplate,
          html: html
        }
      }))
    });
  }

  handleCkEditorInput(event) {
    this.setState(updater => ({
      emailTemplate: {
        ...updater.emailTemplate,
        html: event.editor.getData()
      }
    }));
  }

  /*
  Import Email Template modal fetch the email template from raw message and then callback to set it.
  */
  updateHtmlAfterImportEmail(html, subject) {
    this.setState(updater => ({
      emailTemplate: {
        ...updater.emailTemplate,
        html: html,
        subject: subject
      }
    }));
  }

  //onload is using arrow function as if you pass simple function it won't have access to "this"
  handleFileUploading(event) {
    console.log('Length of Files is', event.target.files.length);
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = (e) => {
      const attachment = {
        name: file.name,
        content: reader.result.split(",")[1],
        type: file.type || "application/octet-stream"
      }
      this.setState(updater => ({
        emailTemplate: {
          ...updater.emailTemplate,
          attachments: [...updater.emailTemplate.attachments, attachment]
        }
      }), () => { console.log(this.state.emailTemplate.attachments); });
    }
    reader.readAsDataURL(file);
  }

  /*
  To hide by default "choose file" option we have hide the real file upload button and use proxy button
  which trigger the file upload event.
  */
  handleClick() {
    this.inputElement.click();
  }

  handleValidation() {
    var fields = this.state.emailTemplate;
    var errors = {};
    var formIsValid = true;

    //Name cannot be empty
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Name cannot be empty";
    }
    //Name cannot be empty
    if (!fields["subject"]) {
      formIsValid = false;
      errors["subject"] = "Subject cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  submit() {
    if (!this.handleValidation()) {
      return;
    }
    const promise = createEmailTemplate(this.state.emailTemplate);
    promise.then(result => {
      this.toggle();
      this.props.onSubmit();
    }).catch(error => {
      console.error("Error submiting admin", error);
      const messageKey = this.state.isEdit ? 'errors.updating' : 'errors.creating';
      toast["error"](this.props.t(messageKey, { element: 'Email template', message: error.message }));
    });
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      emailTemplate: {
        id: "",
        name: "",
        subject: "",
        text: "",
        html: "",
        attachments: []
      },
      add_tracking_image: false,
      errors: {}
    })
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="success"
        onClick={() => this.toggle()}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('email_template.actions.create')}
      </Button>;

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
          <ModalHeader toggle={this.toggle}>{t('email_template.actions.create')}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <FormGroup>
                <Label>Name:</Label>
                <Input type="text" name="name" id="name" value={this.state.emailTemplate.name} onChange={this.handleChange} />
                <span style={{ color: "red" }}>{this.state.errors["name"]}</span>
              </FormGroup>
              <FormGroup>
                <Label>Subject:</Label>
                <Input type="text" name="subject" id="subject" value={this.state.emailTemplate.subject} onChange={this.handleChange} />
                <span style={{ color: "red" }}>{this.state.errors["subject"]}</span>
              </FormGroup>
              <FormGroup>
                <ImportEmailTemplateModal onSubmit={this.updateHtmlAfterImportEmail} />
              </FormGroup>
              <FormGroup>
                <CKEditor
                  data={this.state.emailTemplate.html}
                  name="html"
                  config={{
                    fullPage: true,
                    allowedContent: true
                  }}
                  onChange={this.handleCkEditorInput}
                />
              </FormGroup>
              {/*Add Tracking image is not functional yet.
                  Even on phishing test we are not sending any parameter against this checkbox.*/}
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={this.state.add_tracking_image} onChange={this.handleTrackingImageCheckbox} />{' '}
                  {t('email_template.add_tracking_image')}
                </Label>
              </FormGroup>
              {/*<FormGroup>
                  <Button className="mb-2 mr-2 mt-3 btn-icon btn-shadow"  color="primary" onClick={this.handleClick}>
                    <i className="lnr-plus-circle btn-icon-wrapper"> </i>{t('email_template.add_files')}
                    <input id="attachmentUpload" type="file"   onChange={this.handleFileUploading} ref={input => this.inputElement = input} style={{"display":"none"}} multiple/>
                  </Button>
                </FormGroup>*/}
              <FormGroup>
                {this.state.emailTemplate.attachments.length > 0 && <ReactTable
                  columns={[
                    {
                      Header: t("email_template.name"),
                      accessor: 'name'
                    },
                    {
                      /* TODO: Replace inline style class with CSS class */
                      Header: "Actions",
                      Cell: ({ row }) => (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button className="mb-2 mr-2 btn-icon btn-shadow" size="md" color="success" onClick={() => { this.state.emailTemplate.attachments.pop(row._original); this.forceUpdate(); }}>
                            <i className="pe-7s-trash btn-icon-wrapper mx-auto"> </i>
                          </Button>
                        </div>
                      )
                    }
                  ]}
                  data={this.state.emailTemplate.attachments}
                  showPageSizeOptions={false}
                  defaultPageSize={10}
                  pageSize={this.state.emailTemplate.attachments.length}
                />
                }
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('email_template.actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>Save</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }
}

EmailTemplateModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(EmailTemplateModal);
