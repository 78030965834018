import { circle } from 'leaflet';
import React, { Fragment } from 'react';

class SearchBox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      searchTerms: [],
      multipleFields: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    if (this.props.placeholders && this.props.placeholders.length > 0) {
      this.setState({
        searchTerms: this.props.placeholders.map(_v => { return {term: ""}; }),
        multipleFields: true
      })
    }
  }

  handleChange(event, index) {
    const value = event.target.value;
    if (index >= 0) {
      this.setState({
        searchTerms: this.state.searchTerms.map((v, i) => {
          if (i !== index) {
            return v;
          }
          return { ...v, term: value };
        })
      });
    } else {
      this.setState({
        searchTerm: value
      });
    }
  }

  onKeyUp(event) {
    if (event.key === "Enter") {
      this.search();
    }
  }

  search() {
    const {multipleFields, searchTerms, searchTerm} = this.state;
    if (typeof this.props.doSearch === 'function') {
      this.props.doSearch(multipleFields ? searchTerms.map(v => v.term )  : searchTerm);
    }
  }

  render() {

    const {multipleFields, searchTerms, searchTerm} = this.state;
    const {placeholders, placeholder, wrapperClassName} = this.props;

    const inputHolderCustomClass = (index, last) => {
      if (index === 0 && !last) {
        return "input-holder-first";
      } else if (index > 0 && last) {
        return "input-holder-last";
      } 
      return "";
    }

    return (
      <Fragment>
        {multipleFields ? placeholders.map((value, index) => {
          let last = index === placeholders.length - 1;
          let customClass = inputHolderCustomClass(index, last);
          return <div className={`${wrapperClassName} search-wrapper active pos-line-left`} key={`search-wrapper-${index}`}>
            <div className={`${customClass} input-holder`}>
              <input type="text" className="search-input" placeholder={value} name="searchTerms" value={searchTerms[index].term} 
                onChange={event => this.handleChange(event, index)} onKeyUp={this.onKeyUp} />
              {last ? <button onClick={() => this.search()}
                className="search-icon"><span />
              </button> : ""}
            </div>
          </div>}) : (<div className={`${wrapperClassName} search-wrapper active pos-line-left`}>
            <div className="input-holder">
              <input type="text" className="search-input" placeholder={placeholder} ame="searchTerm" value={searchTerm} 
                onChange={event => this.handleChange(event)} onKeyUp={this.onKeyUp} />
              <button onClick={() => this.search()}
                className="search-icon"><span />
              </button>
            </div>
          </div>)
        }
      </Fragment>
    )
  }
}

export default SearchBox;