import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
  Row, Col, Card, CardBody,
} from 'reactstrap';
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';

import PageTitle from 'Layout/Components/PageTitle';
import BlogPostModal from "./BlogPostModal";

import { getBlogPosts, deleteBlogPost } from "../../API/BlogPostsAPI";
import { toast } from "react-toastify";
import ConfirmAction from "Layout/Components/ConfirmAction";

class BlogPosts extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      lang: "*",
      blogPosts: [],
      blogPost: {},
      openEdit: false
    };

    this.loadBlogPosts = this.loadBlogPosts.bind(this);
    this.onSubmitCallback = this.onSubmitCallback.bind(this);
  }

  componentDidMount() {
    this.loadBlogPosts();
  }

  loadBlogPosts() {
    getBlogPosts(this.state.lang).then(result => {
      this.setState({
        blogPosts: result
      })
    }).catch(error => {
      console.error("Error loading blog", error);
      toast["error"](this.props.t('errors.loading', { element: 'Blog', message: error.message }));
    });
  }

  deleteBlogPost(id) {
    deleteBlogPost(id).then(result => {
      this.loadBlogPosts();
    }).catch(error => {
      console.error("Error deleting blog", error);
      toast["error"](this.props.t('errors.deleting', { element: 'Blog', message: error.message }));
    });
  }

  onSubmitCallback() {
    this.loadBlogPosts();
  }

  render() {
    const { t, i18n } = this.props;

    const editBlogPostModal = this.state.openEdit &&
      <BlogPostModal showOpenButton={false} open={true} blogPost={this.state.blogPost}
        onClose={() => this.setState({ openEdit: false })}
        onSubmitCallback={this.onSubmitCallback} />;

    return (
      <Fragment>
        <PageTitle
          heading={t("blog.title")}
          icon="pe-7s-settings icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <BlogPostModal onSubmitCallback={this.onSubmitCallback} />
                  {editBlogPostModal}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    columns={[
                      {
                        Header: t("blog.blog_title"),
                        accessor: 'title',
                        id: 'title'
                      },
                      {
                        Header: t("blog.language"),
                        accessor: 'language',
                        id: 'language'
                      },
                      {
                        Header: t("blog.by"),
                        accessor: 'by',
                        id: 'by'
                      },
                      {
                        Header: t("blog.url"),
                        accessor: 'url',
                        id: 'url'
                      },
                      {
                        Header: t("blog.updated"),
                        accessor: 'updated',
                        id: 'updated',
                        Cell: (prop) => {
                          const updated = new Date(prop.value).toLocaleString();
                          return (
                            <div className="d-block w-100 text-center">
                              {updated}
                            </div>
                          )
                        }
                      },
                      {
                        Header: t("blog.actions.title"),
                        id: 'actions',
                        accessor: 'title',
                        width: 300,
                        Cell: (prop) => (
                          <div className="d-block w-100 text-center">
                            <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                              message={t('messages.confirm_delete', { element: 'blog', object: prop.value })}
                              onConfirm={() => this.deleteBlogPost(prop.original.id)}>{t("blog.actions.delete")}</ConfirmAction>
                          </div>)
                      }
                    ]}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            if (e.target.tagName.toLowerCase() === 'button') {
                              return; // Ignore clicks on buttons
                            }
                            this.setState({
                              openEdit: true,
                              blogPost: rowInfo.original
                            })
                          },
                          style: {
                            cursor: 'pointer'
                          }
                        }
                      }
                      return {}
                    }}
                    data={this.state.blogPosts}
                    defaultPageSize={10}
                    className="-striped -highlight" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withTranslation()(BlogPosts);