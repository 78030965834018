import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import update from 'immutability-helper';

import { CampaignContext } from '../CampaignContext';
import MessageInfo from '../MessageInfo/MessageInfo';
import Readability from '../MessageInfo/Readability';
import Attachments from '../MessageInfo/Attachments';
import EmbededUrls from '../MessageInfo/EmbededUrls';
import Top10words from '../MessageInfo/Top10words';

class MessageInfoModal extends React.Component {

  static contextType = CampaignContext;

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      MessageInfo: {
        Subject: '',
        To: '',
        Department: '',
        FromEmailAddress: '',
        FromName: '',
        OriginDomain: '',
        OriginSourceIP: '',
        OriginDate: '',
        OriginDayOfWeek: '',
        PhpScript: '',
        XMailer: '',
        List_unsubscribe: '',
        List_id: '',
        EmailLayoutText: '',
        EmailLayoutHtml: '',
        Language: '',
        Readability: {
          LengthSubject: 0,
          LengthBody: 0,
          Charset: '',
          ContentType: '',
          NumberSentences: 0,
          NumberComplexWords: 0,
          NumberOfWords: 0,
          NumberSyllables: 0,
          NumberCharacters: 0,
          NumberUniqueTerms: 0,
          SMOG: 0.0,
          FleschReadingEase: 0.0,
          FleschKincaidGradeLevel: 0.0,
          ARI: 0.0,
          GunningFog: 0.0,
          ColemanLiau: 0.0,
          SMOGIndex: 0.0,
          Smog: 0.0,
          Ari: 0.0,
          Smogindex: 0.0
        },
        Top10simpleWord: {},
        Top10_2gram: {},
        Attachments: {
          Files: [],
          Count: '',
        },
        EmbededUrls: [],
        Keywords: [],
        Text: '',
        MessageId: ''
      }
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateTopWords = this.updateTopWords.bind(this);
    this.updateTopGrams = this.updateTopGrams.bind(this);
    this.addAttachment = this.addAttachment.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.addEmbededUrl = this.addEmbededUrl.bind(this);
    this.removeEmbededUrl = this.removeEmbededUrl.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      MessageInfo: {
        Subject: '',
        To: '',
        Department: '',
        FromEmailAddress: '',
        FromName: '',
        OriginDomain: '',
        OriginSourceIP: '',
        OriginDate: '',
        OriginDayOfWeek: '',
        PhpScript: '',
        XMailer: '',
        List_unsubscribe: '',
        List_id: '',
        EmailLayoutText: '',
        EmailLayoutHtml: '',
        Language: '',
        Readability: {
          LengthSubject: 0,
          LengthBody: 0,
          Charset: '',
          ContentType: '',
          NumberSentences: 0,
          NumberComplexWords: 0,
          NumberOfWords: 0,
          NumberSyllables: 0,
          NumberCharacters: 0,
          NumberUniqueTerms: 0,
          SMOG: 0.0,
          FleschReadingEase: 0.0,
          FleschKincaidGradeLevel: 0.0,
          ARI: 0.0,
          GunningFog: 0.0,
          ColemanLiau: 0.0,
          SMOGIndex: 0.0,
          Smog: 0.0,
          Ari: 0.0,
          Smogindex: 0.0
        },
        Top10simpleWord: {},
        Top10_2gram: {},
        Attachments: {
          Files: [],
          Count: 0
        },
        EmbededUrls: [],
        Keywords: [],
        Text: '',
        MessageId: ''
      }
    });
  }

  handleChange(event) {
    const obj = event.target || event;
    const path = obj.name.split(".");
    const name = path.pop();
    const value = obj.type === 'checkbox' || obj.value === 'select-one'
      ? obj.checked : obj.value;
    this.setState(updater => (
      update(updater, path.reduceRight((obj, next) => ({ [next]: obj }), { [name]: { $set: value } })))
    );
  }

  updateTopWords(words) {
    this.setState(updater => ({
      MessageInfo: {
        ...updater.MessageInfo,
        'Top10simpleWord': words
      }
    }));
  }

  updateTopGrams(grams) {
    this.setState(updater => ({
      MessageInfo: {
        ...updater.MessageInfo,
        'Top10_2gram': grams
      }
    }));
  }

  addAttachment(attachment) {
    const attachments = this.state.MessageInfo.Attachments;
    attachments.Files.push(attachment);
    attachments.Count = attachments.Files.length;
    this.setState(updater => ({
      MessageInfo: {
        ...updater.MessageInfo,
        'Attachments': attachments
      }
    }));
  }

  removeAttachment(index) {
    const attachments = this.state.MessageInfo.Attachments;
    attachments.Files.splice(index, 1);
    attachments.Count = attachments.Files.length;
    this.setState(updater => ({
      MessageInfo: {
        ...updater.MessageInfo,
        'Attachments': attachments
      }
    }));
  }

  addEmbededUrl(embededUrl) {
    const embededUrls = this.state.MessageInfo.EmbededUrls;
    embededUrls.push(embededUrl);
    this.setState(updater => ({
      MessageInfo: {
        ...updater.MessageInfo,
        'EmbededUrls': embededUrls
      }
    }));
  }

  removeEmbededUrl(index) {
    const embededUrls = this.state.MessageInfo.EmbededUrls;
    embededUrls.splice(index, 1);
    this.setState(updater => ({
      MessageInfo: {
        ...updater.MessageInfo,
        'EmbededUrls': embededUrls
      }
    }));
  }

  submit() {
    this.context.addMessageInfo(this.state.MessageInfo);
    this.toggle();
  }

  render() {
    const { t, i18n } = this.props;

    const openButton = this.props.showOpenButton &&
      <Button className={`mb-2 mr-2 btn-icon btn-shadow btn-heading-position`}
        size="sm" color="primary" onClick={() => this.toggle()}>
        <i className="lnr-plus-circle btn-icon-wrapper"> </i>
        {t('campaign.message-info.actions.add-message')}
      </Button>;

    return (
      <Fragment>
        {openButton}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}
          size='xl' scrollable={true}>
          <ModalHeader toggle={this.toggle}>{t('campaign.message-info.actions.add-message')}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
              <MessageInfo MessageInfo={this.state.MessageInfo}
                onHandleChange={this.handleChange} />
              <Readability Readability={this.state.MessageInfo.Readability}
                onHandleChange={this.handleChange} />
              <Row>
                <Col md={6}>
                  <Top10words Words={this.state.MessageInfo.Top10simpleWord}
                    onUpdateWords={this.updateTopWords}
                    title={t('campaign.message-info.top_10_simple_word')} />
                </Col>
                <Col md={6}>
                  <Top10words Words={this.state.MessageInfo.Top10_2gram}
                    onUpdateWords={this.updateTopGrams}
                    title={t('campaign.message-info.top_10_2_gram')} />
                </Col>
              </Row>
              <Attachments Attachments={this.state.MessageInfo.Attachments}
                onAddAttachment={this.addAttachment} onRemoveAttachment={this.removeAttachment} />
              <EmbededUrls EmbededUrls={this.state.MessageInfo.EmbededUrls}
                onAddEmbededUrl={this.addEmbededUrl} onRemoveEmbededUrl={this.removeEmbededUrl} />
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.cancel')}</Button>
            <Button color="primary" onClick={this.submit}>{t('actions.submit')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

MessageInfoModal.defaultProps = {
  open: false,
  showOpenButton: true
}

export default withTranslation()(MessageInfoModal);
