import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import update from 'immutability-helper';

import { CampaignContext } from '../CampaignContext';
import WhowasContact from './WhowasContact';

class WhowasRecordModal extends React.Component {

  static contextType = CampaignContext;

  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.open,
      record: this.whowasRecord()
    };

    this.whowasRecord = this.whowasRecord.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  whowasRecord() {
    return {
      administrative_contact: {
        city_name: '',
        company_name: '',
        country_code: '',
        country_name: '',
        email_address: '',
        full_name: '',
        mailing_address: '',
        phone_number: '',
        state_name: '',
        zip_code: ''
      },
      create_date: '',
      domain_name: '',
      domain_registrar: {
        iana_id: 0,
        registrar_name: '',
        registrar_status: '',
        website_url: '',
        whois_server: ''
      },
      domain_status: [],
      expiry_date: '',
      name_servers: [],
      num: 0,
      query_time: '',
      registrant_contact: {
        city_name: '',
        company_name: '',
        country_code: '',
        country_name: '',
        email_address: '',
        full_name: '',
        mailing_address: '',
        phone_number: '',
        state_name: '',
        zip_code: ''
      },
      technical_contact: {
        city_name: '',
        company_name: '',
        country_code: '',
        country_name: '',
        email_address: '',
        full_name: '',
        mailing_address: '',
        phone_number: '',
        state_name: '',
        zip_code: ''
      },
      update_date: ''
    }
  }

  componentDidMount() {
    this.setState({
      record: this.props.record
    })
  }

  toggle() {
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      record: this.whowasRecord()
    });
  }

  render() {
    const { t, i18n } = this.props;

    const nCols = 4;
    const readOnly = true;

    return (
      <Fragment>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}
          size='xl' scrollable={true}>
          <ModalHeader toggle={this.toggle}>{t('campaign.whowas-info.record')}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => { e.preventDefault(); }}>
              <Row>
                <Col md={nCols}>
                  <FormGroup>
                    <Label for="domain_name">{t('campaign.whowas-info.domain_name')}</Label>
                    <Input type="text" name="domain_name" id="domain_name"
                      value={this.state.record.domain_name} readOnly={readOnly} />
                  </FormGroup>
                </Col>
                <Col md={nCols}>
                  <FormGroup>
                    <Label for="create_date">{t('campaign.whowas-info.create_date')}</Label>
                    <Input type="text" name="create_date" id="create_date"
                      value={this.state.record.create_date} readOnly={readOnly} />
                  </FormGroup>
                </Col>
                <Col md={nCols}>
                  <FormGroup>
                    <Label for="update_date">{t('campaign.whowas-info.update_date')}</Label>
                    <Input type="text" name="update_date" id="update_date"
                      value={this.state.record.update_date} readOnly={readOnly} />
                  </FormGroup>
                </Col>
                <Col md={nCols}>
                  <FormGroup>
                    <Label for="expiry_date">{t('campaign.whowas-info.expiry_date')}</Label>
                    <Input type="text" name="expiry_date" id="expiry_date"
                      value={this.state.record.expiry_date} readOnly={readOnly} />
                  </FormGroup>
                </Col>
                <Col md={nCols}>
                  <FormGroup>
                    <Label for="num">{t('campaign.whowas-info.num')}</Label>
                    <Input type="text" name="num" id="num"
                      value={this.state.record.num} readOnly={readOnly} />
                  </FormGroup>
                </Col>
                <Col md={nCols}>
                  <FormGroup>
                    <Label for="query_time">{t('campaign.whowas-info.query_time')}</Label>
                    <Input type="text" name="query_time" id="query_time"
                      value={this.state.record.query_time} readOnly={readOnly} />
                  </FormGroup>
                </Col>
                <Col md={nCols}>
                  <FormGroup>
                    <Label for="domain_status">{t('campaign.whowas-info.domain_status')}</Label>
                    <Input type="text" name="domain_status" id="domain_status"
                      value={this.state.record.domain_status} readOnly={readOnly} />
                  </FormGroup>
                </Col>
                <Col md={nCols}>
                  <FormGroup>
                    <Label for="name_servers">{t('campaign.whowas-info.name_servers')}</Label>
                    <Input type="text" name="name_servers" id="name_servers"
                      value={this.state.record.name_servers} readOnly={readOnly} />
                  </FormGroup>
                </Col>
              </Row>
              {/* DOMAIN REGISTRAR */}
              <Row>
                <Col md={12}>
                  <h3 className="form-heading pt-3 pb-3 float-left">
                    {t('campaign.whowas-info.domain_registrar')}
                  </h3>
                </Col>
              </Row>
              <Row form>
                <Col md={nCols}>
                  <FormGroup>
                    <Label for="registrar.iana_id">{t('campaign.whowas-info.registrar.iana_id')}</Label>
                    <Input type="text" name="registrar.iana_id" id="registrar.iana_id"
                      value={this.state.record.domain_registrar.iana_id} readOnly={readOnly} />
                  </FormGroup>
                </Col>
                <Col md={nCols}>
                  <FormGroup>
                    <Label for="registrar.registrar_name">{t('campaign.whowas-info.registrar.registrar_name')}</Label>
                    <Input type="text" name="registrar.registrar_name" id="registrar.registrar_name"
                      value={this.state.record.domain_registrar.registrar_name} readOnly={readOnly} />
                  </FormGroup>
                </Col>
                <Col md={nCols}>
                  <FormGroup>
                    <Label for="registrar.registrar_status">{t('campaign.whowas-info.registrar.registrar_status')}</Label>
                    <Input type="text" name="registrar.registrar_status" id="registrar.registrar_status"
                      value={this.state.record.domain_registrar.registrar_status} readOnly={readOnly} />
                  </FormGroup>
                </Col>
                <Col md={nCols}>
                  <FormGroup>
                    <Label for="registrar.website_url">{t('campaign.whowas-info.registrar.website_url')}</Label>
                    <Input type="text" name="registrar.website_url" id="registrar.website_url"
                      value={this.state.record.domain_registrar.website_url} readOnly={readOnly} />
                  </FormGroup>
                </Col>
                <Col md={nCols}>
                  <FormGroup>
                    <Label for="registrar.whois_server">{t('campaign.whowas-info.registrar.whois_server')}</Label>
                    <Input type="text" name="registrar.whois_server" id="registrar.whois_server"
                      value={this.state.record.domain_registrar.whois_server} readOnly={readOnly} />
                  </FormGroup>
                </Col>
              </Row>
              {/* CONTACTS */}
              <WhowasContact contact_title={t('campaign.whowas-info.administrative_contact')}
                contact={this.state.record.administrative_contact} />
              <WhowasContact contact_title={t('campaign.whowas-info.registrant_contact')}
                contact={this.state.record.registrant_contact} />
              <WhowasContact contact_title={t('campaign.whowas-info.technical_contact')}
                contact={this.state.record.technical_contact} />
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('actions.close')}</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default withTranslation()(WhowasRecordModal);
