import React from "react";
import AccountProvider from "../Accounts/AccountContext"
import MSConfigs from "./MSConfigs";

function withContext(Component) {
  class WithContext extends React.Component {
    render() {
      return (
        <AccountProvider>
          <Component />
        </AccountProvider>
      );
    }
  }
  return WithContext;
}

export default withContext(MSConfigs);