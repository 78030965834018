import React from "react";
import { toast } from "react-toastify";
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";

import { toDate } from "../../Helpers/TextHelper";
import {
  getOrganization, updateOrganization, createOrganization, deleteOrganization
} from "../../../API/OrganizationsAPI";
import { 
  getLicense, updateLicense, 
  getPhishingTest, updatePhishingTest,
  getThreatAnalyzer, updateThreatAnalyzer
} from "../../../API/LicenseAPI";
import { getDomain } from "../../../API/BaseAPI";

export const OrganizationContext = React.createContext();
export const OrganizationConsumer = OrganizationContext.Consumer;

class OrganizationProvider extends React.Component {

  constructor(props) {
    super(props);

    const path = props.location.pathname;
    const isAdmin = path.startsWith('/admin');
    const isPartner = path.startsWith('/partner');
    
    this.state = {
      isAdmin: isAdmin,
      isPartner: isPartner,
      domain: isAdmin || isPartner ? this.props.domain : getDomain(),
      organization: {},
      license: {},
      phishingLicense: {},
      threatLicense: {},
      contacts: [],
      contact: {}
    }

    this.isEdit = this.isEdit.bind(this);
    this.isEditContact = this.isEditContact.bind(this);
    this.createOrganization = this.createOrganization.bind(this);
    this.updateOrganization = this.updateOrganization.bind(this);
    this.deleteOrganization = this.deleteOrganization.bind(this);
    this.updateLicense = this.updateLicense.bind(this);
    this.updatePhishingLicense = this.updatePhishingLicense.bind(this);
    this.updateThreatLicense = this.updateThreatLicense.bind(this);
    this.addContact = this.addContact.bind(this);
    this.openContact = this.openContact.bind(this);
    this.editContact = this.editContact.bind(this);
    this.delContact = this.delContact.bind(this);
    this.changeSession = this.changeSession.bind(this);
  }

  componentDidMount() {
    if (this.state.domain) { // editing a organization?
      Promise.all([
        getOrganization(this.state.domain),
        getLicense(this.state.isAdmin ? this.state.domain : null),
        getPhishingTest(this.state.isAdmin ? this.state.domain : null),
        getThreatAnalyzer(this.state.isAdmin ? this.state.domain : null)
      ]).then(values => {
        const organization = values[0];
        organization.created = toDate(organization.created); // Adjust date
        organization.startedPOC = toDate(organization.startedPOC); // Adjust date
        organization.expirePOC = toDate(organization.expirePOC); // Adjust date
        this.setState({
          organization: organization,
          contacts: values[0].contact,
          license: values[1],
          phishingLicense: values[2],
          threatLicense: values[3]
        })
      }).catch(error => {
        console.error("Error getting Organization", error);
        toast["error"](this.props.t('errors.loading', { element: 'Organization', message: error.message }));
      })
    }
  }

  isEdit() {
    return this.state.organization.id ? true : false;
  }

  isEditContact() {
    return this.state.contact.email ? true : false;
  }

  async createOrganization(organization) {
    try {
      if (this.state.isPartner) { // Add partner domain
        organization.partner = getDomain();
      }
      organization = await createOrganization(organization);
      organization.created = toDate(organization.created); // Adjust date
      organization.startedPOC = toDate(organization.startedPOC); // Adjust date
      organization.expirePOC = toDate(organization.expirePOC); // Adjust date
      const license = await getLicense(organization.domain); // Get organization License
      this.setState({
        organization: organization,
        license: license
      });
      toast["success"](this.props.t('messages.success_create', { element: 'Organization' }));
    } catch(error) {
      console.error("Error saving Organization", error);
      toast["error"](this.props.t('errors.creating', { element: 'Organization', message: error.message }));
    }
  }

  updateOrganization(organization) {
    updateOrganization(organization).then(organization => {
      organization.created = toDate(organization.created); // Adjust date
      organization.startedPOC = toDate(organization.startedPOC); // Adjust date
      organization.expirePOC = toDate(organization.expirePOC); // Adjust date
      this.setState({
        organization: organization
      });
      toast["success"](this.props.t('messages.success_update', { element: 'Organization' }));
    }).catch(error => {
      console.error("Error saving Organization", error);
      toast["error"](this.props.t('errors.updating', { element: 'Organization', message: error.message }));
    });
  }

  async deleteOrganization(domain){
    if (!this.state.isAdmin && this.domain === domain) {
      console.error("Unable to delete Organization");
      toast["error"](this.props.t('errors.deleting', { element: 'Organization' }));
    }
    try {
      await deleteOrganization(this.state.domain);
    } catch(error) {
      console.error("Error deleting Organization", error);
      toast["error"](this.props.t('errors.deleting', { element: 'Organization', message: error.message }));
    }
  }

  updateLicense(license) {
    const domain = this.state.organization.domain;
    if (domain && license) {
      updateLicense(license).then(license => {
        this.setState({
          license: license
        });
        toast["success"](this.props.t('messages.success_update', { element: 'PhishingLicense' }));
      }).catch(error => {
        console.error("Error updating License", error);
        toast["error"](this.props.t('errors.updating', { element: 'License', message: error.message }));
      });
    } else {
      toast["error"](this.props.t('messages.unable_to_update', { element: 'License' }));
    }
  }

  updatePhishingLicense(phishingLicense) {
    const domain = this.state.organization.domain;
    if (domain && phishingLicense) {
      updatePhishingTest(phishingLicense).then(phishingLicense => {
        this.setState({
          phishingLicense: phishingLicense
        });
        toast["success"](this.props.t('messages.success_update', { element: 'Phishing Simulator License' }));
      }).catch(error => {
        console.error("Error updating Phishing Simulator License", error);
        toast["error"](this.props.t('errors.updating', { element: 'Phishing Simulator License', message: error.message }));
      });
    } else {
      toast["error"](this.props.t('messages.unable_to_update', { element: 'Phishing Simulator License' }));
    }
  }

  updateThreatLicense(threatLicense) {
    const domain = this.state.organization.domain;
    if (domain && threatLicense) {
      updateThreatAnalyzer(threatLicense).then(threatLicense => {
        this.setState({
          threatLicense: threatLicense
        });
        toast["success"](this.props.t('messages.success_update', { element: 'Threat Analyzer License' }));
      }).catch(error => {
        console.error("Error updating Threat Analyzer License", error);
        toast["error"](this.props.t('errors.updating', { element: 'Threat Analyzer License', message: error.message }));
      });
    } else {
      toast["error"](this.props.t('messages.unable_to_update', { element: 'Threat Analyzer License' }));
    }
  }

  addContact(contact) {
    if (contact) {
      this.setState(updater => {
        const contacts = updater.contacts.concat(contact);
        return {
          contacts: contacts
        }
      });
      toast["warning"](this.props.t('organization.contact.messages.contacts_changed'));
    }
  }

  openContact(contact = {}) {
    this.setState({
      contact: contact
    })
  }

  editContact(contact) {
    if (contact) {
      this.setState(updater => { // Override the contact
        let contacts = updater.contacts.filter(c => c.email !== contact.email).concat(contact);
        return {
          contacts: contacts
        }
      });
      toast["warning"](this.props.t('organization.contact.messages.contacts_changed'));
    }
  }

  delContact(contact) {
    if (contact) {
      this.setState(updater => {
        const contacts = updater.contacts.filter(c => c.email !== contact.email);
        return {
          contacts: contacts
        }
      });
      toast["warning"](this.props.t('organization.contact.messages.contacts_changed'));
    }
  }

  changeSession(name, email, authContext) {
    if (!name || !email || !authContext) {
      toast["warning"](this.props.t('organization.contact.messages.unable_to_login_as'));
      return;
    }
    const { history } = this.props;
    authContext.loginAsUser(name, email).then(result => { 
      const user = authContext.user;
      if (user.roles.includes('Organization Admin')) {
        history.push('/organization');
      } else if (user.roles.includes('Partner Admin')) {
        history.push('/partner');
      } else if (user.roles.includes('User')) {
        history.push('/user/dashboard');
      } else {
        throw Error(this.props.t('login.error'));
      }
    }).catch(error => {
      console.error("Error changing user session", error);
      toast["error"](this.props.t('organization.contact.messages.unable_to_login_as'));
    });
  }

  render() {
    return (
      <OrganizationContext.Provider value={
        {
          domain: this.state.domain,
          organization: this.state.organization,
          license: this.state.license,
          phishingLicense: this.state.phishingLicense,
          threatLicense: this.state.threatLicense,
          contacts: this.state.contacts,
          contact: this.state.contact,
          isAdmin: this.state.isAdmin,
          isPartner: this.state.isPartner,
          isEdit: this.isEdit,
          isEditContact: this.isEditContact,
          createOrganization: this.createOrganization,
          updateOrganization: this.updateOrganization,
          deleteOrganization: this.deleteOrganization,
          addContact: this.addContact,
          openContact: this.openContact,
          editContact: this.editContact,
          delContact: this.delContact,
          updateLicense: this.updateLicense,
          updatePhishingLicense: this.updatePhishingLicense,
          updateThreatLicense: this.updateThreatLicense,
          changeSession: this.changeSession
        }
      }>
        {this.props.children}
      </OrganizationContext.Provider>
    )
  }
}

export default withRouter(withTranslation()(OrganizationProvider));
