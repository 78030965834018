import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Col, Form, FormGroup, Label, Input, Row, Card, CardBody
} from 'reactstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { withTranslation } from 'react-i18next';
import update from 'immutability-helper';
import DatePicker from 'react-datepicker';

import PageTitle from '../../../Layout/Components/PageTitle';
import ConfirmAction from '../../../Layout/Components/ConfirmAction';

import { OrganizationContext } from './OrganizationContext';
import Contacts from './Contacts';
import { PaymentsAdminModal } from '../../Payments';
import { DashboardSelection } from '../../Dashboard';
import { PDFReportFragment } from 'Application/Reports';
import { AuthConsumer } from 'context/AuthContext';

class Organization extends React.Component {

  static contextType = OrganizationContext;

  constructor(props) {
    super(props);

    this.state = {
      organization: {
        id: "",
        name: "",
        domain: "",
        partner: "",
        address: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        registrationNumber: "",
        type: "",
        lang: "",
        created: "",
        poc: false,
        startedPOC: "",
        expirePOC: "",
        numberemployees: "",
        openIncidentManagedByAdmin: false
      },
      license: {
        number: "",
        active: false
      },
      phishingLicense: {
        number: "",
        active: false,
        apikey: ""
      },
      threatLicense: {
        number: "",
        active: false,
        apikey: ""
      },
    }

    this.handleChange = this.handleChange.bind(this);
    this.saveOrganization = this.saveOrganization.bind(this);
    this.saveLicense = this.saveLicense.bind(this);
    this.savePhishingLicense = this.savePhishingLicense.bind(this);
    this.saveThreatLicense = this.saveThreatLicense.bind(this);
    this.deleteOrganization = this.deleteOrganization.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.context.isEdit() && this.state.organization.id !== this.context.organization.id) {
      this.setState({
        organization: this.context.organization,
        license: this.context.license,
        phishingLicense: this.context.phishingLicense,
        threatLicense: this.context.threatLicense
      })
    }
  }

  handleChange(event) {
    const obj = event.target || event;
    const path = obj.name.split(".");
    const name = path.pop();
    const value = obj.type === 'checkbox' || obj.value === 'select-one'
      ? obj.checked : obj.value;
    this.setState(updater => (
      update(updater, path.reduceRight((obj, next) => ({ [next]: obj }), { [name]: { $set: value } })))
    );
  }

  saveOrganization() {
    let organization = this.state.organization;
    organization.contact = this.context.contacts; // Update contact(s)
    organization.partner = organization.partner || "";
    if (this.context.isEdit()) {
      this.context.updateOrganization(organization)
    } else {
      this.context.createOrganization(organization)
    }
  }

  async deleteOrganization() {
    if (this.context.isEdit()) {
      const organization = this.state.organization;
      await this.context.deleteOrganization(organization.domain);
      const { location, history } = this.props;
      const source = location.pathname.split("/")[1];
      history.push(`/${source}/organizations`);
    }
  }

  saveLicense() {
    this.context.updateLicense(this.state.license);
  }

  savePhishingLicense() {
    this.context.updatePhishingLicense(this.state.phishingLicense);
  }

  saveThreatLicense() {
    this.context.updateThreatLicense(this.state.threatLicense);
  }

  render() {
    const { t, i18n } = this.props;

    const title = this.context.isEdit() ? t("organizations.actions.edit") : t("organizations.actions.create");
    const description = t("organizations.description")

    const disableOnCreate = !this.context.isEdit();

    const orgProtectedFields = this.context.isAdmin || this.context.isPartner ?
      <Fragment>
        <Col md={4}>
          <FormGroup>
            <Label for="organization.created">{t('organizations.organization.created')}</Label>
            <DatePicker name="organization.created" id="organization.created" className="form-control"
              selected={this.state.organization.created} dateFormat="yyyy-MM-dd h:mm:ss aa"
              readOnly={true} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="organization.type">{t('organizations.organization.type')}</Label>
            <Input type="select" name="organization.type" id="organization.type"
              value={this.state.organization.type} onChange={this.handleChange}>
              <option>google</option>
              <option>microsoft</option>
              <option>zimbra</option>
              <option>other</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="organization.numberemployees">{t('organizations.organization.numberemployees')}</Label>
            <Input type="select" name="organization.numberemployees" id="organization.numberemployees"
              value={this.state.organization.numberemployees} onChange={this.handleChange}>
              <option></option>
              <option>0-25</option>
              <option>26-50</option>
              <option>51-100</option>
              <option>101-250</option>
              <option>251-500</option>
              <option>501-1000</option>
              <option>1001+</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup check>
            <Input type="checkbox" name="organization.poc" id="organization.poc"
              checked={this.state.organization.poc} onChange={this.handleChange} />
            <Label for="organization.poc">{t('organizations.organization.poc')}</Label>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="organization.startedPOC">{t('organizations.organization.startedPOC')}</Label>
            <DatePicker name="organization.startedPOC" id="organization.startedPOC" className="form-control"
              selected={this.state.organization.startedPOC} dateFormat="yyyy-MM-dd h:mm:ss aa"
              onChange={date => this.handleChange({ name: "organization.startedPOC", value: date })} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="organization.expirePOC">{t('organizations.organization.expirePOC')}</Label>
            <DatePicker name="organization.expirePOC" id="organization.expirePOC" className="form-control"
              selected={this.state.organization.expirePOC} dateFormat="yyyy-MM-dd h:mm:ss aa"
              onChange={date => this.handleChange({ name: "organization.expirePOC", value: date })} />
          </FormGroup>
        </Col>
      </Fragment> : '';

    const orgProtectedPartnerFields = this.context.isAdmin ?
      <Fragment>
        <Col md={6}>
          <FormGroup>
            <Label for="organization.partner">{t('organizations.organization.partner')}</Label>
            <Input type="text" name="organization.partner" id="organization.partner"
              value={this.state.organization.partner} onChange={this.handleChange} />
          </FormGroup>
        </Col>
      </Fragment> : '';

    const contacts = this.context.isAdmin ?
      <AuthConsumer>{value => <Contacts authContext={value}/>}</AuthConsumer> :
      <Contacts />;

    const licenseFields = !this.context.isPartner ?
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <h3 className="form-heading pt-3 pb-3">
                {t('organizations.license.title')}
              </h3>
              {
                this.context.isAdmin ?
                  <Fragment>
                    <Row form>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="license.number">{t('organizations.license.number')}</Label>
                          <Input type="text" name="license.number" id="license.number"
                            value={this.state.license.number} onChange={this.handleChange} disabled={disableOnCreate} />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup check>
                          <Input type="checkbox" name="license.active" id="license.active"
                            checked={this.state.license.active} onChange={this.handleChange} disabled={disableOnCreate} />
                          <Label for="license.active">{t('organizations.license.active')}</Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" onClick={this.saveLicense} disabled={disableOnCreate}>
                      {t('organizations.actions.save-license')}
                    </Button>
                  </Fragment> :
                  <Fragment>
                    <Row>
                      <Col md={4}>
                        <Label className="mr-sm-2">{t("organization.details.license.status")}</Label>
                        <Label>{this.context.license.active ? 'Active' : 'Disabled'}</Label>
                      </Col>
                      <Col md={4}>
                        <Label className="mr-sm-2">{t("organization.details.license.number")}</Label>
                        <Label>{this.context.license.number}</Label>
                      </Col>
                    </Row>
                  </Fragment>
              }
            </CardBody>
          </Card>
        </Col>
      </Row> : '';

    const phishingLicense = this.context.isAdmin ?
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <h3 className="form-heading pt-3 pb-3">
                {t('organizations.phishing_test_license.title')}
              </h3>
              <Row form>
                <Col md={3}>
                  <FormGroup>
                    <Label for="phishingLicense.number">{t('organizations.phishing_test_license.number')}</Label>
                    <Input type="text" name="phishingLicense.number" id="phishingLicense.number"
                      value={this.state.phishingLicense.number} onChange={this.handleChange} disabled={disableOnCreate} />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup check>
                    <Input type="checkbox" name="phishingLicense.active" id="phishingLicense.active"
                      checked={this.state.phishingLicense.active} onChange={this.handleChange} disabled={disableOnCreate} />
                    <Label for="phishingLicense.active">{t('organizations.phishing_test_license.active')}</Label>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="phishingLicense.apikey">{t('organizations.phishing_test_license.apiKey')}</Label>
                    <Input type="text" name="phishingLicense.apikey" id="phishingLicense.apikey"
                      value={this.state.phishingLicense.apikey} onChange={this.handleChange} disabled={disableOnCreate} />
                  </FormGroup>
                </Col>
              </Row>
              <Button color="primary" onClick={this.savePhishingLicense} disabled={disableOnCreate}>
                {t('organizations.actions.save-license')}
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row> : '';

    const threatLicense = this.context.isAdmin ?
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <h3 className="form-heading pt-3 pb-3">
                {t('organizations.threat_analyzer_license.title')}
              </h3>
              <Row form>
                <Col md={3}>
                  <FormGroup>
                    <Label for="threatLicense.number">{t('organizations.threat_analyzer_license.number')}</Label>
                    <Input type="text" name="threatLicense.number" id="threatLicense.number"
                      value={this.state.threatLicense.number} onChange={this.handleChange} disabled={disableOnCreate} />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup check>
                    <Input type="checkbox" name="threatLicense.active" id="threat_analyzer_license.active"
                      checked={this.state.threatLicense.active} onChange={this.handleChange} disabled={disableOnCreate} />
                    <Label for="threatLicense.active">{t('organizations.threat_analyzer_license.active')}</Label>
                  </FormGroup>
                </Col>
              </Row>
              <Button color="primary" onClick={this.saveThreatLicense} disabled={disableOnCreate}>
                {t('organizations.actions.save-license')}
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row> : '';

    const payments = this.context.isAdmin ?
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <h3 className="form-heading pt-3 pb-3">
                {t('payments.title')}
              </h3>
              <PaymentsAdminModal domain={this.context.organization.domain}
                email={this.context.contacts[0] ? this.context.contacts[0].email : ''} />
            </CardBody>
          </Card>
        </Col>
      </Row> : '';

    const report = <PDFReportFragment domain={this.context.organization.domain}
      disabled={disableOnCreate} isAdmin={this.context.isAdmin} />;

    const dashboard = this.context.isAdmin ?
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <h3 className="form-heading pt-3 pb-3">
                {t('dashboards.title')}
              </h3>
              <DashboardSelection />
            </CardBody>
          </Card>
        </Col>
      </Row> : '';

    const deleteButton = this.context.isAdmin && this.context.isEdit() ?
      <ConfirmAction color="danger" title={t('actions.confirmation')}
        message={t('messages.confirm_delete_element', { element: 'organization' }) +
          " " + t('organizations.messages.confirm_delete_question')}
        confirmation={this.state.organization.domain} onConfirm={() => this.deleteOrganization()}>
        {t('organizations.actions.delete')}
      </ConfirmAction> : ''

    return (
      <Fragment>
        <PageTitle heading={title} subheading={description} icon="pe-7s-cloud icon-gradient bg-primary" />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <h3 className="form-heading pt-3 pb-3">
                    {t('organizations.organization.title')}
                  </h3>
                  <Form onSubmit={(e) => { e.preventDefault(); }}>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="organization.name">{t('organizations.organization.name')}</Label>
                          <Input type="text" name="organization.name" id="organization.name"
                            value={this.state.organization.name} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="organization.domain">{t('organizations.organization.domain')}</Label>
                          <Input type="text" name="organization.domain" id="organization.domain"
                            value={this.state.organization.domain} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="organization.registrationNumber">{t('organizations.organization.registrationNumber')}</Label>
                          <Input type="text" name="organization.registrationNumber" id="organization.registrationNumber"
                            value={this.state.organization.registrationNumber} onChange={this.handleChange} disabled={!this.context.isAdmin} />
                        </FormGroup>
                      </Col>
                      {orgProtectedPartnerFields}
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="organization.address">{t('organizations.organization.address')}</Label>
                          <Input type="text" name="organization.address" id="organization.address"
                            value={this.state.organization.address} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="organization.city">{t('organizations.organization.city')}</Label>
                          <Input type="text" name="organization.city" id="organization.city"
                            value={this.state.organization.city} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="organization.state">{t('organizations.organization.state')}</Label>
                          <Input type="text" name="organization.state" id="organization.state"
                            value={this.state.organization.state} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="organization.country">{t('organizations.organization.country')}</Label>
                          <Input type="text" name="organization.country" id="organization.country"
                            value={this.state.organization.country} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="organization.postalCode">{t('organizations.organization.postalCode')}</Label>
                          <Input type="text" name="organization.postalCode" id="organization.postalCode"
                            value={this.state.organization.postalCode} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      {orgProtectedFields /* CreateOn, Type and POC */}
                      <Col md={2}>
                        <FormGroup>
                          <Label for="organization.lang">{t('organizations.organization.lang')}</Label>
                          <Input type="select" name="organization.lang" id="organization.lang"
                            value={this.state.organization.lang} onChange={this.handleChange}>
                            <option></option>
                            <option>en</option>
                            <option>pt</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup check>
                          <Input type="checkbox" name="organization.openIncidentManagedByAdmin" id="organization.openIncidentManagedByAdmin"
                            checked={this.state.organization.openIncidentManagedByAdmin} onChange={this.handleChange} />
                          <Label for="organization.openIncidentManagedByAdmin">{t('organizations.organization.open_incident')}</Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={12}>
                        {contacts}
                      </Col>
                    </Row>
                    <Button color="primary" onClick={this.saveOrganization}>{t('organizations.actions.save')}</Button>
                    &nbsp;&nbsp;
                    {deleteButton}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {licenseFields}
          {phishingLicense}
          {threatLicense}
          {payments}
          {report}
          {dashboard}
        </CSSTransitionGroup>
      </Fragment>
    );
  }
}

export default withRouter(withTranslation()(Organization));