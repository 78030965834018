import React from "react";
import IncidentProvider from './IncidentContext';
import Incidents from './Incidents';
import Incident from './Incident';

function withContext(Component) {
  class WithContext extends React.Component {
    render() {
      const providerMessageId = this.props.location && this.props.location.state
        && this.props.location.state.providerMessageId;
      return (
        <IncidentProvider providerMessageId={providerMessageId}>
          <Component />
        </IncidentProvider>
      );
    }
  }
  return WithContext;
}

const IncidentDetail = withContext(Incident);

export {
  IncidentDetail
}

export default withContext(Incidents);