import React from "react";
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";
import getUserLocale from 'get-user-locale';

import { getOverview, getOverviewUser, getOverviewDomain } from '../../API/ReportsAPI';

export const DashboardContext = React.createContext();
export const DashboardConsumer = DashboardContext.Consumer

const DashboardType = {
  ADMIN: "admin",
  ORGANIZATION: "organization",
  PARTNER: "partner",
  USER: "user"
};
class DashboardProvider extends React.Component {

  constructor(props) {
    super(props);

    const path = props.location.pathname;
    const type = path.startsWith('/user') ? DashboardType.USER
      : path.startsWith('/organization') ? DashboardType.ORGANIZATION
        : DashboardType.ADMIN;

    this.state = {
      dashboard_type: type,
      domain: props.domain,
      result: [],
      periods: [],
      totals: new Map(),
      totalsByCategory: new Map(),
      threatsByDate: new Map()
    };

    this.getReports = this.getReports.bind(this);
    this.exportReport = this.exportReport.bind(this);
  }

  componentDidMount() {
    this.getReports();
  }

  getReports() {
    let promise = null;
    if (this.state.dashboard_type === DashboardType.USER) {
      promise = getOverviewUser();
    } else if (this.state.dashboard_type === DashboardType.ORGANIZATION) {
      promise = getOverview();
    } else if (this.state.domain) {
      promise = getOverviewDomain(this.state.domain);
    }
    if (promise) {
      promise.then(result => {
        this.setState({
          result: result,
          periods: this.extractPeriods(result),
          totals: this.computeTotals(result),
          totalsByCategory: this.computeTotalsByCategory(result),
          threatsByDate: this.computeThreatsByDate(result)
        });
      }).catch(error => {
        console.error("Error getting Overview Report", error);
        toast["error"](this.props.t('errors.loading', { element: 'Overview Report', message: error.message }));
      });
    }
  }

  exportReport() {
    let report = ["period", "malware", "phishing", "warning", "spam", "legitimate", "date"].join(",");
    report += "\n";
    report += this.state.result.map(period => {
      return period.details.map(detail => {
        return [
          period.period,
          detail.malware,
          detail.phishing,
          detail.warning,
          detail.spam,
          detail.legitimate,
          detail.date];
      }).join('\n')
    }).join('\n');
    return report;
  }

  extractPeriods(overview) {
    return overview.map(element => {
      return element.period <= 30 ? element.period.toString() : "All";
    });
  }

  computeTotals(overview) {
    return new Map(overview.map(element => {
      let sumAll = 0;
      let sumThreats = 0;
      const total = element.total;
      Object.keys(total).forEach(key => {
        sumAll += total[key];
        if (key !== 'legitimate' && key !== 'date') {
          sumThreats += total[key];
        }
      });
      let result = [
        {
          title: 'Messages Received',
          value: this.formatValue(sumAll),
          percent: this.formatPercent(sumAll, sumAll)
        },
        {
          title: 'Threats Detected',
          value: this.formatValue(sumThreats),
          percent: this.formatPercent(sumAll, sumThreats)
        }
      ]
      return [element.period <= 30 ? element.period.toString() : "All", result];
    }));
  }

  computeTotalsByCategory(overview) {
    return new Map(overview.map(element => {
      let sumAll = 0;
      const total = element.total;
      Object.keys(total).forEach(key => {
        if (key !== 'date') {
          sumAll += total[key];
        }
      });
      let result = Object.keys(total).filter(key => key !== 'date').map(key => {
        return {
          title: key.charAt(0).toUpperCase() + key.slice(1),
          value: this.formatValue(total[key]),
          percent: this.formatPercent(sumAll, total[key])
        }
      });
      return [element.period <= 30 ? element.period.toString() : "All", result];
    }));
  }

  computeThreatsByDate(overview) {
    const locale = getUserLocale();
    return new Map(overview.map(element => {
      let result = element.details ? element.details.map(detail => {
        let value = { ...detail };
        let date = new Date(detail.date);
        switch (element.period) {
          case 1:
            value.date = date.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' });
            break;
          case 7:
          case 30:
            value.date = date.toLocaleDateString(locale);
            break;
          default:
            value.date = date.toLocaleDateString(locale); // (date.getMonth() + 1).toString().padStart(2, "0") + "/" + date.getFullYear();
        }
        return value;
      }) : [];
      return [element.period <= 30 ? element.period.toString() : "All", result];
    }));
  }

  formatValue(number) {
    if (number >= 1000000) {
      return `${Math.floor(number / 1000000)}M`;
    } else if (number >= 1000) {
      return `${Math.floor(number / 1000)}K`;
    }
    return number;
  }

  formatPercent(total, number) {
    return total > 0 ? (number * 100 / total).toFixed(2) : 0;
  }

  render() {
    return (
      <DashboardContext.Provider value={
        {
          dashboard_type: this.state.dashboard_type,
          periods: this.state.periods,
          totals: this.state.totals,
          totalsByCategory: this.state.totalsByCategory,
          threatsByDate: this.state.threatsByDate,
          exportReport: this.exportReport
        }
      }>
        {this.props.children}
      </DashboardContext.Provider>
    )
  }

}

export default withRouter(withTranslation()(DashboardProvider))
