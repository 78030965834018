import React, {Fragment} from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, CardTitle, Input, Button, Spinner, Alert, Nav, NavItem, NavLink  } from 'reactstrap';

import PageTitle from '../../../Layout/Components/PageTitle';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import LandingPageModal from './LandingPageModal';
import LandingPageTable from './LandingPageTable';
import classnames from 'classnames';

import {getlandingpages,deleteLandingPage} from "../../../API/PhishingAPI";
import {lpReactTableConfiguration_ForLandingPage, lpReactTableConfiguration_ForImportScreen} from "./LPColumnGenerator"
import PhishingDataTable from '../PhishingDataTable'

class LandingPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      openEdit: false,
      landingPage: {},
      landingPageList: [],
      isLoaded: false,
      error: "",
      errorFetchingData: false,
      activeTab: 1,
      datasets: {
        1: {
          data: null,
          dataFetchFunc: getlandingpages,
          config: lpReactTableConfiguration_ForLandingPage
        },
        2 : {
          data: null,
          dataFetchFunc: () => getlandingpages({domain: "0.template"}),
          config: lpReactTableConfiguration_ForImportScreen
        }
      }
    };
    this.onEditOrCopyCallback = this.onEditOrCopyCallback.bind(this);
    this.onSubmitCallback = this.onSubmitCallback.bind(this);
    this.loadLandingPageList = this.loadLandingPageList.bind(this);
    this.deleteLandingPage = this.deleteLandingPage.bind(this);
    this.addPolicyCallback = this.addPolicyCallback.bind(this);
    this.setDataForActiveTab = this.setDataForActiveTab.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
  }

  onEditOrCopyCallback(page, copiedVersion){
    this.setState({
      openEdit: true,
      landingPage: page,
      copiedVersion: copiedVersion
    })
  }

  onSubmitCallback(){
    this.loadLandingPageList();
  }
  componentDidMount(){
    this.loadLandingPageList();
  }

  loadLandingPageList(){
    this.setState({
      isLoaded: false
    });
    // TODO(Refactor): Date format should be handled in it's own method
    const {datasets, activeTab} = this.state;
    datasets[activeTab].dataFetchFunc()
      .then(res => {
        res.forEach((item, i) => {
          item.modified_date = (new Date(item.modified_date)).toLocaleString(undefined, {dateStyle:"full", timeStyle:"medium"})
        });
        this.setDataForActiveTab(res);
        this.setState({
          landingPageList : res,
          isLoaded : true
        });
    }).catch(error => {
      console.log("Error while fetching data for Landing Page",error);
      this.setState({
        errorFetchingData: true,
        error: "Error fetching data"
      })
    });
  }

  deleteLandingPage(e){
    const id = e._original.id;
    deleteLandingPage(id)
      .then(res => {
        this.setDataForActiveTab();
        this.toggleTab(1);
      }).catch(error => {
        toast["error"](this.props.t("deleting", { element: 'Landing Page', message: error.message }));
      })
  }
  setDataForActiveTab(data){
    const {activeTab} = this.state;
    this.setState(updater => ({
      datasets: {
        ...updater.datasets,
        [activeTab]: {
          ...updater.datasets[activeTab],
          data: data
        }
      }
    }))
  }
  render(){
    const { t, i18n } = this.props;
    const editLandingPageModal = this.state.openEdit &&
      <LandingPageModal open={true} showOpenButton={false} onClose={this.addPolicyCallback}
      landingPage={this.state.landingPage} copiedVersion={this.state.copiedVersion} onSubmit={this.onSubmitCallback}/>

    const{datasets, activeTab} = this.state;
    const activeData = datasets[activeTab].data;
    const activeConfig = datasets[activeTab].config;

    return (
      <Fragment>
        <PageTitle
          heading={t("landing_page.title")}
          icon="pe-7s-note icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12" sm="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <LandingPageModal open={this.state.open} onClose={this.addPolicyCallback} onSubmit={this.onSubmitCallback}/>
                  {editLandingPageModal}
                  {this.state.errorFetchingData? <Alert color="danger">
                  {this.state.error}
                  </Alert> :
                  <div className="mt-3">
                    <Nav tabs>
                      <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab == '1' })} onClick={() => this.toggleTab('1')}>My Page</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab == '2' })} onClick={() => this.toggleTab('2')}>Other Pages</NavLink>
                      </NavItem>
                    </Nav>
                    {this.state.isLoaded?
                      <PhishingDataTable key={activeTab} config={activeConfig}  data={activeData} onEditOrCopy={this.onEditOrCopyCallback} onDelete={this.deleteLandingPage}/>
                      :<div><Spinner color="primary" /> Data is loading!!!</div>}
                  </div>
                }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
  addPolicyCallback(){
    this.setState({
      openEdit: false,
      landingPage:{}
    }, () => {
      this.setDataForActiveTab();
      this.toggleTab(1);
    })
  }
  toggleTab(tabActivatedNum){
    this.setState({
      activeTab: tabActivatedNum
    }, () => {
      const {datasets, activeTab} = this.state;
      if(!datasets[activeTab].data){
        this.loadLandingPageList();
      }
    })
  }
}
export default withTranslation()(LandingPage);
