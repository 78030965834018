import React, { Fragment } from "react";
import { Line } from 'react-chartjs-2';

class LinesGraph extends React.Component {

  render() {
    return (
      <Fragment>
        <Line data={this.props.data} options={{ maintainAspectRatio: false }} />
      </Fragment>
    )
  }
}

export default LinesGraph;