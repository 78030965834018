import React, { Fragment } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Col, Form, FormGroup, Label, Input, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ReactTable from "react-table";

import {sendEmail} from "../../../API/PhishingAPI";

class SMTPSendTestEmailModal extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      modal: this.props.open,
      sendEmailInfo : {
        first_name:"",
        last_name:"",
        email:"",
        position:"",
        smtp:this.props.smtpConfig
      }
    }
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.smtpConfig !== state.smtp) {
      const {id, modified_date, ...rest} = props.smtpConfig;
      return {
        sendEmailInfo: {
          ...state.sendEmailInfo,
          smtp: rest
        }
      };
    }
    return null;
  }
  handleChange(event){
    const key = event.target.name;
    const value = event.target.type === 'checkbox'
      ? event.target.checked : event.target.value;
    this.setState(updater => ({
      sendEmailInfo: {
        ...updater.sendEmailInfo,
        [key]: value
      }
    }));
  }

  sendEmail(){
    console.log(this.state.sendEmailInfo);
    const promise = sendEmail(this.state.sendEmailInfo);
    promise
      .then(result => {
        this.toggle();
      }).catch(error => {
        toast["error"]("Error Sending Email");
      })
  }
  render(){
    const { t, i18n } = this.props;
    return (
      <Fragment>
        <Button className="mb-2 mr-2 btn-icon btn-shadow" size="lg" color="primary" onClick={this.toggle}>
          <i className="pe-7s-mail btn-icon-wrapper"> </i>
          {t('smtp_config.send_email')}
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
          <ModalHeader toggle={this.toggle}>{t('smtp_config.send_email')}</ModalHeader>
          <ModalBody>

          <FormGroup>
            <Row>
              <Label sm={3}>{t('smtp_config.send_email_title')}</Label>
            </Row>
            <Row>
              <Col sm={3} >
                <Input type="text" name="first_name" id="first_name" placeholder={t('smtp_config.placeholder.first_name')}
                value={this.state.sendEmailInfo.first_name} onChange={this.handleChange}/>
              </Col>
              <Col sm={3}>
                <Input type="text" name="last_name" id="last_name" placeholder={t('smtp_config.placeholder.last_name')}
                value={this.state.sendEmailInfo.last_name} onChange={this.handleChange}/>
              </Col>
              <Col sm={3}>
                <Input type="text" name="email" id="email" placeholder={t('smtp_config.placeholder.email')}
                value={this.state.sendEmailInfo.email} onChange={this.handleChange}/>
              </Col>
              <Col sm={3}>
                <Input type="text" name="position" id="position" placeholder={t('smtp_config.placeholder.position')}
                value={this.state.sendEmailInfo.position} onChange={this.handleChange}/>
              </Col>
            </Row>
          </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>{t('smtp_config.actions.cancel')}</Button>
            <Button color="primary" onClick={this.sendEmail}>{t('smtp_config.actions.send')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }
  toggle(){
    if (this.state.modal && typeof this.props.onClose === 'function') {
      this.props.onClose(); // call onClose when closing
    }
    this.setState({
      modal: !this.state.modal,
      sendEmailInfo : {
        first_name:"",
        last_name:"",
        email:"",
        position:"",
        smtp:{}
      }
    });
  }
}

export default withTranslation()(SMTPSendTestEmailModal)
