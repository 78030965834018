import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Card, CardBody, Button, Row, Col, FormGroup, Label, Input
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import { ThreatContext, ThreatOriginQuery } from '../ThreatContext';

import { createBlacklist } from '../../../API/BlacklistAPI';
import InfoPopover from 'Layout/Components/InfoPopover';
import { Multiselect } from 'react-widgets';
import EmailDetails from '../EmailDetails';
import RenderInWindow from 'Layout/Components/RenderInWindow';

class MessageOriginTable extends React.Component {

  static contextType = ThreatContext

  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      modalState: {
        decision: "",
        message: {},
        threats: [],
        incident: {}
      },
      sourceIP: '',
      smtpIPs: [],
      classification_values: ['Legitimate', 'Warning', 'Spam', 'Phishing', 'Malware'],
      threatOriginQuery: ThreatOriginQuery,
      columns: this.buildColumns(this.props.t),
      dataToDownload: []
    }

    this.handleChange = this.handleChange.bind(this);
    this.blacklistSource = this.blacklistSource.bind(this);
    this.search = this.search.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.download = this.download.bind(this);
  }

  buildColumns(t) {
    return [
      {
        Header: t("threat_intelligence.origin.sent_date"),
        accessor: 'messageDetails.originDate',
        Cell: (prop) => {
          const updated = new Date(prop.value).toLocaleString();
          return (
            <div className="d-block w-100 text-left">
              {updated}
            </div>
          )
        }
      },
      {
        Header: t("threat_intelligence.origin.sender"),
        accessor: 'messageDetails.originEmailAddress'
      },
      {
        Header: t("threat_intelligence.origin.sender_ip"),
        accessor: 'messageDetails.originSourceIP'
      },
      {
        Header: t("threat_intelligence.origin.recipient"),
        accessor: 'messageDetails.receiver'
      },
      {
        Header: t("threat_intelligence.origin.subject"),
        accessor: 'messageDetails.subject'
      },
      {
        Header: t("threat_intelligence.origin.classification"),
        accessor: 'decision'
      },
      {
        Header: t("threat_intelligence.origin.reported"),
        accessor: 'reported',
        Cell: prop => (
          <span>
            {prop.value? "yes" : "no"}
          </span>
        )
      },
      {
        Header: t("threat_intelligence.origin.phishingSimulator"),
        accessor: 'phishingSimulator',
        Cell: prop => (
          <span>
            {prop.value? "yes" : "no"}
          </span>
        )
      },
      {
        Header: t("threat_intelligence.origin.desc"),
        accessor: 'threats',
        Cell: prop => (
          <span>
            {prop.value ? prop.value.map(value => `${value}; `) : ''}
          </span>
        )
      }
    ];
  }

  handleChange(event) {
    const obj = event.target || event;
    const value = obj.type === 'checkbox' ? obj.checked : obj.value;
    this.setState(updater => ({
      threatOriginQuery: {
        ...updater.threatOriginQuery,
        [obj.name]: value
      }
    }));
  }

  blacklistSource(ipUrl) {
    if (ipUrl) {
      createBlacklist(ipUrl).then(result => {
        toast["success"](this.props.t('threat_intelligence.messages.success_create_blacklist'));
      }).catch(error => {
        console.error(error);
        toast["error"](this.props.t('errors.creating', { element: 'Blacklist', message: error.message }));
      });
    } else {
      toast["warning"](this.props.t('messages.unable_to_create', {
        element: 'blacklist',
        message: this.props.t('messages.empty_field', { field: "Sender IP" })
      }));
    }
  }

  search() {
    this.context.getThreats(this.context.page, this.state.threatOriginQuery);
  }

  toggleModal(open) {
    this.setState({ openModal: open })
  }

  download(event) {
    const {columns} = this.state;
    const currentRecords = this.reactTable.getResolvedState().sortedData;
    var data_to_download = [];
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < columns.length; colIndex++) {
        record_to_download[columns[colIndex].Header] =
          currentRecords[index][columns[colIndex].accessor];
      }
      data_to_download.push(record_to_download);
    }
    this.setState({ dataToDownload: data_to_download }, () => {
      // click the CSVLink component to trigger the CSV download
      this.csvLink.link.click();
    });
  }

  render() {
    const { t, i18n, history } = this.props;

    return (
      <Card className="main-card mb-3">
        <Row>
          <Col md="12">
            <Card className="">
              <CardBody>
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="initial" >{t('threat_intelligence.origin.start_date')}</Label>
                      <InfoPopover id="sent_date-instructions" small body={t('threat_intelligence.origin.date-information')} />
                      <DatePicker id="initial" className="form-control"
                        selected={this.state.threatOriginQuery.initial} dateFormat="yyyy-MM-dd"
                        onChange={date => this.handleChange({ name: "initial", value: date })} />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                    <Label for="end" >{t('threat_intelligence.origin.end_date')}</Label>
                      <DatePicker id="end" className="form-control"
                        selected={this.state.threatOriginQuery.end} dateFormat="yyyy-MM-dd"
                        onChange={date => this.handleChange({ name: "end", value: date })} />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="sender" >{t('threat_intelligence.origin.sender')}</Label>
                      <Input type="email" name="sender" id="sender"
                        value={this.state.threatOriginQuery.sender} onChange={this.handleChange} />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="senderip" >{t('threat_intelligence.origin.sender_ip')}</Label>
                      <Input type="text" name="senderip" id="senderip"
                        value={this.state.threatOriginQuery.senderip} onChange={this.handleChange} />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="receiver" >{t('threat_intelligence.origin.recipient')}</Label>
                      <Input type="email" name="receiver" id="receiver"
                        value={this.state.threatOriginQuery.receiver} onChange={this.handleChange} />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="classification" >{t('threat_intelligence.origin.classification')}</Label>
                      <Multiselect
                        id="classification" multiple={true}
                        data={this.state.classification_values}
                        value={this.state.threatOriginQuery.classification}
                        onChange={value => this.handleChange({ name: "classification", value: value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <Label for="subject" >{t('threat_intelligence.origin.subject')}</Label>
                      <Input type="text" name="subject" id="subject"
                        value={this.state.threatOriginQuery.subject} onChange={this.handleChange} />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup check>
                      <Input type="checkbox" name="reported" id="reported"
                        checked={this.state.threatOriginQuery.reported} onChange={this.handleChange} />
                      <Label for="checkbox" >{t('threat_intelligence.origin.reported')}</Label>
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup className='custom-form-button'>
                      <Button color="primary" onClick={this.search}>{t('threat_intelligence.actions.find')}</Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <CardBody>
            <div>
              <RenderInWindow open={this.state.openModal} setOpen={this.toggleModal} title={t(t("threat_intelligence.email_details.title"))}>
                <EmailDetails state={this.state.modalState} />
              </RenderInWindow>
              <Button color="primary" onClick={this.download} disabled={this.context.messagesOrigin.length === 0}>{t('threat_intelligence.actions.download')}</Button>
              <InfoPopover id="table-information" small body={t('threat_intelligence.origin.table-information')} />
              <CSVLink data={this.state.dataToDownload} filename="messageOriginTable.csv"
                className="hidden" ref={(r) => (this.csvLink = r)} target="_blank" />
            </div>
              <ReactTable
                columns={this.state.columns}
                getTrProps={(state, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      onMouseEnter: (e) => {
                        if (rowInfo.row.threats && rowInfo.row.threats.length > 0) {
                          e.currentTarget.setAttribute("title", `Threats: ${rowInfo.row.threats.toString()}`)
                        }
                      },
                      onClick: (e) => {
                        if (e.target.tagName.toLowerCase() === 'button') {
                          return; // Ignore clicks on buttons
                        }
                        this.setState({
                          openModal: true,
                          modalState: {
                            decision: rowInfo.original.decision,
                            message: rowInfo.original.messageDetails,
                            threats: rowInfo.original.threats,
                            incident: rowInfo.original
                          }
                        });
                      },
                      style: {
                        cursor: 'pointer'
                      }
                    }
                  }
                  return {}
                }}
                data={this.context.messagesOrigin}
                pages={this.context.totalPages}
                pageSize={this.context.pageSize}
                page={this.context.page}
                manual={true}
                showPageSizeOptions={true}
                defaultPageSize={10}
                sortable={false}
                onPageChange={(page) => {
                  this.context.getThreats(page, this.state.threatOriginQuery);
                }}
                onPageSizeChange={(newPageSize, newPage) => {
                  // this.handleChange({ name: "pageSize", value: newPageSize });
                  let threatOriginQuery = this.state.threatOriginQuery
                  threatOriginQuery.pageSize = newPageSize;
                  this.context.getThreats(newPage, threatOriginQuery);
                }}
                // onFetchData={(state, instance) => {
                //   this.context.getThreats(state.page, this.state.threatOriginQuery);
                // }}
                ref={(r) => (this.reactTable = r)}
                className="-striped -highlight" />
            </CardBody>
          </Col>
        </Row>
      </Card >
    );
  }
}

export default withRouter(withTranslation()(MessageOriginTable));