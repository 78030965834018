import React, { Fragment } from "react";
import {
  Row, Col, Card, CardBody, CardTitle, Label, Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import LanguageHelper from '../Helpers/LanguageHelper'
import { QuarantineContext } from "./QuarantineContext";

class ThreatHighlights extends React.Component {

  static contextType = QuarantineContext;

  render() {
    const { t, i18n } = this.props;

    const { loaded, originalClassification, userClassification,
      message, threats, origin, campaign } = this.context;

    const classificationClass = (classification) => {
      switch (classification) {
        case 'Phishing':
        case 'Spam':
        case 'Malware':
          return 'fg-classification bg-ev-phishing';
        case 'Warning':
          return 'fg-classification bg-ev-warning';
        case 'Legitimate':
          return 'fg-classification bg-ev-legitimate';
        default:
          return '';
      }
    }

    const action = (action, label, color) => {
      return (
        <Button color={color} className="mr-1" disabled={!loaded}
          onClick={() => this.context.releaseQuarantine(action)}>
          {label}
        </Button>
      );
    }

    const showActions = <Fragment>
      {action("legitimate", t("quarantines.actions.release"), "primary")}
      {action("phishing", t("quarantines.actions.delete"), "danger")}
    </Fragment>;

    const threatList = threats && threats.length > 0 ? threats.map((t, i) => <li key={`threat_${i}`}>{t}</li>) : "";
    const language = message.language ? LanguageHelper.getLanguage(message.language) : "";
    const originAddress = origin && (origin.city || origin.country) ? `${origin.city} (${origin.country})` : "";

    return (
      <Fragment>
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>{t("quarantines.threat_highlights")}</CardTitle>
            <Row form>
              <Col md={12}>
                <Label className="mr-sm-2">{t("quarantines.classification")}:</Label>
                <Label>{originalClassification}</Label>
              </Col>
              <Col md={12}>
                <ul className="custom-list">
                  {threatList}
                </ul>
              </Col>
              <Col md={12} className={classificationClass(userClassification)}>
                <Label className="mr-sm-2">{t("quarantines.user_classification")}:</Label>
                <Label>{userClassification}</Label>
              </Col>
              <Col md={12}>
                {showActions}
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>{t("quarantines.key_characteristics")}</CardTitle>
            <Row form>
              <Col md={12}>
                <Label className="mr-sm-2">{t("quarantines.message_origin")}:</Label>
                <Label>{originAddress}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("quarantines.message_language")}:</Label>
                <Label>{language}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("quarantines.message_agent")}:</Label>
                <Label>{message.userAgent}</Label>
              </Col>
              <Col md={12}>
                <Label className="mr-sm-2">{t("quarantines.campaign_name")}:</Label>
                <Label>{campaign.name}</Label>
                <Label>{campaign.id}</Label>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Fragment >
    )
  }
}

export default withTranslation()(ThreatHighlights);
