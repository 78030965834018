import React from 'react';
import {
  DropdownToggle, DropdownMenu, Button, UncontrolledButtonDropdown, Nav, NavItem
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import { AuthContext } from 'context/AuthContext';

class UserBox extends React.Component {

  static contextType = AuthContext;

  render() {
    const { t, i18n } = this.props;

    const user = this.context.user;

    const dashboards = user.roles.map((role, i) => {
      let link = null;
      let label = null;
      if (role === 'EmailVeritas Admin') {
        link = '/admin/services';
        label = t('dashboards.emailveritas');
      } else if (role === 'Organization Admin') {
        link = '/organization';
        label = t('dashboards.organization');
      } else if (role === 'Partner Admin') {
        link = '/partner';
        label = t('dashboards.partner');
      } else if (role === 'User') {
        link = '/user/dashboard';
        label = t('dashboards.user');
      }
      if (link && label) {
        return (
          <NavItem key={i}>
            <Link to={link} className="nav-link">
              {label}
            </Link>
          </NavItem>
        )
      }
      return '';
    });

    return (
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  <span className="pe-7s-user" style={{
                    fontSize: '48px'
                  }}> </span>
                  <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown} />
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-primary">
                      {/* No bg image */}
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper menu-header-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <span className="pe-7s-user" style={{
                                fontSize: '42px'
                              }}> </span>
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading">
                                {user.name}
                              </div>
                              <div className="widget-subheading">
                                {user.email}
                              </div>
                            </div>
                            <div className="widget-content-right mr-2">
                              <Button className="btn-pill btn-shadow btn-shine"
                                color="focus" onClick={() => this.context.doLogout()}>
                                {t("login.actions.logout")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="scroll-area-xs">
                    <Nav vertical>
                      <NavItem className="nav-item-header">
                        {t("dashboards.title")}
                      </NavItem>
                      {dashboards}
                    </Nav>
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <div className="widget-content-left ml-3 header-user-info">
              <div className="widget-heading">
                {user.name}
              </div>
              <div className="widget-subheading">
                {user.email}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(withTranslation()(UserBox));