import React from "react";
import { withTranslation } from 'react-i18next';
import * as d3 from 'd3';
import CountryHelper from "Application/Helpers/CountryHelper";

class ProximityGraph extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {}
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.loading) {
      d3.selectAll("svg").remove();
      return;
    }

    if (this.props.campaign !== prevProps.campaign) {
      const campaignId = this.props.campaignId;
      const campaign = this.props.campaign;
      const proximity = this.props.proximity;

      let data = {
        text: this.joinTexts(campaignId,
          campaign.generalInfo.campaignDescription.length > 21 ? `${campaign.generalInfo.campaignDescription.substring(0, 21)}...` : '',
          campaign.dnsInfo.map(element => `${element.domainName}/${element.ip}`)),
        icon: '/imgs/input.ico',
        children: []
      };

      let children = campaign.dnsInfo.map((element, index) => {
        const regText = Object.entries(element.whoisInfo.registrant).filter(el => el[1] !== null).map(el => {
          let key = el[0];
          let val = el[1];
          if (key === 'country') {
            let country = CountryHelper.getCountry(val);
            val = country.length > 25 ? `${country.substring(0, 25)}...` : country;
          }
          return val;
        });
        return {
          text: `${regText || ''}`,
          icon: '/imgs/registrant.ico',
          linkText: ["registrant"]
        }
      });
      children[0].children = [{
        text: Object.entries(this.props.params).filter(el => el[1]).map(el => {
          let key = el[0];
          let val = el[1];
          if (key === 'country') {
            let country = CountryHelper.getCountry(val);
            val = country.length > 25 ? `${country.substring(0, 25)}...` : country;
          }
          return val;
        }),
        icon: '/imgs/registrant_256x256.ico',
        linkText: [`proximity=${proximity.shift().toFixed(2)}`]
      }];
      data.children = children;

      this.update(data, proximity);
    }
  }

  joinTexts(...text) {
    return text.filter(el => el !== null)
  }

  update(root, proximity) {
    // set the dimensions and margins of the diagram
    const margin = { top: 40, right: 40, bottom: 40, left: 40 };
    const width = 750 - margin.right - margin.left;
    const height = 750 - margin.top - margin.bottom;

    // declares a tree layout and assigns the size
    let treemap = d3.tree().size([height - 100, width - 100]);

    //  assigns the data to a hierarchy using parent-child relationships
    let nodes = d3.hierarchy(root);

    // maps the node data to the tree layout
    nodes = treemap(nodes);

    let couplingChild = nodes.children[0].children[0];
    couplingChild.x = nodes.x;

    // append the svg obgect to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    this.svg = d3.select("#graph").append("svg")
      .attr("width", width + margin.right + margin.left)
      .attr("height", height + margin.top + margin.bottom)
    let g = this.svg.append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // adds the links between the nodes
    let link = g.selectAll(".link")
      .data(nodes.descendants().slice(1))
      .enter().append("g")
      .attr("class", "link");

    link.append("path")
      .attr("d", (d) => this.diagonal(d));

    link.append("text")
      .attr("transform", function (d) {
        return "translate(" +
          ((d.parent.x + d.x) / 2) + "," +
          ((d.parent.y + d.y) / 2) + ")";
      })
      .attr("dy", ".35em")
      .attr("style", "text-anchor: middle")
      .text(function (d) {
        return d.data.linkText;
      });

    // adds each node as a group
    let node = g.selectAll(".node")
      .data(nodes.descendants())
      .enter().append("g")
      .attr("class", function (d) {
        return "node" +
          (d.children ? " node--internal" : " node--leaf");
      })
      .attr("transform", function (d) {
        return "translate(" + d.x + "," + d.y + ")";
      });

    // adds the image to the node
    node.append("image")
      .attr("href", function (d) { return d.data.icon; })
      .attr("x", "-21px")
      .attr("y", "-21px")
      .attr("width", "42px")
      .attr("height", "42px");

    node.append("text")
      .attr("dy", ".35em")
      .attr("x", function (d) { return d.children ? 24 : -42; })
      .attr("y", function (d) { return d.children ? -20 : 20; })
      .text(function (d) { return d.data.text; })
      .call(this.wrapText, 30);


    let couplingParent = nodes.children.filter((el, index) => index > 0).map(el => el);
    couplingParent.forEach((parent, index) => {
      let couplingLink = g.append("g")
        .attr("class", "link");

      couplingLink.append("path")
        .attr("d", () => this.diagonal({
          x: couplingChild.x,
          y: couplingChild.y,
          parent: {
            x: parent.x,
            y: parent.y
          }
        }));

      couplingLink.append("text")
        .attr("transform", function () {
          return "translate(" +
            ((parent.x + couplingChild.x) / 2) + "," +
            ((parent.y + couplingChild.y) / 2) + ")";
        })
        .attr("dy", ".35em")
        .attr("style", "text-anchor: middle")
        .text(function (d) {
          return `proximity=${proximity[index].toFixed(2)}`
        });
    });
  }

  diagonal(d) {
    return ("M" + d.x + "," + d.y
      + "C" + d.x + "," + (d.y + d.parent.y) / 2
      + " " + d.parent.x + "," + (d.y + d.parent.y) / 2
      + " " + d.parent.x + "," + d.parent.y);
  }

  wrapText(text, width) {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(',').reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        x = text.attr("x"),
        y = text.attr("y"),
        dy = 0, //parseFloat(text.attr("dy")),
        tspan = text.text(null)
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", dy + "em");
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text.append("tspan")
            .attr("x", x)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  }

  render() {
    return (
      <div id="graph"></div>
    )
  }
}

export default withTranslation()(ProximityGraph);