import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { PartnerContext } from './PartnerContext';
import { Col, Row, FormGroup } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ContactModal from './ContactModal';

class Contacts extends React.Component {

  static contextType = PartnerContext;

  constructor(props) {
    super(props);

    this.state = {
      openEdit: false
    };
  }

  render() {
    const { t, i18n } = this.props;

    const editContactModal = this.state.openEdit &&
      <ContactModal showOpenButton={false} open={true}
        onClose={() => this.setState({ openEdit: false })} />;
    
    return (
      <Fragment>
        <h3 className="form-heading pt-3 pb-3 float-left">
          {t('partners.contact.title')}
        </h3>
        <ContactModal buttonClass="btn-heading-position" />
        {editContactModal}

        <BootstrapTable
          bootstrap4
          keyField="email"
          data={this.context.contacts}
          bordered={false}
          columns={[
            {
              dataField: 'name',
              text: t("partners.contact.name")
            },
            {
              dataField: 'email',
              text: t("partners.contact.email")
            },
            {
              dataField: 'address',
              text: t("partners.contact.address")
            },
            {
              dataField: 'phone',
              text: t("partners.contact.phone")
            },
            {
              dataField: 'rule',
              text: t("partners.contact.rule")
            }
          ]}
          rowEvents={{
            onClick: (e, row, rowIndex) => {
              this.context.openContact(row);
              this.setState({ openEdit: true })
            },
            style: {
              cursor: 'pointer'
            }
          }} />

      </Fragment >
    )
  }
}

export default withRouter(withTranslation()(Contacts));