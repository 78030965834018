import React, { Fragment } from 'react';
import {
  Col, FormGroup, Label, Input, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import NumericInput from 'react-numeric-input';

class Readability extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const obj = event.target || event;
    if (typeof this.props.onHandleChange === 'function') {
      this.props.onHandleChange({
        name: "MessageInfo.Readability." + obj.name,
        value: obj.value
      })
    }
  }

  render() {
    const { t, i18n } = this.props;

    const nCols = 3;

    return (
      <Fragment>
        <Row form>
          <Col md={12}>
            <h3 className="form-heading pt-3 pb-3 float-left">
              {t('campaign.message-info.readability.title')}
            </h3>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="LengthSubject" >{t('campaign.message-info.readability.length_subject')}</Label>
              <NumericInput id="LengthSubject" className="form-control"
                value={this.props.Readability.LengthSubject} min={0}
                onChange={num => this.handleChange({ name: "LengthSubject", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="LengthBody" >{t('campaign.message-info.readability.length_body')}</Label>
              <NumericInput id="LengthBody" className="form-control"
                value={this.props.Readability.LengthBody} min={0}
                onChange={num => this.handleChange({ name: "LengthBody", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="Charset" >{t('campaign.message-info.readability.charset')}</Label>
              <Input type="text" name="Charset" id="Charset"
                value={this.props.Readability.Charset} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="ContentType" >{t('campaign.message-info.readability.content_type')}</Label>
              <Input type="text" name="ContentType" id="ContentType"
                value={this.props.Readability.ContentType} onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="NumberSentences" >{t('campaign.message-info.readability.number_sentences')}</Label>
              <NumericInput id="NumberSentences" className="form-control"
                value={this.props.Readability.NumberSentences} min={0}
                onChange={num => this.handleChange({ name: "NumberSentences", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="NumberComplexWords" >{t('campaign.message-info.readability.number_complex_words')}</Label>
              <NumericInput id="NumberComplexWords" className="form-control"
                value={this.props.Readability.NumberComplexWords} min={0}
                onChange={num => this.handleChange({ name: "NumberComplexWords", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="NumberOfWords" >{t('campaign.message-info.readability.number_of_words')}</Label>
              <NumericInput id="NumberOfWords" className="form-control"
                value={this.props.Readability.NumberOfWords} min={0}
                onChange={num => this.handleChange({ name: "NumberOfWords", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="NumberSyllables" >{t('campaign.message-info.readability.number_syllables')}</Label>
              <NumericInput id="NumberSyllables" className="form-control"
                value={this.props.Readability.NumberSyllables} min={0}
                onChange={num => this.handleChange({ name: "NumberSyllables", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="NumberCharacters" >{t('campaign.message-info.readability.number_characters')}</Label>
              <NumericInput id="NumberCharacters" className="form-control"
                value={this.props.Readability.NumberCharacters} min={0}
                onChange={num => this.handleChange({ name: "NumberCharacters", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="NumberUniqueTerms" >{t('campaign.message-info.readability.number_unique_terms')}</Label>
              <NumericInput id="NumberUniqueTerms" className="form-control"
                value={this.props.Readability.NumberUniqueTerms} min={0}
                onChange={num => this.handleChange({ name: "NumberUniqueTerms", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="SMOG" >{t('campaign.message-info.readability.smog')}</Label>
              <NumericInput id="SMOG" className="form-control"
                value={this.props.Readability.SMOG} min={0.0} step={0.1}
                onChange={num => this.handleChange({ name: "SMOG", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="FleschReadingEase" >{t('campaign.message-info.readability.flesch_reading_ease')}</Label>
              <NumericInput id="FleschReadingEase" className="form-control"
                value={this.props.Readability.FleschReadingEase} min={0.0} step={0.1}
                onChange={num => this.handleChange({ name: "FleschReadingEase", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="FleschKincaidGradeLevel" >{t('campaign.message-info.readability.flesch_kincaid_gradd_level')}</Label>
              <NumericInput id="FleschKincaidGradeLevel" className="form-control"
                value={this.props.Readability.FleschKincaidGradeLevel} min={0.0} step={0.1}
                onChange={num => this.handleChange({ name: "FleschKincaidGradeLevel", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="ARI" >{t('campaign.message-info.readability.ari')}</Label>
              <NumericInput id="ARI" className="form-control"
                value={this.props.Readability.ARI} min={0.0} step={0.1}
                onChange={num => this.handleChange({ name: "ARI", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="GunningFog" >{t('campaign.message-info.readability.gunning_fog')}</Label>
              <NumericInput id="GunningFog" className="form-control"
                value={this.props.Readability.GunningFog} min={0.0} step={0.1}
                onChange={num => this.handleChange({ name: "GunningFog", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="ColemanLiau" >{t('campaign.message-info.readability.coleman_liau')}</Label>
              <NumericInput id="ColemanLiau" className="form-control"
                value={this.props.Readability.ColemanLiau} min={0.0} step={0.1}
                onChange={num => this.handleChange({ name: "ColemanLiau", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="SMOGIndex" >{t('campaign.message-info.readability.smog_index')}</Label>
              <NumericInput id="SMOGIndex" className="form-control"
                value={this.props.Readability.SMOGIndex} min={0.0} step={0.1}
                onChange={num => this.handleChange({ name: "SMOGIndex", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="Smog" >{t('campaign.message-info.readability.smog_2')}</Label>
              <NumericInput id="Smog" className="form-control"
                value={this.props.Readability.Smog} min={0.0} step={0.1}
                onChange={num => this.handleChange({ name: "Smog", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="Ari" >{t('campaign.message-info.readability.ari_2')}</Label>
              <NumericInput id="Ari" className="form-control"
                value={this.props.Readability.Ari} min={0.0} step={0.1}
                onChange={num => this.handleChange({ name: "Ari", value: num })} />
            </FormGroup>
          </Col>
          <Col md={nCols}>
            <FormGroup>
              <Label for="Smogindex" >{t('campaign.message-info.readability.smog_index_2')}</Label>
              <NumericInput id="Smogindex" className="form-control"
                value={this.props.Readability.Smogindex} min={0.0} step={0.1}
                onChange={num => this.handleChange({ name: "Smogindex", value: num })} />
            </FormGroup>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default withTranslation()(Readability);
