import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, CardTitle, Button, Input, Label, Form, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { getCampaign, getLocalProximity } from '../../../API/ConsoleServicesAPI'

import PageTitle from '../../../Layout/Components/PageTitle';
import ProximityGraph from "./ProximityGraph";
import { isReturnStatement } from "typescript";

class CampaignProximity extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      loading: false,
      campaignId: '',
      campaign: '',
      params: {
        phone: '',
        organization: '',
        country: '',
        name: '',
        street: '',
        city: '',
        province: '',
        postalCode: '',
        fax: '',
        email: '',
      },
      proximity: []
    }

    this.getCampaign = this.getCampaign.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleParamChange = this.handleParamChange.bind(this);
  }

  getCampaign() {
    if (!this.state.campaignId) {
      toast["warning"](this.props.t('threat_hunting.campaign_proximity.empty_id_error'));
      return;
    }
    this.setState({
      loading: true
    });
    const query = Object.entries(this.state.params).filter(el => el[1]).map(el => `${el[0]}=${el[1]}`).join("&");
    Promise.all([getCampaign(this.state.campaignId), getLocalProximity(this.state.campaignId, query)])
      .then(values => {
        if (values[0].errorMessage) {
          console.warn("Error message received", values[0]);
          toast["warning"](this.props.t('threat_hunting.campaign_proximity.find_campaign_error', { message: values[0].errorMessage }));
          return;
        }
        this.setState({
          campaign: values[0].data,
          proximity: values[1].data,
          loading: false
        })
      }).catch(error => {
        console.error("Error loading campaign", error);
        toast["error"](this.props.t('errors.loading', { element: 'Campaign', message: error.message }));
        this.setState({
          loading: false
        })
      })
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleParamChange(event) {
    const { name, value } = event.target;
    const params = {
      ...this.state.params,
      [name]: value
    };
    this.setState({
      "params": params
    });
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={t("threat_hunting.campaign_proximity.title")}
          icon="pe-7s-target icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12" sm="12">
              <Card className="main-card mb-3">
                <CardBody>
                  {t("threat_hunting.campaign_proximity.info_text")}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="6" sm="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>{t("threat_hunting.campaign_proximity.campaign_text")}</CardTitle>
                  <Input type="text" name="campaignId" id="campaignId"
                    value={this.state.campaignId} onChange={this.handleChange} />
                </CardBody>
              </Card>
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>{t("threat_hunting.campaign_proximity.information_text")}</CardTitle>
                  <Form onSubmit={(e) => { e.preventDefault(); this.getCampaign(); }}>
                    <FormGroup row>
                      <Label for="phone" sm={2}>{t('threat_hunting.campaign_proximity.phone')}</Label>
                      <Col sm={10}>
                        <Input type="text" name="phone" id="phone"
                          value={this.state.params.phone} onChange={this.handleParamChange} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="organization" sm={2}>{t('threat_hunting.campaign_proximity.organization')}</Label>
                      <Col sm={10}>
                        <Input type="text" name="organization" id="organization"
                          value={this.state.params.organization} onChange={this.handleParamChange} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="country" sm={2}>{t('threat_hunting.campaign_proximity.country')}</Label>
                      <Col sm={10}>
                        <Input type="text" name="country" id="country"
                          value={this.state.params.country} onChange={this.handleParamChange} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="name" sm={2}>{t('threat_hunting.campaign_proximity.name')}</Label>
                      <Col sm={10}>
                        <Input type="text" name="name" id="name"
                          value={this.state.params.name} onChange={this.handleParamChange} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="street" sm={2}>{t('threat_hunting.campaign_proximity.street')}</Label>
                      <Col sm={10}>
                        <Input type="text" name="street" id="street"
                          value={this.state.params.street} onChange={this.handleParamChange} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="city" sm={2}>{t('threat_hunting.campaign_proximity.city')}</Label>
                      <Col sm={10}>
                        <Input type="text" name="city" id="city"
                          value={this.state.params.city} onChange={this.handleParamChange} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="province" sm={2}>{t('threat_hunting.campaign_proximity.province')}</Label>
                      <Col sm={10}>
                        <Input type="text" name="province" id="province"
                          value={this.state.params.province} onChange={this.handleParamChange} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="postalCode" sm={2}>{t('threat_hunting.campaign_proximity.postalCode')}</Label>
                      <Col sm={10}>
                        <Input type="text" name="postalCode" id="postalCode"
                          value={this.state.params.postalCode} onChange={this.handleParamChange} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="fax" sm={2}>{t('threat_hunting.campaign_proximity.fax')}</Label>
                      <Col sm={10}>
                        <Input type="text" name="fax" id="fax"
                          value={this.state.params.fax} onChange={this.handleParamChange} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="email" sm={2}>{t('threat_hunting.campaign_proximity.email')}</Label>
                      <Col sm={10}>
                        <Input type="text" name="email" id="email"
                          value={this.state.params.email} onChange={this.handleParamChange} />
                      </Col>
                    </FormGroup>

                  </Form>
                  <Button color="primary" className="mb-2 mr-2" onClick={() => this.getCampaign()}>
                    {t("threat_hunting.campaign_proximity.action.calculate_proximity")}
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" sm="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ProximityGraph campaignId={this.state.campaignId} campaign={this.state.campaign}
                    proximity={this.state.proximity} params={this.state.params} loading={this.state.loading} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withTranslation()(CampaignProximity);