import React from "react";
import PartnerProvider from './PartnerContext'
import Partner from './Partner'

function withContext(Component) {
  class WithContext extends React.Component {
    render() {
      const { match: { params } } = this.props;
      return (
        <PartnerProvider domain={params.domain}>
          <Component />
        </PartnerProvider>
      );
    }
  }
  return WithContext;
}

export default withContext(Partner);
