import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Col, Row, FormGroup, Button } from 'reactstrap';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import { CampaignContext } from '../CampaignContext';
import WhoisInfoModal from './WhoisInfoModal';
import ConfirmAction from 'Layout/Components/ConfirmAction';

class WhoisInfo extends React.Component {

  static contextType = CampaignContext;

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <Row>
          <Col md={12}>
            <h3 className="form-heading pt-3 pb-3 float-left">
              {t('campaign.whois-info.title')}
            </h3>
            <WhoisInfoModal showOpenButton={!this.context.isEdit()} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ReactTable
              columns={[
                {
                  Header: t("campaign.whois-info.data-contact-email"),
                  accessor: 'RegistryDataContactEmail'
                },
                {
                  Header: t("campaign.whois-info.registrant.title"),
                  columns: [
                    {
                      Header: t("campaign.whois-info.registrant.phone"),
                      accessor: 'Registrant.Phone'
                    },
                    {
                      Header: t("campaign.whois-info.registrant.country"),
                      accessor: 'Registrant.Country'
                    },
                    {
                      Header: t("campaign.whois-info.registrant.organization"),
                      accessor: 'Registrant.Organization'
                    },
                    {
                      Header: t("campaign.whois-info.registrant.name"),
                      accessor: 'Registrant.Name'
                    },
                    {
                      Header: t("campaign.whois-info.registrant.email"),
                      accessor: 'Registrant.Email'
                    },
                    {
                      Header: t("actions.title"),
                      id: 'actions',
                      accessor: 'RegistryDataContactEmail',
                      width: 200,
                      Cell: (prop) => (
                        <div className="d-block w-100 text-center">
                          <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                            message={t('messages.confirm_remove', { object: prop.value })}
                            onConfirm={() => this.context.removeWhoisInfo(prop.index)}
                            disabled={this.context.isEdit()}>
                            {t("campaign.dns-info.actions.remove")}
                          </ConfirmAction>
                        </div>
                      )
                    }
                  ]
                }
              ]}
              data={this.context.campaign.WhoisInfo}
              showPageSizeOptions={false}
              showPaginationBottom={false}
              minRows={4}
              filterable={false}
              className="-striped -highlight"
            />
          </Col>
        </Row>
      </Fragment >
    )
  }
}

export default withRouter(withTranslation()(WhoisInfo));