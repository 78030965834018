import React, { Component }  from 'react';
const ugReactTableConfiguration = {
  columns: [
    {
      Header: "users_and_groups.name",
      accessor : 'name'
    },
    {
      Header: "users_and_groups.last_modified_date",
      accessor : 'modified_date',
    },
    {
      Header: "users_and_groups.number_of_targets",
      accessor : 'targets',
      Cell: ({ value }) => value.length
    }
  ],
  selectionColumn: true,
  editOptions: false
}
export default ugReactTableConfiguration;
