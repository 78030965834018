import React from "react";
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";

import { getMessagesOrigin, getMessagesOriginUser } from '../../API/ReportsAPI';
import { getGeolocation } from '../../API/ConsoleServicesAPI';

export const ThreatContext = React.createContext();
export const ThreatConsumer = ThreatContext.Consumer

export const ThreatOriginQuery = {
  pageSize: 10, initial: null, end: null, sender: null, receiver: null, senderip: null, classification: [], subject: null, reported: null
}

class ThreatProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      user_report: props.location.pathname.startsWith('/user'),
      totalPages: 1,
      pageSize: 10,
      page: 0,
      messagesOrigin: [],
      geolocation: [],
    };

    this.getThreats = this.getThreats.bind(this);
    this.getThreatsUser = this.getThreatsUser.bind(this);
    this.getGeolocation = this.getGeolocation.bind(this);
  }

  getThreats(pageIndex, threatOriginQuery) {
    const {pageSize, initial, end, sender, receiver, senderip, classification, subject, reported} = threatOriginQuery;
    getMessagesOrigin(pageIndex + 1, pageSize, initial, end, sender, receiver, senderip, classification, subject, reported).then(result => {
      console.log(result.pageable.pageNumber || 0);
      this.setState({
        totalPages: result.totalPages || 0,
        pageSize: result.pageable.pageSize || 0,
        page: result.pageable.pageNumber || 0,
        messagesOrigin: result.content || []
      });
      if (result.content) {
        const ipArray = result.content.map(val => val.messageDetails.originSourceIP);
        this.getGeolocation(ipArray);
      }
    }).catch(error => {
      console.error("Error getting Message Origin", error)
      toast["error"](this.props.t('errors.loading', { element: 'Message Origin', message: error.message }));
    });
  }

  getThreatsUser(pageIndex) {
    getMessagesOriginUser(pageIndex + 1).then(result => {
      this.setState({
        totalPages: result.totalPages,
        page: result.page > 0 ? result.page - 1 : 0,
        messagesOrigin: result.malicious || []
      });
      if (result.malicious) {
        const ipArray = result.malicious.map(val => val.message.originSourceIP);
        this.getGeolocation(ipArray);
      }
    }).catch(error => {
      console.error("Error getting Message Origin", error)
      toast["error"](this.props.t('errors.loading', { element: 'Message Origin', message: error.message }));
    });
  }

  getGeolocation(ipArray) {
    if (!ipArray || ipArray.length === 0) {
      return;
    }
    getGeolocation(ipArray).then(result => {
      this.setState({
        geolocation: result.geoips.filter(loc => !loc.error).map(loc => {
          return { coordinates: [loc.longitude, loc.latitude] }
        })
      });
    }).catch(error => {
      console.error("Error getting Origin Geolocation", error);
      toast["error"](this.props.t('errors.loading', { element: 'Origin Geolocation', message: error.message }));
    });
  }

  render() {
    return (
      <ThreatContext.Provider value={
        {
          user_report: this.state.user_report,
          totalPages: this.state.totalPages,
          pageSize: this.state.pageSize,
          page: this.state.page,
          messagesOrigin: this.state.messagesOrigin,
          geolocation: this.state.geolocation,
          getThreats: this.getThreats,
          getThreatsUser: this.getThreatsUser
        }
      }>
        {this.props.children}
      </ThreatContext.Provider>
    )
  }
}

export default withRouter(withTranslation()(ThreatProvider))
