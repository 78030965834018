import React from "react";
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";
import PostalMime from 'postal-mime';

import { parseEmailLinks } from "../Helpers/TextHelper";

import { getIncident, closeIncident, getIncidents, closeListOfIncidents } from "API/IncidentsAPI";
import { getGeolocation } from 'API/ConsoleServicesAPI';

export const IncidentContext = React.createContext();
export const IncidentConsumer = IncidentContext.Consumer

class IncidentProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      incidents: [],
      incident: {},
      providerMessageId: "",
      originalClassification: "",
      userClassification: "",
      message: {},
      threats: [],
      campaign: {},
      origin: [],
      emlDecoded: "",
      emailHeaders: new Map(),
      emailBody: "",
      emailAttachments: [],
      emailLinks: []
    }

    this.loadIncidents = this.loadIncidents.bind(this);
    this.loadIncident = this.loadIncident.bind(this);
    this.loadGeolocalization = this.loadGeolocalization.bind(this);
    this.parseEML = this.parseEML.bind(this);
    this.closeIncidents = this.closeIncidents.bind(this);
    this.closeIncident = this.closeIncident.bind(this);
  }

  componentDidMount() {
    if (this.props.providerMessageId) {
      this.loadIncident(this.props.providerMessageId);
    } else { // listing
      this.loadIncidents();
    }
  }

  loadIncidents() {
    getIncidents().then(result => {
      this.setState({
        incidents: result
      })
    }).catch(error => {
      console.error("Error getting Incidents", error);
      toast["error"](this.props.t('errors.loading', { element: 'Incidents', message: error.message }));
    });
  }

  loadIncident(providerMessageId) {
    getIncident(providerMessageId).then(result => {
      if (!result) {
        throw new Error("");
      }
      const { messageDetails, threats } = result.details;

      this.setState({
        loaded: true,
        providerMessageId: providerMessageId,
        incident: result,
        originalClassification: result.originalClassification,
        userClassification: result.userClassification,
        message: messageDetails,
        threats: threats,
        campaign: {}
      });

      this.loadGeolocalization(messageDetails.originSourceIP);
      this.parseEML(result.eml);
    }).catch(error => {
      console.error("Error getting Incident", error);
      toast["error"](this.props.t('errors.loading', { element: 'Incident', message: error.message }));
    });
  }

  loadGeolocalization(ip) {
    if (!ip) {
      console.error("No IP to get Geo");
      return;
    }
    getGeolocation([ip]).then(result => {
      this.setState({
        origin: result.geoips[0]
      });
    }).catch(error => {
      console.error("Error getting Origin Geolocation", error);
      toast["error"](this.props.t('errors.loading', { element: 'Origin Geolocation', message: error.message }));
    });
  }

  parseEML(eml) {
    if (!eml) {
      console.error("No EML to parse");
      return;
    }
    const emlDecoded = atob(eml);
    this.setState({
      emlDecoded: emlDecoded
    });
    const parser = new PostalMime();
    parser.parse(emlDecoded).then(result => {
      this.setState({
        emailHeaders: new Map(result.headers.map((obj) => [obj.key, obj.value])),
        emailBody: result.html ? result.html : result.text,
        emailAttachments: result.attachments
      });
      parseEmailLinks(result.html).then(links => {
        this.setState({
          emailLinks: links
        });
      })
    }).catch(err => {
      console.error("Error parsing email", err);
    })
  }

  closeIncidents(incidents) {
    closeListOfIncidents(incidents).then(result => {
      toast["success"](this.props.t('incidents.messages.success_closing_list'));
      this.loadIncidents();
    }).catch(error => {
      console.error("Error closing Incident", error);
      toast["error"](this.props.t('incidents.messages.success_closing_list', { message: error.message }));
    });
  }

  closeIncident(resolution) {
    closeIncident(this.state.providerMessageId, resolution).then(result => {
      toast["success"](this.props.t('incidents.messages.success_closing'));
    }).catch(error => {
      console.error("Error closing Incident", error);
      toast["error"](this.props.t('incidents.messages.error_closing', { message: error.message }));
    });
  }

  render() {
    return (
      <IncidentContext.Provider value={
        {
          loaded: this.state.loaded,
          incidents: this.state.incidents,
          incident: this.state.incident,
          originalClassification: this.state.originalClassification,
          userClassification: this.state.userClassification,
          message: this.state.message,
          threats: this.state.threats,
          campaign: this.state.campaign,
          origin: this.state.origin,
          emlDecoded: this.state.emlDecoded,
          emailHeaders: this.state.emailHeaders,
          emailBody: this.state.emailBody,
          emailAttachments: this.state.emailAttachments,
          emailLinks: this.state.emailLinks,
          closeIncidents: this.closeIncidents,
          closeIncident: this.closeIncident
        }
      }>
        {this.props.children}
      </IncidentContext.Provider>
    )
  }
}

export default withRouter(withTranslation()(IncidentProvider))
