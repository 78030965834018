import React, { Fragment } from 'react';
import {
  Input
} from 'reactstrap';
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';

class PhishingCustomSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      arr: this.props.data,
      name: this.props.name,
      multiple: this.props.multiple,
      selected: this.props.selected ? this.props.selected : ""
    }
    this.handleChange = this.handleChange.bind(this);
    this.createMultipleOptionsFromArray = this.createMultipleOptionsFromArray.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      arr: props.data
    })
  }

  createMultipleOptionsFromArray() {
    var rows = [];
    if (this.state.arr) {
      var length = this.state.arr.length;
      if (!this.state.selected.name) {
        rows.push(<option disabled key="option_select"> -- select an option -- </option>);
      }
      for (var i = 0; i < length; i++) {
        if (this.state.selected.name == this.state.arr[i].name) {
          console.log("selected is ", this.state.selected.name);
          rows.push(<option value={this.state.arr[i].name} key={i}>{this.state.arr[i].name}</option>);
        } else {
          rows.push(<option value={this.state.arr[i].name} key={i}>{this.state.arr[i].name}</option>)
        }
      }
    }
    return rows;
  }

  handleChange(event) {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  render() {
    const { name, multiple } = this.state;
    return (
      <Fragment>
        <Input
          type="select"
          name={name}
          id="exampleSelectMulti"
          onChange={this.handleChange}
          multiple={multiple}
        >
          {this.createMultipleOptionsFromArray()}
        </Input>
      </Fragment>
    )
  }
}

export default withTranslation()(PhishingCustomSelect);
