import { doGet, doPost } from "./BaseAPI";

export function makePayment(payment) {
  return doPost(`/organization/payment/update`, payment);
}

export function getPaymentsReport(page, status) {
  return doGet(`/organization/payment/report/${page}/${status}`);
}

export function getPaymentsDomain(domain = null) {
  if (!domain) {
    return doGet(`/organization/payment/domain`); // organization
  }
  return doGet(`/organization/payment/domain/${domain}`); // admin
}

export function getPayment(email = null) {
  if (!email) {
    return doGet(`/organization/payment/email`); // Current authenticated email
  }
  return doGet(`/organization/payment/email/${email}`);
}
