import React, { Fragment } from "react";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import ReactTable from "react-table";
import { withTranslation } from "react-i18next";

import PageTitle from '../../Layout/Components/PageTitle';
import ConfirmAction from '../../Layout/Components/ConfirmAction';
import PolicyModal from "./PolicyModal";
import { deletePolicy, getPolicies } from "API/DlpAPI";
import { toast } from "react-toastify";

class Policies extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      policies: [],
      openEdit: false,
      policy: {},
    };

    this.fillTable = this.fillTable.bind(this);
    this.deletePolicy = this.deletePolicy.bind(this);
    this.addPolicyCallback = this.addPolicyCallback.bind(this);
  }

  componentDidMount() {
    this.fillTable();
  }

  fillTable() {
    getPolicies().then(result => {
      this.setState({
        policies: result
      });
    }).catch(error => {
      console.error("Error getting Policies", error);
      toast["error"](this.props.t('errors.loading', { element: 'Policies', message: error.message }));
    });
  }

  deletePolicy(policy) {
    deletePolicy(policy.id).then(result => {
      this.fillTable();
    }).catch(error => {
      console.error("Error deleting Policy", error);
      toast["error"](this.props.t('errors.deleting', { element: 'Policy', message: error.message }));
    });
  }

  addPolicyCallback() {
    this.fillTable();
  }

  getScope(policy) {
    if (policy.scopeuser && policy.scopeuser.length > 0) {
      return this.props.t('dlp.policies.account');
    }
    if (policy.scopedepartment && policy.scopedepartment.length > 0) {
      return this.props.t('dlp.policies.department');
    }
    if (policy.scopeorganization) {
      return this.props.t('dlp.policies.organization');
    }
    return "";
  }

  render() {
    const { t, i18n } = this.props;

    const editPolicyModal = this.state.openEdit &&
      <PolicyModal showOpenButton={false} open={true} policy={this.state.policy}
        onClose={() => {
          this.setState({ openEdit: false, policy: {} });
          this.addPolicyCallback();
        }} />;

    return (
      <Fragment>
        <PageTitle
          heading={t("dlp.policies.title")}
          subheading={t("dlp.policies.title-description")}
          icon="pe-7s-lock icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <PolicyModal onClose={() => this.addPolicyCallback()} />
                  {editPolicyModal}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    columns={[
                      {
                        Header: t("dlp.policies.description"),
                        accessor: 'description',
                      },
                      {
                        Header: t("dlp.policies.expression"),
                        accessor: 'expression'
                      },
                      {
                        Header: t("dlp.policies.scope"),
                        accessor: 'scope',
                        Cell: (prop) => (
                          <div className="d-block w-100 text-center">
                            {this.getScope(prop.original)}
                          </div>
                        )
                      },
                      {
                        Header: t("dlp.policies.action"),
                        accessor: 'action'
                      },
                      {
                        Header: t("dlp.policies.actions.title"),
                        id: 'actions',
                        accessor: 'expression',
                        width: 300,
                        Cell: (prop) => (
                          <div className="d-block w-100 text-center">
                            <ConfirmAction btnClassName="mb-2 mr-2" color="danger" title={t('actions.confirmation')}
                              message={t('messages.confirm_delete', { element: 'policy', object: prop.value })}
                              onConfirm={() => this.deletePolicy(prop.original)}>{t("dlp.policies.actions.delete")}</ConfirmAction>
                          </div>
                        )
                      }
                    ]}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: (e) => {
                            if (e.target.tagName.toLowerCase() === 'button') {
                              return; // Ignore clicks on buttons
                            }
                            this.setState({
                              openEdit: true,
                              policy: rowInfo.original
                            });
                          },
                          style: {
                            cursor: 'pointer'
                          }
                        }
                      }
                      return {}
                    }}
                    data={this.state.policies}
                    defaultPageSize={10}
                    className="-striped -highlight" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withTranslation()(Policies);
